import $, { extend } from 'jquery';
import React from 'react';
import { UserToken, getUserObjData } from '../function_lib/userToken';
import { CallMapMethod,CallMapMethod_CBack, AjaxCallMethod,FormatDate,GetDateDifference } from '../function_lib/common_lib';
import {StateIndia} from '../function_lib/city_india';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Library_Opt_Modules from '../user_pages/library_opt_modules';
import Set_Pre_Date_Library from '../components/set_pre_date_library';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';
import imgNewMsg from '../assets/images/new_msg.png';


let loginUser = "";
let userObject = "";
let companyName = "";
let clsObject;

let stateList=[];
let appliState=[];
let nonAppliState=[];

class Pre_Minimum_Wages extends React.Component {
  
forceUpdateHandler(){
  this.forceUpdate();
};
  constructor(props) {
    super(props);
    this.state={stListPreDate:[],stListPreDataServer:[]};
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    if (localStorage.getItem("userData") != null) {
      userObject = getUserObjData();

      //alert(JSON.stringify(userObject));
      loginUser = userObject[0].first_name;
      companyName = userObject[0].first_name;

    }
    stateList=StateIndia();

  }
  getPreLibraryList(){
    var libType=[];
    libType.push({name:"curl_type",value:"getPreDataLibrary"});
    libType.push({name:"res_func",value:"callBackLibraryList"});
    libType.push({name:"type_id",value:"70"});
    libType["res_func"]="callBackLibraryList";
    libType["curl_type"]="getPreDataLibrary";
    CallMapMethod_CBack(this,libType,function(data,msg){
      //alert(data);
      if(data!=="0"){
        clsObject.setState({stListPreDataServer:JSON.parse(data)});
        clsObject.forceUpdateHandler();
      }
    });
  }

  getAppliandNonAppliState(){
    for(let s=0;s<stateList.length;s++){
        if(stateList[s]["applicability"].indexOf("lwf")!==-1){
            appliState.push(stateList[s]);
        }
    }
  }

  callBackNg(data, msg) {
    //  alert(data);
    if (data.trim() !== "" && data.trim() !== "0") {
      var listItems = JSON.parse(data);
      var str = "";
      var countEmp = 0;

      for (var i = 0; i < listItems.length; i++) {
        countEmp++;

        str += "<tr>" +
          "<td>" + (i + 1) + "</td>" +
          "<td>" + listItems[i]["p_details"] + "</td>" +
          "<td>" + listItems[i]["details"] + "</td>" +
          "<td>" + "" + "</td>" +
          "</tr>"
      }
      //$("#tbData").html(str);
      //$(".total_emp").html(countEmp);
    }
  }
  getCompanyMaster() {

  }
  onEditClickPreData(item){
    this.setState({stListPreDate:item});
  this.forceUpdateHandler();
  }
  callBackParent(data, msg) {
    // alert(data);
    var strParentId = "<option value=\"0\">" + "-Select-" + "</option>";
    if (data.trim() !== "" && data.trim() !== "0") {

      var listParent = JSON.parse(data);
      for (var p = 0; p < listParent.length; p++) {
        strParentId += "<option value=\"" + listParent[p]["id"] + "\">" + listParent[p]["details"] + "</option>";
      }
    }
    $("#parent_id").html(strParentId);
  }
  getCheckListParent() {
    var map = [];
    map.push({ name: "curl_type", value: "getCheckList" });
    map.push({ name: "res_func", value: "callBackParent" });
    map.push({ name: "local_token", value: userObject[0].app_user_id });
    map.push({ name: "parent_id", value: "0" });
    map.push({ name: "type_id", value: "10" });
    map["res_func"] = "callBackParent";
    map["curl_type"] = "getCheckList";
    CallMapMethod(this, map);
  }
  componentDidMount() {
    clsObject = this;
    // this.getCompanyMaster();
    // this.getCheckListParent();
    /*
     var empCategory=[];
     empCategory.push({name:"curl_type",value:"getEmployeeCategory"});
     empCategory.push({name:"res_func",value:"callBackEmpCate"});
     empCategory.push({name:"local_token",value:userObject[0].app_user_id});
     empCategory.push({name:"ref2_id",value:userObject[0].id});
     empCategory["res_func"]="callBackEmpCate";
     empCategory["curl_type"]="getEmployeeCategory";
     CallMapMethod(this,empCategory);
   */
  this.getPreLibraryList();
    AjaxCallMethod(this, "okSuccCNOT");
    $("#app_user_id").val(userObject[0].id);
  }
  callBackEmpCate(data, msg) {
    //alert(data);
    var listEmpCategor = JSON.parse(data);
    var strTmp = "<option value=\"0\">-select-</option>";
    for (var l = 0; l < listEmpCategor.length; l++) {
      strTmp += "<option value=\"" + listEmpCategor[l]["id"] + "\">" + listEmpCategor[l]["category_name"] + "</option>"
    }
    $("#party_mapping_id").html(strTmp);

  }
  okSucc(data, msg) {
    //alert(data+"===="+msg);
    if (data === "001") {

      $("#frmCreate").find("input[type=text]").val("");
      //$("#frmCreate").find()
      $(".respSSL").html(msg);
      clsObject.getCompanyMaster();
    } else {
      $(".respSSL").html(msg);
    }
  }
  render() {
    return (<div>
      <Header />
      <div className="content">
        <div className="container-fluid mt-4">
          <div className="row">
            <div className="col-md-2">

              {/* <!-- Widget: user widget style 2 --> */}
              <div className="card card-widget widget-user-2 shadow ">
                {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}


                {/* <!-- /.widget-user -->  */}
              </div>

              {/* <!-- Widget: user widget style 2 --> */}
              <div className="card card-widget widget-user-2 shadow ">
                {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}
                <Library_Opt_Modules />

                {/* <!-- /.widget-user -->  */}
              </div>
            </div>
            <div className="col-lg-10 col-md-10 col-sm-12 col-12">
              <section className="content">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">ACTA E-Library</h3>

                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                        <i class="fas fa-minus"></i></button>
                      <button type="button" class="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                        <i class="fas fa-times"></i></button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div class="post">
                          <div className="user-block">

                            <span className="username1">
                              <a href="javascript:void(0)"><b>Hi ! {companyName},</b></a>
                            </span>

                          </div>

                          <p>
                            Welcome to ACTA,Our Automated Compliance Tracking Application.You are currently using E-Library
                          </p>
                          {/* applicable list */}
                          <div class="card">
                            <div class="card-header">
                              <h3 class="card-title">
                                <i class="fa fa-long-arrow-right"></i>
                                Minimum Wages State wise applicability List
                              </h3>
                            </div>

                            <div class="card-body">
                             <div className='row'>
                                
                                    {this.state.stListPreDataServer.map((item,index)=>{
                                      let checkDiff= GetDateDifference(item["effective_date"]);
                                      //alert(checkDiff);
                                      let strNewImg="";
                                      if(parseInt(checkDiff)<=30){
                                        strNewImg=<img src={imgNewMsg} width="50"  />;
                                      }
                                      let fmDate=FormatDate(item["effective_date"]);

                                            return (
                                            <div className='col-3 box-t-l-r'>
                                              {userObject[0].id==="VKeBS5Qp44FXKLwPLhb+ZA=="?<a href="javascript:void(0)" onClick={()=>this.onEditClickPreData(item)} class="float-right btn-tool" data-toggle="modal" data-target="#divSetPreDateLibrary"><i class="fa fa-pencil"></i></a>:''}
                                              <a href={"../minimum_wages/"+item["name"]}>{item["name"]}</a>
                                              <br></br>
                                              <a href={"../minimum_wages/"+item["name"]}>Effective Date:{fmDate}{strNewImg}</a>
                                              </div>
                                              )
                                    })}
                                
                             </div>
                            </div>

                          </div>

                          {/* thumbnail */}
                        
            
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </section>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Set_Pre_Date_Library partyInfoData={""} type_id={"70"} listPreDate={this.state.stListPreDate} />
    </div>)
  }
}
export default Pre_Minimum_Wages;