import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,RespBkMthdMapp,getSearchOutPut,CallMapMethod_CBack} from '../function_lib/common_lib';
import QueryString from 'query-string';
import Header from './header';
import Footer from './footer';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';


let loginUser="";
let userObject="";
let companyName="";
let listItems;
let clsObject;
let userType="";

let rf="0";
let queries;
class Entity_Module extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        this.state={ isAllow:false,stEntityMaster:[]};
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        userType="0";
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      if(props !=null){
        queries = QueryString.parse(this.props.location.search);
              if(queries.rf!=null){
                rf=queries.rf;
              }
      }
      }
      getEntityListMaster(){
        var libType=[];
        libType.push({name:"curl_type",value:"getEntity"});
        libType.push({name:"res_func",value:"callBackEntityList"});
        libType.push({name:"id",value:userObject[0].id});
        libType.push({name:"app_user_id",value:userObject[0].app_user_id});
        libType.push({name:"type_id",value:"4"});
        libType.push({name:"category_id",value:1});
        libType["res_func"]="callBackEntityList";
        libType["curl_type"]="getEntity";
        CallMapMethod_CBack(this,libType,function(data,msg){
          //alert(data);
          if(data!=="0"){
            let listD=JSON.parse(data);
            if(listD.length>0){
                clsObject.setState({stEntityMaster:listD})
           
            }
            
          }
          
        });
       }    
   

      
     
    
  
componentDidMount(){
  clsObject=this;
  this.getEntityListMaster();
  RespBkMthdMapp(clsObject,"_setSearchKeyUp","getSearch");
}
getSearch(data){
 // getSearchOutPut
 // alert(data);
 getSearchOutPut("desk_thumb","child_desk_thumb",data);
}


      render(){
          return(<div>
<Header/>
<div className="content">
  <div id="tmpRespt"></div>
<div className="container-fluid mt-4">
    {/* acta component */}
    <div class="row">
<div class="col-md-3 col-sm-6 col-12">
  <a href='../entity_module?rf=202114'>
  <div class="info-box">
    <span class="info-box-icon bg-info">2</span>
    <div class="info-box-content">
      <span class="info-box-text">Entities Non Compliant</span>
      <span class="info-box-number">Major</span>
    </div>
  </div>
  </a>
</div>

<div class="col-md-3 col-sm-6 col-12">
<a href='../entity_module?rf=202112'>
  <div class="info-box">
    <span class="info-box-icon bg-success">0</span>
    <div class="info-box-content">
      <span class="info-box-text">Entities Non Complaint</span>
      <span class="info-box-number">Moderate</span>
    </div>
  </div>
  </a>
</div>

<div class="col-md-3 col-sm-6 col-12">
<a href='../entity_module?rf=202112'>
  <div class="info-box">
    <span class="info-box-icon bg-warning">0</span>
    <div class="info-box-content">
      <span class="info-box-text">Entities Non Complaint</span>
      <span class="info-box-number">Minor</span>
    </div>
  </div>
  </a>
</div>

<div class="col-md-3 col-sm-6 col-12">
<a href='../entity_module?rf=202114'>
  <div class="info-box">
    <span class="info-box-icon bg-danger">2</span>
    <div class="info-box-content">
      <span class="info-box-text">Entities</span>
      <span class="info-box-number">Compliant</span>
    </div>
  </div>
  </a>
</div>

</div>
    {/* end of acta component */}
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
           <div className="row">
             <div className="col-lg-2 col-md-2 col-sm-4">
             <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Search</b></h3>
             </div>
   
    
    
   
    <div className="col-lg-5 col-md-5 col-sm-5">
    <div className="input-group">
                    <input autoComplete="off" type="text" id="searchTxt" name="searchTxt" className="form-control form-control-sm _setSearchKeyUp"   placeholder="Search" />
                    <div className="input-group-prepend">
                      <span className="input-group-text form-control-sm currency"><i className="fa fa-search" aria-hidden="true"></i></span>
                    </div>
                  </div>
     
    </div>
  </div>
              </div>
                <table style={{maxWidth:"100%"}} id="desk_thumb" class="dtgrid1 table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                  <tr class="border">
                        <th>S.No.</th>
                        <th>Creation Date</th>
                        <th>Entity Name</th>
                        <th>Contact Person</th>
                        <th>Contact Number</th>
                        <th>Compliance Score</th>
                        <th>Status</th>
                        <th>Action</th>
                 </tr>
                  </thead>
                  <tbody className='child_desk_thumb1' id="tbData">
                   {this.state.stEntityMaster.map((item,index)=>{
                    return( <tr className='child_desk_thumb'>
                    <td>{index+1}</td>
                    <td>{item["date"]}</td>
                    <td><a href="../UserDashboard?rf=uiu78jjt676">{item["company_name"]}</a></td>
                    <td>{item["person_name"]}</td>
                    <td>{item["contact_no"]}</td>
                    <td><span class="badge badge-danger">50%</span></td>
                    <td>Active</td>
                    <td><a href="../branches_list?rf=uiu78jjt676">View</a></td>
                    </tr>)
                   })} 
                    
                  </tbody>
                </table>
             
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>



          </div>)
      }
}
export default Entity_Module;