import $ from 'jquery';
//import 'jquery-ui';
import React,{ useState } from 'react';
export class Cls_Common_Lib{
    constructor(){
        
 this.appUrl="https://actadata.indithinkk.com/authProcess.asmx/";
this.appUrtPath="https://actadata.indithinkk.com/";
    }
     AjaxCallMethod(obCls,cBack){
        $("._brFileThumb").unbind('click');
        $(".updImgThumb").unbind('change');
      
        $("._brFileThumb").click(function(){
          var getNm=$(this).attr("setTo");
          document.getElementById(getNm).click();
        });
        $(".updImgThumb").change(function(){
          var currentObj=$(this);
          var refBack=$(this).attr("ref_img");
          var refFlName=this.value.split('\\').pop();
          //alert();
          //alert(refBack);
          if (this.files && this.files[0]) {
            //alert("i am read url");
              var reader = new FileReader();
              reader.onload = function (e) {
                //var refBack=$(this).attr("ref_img");
                //alert(refBack);
                //alert(e.target.name);
                return obCls[refBack](e.target.result,refFlName,currentObj);
                //alert($(input).attr("ref_img"));
                 // $('.'+$(input).attr("ref_img")).attr('src', e.target.result);
              }
       
              reader.readAsDataURL(this.files[0]);
          }
        });
      
        $('._brFile').click(function(){
           var getNm=$(this).attr("setTo");
           
           document.getElementById(getNm).click();
           });
        $(".updImg").change(function(){
           //readURL(this);
           //alert("A file has been selected.");
       });
       $(".select_target").change(function () {
        var getCallFunc= $(this).attr("resp_dest");
        var refId=$(this).val();
        return obCls[getCallFunc](refId);
       });
       
      
         $('._btnSetColor').click(function () {
              //var m_type=$("#m_type").val();
          //var curl_type=$("#curl_type").val();
        //$("#modal_login").serialize();
        //ajaxindicatorstart("Loading Please Wait..");
        var ssFrm=$(this).closest('form');
        var m_type= $(ssFrm).find('input[name="m_type"]').val();
        var curl_type=$(ssFrm).find('input[name="m_type"]').val();
        var getCallFunc=$(ssFrm).find('input[name="res_func"]').val();
         var ss = $(this).closest('form').serializeArray();
         
         var xs = "{'objs':" + JSON.stringify(ss) + "}";
         //alert(xs);
        if(!getCallFunc){
      getCallFunc="";
      
         }
         //alert(xs);
        
         //ajaxindicatorstart('loading data.. please wait..');
      //alert(getCallFunc);
        //var ss=$("#"+fName).serialize();
              $.ajax({
                 crossDomain: true,
                     type: "POST",
                  url: this.appUrl + curl_type,
                  data: xs,
                  contentType: "application/json;charset=utf-8",
                  dataType: "json",
                  success: function (data) {
                   // ajaxindicatorstop();
                     //alert(data.d);
                      //ajaxindicatorstop();
                         //alert("Registration Completed Successfully ");
                         // document.getElementById("frmRegister").reset();
                         //location.reload();
                          if(getCallFunc!="")
                          {
                             var checkData=$($($.parseHTML(data.d)).filter("#resP")).text();
                             var checkMsg=$($($.parseHTML(data.d)).filter("#resPS")).text();
                       //  eval(getCallFunc);
                       //alert(checkData);
                             return obCls[getCallFunc](checkData,checkMsg);
                          }else
                          {
                            //parent.$.colorbox.close();
                         }
                     }
                 });
      
      });
      }
}