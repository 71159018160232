import $ from 'jquery';
import React from 'react';
import {GetImgCertUser_CBack} from '../function_lib/common_lib';

export function editProductEvent(){
    GetImgCertUser_CBack(this,"#frmEditProduct","editProductMast",function(checkData,checkMsg,userMsg){
      if(checkData===-10){
        alert("Kindly fill required fields");
        return;
      }else {
        //Custom_Alert("Successfully Added",checkMsg,false);
        alert(checkMsg);
        $('#divEditProduct').modal('hide');
        window.location.reload();
      }
    });
}             
  
const Edit_Product_Master=(props)=>{
    //alert(JSON.stringify(props));
        return(<div className="modal fade bd-example-modal-xl"  id="divEditProduct" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Edit Product</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            <form class="form-group" id="frmEditProduct" name="frmEditProduct" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="editProductMast" />
                            <input type="hidden" name="m_type" id="m_type" value="editProductMast" />
                            <input type="hidden" name="product_id" id="product_id" value={typeof props.editProduct.rf_id!=="undefined"?props.editProduct.rf_id:""} />
                            <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={props.app_user_id}/>
                          
                          
                            <div class="card-body">
                                <div className="row">
                                <div className="col-lg-8 col-md-8">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Product Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="txt_name" name="txt_name" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div>

                                  <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Product Code</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="txt_code" name="txt_code" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  
                                  <div className="col-md-3">
                                    <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-danger">Product Cost</button>
                  </div>
                 
                  <input id="pre_cost" name="pre_cost" type="text" maxlength={10} class="form-control required cd_input_change" />
                </div>
                                    </div>
                                    <div className="col-md-3">
                                    <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-secondary">Discount(%)</button>
                  </div>
                 
                  <input id="offer_per" name="offer_per" type="text" maxlength={10} class="form-control cd_input_change" />
                </div>
                                    </div>

                                    <div className="col-md-3">
                                    <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-danger">Final Cost</button>
                  </div>
                 
                  <input id="txt_cost" readOnly={true} name="txt_cost" type="text" maxlength={10} class="form-control required" />
                </div>
                                    </div>

                                    {/* <div className="col-md-3">
                                    <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-danger">Discount(%)</button>
                  </div>
                 
                  <input id="offer_per" name="offer_per" type="text" maxlength={10} class="form-control required" />
                </div>
                                    </div> */}
                                 

                                    <div className="col-md-3">
                                    <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-secondary">Product Weight</button>
                  </div>
                 
                  <input id="txt_weight" name="txt_weight" type="text" maxlength={10} class="form-control required" />
                </div>
                                    </div>

                            <div className="col-md-3">
                                    <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-secondary">Product Unit</button>
                  </div>
                 
                  <select id="cmbUnit" name="cmbUnit" className="form-control required">
                  <option>Select</option>
<option value="ML">ML</option>
<option value="GM">GM</option>
<option value="KG">KG</option>
<option value="NO">NO</option>
<option value="LTR">LTR</option>
                                                  
                                                </select>
                </div>
                                    </div>

                                    

                                    
                                </div>
                                <div class="row">
                                <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Tax Slab</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="tax_slab" name="tax_slab" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Package Length</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="p_length" name="p_length" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Package Width</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="p_width" name="p_width" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>                              
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Package Height</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="p_height" name="p_height" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div> 
    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Short Description *</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="txtProDes" name="txtProDes"></textarea>
                                            </div>
                                        </div>
                                    </div>
                              
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">OverView *</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="txtUsa" name="txtUsa"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Features *</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="txtCom" name="txtCom"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Specifications *</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="txtFAQ" name="txtFAQ"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    
                                     


                                </div>
                                 
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                                <div class="row">
                                    <div class="col-12">
                                      
                                        <div class="form-group">
                                        <center>
                                            <div class="input-group input-group-sm" style={{"justify-content":"center"}}>
                                                <button type="button" onClick={()=>editProductEvent()} class="btn btn-primary">Save Edit</button>
                                            </div>
                                        </center>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>
                            </form>
          </div>
          </div>
          </div>
          </div>);
    
}
export default Edit_Product_Master;
