import $, { extend } from 'jquery';
import React from 'react';
class Option_Module extends React.Component{
    componentDidMount()
    {
        let strMonth="<option value=\"0\">-Month-</option>";
        for(var i=1;i<=12;i++){
            strMonth+="<option value=\""+i+"\">"+i+"</option>";
        }
        $("#cmb_month_pay").html(strMonth);

        let strYear="<option value=\"0\">-Year-</option>";
        for(var i=2022;i>=2012;i--){
            strYear+="<option value=\""+i+"\">"+i+"</option>";
        }
        $("#cmb_year_pay").html(strYear);
    }
    render(){
        return(<div>
             <div className="card-footer p-0">
            <ul className="nav flex-column">
              <li className="nav-item"><h6> <a href="../branches_list?rf=uiu78jjt676" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i>Branches</a></h6> </li>
              <li className="nav-item"><h6> <a href="../compliance_list" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i> Compliance List </a></h6> </li>
              <li className="nav-item"><h6> <a href="../registration_licenses" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i> Registration & Licenses </a></h6> </li>
              <li className="nav-item"><h6> <a href="javascript:void(0)" data-toggle="modal" data-target="#divUploadPayroll" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i> Upload Payroll </a></h6> </li>
              <li className="nav-item"><h6> <a href="../notices" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i> Notice </a></h6> </li>
              <li className="nav-item"><h6> <a href="javascript:void(0)" data-toggle="modal" data-target="#divUploadSingature" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i> Upload Signature </a></h6> </li>
              <li className="nav-item"><h6> <a href="../abstract_notices"  className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i> Abstract & Notices </a></h6> </li>
            </ul>
          </div>
        </div>)
    }
}
export default Option_Module;