import $ from 'jquery';
import React from 'react';
import {GetImgCert_CBack} from '../function_lib/common_lib';
let listVendorDisplay={ tt: [] };
let vBranchList=[];
class Comp_Add_Vendor extends React.Component{
    constructor(props){
        super(props);
    }
    setVendrorData(obj){
        //alert(obj.selectedIndex);
        $("#frmCreateVendor :input, select, hidden").each(function(){
            let attId=$(this).attr('id');
            if(attId==="col1"){
                $(this).val(obj.options[obj.selectedIndex].text);
                //break;
            }
        })
        
    }
    setClientData(valId){
        for(let b=0;b<vBranchList.length;b++){
            if(vBranchList[b]["party_info_id"]===valId){
                
        $("#frmCreateVendor :input, select, hidden").each(function(){
            let attId=$(this).attr('id');
            if(attId==="state_id"){
                //alert("i m in condition "+vBranchList[b]["state_id"]);
                $(this).val(vBranchList[b]["state_id"]);
            }else if(attId==="col2"){
                $(this).val(vBranchList[b]["location"]);
            }else if(attId==="col3"){
                $(this).val(vBranchList[b]["address"]);
            }
         })
        }
        }
    }
    addVendorToData(){
        GetImgCert_CBack(this,"#frmCreateVendor","addClientVendorMgmt",function(data,msg){
            if(data==="001"){
                $("#frmCreateVendor").find("input[type=text]").val("");
                $("#frmCreateVendor").find("input[type=number]").val("");
                $("#frmCreateVendor").find("input[type=password]").val("");
                $("#getReponse_add_vendor").html("Vendor Added Successfully");
            }else{
                alert("Unable to add Record ,Kindly check record");
            }

        });
    }
    addToList(){
         //addToList is replaced by addVendorToData;
        //let clsAddBranch=new listData("sdfsdf","sdfsdf");
       
        const array = $("#frmCreateVendor").serializeArray(); // Encodes the set of form elements as an array of names and values.
        const json = {};
        $.each(array, function () {
          json[this.name] = this.value || "";
        });
        //return json;
        listVendorDisplay.tt.push(json);
        //alert(JSON.stringify(listBranchDisplay));
        let strList="";
        for(let i=0;i<listVendorDisplay.tt.length;i++){
           strList+="<tr>"+
                    "<td>"+listVendorDisplay.tt[i]["col1"]+"</td>"+
                    "<td>"+listVendorDisplay.tt[i]["col3"]+"</td>"+
                    "<td>"+listVendorDisplay.tt[i]["col4"]+"</td>"+
                    "<td>"+listVendorDisplay.tt[i]["col14"]+"-"+listVendorDisplay.tt[i]["col15"]+"</td>"+
                    "<td>"+listVendorDisplay.tt[i]["col8"]+"</td>"+
                    "<td>"+listVendorDisplay.tt[i]["col9"]+"/"+listVendorDisplay.tt[i]["col10"]+"</td>";
        }
        $("#list_vendors").html(strList);
        $("#entity_vendors").val(JSON.stringify(listVendorDisplay));
        $("#frmCreateVendor").find("input[type=text]").val("");
        alert("Branch Added Successfully");
    }
    componentWillReceiveProps(props){
        this.props=props;
        if(typeof this.props.partyInfoData.id!=="undefined"){
            //alert("i m in props");
            this.ac_getBranches(this.props.partyInfoData.id);
        }
    }

    ac_getBranches(vEntityId){
        
        this.props.ViewEntity_getBranches(vEntityId,function(data,msg){
            let listData=JSON.parse(data);
            vBranchList=listData;
            let strData="<option value=\"0\">-Select Branch-</option>"
            for(let i=0;i<listData.length;i++){
                strData+="<option value=\""+listData[i]["party_info_id"]+"\">"+listData[i]["company_name"]+"</option>"
            }
            $("#branch_id").html(strData);
        });
    }    
    
render(){
    return(<div className="modal fade bd-example-modal-xl"  id="divUpdVendor" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Add Vendor {typeof this.props.partyInfoData.company_name!=="undefined"?": for "+this.props.partyInfoData.company_name:''}</h5>
          <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmCreateVendor" name="frmCreateVendor">
       
       
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  <div className="col-md-12">
                      <div className="card card-danger">
                              <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                              <input type="hidden" name="curl_type" id="curl_type" value="createLogMaster" />
                              <input type="hidden" name="m_type" id="m_type" value="createLogMaster" />
                              <input type="hidden" name="type_id" id="type_id" value="3" />
                              <input type="hidden" name="app_user_id" id="app_user_id" value="" />
                              <input type="hidden" name="entity_id" id="entity_id" value={this.props.partyInfoData.id}/>
                              <input type="hidden" name="state_id" id="state_id" value={"0"}/>
                              <div className="card-body">
                                <div className="row">
                                    
                                <div className='col-lg-3 col-md-6'>
                                    <div className="form-group">
                                              <label for="exampleInputEmail1">Select Branch of Entity</label>
                                              <div className="input-group input-group-sm">
                                              <select onChange={(event)=>this.setClientData(event.target.value)} id="branch_id" name="branch_id" className="form-control">
                                              </select>
                                              </div>
                                          </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6'>
                                    <div className="form-group">
                                              <label for="exampleInputEmail1">Select Vendor from List</label>
                                              <div className="input-group input-group-sm">
                                              <select onChange={(event)=>this.setVendrorData(event.target)} id="_comp_add_vendor_child_entity_id" name="_comp_add_vendor_child_entity_id" className="form-control">
                                              <option value="0">Select Client</option>
                            {this.props.entityList.length>0?this.props.entityList.map((item,index)=>{
                                if(item.id!==this.props.partyInfoData.id){
                                    return (<option value={item.id}>{item.company_name}</option>)
                                }
                                
                            }):''}
                            
                          </select>
                                              </div>
                                          </div>
                                    </div>
                                </div>
                                  <div className="row">
                                 
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Vendor Name</label>
                                              <div className="input-group input-group-sm">
                                                  <input readOnly type="text" id="col1" name="col1" className="form-control" placeholder="Vendor Name" />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Location</label>
                                              <div className="input-group input-group-sm">
                                                  <input readOnly type="text" id="col2" name="col2" className="form-control" placeholder="Location" />
                                              </div>
                                          </div>
                                      </div>
                                      
                                     
                                      <div className="col-lg-6 col-md-12">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Address</label>
                                              <div className="input-group input-group-sm">
                                                  <input readOnly type="text" id="col3" name="col3" className="form-control" placeholder="Address" />
                                              </div>
                                          </div>
                                      </div>
                                    
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Nature of Service</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" id="col4" name="col4" className="form-control" placeholder="Nature of Service" />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="row">
                                <table class="table table-bordered table-sm">
                                <thead>
                                    <tr style={{backgroundColor:"#1a857a",color:"#fff"}}>
                                        <td>
                                            Men Power Deployed
                                        </td>
                                        <td>
                                            Male
                                        </td>
                                        <td>
                                            Female
                                        </td>
                                        <td>
                                            Transgender
                                        </td>
                                        <td>
                                            Total
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>
                                            Men Power Deployed
                                        </td>
                                        <td>
                                        <input type="number" id="col5" name="col5" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="number" id="col6" name="col6" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="number" id="col7" name="col7" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="number" id="col8" name="col8" className='form-control form-control-sm'/>
                                        </td>
                                    </tr>
                                    
                                    </thead>
                                </table>
                                </div>
                               
                               
                                <div className='row'>
                                <table class="table table-bordered table-sm">
                                <thead>
                                    <tr style={{backgroundColor:"#1a857a",color:"#fff"}}>
                                        <td>
                                            Vendor Contact Person
                                        </td>
                                        <td>
                                            Mobile No.
                                        </td>
                                        <td>
                                            Email
                                        </td>
                                        <td>
                                            Residential Address
                                        </td>
                                        <td>
                                            Aadhar
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>
                                    <input type="text" id="col9" name="col9" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="number" id="col10" name="col10" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="text" id="col11" name="col11" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="text" id="col12" name="col12" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="number" id="col13" name="col13" className='form-control form-control-sm'/>
                                        </td>
                                    </tr>
                                    
                                    </thead>
                                </table>

                                </div>
                             
                                <div className='row'>
                                <table class="table table-bordered table-sm">
                                <thead>
                                    <tr style={{backgroundColor:"#1a857a",color:"#fff"}}>
                                        <td>
                                            Contract Period
                                        </td>
                                        <td>
                                            From
                                        </td>
                                        <td>
                                            To
                                        </td>
                                        
                                        {/* <td>
                                            Add
                                        </td> */}
                                    </tr>
                                    <tr>
                                    <td>
                                   
                                        </td>
                                        <td>
                                        <input type="text" id="col14" name="col14" className='form-control form-control-sm' placeholder="Date" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask/>
                                        </td>
                                        <td>
                                        <input type="text" id="col15" name="col15" className='form-control form-control-sm' placeholder="Date" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask/>
                                        </td>
                                        <td>
                                        {/* <input/> */}
                                        </td>
                                      
                                    </tr>
                                    
                                    </thead>
                                </table>

                                </div>
                               
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                              </div>
                          
                      </div>
                  </div>
              </div>
        </div>
        <div className="modal-footer">
        <div id="getReponse_add_vendor" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" onClick={()=>this.addVendorToData()} className="btn btn-primary">Save changes</button>
        </div>
        </form>
      </div>
      </div>
      </div>)
}
}
export default Comp_Add_Vendor;