import { render } from '@testing-library/react';
import $ from 'jquery';
import React from 'react';
// import onSetState from './createThread';
let we;
let initWin;
class TestWhat extends React.Component{
    stopInstance(){
        //we.terminate();
        initWin.location.href="https://web.whatsapp.com/send?phone=918109448061";
        var myInit=initWin;
        myInit.onload = function() {
            alert(myInit.document.documentElement.outerHTML);
            //setTimeout(function(){ console.log(popup.document.documentElement.outerHTML) }, 2000);
        }  
      //var getData=  myInit.document.body.innerHtml; 
      //alert(getData);
    }
    setInstance(){
        alert("i am in load");
        initWin=window.open("https://web.whatsapp.com/");

        //we=new Worker("./createThread.js");
        //we.setEvetns=function(e){
         //   alert("i m tesitn");
          //  initWin.location.href="https://web.whatsapp.com/send?phone=918109448061";
    //alert("set to main function done ");
        
        //we.setEvetns(initWin);
        //we.onSetState("testing");
         /*
    let nums1 = ["917089065195","916261038929","919993023457","918109448061","917580800800"];
    window.open("https://web.whatsapp.com/")*/
  
      
     //we.onSetData();
     
      //  we.postMessage("test");
      /*
        we.onmessage = function(e) {
            console.log("testing of new");
            //initWin.location.href="https://web.whatsapp.com/send?phone=918109448061";
            //result.textContent = e.data;
            //console.log('Message received from worker');
          }
          */
          

    }
    render(){
        return(<div>
            <input type="button" id="test_nm" name="test_nm" value="Start" onClick={()=>this.setInstance()} /><br>
            </br>
            <input type="button" id="test_stop" name="test_stop" value="Stope" onClick={()=>this.stopInstance()} />
        </div>)
    }
}
   
export default TestWhat;