import React from 'react';
import {getAppUrl} from '../function_lib/common_lib';
export const Comp_Ptr_Invoice=(props)=>{
    let ptrAppUrl=getAppUrl();
    
    ptrAppUrl+="ptrInvoiceCustomer&ref="+props.ref_invoiceId;
return (<div className="modal fade bd-example-modal-xl"  id="divPtrInvoice" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div className="modal-dialog modal-xl" role="document">
  <div className="modal-content">
  <div className="modal-header bg-primary">
      <h5 className="modal-title text-white" id="exampleModalLabel">Edit Product</h5>
      <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true" className="text-white">&times;</span>
      </button>
    </div>
    <div className="modal-body">
    <embed width="100%" height="500px" src={ptrAppUrl+"#toolbar=0&navpanes=0"} toolbar="0"></embed>  
  </div>
  </div>
  </div>
  </div>);
}
export default Comp_Ptr_Invoice;