import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,setEventsLib} from '../function_lib/common_lib';
import {CallCMSMapMethod_CBack} from '../function_lib/common_cms';
import Wa_Thrashold from '../components/wa_thrashold';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Option_Module from '../user_pages/option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';



let loginUser="";
let userObject="";
let companyName="";
let clsContactRequest;

let rf="0";

class CMS_Contact_Request extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
            userObject= getUserObjData();
            //alert(JSON.stringify(userObject));
            loginUser=userObject[0].first_name;
            companyName=userObject[0].first_name;
        }
        clsContactRequest=this;
        this.state={ listThrashold:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      }

      

      getContactRequest(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getCMSContactMsg"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getCMSContactMsg";
        CallCMSMapMethod_CBack(clsContactRequest,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                clsContactRequest.setState({listThrashold:listData},()=>{
                    clsContactRequest.forceUpdateHandler();
                    setEventsLib("editable","confirm",function(e_id,e_key,text){
                        clsContactRequest.editThrasholdData(e_id,e_key,text);
                    })
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                 
                  if(rf!=="0"){
                    clsContactRequest.getSearch(rf);
                    //alert("i m in setup "+rf);
                    $("#serLoanStatus").val(rf);
                  }
                });
                
               
              }
        });
      }
      
      okSucc(data,msg){
        
        if(data==="001"){
          alert(msg);
          clsContactRequest.getThrasholdData();
        }
        
      }    
      
componentDidMount(){
   this.getContactRequest();

  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
              <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Contact Request</b></h3>
                
                {/* <a className="btn btn-sm btn-danger float-right" data-toggle="modal" data-target="#divWaThrashold" href="javascript:void(0)">Add New</a> */}
              </div>
                <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Person Name</th>
                      <th>Email Id</th>
                      <th>Contact No</th>
                      <th>Msg Description</th>
                      <th>Status</th>
                      <th>Date</th> 
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="tbData">
                  {(this.state.listThrashold.map((item,index)=>{
                       return(<tr>
                        <td>{index+1}</td>
                    <td>{item._name}</td>
                    <td>{item.email}</td>
                    <td>{item.contact_no}</td>
                    <td>{item.message}</td>
                  <td>{item.status}</td>
                  <td>{item.date}</td>
                  <td></td>
                    </tr>)
                  }))}
                  </tbody>
                </table>
           
              
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>
<Wa_Thrashold user_object={userObject}/>
          </div>)
      }
}
export default CMS_Contact_Request;