import $, { extend } from 'jquery';
import React from 'react';
import QueryString from 'query-string';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,RespBkMthdMapp,getSearchOutPut,CallMapMethod_CBack,ajaxindicatorstart,ajaxindicatorstop} from '../function_lib/common_lib';
import {ComplianceList} from '../function_lib/compliance_list';
import Comp_Submit_Compliance from '../components/comp_submit_compliance';
import Header from './header';
import Footer from './footer';
import Option_Module from './option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';


let loginUser="";
let userObject="";
let companyName="";
let clsObject;
let listCompliance=[];
let rf="0";
let rf_e="0";
let queries;

let domDeskThumb;

let countDue=0;
let countPending=0;
let countApproved=0;
let countRejected=0;
let countAll=0;
let isInitTable=false;

/* for filter varaibls */ 
let filterOptList=[];
let filtVendors=[];
let filtVenBran=[];
let filtClient=[];
let filtCliBran=[];
let filtMonths=[];

/* Applicable Entity/Branches List */
let vListEntities=[];
let vListBranches=[];

let vComplianceFor="";
class Compliance_List extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };
    constructor(props){
        super(props);
        clsObject=this;
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        this.state={isAllow:false,stComplianceList:[],stCompData:[]};
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
         
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
          listCompliance=ComplianceList();
      }
      if(props !=null){
        queries = QueryString.parse(this.props.location.search);
        if(queries.rf!==null && typeof queries.rf!=="undefined"){
          rf=queries.rf;
        }
        if(queries.rf_e!==null && typeof queries.rf_e !=="undefined"){
          rf_e=queries.rf_e;
        }

        /*encrypted key + string have issue */
        rf=rf.replace(" ","+");
        rf_e=rf_e.replace(" ","+");

        vComplianceFor=this.props.match.params.name;
        //alert(vComplianceFor);
      }
      
       
    }
/* Search Parameters and Settings */
    getClientVendorBranchList(){
      var libType=[];
      libType.push({name:"curl_type",value:"getEntClientBranch_Month_VC"});
      libType.push({name:"res_func",value:"callBackEntityList"});
      libType.push({name:"id",value:userObject[0].id});
      libType.push({name:"app_user_id",value:userObject[0].app_user_id});
      libType.push({name:"type_id",value:"4"});
      libType.push({name:"category_id",value:1});
      libType["res_func"]="callBackEntityList";
      libType["curl_type"]="getEntClientBranch_Month_VC";
      //ajaxindicatorstart("Loading Data ..");
      CallMapMethod_CBack(this,libType,function(data,msg){
        //ajaxindicatorstop();
        //alert(msg);
        // return;
        // $("#tbData").html(msg);
        // return;
        if(data!=="0"){
          let listD=JSON.parse(data);
          filterOptList=listD.cvta;
          filtMonths=listD.audit_months;
          clsObject.setSearchVendorWithBranches();
          clsObject.setMonths();
          
        }
        
      });
     }
  setMonths(){
    let strMonths="<option value=\"0\">"+"-Months-"+"</option>";
    for(let i=0;i<filtMonths.length;i++){
      strMonths+="<option value=\""+filtMonths[i]["id"]+"\">"+filtMonths[i]["mth_year"]+"</option>";
    }
    $("#ser_month").html(strMonths);
  }   

   setSearchVendorWithBranches(){
    
    for(let f=0;f<filterOptList.length;f++){
        if(filterOptList[f]["vendor_enc_id"]!==userObject[0].app_user_id){
          filtVenBran.push(filterOptList[f]);
        }
        if(filterOptList[f]["client_enc_id"]!==userObject[0].app_user_id){
          filtCliBran.push(filterOptList[f]);
        }
    }
    
    let uniqVendors={};
    for(let v=0;v<filtVenBran.length;v++ ){
      
       if(!(filtVenBran[v]["vendor_enc_id"] in uniqVendors)){
        uniqVendors[filtVenBran[v]["vendor_enc_id"]]="1";
        filtVendors.push(filtVenBran[v]);
       }
    }

    let uniqClients={};
    for(let c=0;c<filtCliBran.length;c++){
      if(!(filtCliBran[c]["client_enc_id"] in uniqClients)){
        uniqClients[filtCliBran[c]["client_enc_id"]]="1";
        filtClient.push(filtCliBran[c]);
       }
    }

    clsObject.setSerClient();
    clsObject.setSerVendor();
   
  }

  onChgCliBranches(cName){
    let venVal=$("#"+cName).val();
    //alert(venVal);
    let strCliBran="<option value=\"0\">"+"-Client Branches-"+"</option>";
    for(let i=0;i<filtCliBran.length;i++){
       if(filtCliBran[i]["client_enc_id"]===venVal){
        strCliBran+="<option value=\""+filtCliBran[i]["branch_enc_id"]+"\">"+filtCliBran[i]["branch_name"]+"</option>";
       }
    }
    $("#ser_client_branches").html(strCliBran);
  }

  onChgVenBranches(cName){
    let venVal=$("#"+cName).val();

    let strVenBran="<option value=\"0\">"+"-Alloted Branch-"+"</option>";
    for(let i=0;i<filtVenBran.length;i++){
       if(filtVenBran[i]["vendor_enc_id"]===venVal){
        strVenBran+="<option value=\""+filtVenBran[i]["branch_enc_id"]+"\">"+filtVenBran[i]["branch_name"]+"</option>";
       }
    }
    $("#ser_vendors_branches").html(strVenBran);
  }

  setSerClient(){
    let serClient="<option values=\"0\">"+"--Clients--"+"</option>";
    for(let sc=0;sc<filtClient.length;sc++){
      serClient+="<option value=\""+filtClient[sc]["client_enc_id"]+"\" >"+filtClient[sc]["client"]+"</option>";
    }
    $("#ser_client").html(serClient);
  }
  setSerVendor(){
    let serVendor="<option values=\"0\">"+"--Vendors--"+"</option>";
    for(let sc=0;sc<filtVendors.length;sc++){
      serVendor+="<option value=\""+filtVendors[sc]["vendor_enc_id"]+"\" >"+filtVendors[sc]["vendor"]+"</option>";
    }
    $("#ser_vendors").html(serVendor);
  }   
/*End of Search Parameters and Settings */     
   InitDataTable(){
  //   alert(countAll);

   
     
     

$("#cDue").html(countDue);
$("#cPending").html(countPending);
$("#cApproved").html(countApproved);
$("#cRejected").html(countRejected);
$("#cAll").html(countAll);
isInitTable=true;
   }
     
    getComplianceList(){
      //alert("Check rf=="+rf);
      /* default parameters */
      let vClientBranchId=0;
      let vClientId=0;

      let vVendorBranchId=0;
      let vVendorId=0;
      let vSelfBranchId=0
      if(vComplianceFor==="client"){
        vClientBranchId=rf;
        vClientId=rf_e;
      }else if(vComplianceFor==="vendor"){
        vVendorBranchId=rf;
        vVendorId=rf_e;
      }else{
        vSelfBranchId=rf;
      }

      //alert("check self == "+rf+","+rf_e);
      
      var libType=[];
      libType.push({name:"curl_type",value:"getComplainceAuditList_New"});
      libType.push({name:"res_func",value:"callBackEntityList"});
      libType.push({name:"id",value:userObject[0].id});
      libType.push({name:"app_user_id",value:userObject[0].app_user_id});
      
      libType.push({name:"self_branch_id",value:vSelfBranchId});//in case self nonzero

      libType.push({name:"client_branch_id",value:vClientBranchId});
      libType.push({name:"client_id",value:vClientId});

      libType.push({name:"vendor_branch_id",value:vVendorBranchId});
      libType.push({name:"vendor_id",value:vVendorId});
    
      libType.push({name:"type_id",value:"4"});
      libType.push({name:"category_id",value:1});
      libType["res_func"]="callBackEntityList";
      libType["curl_type"]="getComplainceAuditList_New";
      CallMapMethod_CBack(this,libType,function(data,msg){
        //alert(msg);
        //$("#tbData").html(msg+data);
        //return;
        if(data!=="0"){
          let listD=JSON.parse(data);
          //alert(listD.length);
          if(listD.length>0){
              clsObject.setState({stComplianceList:listD},()=>{
              clsObject.InitDataTable();

              });
              //clsObject.forceUpdateHandler();
          }
          
        }
        
      });
     }
 
 setSearchStatus(status){
  $("#ser_status_comp").val(status);

  getSearchOutPut("desk_thumb","child_desk_thumb",status);
  //clsObject.getSerComplianceList();
 }    
 getSerComplianceList(){

  
 countDue=0;
 countPending=0;
 countApproved=0;
 countRejected=0;
 countAll=0;


  let serClientBranchId=0;
  let serVendorBranchId=0;
  
 if($("#ser_client_branches").val()!=="" && $("#ser_client_branches").val()!=="0" )
 {
  serClientBranchId=$("#ser_client_branches").val();
 }
 if($("#ser_vendors_branches").val()!=="" && $("#ser_vendors_branches").val()!=="0"){
  
  serVendorBranchId=$("#ser_vendors_branches").val();
 }

 if(serClientBranchId==="undefined" || typeof serClientBranchId==="undefined"){
  serClientBranchId=0;
 }
 if(serVendorBranchId==="undefined" || typeof serVendorBranchId==="undefined"){
  serVendorBranchId=0;
 }

 let serClientId=0;
 let serVendorId=0;

  serClientId=$("#ser_client").val();
  serVendorId=$("#ser_vendors").val();
  if(serClientId==="undefined" || typeof serClientId==="undefined"){
    serClientId=0;
  }
  if(serVendorId==="undefined" || typeof serVendorId==="undefined"){
    serVendorId=0;
  }

  let serSelfBranchId=0;

/* re_init varaible if rf and ref present */
  if(vComplianceFor==="client"){
    if(rf!=="0"){
      serClientBranchId=rf;
      serClientId=rf_e;
    }
    
  }else if(vComplianceFor==="vendor"){
    if(rf!=="0"){
      serVendorBranchId=rf;
      serVendorId=rf_e;
    }
    
  }else{
    if(rf!=="0"){
      serSelfBranchId=rf;
    }
    
  }


  //alert("client"+serClientBranchId);
//  alert("vendor "+serVendorBranchId);
 //return;
 
 let serMonthId=0;
 if($("#ser_month").val()!=="" && $("#ser_month").val()!=="0"){
  serMonthId=$("#ser_month").val();
 }

  var libType=[];
  //libType.push({name:"curl_type",value:"getComplainceAuditList"});
  libType.push({name:"curl_type",value:"getComplainceAuditList_New"});

  libType.push({name:"res_func",value:"callBackEntityList"});
  libType.push({name:"id",value:userObject[0].id});
  libType.push({name:"app_user_id",value:userObject[0].app_user_id});

  libType.push({name:"self_branch_id",value:serSelfBranchId});//in case self nonzero

  libType.push({name:"vendor_branch_id",value:serVendorBranchId});
  libType.push({name:"client_branch_id",value:serClientBranchId});
  
  
  libType.push({name:"client_id",value:serClientId});
  
  libType.push({name:"vendor_id",value:serVendorId});

  libType.push({name:"compliance_status",value:$("#ser_status_comp").val()});
  

  libType.push({name:"type_id",value:"4"});
  //alert(serMonthId);
  if(serMonthId!==0 && serMonthId!=="0"){
    libType.push({name:"month_id",value:serMonthId});
  }
  
  libType.push({name:"category_id",value:1});
  libType["res_func"]="callBackEntityList";
  libType["curl_type"]="getComplainceAuditList_New";
  CallMapMethod_CBack(this,libType,function(data,msg){
    
    //$("#tbData").html(msg+data);
    //return;
    if(data!=="0"){
      let listD=JSON.parse(data);
      //alert(listD.length);
      //if(listD.length>0)
      {
          clsObject.setState({stComplianceList:listD},()=>{
            clsObject.InitDataTable();
           
          })
          //clsObject.forceUpdateHandler();
      }
      
    }
    
  });
 }
 /* Set Compliance Master Data and Log */
 setComplianceFillingData(compData){
  this.setState({stCompData:compData},()=>{
    RespBkMthdMapp(clsObject,"custom-file-input","");
  });
  this.forceUpdateHandler();
 }    
 
componentDidMount(){
    
 //this.getCompanyMaster();
 //this.getCheckListParent();
 /*
  var empCategory=[];
  empCategory.push({name:"curl_type",value:"getEmployeeCategory"});
  empCategory.push({name:"res_func",value:"callBackEmpCate"});
  empCategory.push({name:"local_token",value:userObject[0].app_user_id});
  empCategory.push({name:"ref2_id",value:userObject[0].id});
  empCategory["res_func"]="callBackEmpCate";
  empCategory["curl_type"]="getEmployeeCategory";
  CallMapMethod(this,empCategory);
*/
this.getComplianceList();
this.getClientVendorBranchList();
  AjaxCallMethod(this,"okSuccCNOT");
  RespBkMthdMapp(clsObject,"_setSearchKeyUp","getSearch");
$("#app_user_id").val(userObject[0].app_user_id);
$("#ser_status_comp").val("Due");



}


//Edit Modules Functions
      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row">

      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="card">
    
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Compliances</b></h3>
              </div>
              <div className="row content12 mt-2 mb-2">
        
        {/* <div className="col-lg-2 col-md-2 col-sm-4">
        <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Search</b></h3>
        </div> */}

{(vComplianceFor==="client" && rf==="0")?<div className="col-lg-2 col-md-2 col-sm-4">
<select onChange={()=>this.onChgCliBranches("ser_client")} className="form-control form-control-sm" id="ser_client" name="ser_client">
           <option value="0">-Clients-</option>
         </select>
</div>:''}        
{(vComplianceFor==="client" && rf==="0")?<div className="col-lg-2 col-md-2 col-sm-4">
<select  className="form-control form-control-sm" id="ser_client_branches" name="ser_client_branches">
           <option value="0">-Client Branches-</option>
           
         </select>
</div>:''}
{(vComplianceFor==="vendor" && rf==="0")?<div className="col-lg-2 col-md-2 col-sm-4">
<select onChange={()=>this.onChgVenBranches("ser_vendors")} className="form-control form-control-sm" id="ser_vendors" name="ser_vendors">
           <option value="0">-Vendors-</option>
           
         </select>
</div>:''}
{(vComplianceFor==="vendor" && rf==="0")?<div className="col-lg-2 col-md-2 col-sm-4">
<select className="form-control form-control-sm" id="ser_vendors_branches" name="ser_vendors_branches">
           <option value="0">-Alloted Branch-</option>
        
         </select>
</div>:''}

<div className="col-lg-2 col-md-2 col-sm-4">
<select  className="form-control form-control-sm" id="ser_month" name="ser_month">
           <option value="0">-Month-</option>
          
         </select>
</div>
{/* <div className="col-lg-2 col-md-2 col-sm-4">
<select className="form-control form-control-sm" id="ser_status" name="ser_status">
           <option value="0">-States-</option>
           <option value="Approved">Due</option>
           <option value="Documents Pending">Documents Pending</option>
           <option value="Pending">Pending</option>
           <option value="Rejected">Rejected</option>
         </select>
</div> */}
<input type="hidden" name="ser_status_comp" id="ser_status_comp" />

{/* <div className='col-lg-2 col-md-2 col-sm-4'>
<div className="form-group">
         <label for="exampleInputEmail1">From</label>
         <div className="input-group">
               <div className="input-group-prepend">
                 <span className="input-group-text"><i className="fa fa-calendar"></i></span>
               </div>
               <input type="text" id="col78" name="col78" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
             </div>
         
         </div>

</div> */}
{/* <div className='col-lg-2 col-md-2 col-sm-4'>
<div className="form-group">
         <label for="exampleInputEmail1">To</label>
         <div className="input-group">
               <div className="input-group-prepend">
                 <span className="input-group-text"><i className="fa fa-calendar"></i></span>
               </div>
               <input type="text" id="col78" name="col78" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
             </div>
         
         </div>

</div> */}
<div className='col-lg-4 col-md-4 col-sm-4'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="col78" name="col78" className="_setSearchKeyUp form-control form-control-sm" placeholder='search' />
             </div>
         
         </div>

</div>

<div className="col-lg-2 col-md-2 col-sm-4 vcenter-item1">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div onClick={()=>this.getSerComplianceList()}  style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <div  className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>

</div>
<div className="col-lg-2 col-md-2 col-sm-4">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-4'></div>
               </div>
             </div>

</div>
</div>

<div class="card-header d-flex p-0">
                    <h3 class="card-title p-3"></h3>
                    <ul class="nav nav-pills_c1 ml-auto p-2">
                    <li class="nav-item"><a class="nav-link active ordStatus" href="#tab_3" onClick={()=>this.setSearchStatus("0")} ref_node="0" data-toggle="tab">All<span id="cAll" className='badge badge-warning navbar-badge1'></span></a></li>
                        <li class="nav-item"><a class="nav-link  ordStatus" href="#tab_1" onClick={()=>this.setSearchStatus("Due")} ref_node="Due" data-toggle="tab">Due<span id="cDue" className='badge badge-warning navbar-badge1'></span></a></li>
                        <li class="nav-item"><a class="nav-link ordStatus" href="#tab_2" onClick={()=>this.setSearchStatus("Pending Approval")} ref_node="Pending Approval" data-toggle="tab">Pending Approval<span id="cPending" className='badge badge-warning navbar-badge1'></span></a></li>
                        <li class="nav-item"><a class="nav-link ordStatus" href="#tab_3" onClick={()=>this.setSearchStatus("Approved")} ref_node="Approved" data-toggle="tab">Approved<span id="cApproved" className='badge badge-warning navbar-badge1'></span></a></li>
                        <li class="nav-item"><a class="nav-link ordStatus" href="#tab_3" onClick={()=>this.setSearchStatus("Rejected")} ref_node="Rejected" data-toggle="tab">Rejected<span id="cRejected" className='badge badge-warning navbar-badge1'></span></a></li>
                        
                    </ul>
                </div>

                <table style={{maxWidth:"100%"}} id="desk_thumb" class="dtgrid1 table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                        {/* <th>S.No.</th> */}
                      <th>Entity</th>
                      <th>Branch</th>  
                      <th>Due Date</th>
                      <th>Act Name</th>
                       <th>Rule/Form</th> 
                      <th>Type</th>
                      <th>Month</th>
                      <th>Compliance Status</th>
                      <th>Actual Submission Date</th>
                      <th></th>
                      
                    </tr>
                  </thead>
                  <tbody id="tbData">
                      {(this.state.stComplianceList.length>0?this.state.stComplianceList.map((item,index)=>{
                          countAll++;
                          if(item["compliance_status"]==="Due"){
                            countDue++;
                          }else if(item["compliance_status"]==="Pending Approval"){
                            countPending++;
                          }else if(item["compliance_status"]==="Approved"){
                            countApproved++;
                          }else if(item["compliance_status"]==="Rejected"){
                            countRejected++;
                          }



                            return(<tr className='child_desk_thumb'>
                                <td>{item["entity_name"]}</td>
                                <td>{item["branch_name"]}</td>
                                <td>{item["due_date"]}</td>
                                <td>{item["act_name"]}</td>
                                <td>{item["rule_form"]}</td>
                                <td>{item["compliance_type"]+" / "+item["compliance_category"]}</td>
                                <td>{item["audi_month"]?item["audi_month"]:($("#ser_month").val()!=="0"?$("#ser_month option:selected").text():'NN')}</td>
                                <td>{<a href="javascript:void(0)" className="nav-link1 hover"><span class="badge bg-danger dash_rejected_claim1">{item["compliance_status"]}</span></a>}</td>
                                <td></td>
                                <td><a data-toggle="modal" data-target="#divUploadDoc" onClick={()=>this.setComplianceFillingData(item)} href="javascript:void(0)" className="nav-link1 hover"><span class="badge bg-primary dash_rejected_claim1">Submit Compliance</span></a></td>
                                
                            </tr>)
                          
                         
                      }):''
                      )}
                  </tbody>
                </table>
             
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>
{/* Upload document if applicable */}
<Comp_Submit_Compliance compData={this.state.stCompData} compMonthNm={$("#ser_month option:selected").text()} compMonthVal={$("#ser_month").val()} />
    {/* end of upd */}
<div className="modal fade bd-example-modal-xl"  id="divEmployee" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Document Compliance112</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmUserCompMasterCreate" name="frmUserCompMasterCreate">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="createLogMaster" />
                            <input type="hidden" name="m_type" id="m_type" value="createLogMaster" />
                            <input type="hidden" name="type_id" id="type_id" value="2" />
                            <input type="hidden" name="app_user_id" id="app_user_id" value="" />
                            <div className="card-body">
                                <div className="row">
                                  <div className='col-lg-6 col-md-6'>
                                    <div className='form-group'>
                                    <label for="exampleInputEmail1">Select Entity</label>
                                    </div>
                                  </div>
                                  <div className='col-lg-6 col-md-6'>
                                    <div className='form-group'>
                                    <label for="exampleInputEmail1">Select Branch</label>
                                    </div>
                                  </div>
                               
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Act Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="act_name" name="act_name" className="form-control" placeholder="Act Name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Compliance Type</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="compliance_type" name="compliance_type" className="form-control" placeholder="Compliance Type" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Rule Form</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="rule_form" name="rule_form" className="form-control" placeholder="Rule Form" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                    <div className="form-group">
                                            <label for="exampleInputEmail1">Completion Date</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="completion_date" name="completion_date" className="form-control" placeholder="Completion Date" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                    <div className="form-group">
<label for="exampleInputFile">Upload Proof</label>
<div className="input-group">
  <div className="custom-file">
    <input type="file" className="custom-file-input" label="lbSubmitComp1" name="exampleInputFile" id="exampleInputFile" />
    <label id="lbSubmitComp1" className="custom-file-label" for="exampleInputFile">Choose file</label>
  </div>
  {/* <div className="input-group-append">
  <button type="button" className="btn btn-primary">Upload Now</button>
  </div> */}
</div>
</div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                            <label for="exampleInputEmail1">Remarks if Any</label>
                                            <div className="input-group input-group-sm">
                                                <textarea className='form_control' id="remarks_if" name="remarks_if"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor123 btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>
{/* view document */}
    <div className="modal fade bd-example-modal-xl"  id="divEmpViewDoc" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Document Compliance</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmC" name="frmC">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                    <embed width="100%" height="500px" src={"https://acta.indithinkk.com/acta_21.pdf"} toolbar="0"></embed>
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
      </form>
    </div>
    </div>
    </div>
          </div>)
      }
}
export default Compliance_List;