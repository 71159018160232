import $ from 'jquery';
import React from 'react';
import {getCheckRolesNew} from '../function_lib/common_lib';
import {btnSetColor_CBack} from '../function_lib/common_lib';

let strCheckRoles="";
class Comp_Edit_Entity extends React.Component{
    constructor(props){
        super(props);
        strCheckRoles=getCheckRolesNew();
    }
    componentWillReceiveProps(props){
        this.props=props;
        //alert(this.props.partyInfoData.id);
        //alert(JSON.stringify(props));
        if(typeof props.partyInfoId!=="undefined"){
            if(props.partyInfoId!=="" && props.partyInfoId!=="0"){
            //    this.setState({partyInfoId:props.partyInfoId});
            //    this.forceUpdateHandler();
            }
            
        }
    }
    editEntityData(frmName){
        btnSetColor_CBack(this,frmName,"editEntity",function(data,msg){
            //alert(data+msg);
            if(data==="001"){
                alert("Updated Successfully");
                
            }else{
                alert("Unable to Update Record !kindly check and try again");
            }
            
        });
     }

    componentDidMount(){
        $("#comp_access_roles").html(strCheckRoles);
        // document.addEventListener("keydown", (event) => {
        //      //event.preventDefault();
        // });
        $('#divCompEditEntity').on('hidden.bs.modal', function () {
            window.location.reload();
            // do something…
          });
    }
    render(){
        return(<div className="modal fade bd-example-modal-xl"  id="divCompEditEntity" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Edit Entity</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            {typeof this.props.partyInfoData.id!=="undefined"?
              <form method='POST' id="frmCompEditEntity" name="frmCompEditEntity">
           
              <div className="modal-body">
              <div className="row">
                        {/* <!-- left column --> */}
                        <div className="col-md-12">
                            <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                              <input type="hidden" name="curl_type" id="curl_type" value="editEntity" />
                              <input type="hidden" name="m_type" id="m_type" value="editEntity" />
                              <input type="hidden" id="entity_id" name="entity_id" defaultValue={this.props.partyInfoData.enc_id}/>
                              <div class="card-body">
                                  <div class="row">
                                  <div class="col-3">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">Name of Company*</label>
                                              <div class="input-group input-group-sm">
                                                  <input required type="text" id="company_name" name="company_name" class="form-control" placeholder="Company Name" defaultValue={this.props.partyInfoData.company_name} />
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">PAN *</label>
                                              <div class="input-group input-group-sm">
                                                  <input type="text" class="form-control" id="company_pan" name="company_pan" placeholder="PAN" defaultValue={this.props.partyInfoData.pan_no} />
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">GST *</label>
                                              <div class="input-group input-group-sm">
                                                  <input type="text" class="form-control" id="company_gst" name="company_gst" placeholder="GST" defaultValue={this.props.partyInfoData.gstin_no} />
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">CIN *</label>
                                              <div class="input-group input-group-sm">
                                                  <input type="text" class="form-control" id="company_cin" name="company_cin" placeholder="CIN" defaultValue={this.props.partyInfoData.cin_no} />
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">TAN *</label>
                                              <div class="input-group input-group-sm">
                                                  <input type="text" class="form-control" id="company_tan" name="company_tan" placeholder="TAN" defaultValue={this.props.partyInfoData.tan_no} />
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">Contact Person *</label>
                                              <div class="input-group input-group-sm">
                                                  <input type="text" class="form-control" id="company_contact_person" name="company_contact_person" placeholder="Contact Person" defaultValue={this.props.partyInfoData.person_name} />
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">Contact No *</label>
                                              <div class="input-group input-group-sm">
                                                  <input type="text" class="form-control" id="company_contact_no" name="company_contact_no" placeholder="Contact No" defaultValue={this.props.partyInfoData.contact_no} />
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">Email *</label>
                                              <div class="input-group input-group-sm">
                                                  <input type="text" class="form-control" id="company_email" name="company_email" placeholder="Email" defaultValue={this.props.partyInfoData.email} />
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">Website *</label>
                                              <div class="input-group input-group-sm">
                                                  <input type="text" class="form-control" id="company_website" name="company_website" placeholder="Website" defaultValue={this.props.partyInfoData.website} />
                                              </div>
                                          </div>
                                      </div>
                                  <div class="col-9">
                                  <div class="form-group">
                                              <label for="exampleInputEmail1">Address *</label>
                                              <div class="input-group input-group-sm">
                                                  <input type="text" class="form-control" id="company_address" name="company_address" placeholder="Full Address" defaultValue={this.props.partyInfoData.address} />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col-3">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">Remark :</label>
  
                                          </div>
                                      </div>
                                      <div class="col-9">
                                          <div class="form-group">
                                              <div class="card-body pad">
                                                  <div class="mb-3">
                                                      <textarea id="company_details" name="company_details" class="textarea" placeholder="Place some text here"
                                                                >{this.props.partyInfoData.details}</textarea>
                                                  </div>l
                                              </div>
                                          </div>
                                      </div>
  
                                  </div>
                                  <div className='row'>
                                      <div class="col-3">
                                      <div class="form-group">
                                              <label for="exampleInputEmail1">UserName *</label>
                                              <div class="input-group input-group-sm">
                                                  <input type="text" class="form-control" id="entity_user_name" name="entity_user_name" placeholder="User Name" defaultValue={this.props.partyInfoData.user_name} />
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-3">
                                      <div class="form-group">
                                              <label for="exampleInputEmail1">Password *</label>
                                              <div class="input-group input-group-sm">
                                                  <input type="password" class="form-control" id="entity_password" name="entity_password" placeholder="Password" defaultValue={this.props.partyInfoData.password} />
                                              </div>
                                          </div>
                                      </div>
  
                                  </div>
                                  </div>
                            </div>
                        </div>
                    </div>
              </div>
              <div className="modal-footer">
              <div id="getReponse_add_client" style={{color:"red"}}></div>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" onClick={()=>this.editEntityData("frmCompEditEntity")} className="btn btn-primary">Save changes</button>
              </div>
              </form>:''
            }
          
          </div>
          </div>
          </div>)
    }
}
export default Comp_Edit_Entity;