import $ from 'jquery';
import React from 'react';
import {GetImgCert_CBack,appGetUrlPath,CallMapMethod_CBack} from '../function_lib/common_lib';
import {getUserObjData} from '../function_lib/userToken';
import {Comp_Chat_Log} from '../components/comp_chat_log';

let compAppPath="";
let userObject;
let clsObject;
class Comp_Submit_Compliance extends React.Component{
    constructor(props){
        super(props);
        compAppPath=appGetUrlPath();
        this.state={stComplianceLog:[]};
        if(localStorage.getItem("userData")!=null){
            userObject= getUserObjData();
            //alert(JSON.stringify(userObject));
        }
        clsObject=this;
    }

    getComplianceLog(complianceId){
        let libType=[];
        libType.push({name:"curl_type",value:"getComplianceLog"});

  libType.push({name:"res_func",value:"callBackEntityList"});
  libType.push({name:"compliance_id",value:complianceId});
  libType["res_func"]="callBackEntityList";
  libType["curl_type"]="getComplianceLog";
  CallMapMethod_CBack(this,libType,function(data,msg){
    //alert("get logs =="+data+msg);
    if(data!=="0"){
        clsObject.setState({stComplianceList:JSON.parse(data)})
    }
  });
    }

    componentWillReceiveProps(props){
        this.props=props;
        if(typeof this.props.compData.act_name!=="undefined"){
            this.getComplianceLog(this.props.compData.encKey);
        }
        //alert("component=="+JSON.stringify(this.props.compData));
    }

    saveCompliance(){
        GetImgCert_CBack(this,"#frmSubComp","submitCompliance",function(data,msg){
            if(data==="001"){
                alert(msg);
            }else{
                alert(msg)
            }
            
        })
    }

    

    render(){
        return( <div className="modal fade bd-example-modal-xl"  id="divUploadDoc" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Upload Document Compliance</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            {typeof this.props.compData.act_name!=="undefined"?<div className="modal-body">
           <div className="row">
                     {/* <!-- left column --> */}
                     <div className="col-md-12">
                         <div className="card card-danger">
                                                               <div className="card-body">
                                     <div className="row">
                                     <div className="col-lg-8 col-md-8">
                                             <div className="form-group">
                                                 <label for="exampleInputEmail1">Act</label>
                                                 <div className="input-group input-group-sm">
                                                     {this.props.compData["act_name"]}
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="col-lg-4 col-md-4">
                                             <div className="form-group">
                                                 <label for="exampleInputEmail1">Compliance Status</label>
                                                 <div className="input-group input-group-sm">
                                                 {<a href="javascript:void(0)" className="nav-link1 hover"><span class="badge bg-danger dash_rejected_claim1">{this.props.compData["compliance_status"]}</span></a>}
                                                 </div>
                                             </div>
                                         </div> 
                                         <div className="col-lg-6 col-md-6">
                                             <div className="form-group">
                                                 <label for="exampleInputEmail1">Name</label>
                                                 <div className="input-group input-group-sm">
                                                     {this.props.compData["simplified_name"]}
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="col-lg-6 col-md-6">
                                             <div className="form-group">
                                                 <label for="exampleInputEmail1">Rule/Form</label>
                                                 <div className="input-group input-group-sm">
                                                     {this.props.compData["rule_form"]}
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="col-lg-2 col-md-2">
                                             <div className="form-group">
                                                 <label for="exampleInputEmail1">Compliance Type</label>
                                                 <div className="input-group input-group-sm">
                                                     {this.props.compData["compliance_type"]}
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="col-lg-2 col-md-2">
                                             <div className="form-group">
                                                 <label for="exampleInputEmail1">Applicability</label>
                                                 <div className="input-group input-group-sm">
                                                     {this.props.compData["applicability"]}
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="col-lg-2 col-md-2">
                                             <div className="form-group">
                                                 <label for="exampleInputEmail1">Fine</label>
                                                 <div className="input-group input-group-sm">
                                                     {this.props.compData["fine"]}
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="col-lg-2 col-md-2">
                                             <div className="form-group">
                                                 <label for="exampleInputEmail1">Periodicity</label>
                                                 <div className="input-group input-group-sm">
                                                     {this.props.compData["periodicity"]}
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="col-lg-2 col-md-2">
                                             <div className="form-group">
                                                 <label for="exampleInputEmail1">Responsibiltiy</label>
                                                 <div className="input-group input-group-sm">
                                                     {this.props.compData["responsibility"]}
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="col-lg-12 col-md-12">
                                             <div className="form-group">
                                                 <label for="exampleInputEmail1">Month</label>
                                                 <div className="input-group input-group-sm">
                                                   {this.props.compData["audi_month"]?this.props.compData["audi_month"]:this.props.compMonthNm}
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="col-lg-12 col-md-12">
                                             <div className="form-group">
                                                 <label for="exampleInputEmail1">Reason for Rejection</label>
                                                 <div className="input-group input-group-sm">
                                                     
                                                 </div>
                                             </div>
                                         </div>
                                   <div className="col-lg-12 col-md-12">
                                   <form id="frmSubComp" name="frmSubComp" enctype="multipart/form-data" method="POST" action="#">
                                    <div className='row'>
                                    
                                        <div style={{marginLeft:"auto"}} className=' col-md-6 login-wrap p-4'>
                                        
                                        <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                                 <input type="hidden" name="curl_type" id="curl_type" value="submitCompliance" />
                                 <input type="hidden" name="m_type" id="m_type" value="submitCompliance" />
                                 <input type="hidden" name="ref_id" id="ref_id" value={this.props.compData["encKey"]}/>
                                 <input type="hidden" name="app_user_id" id="app_user_id" value={userObject[0].app_user_id} />
     <div className="form-group">
                                                 <label for="exampleInputEmail1">Remarks if Any</label>
                                                 <div className="input-group input-group-sm">
                                                     <textarea className='form_control' cols="50" rows="10" id="descr" name="descr"></textarea>
                                                 </div>
                                             </div>
                                             <div className="form-group col-md-12">
     <label for="exampleInputFile">Upload Proof(.jpg,png,jpeg,pdf,doc,docx,zip allowed)</label>
     <div className="input-group">
       <div className="custom-file">
         <input type="file" label="lbSubmitComp2" className="custom-file-input" name="exampleInputFile" id="exampleInputFile" />
         <label id="lbSubmitComp2" className="custom-file-label" for="exampleInputFile">Choose file</label>
       </div>
       {/* <div className="input-group-append">
       <button type="button" className="btn btn-primary">Upload Now</button>
       </div> */}
     </div>
   
     </div>
     <div className='form-group col-md-12'>
                                                    <label>Update Status</label>
                                                
                                                <select id="comp_status" name="comp_status" className='form-control'>
                                                    <option value="">-Status-</option>
                                                    <option selected={this.props.compData["compliance_status"]==="Approved"} value="Approved">Approved</option>
                                                    <option selected={this.props.compData["compliance_status"]==="Pending Approval"} value="Pending Approval">Pending Approval</option>
                                                    <option selected={this.props.compData["compliance_status"]==="Rejected"} value="Rejected">Rejected</option>

                                                </select>
                                                </div>  
                                                <div className='d-flex p-2 text-center justify-content-center'>
                                    <div className='form-group text-center'>
     <div id="getReponse" style={{color:"red"}}></div>
             <button type="button" className="btn btn-secondary m-2" data-dismiss="modal">Close</button>
             <button type="button" onClick={()=>this.saveCompliance()} className="btn btn-primary">Save changes</button>
        </div> 
                                    </div>                   
                                        </div>
                                    
                                    <div style={{marginRight:"auto"}} className='col-md-6 login-wrap p-4'>
{/* <h5 className='mb-2'>Recent Log</h5> */}
<div className="form-group">
    <Comp_Chat_Log appUser={userObject[0].app_user_id} complianceLog={this.state.stComplianceList}/>
                                                 
                                                 {/* <div className="input-group input-group-sm">
                                                 {this.props.compData.descr}
                                                     
                                                 </div> */}
                                                 
                                                 {this.props.compData.upd_doc!==""?
                                                 <label for="exampleInputEmail1"><a target="_blank"  href={compAppPath+"compliance_dfile/"+this.props.compData.upd_doc} download={this.props.compData.upd_doc}><i class="fa fa-paperclip fa-1x"></i>View Recently Attached</a></label>
                                                 :''} 
                                                
                                             </div>



                                        </div>
                                    </div>
                                    </form>
                                    </div>     
                                         
                                        
                                         <div className="col-lg-12 col-md-12">
                                       
                                         </div>
                                         <div className="col-lg-6 col-md-6">
                                         
                                         </div>
                                         <div className="col-lg-12 col-md-12">
                                             <div className="form-group">
                                                 <label for="exampleInputEmail1">Declaration</label>
                                                 <div className="input-group input-group-sm">
                                                 Indithinkk Tech Pvt. Ltd. hereby declares that it has complied with all the provisions of laws as applicable on it and discharged all its liabilities under the said provisions. Indithinkk Tech Pvt. Ltd. shall not be liable or responsible for any cost, damage or liability that may arise for as a consequence of any non-compliance on the part of Indithinkk Tech Pvt. Ltd.. The documents uploaded and information provided are true and correct to the best of our knowledge. 
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                   
                                     <div className="col-md-6 respSSL"></div>
                                     <div id="testing2dd2222"></div>
                                 </div>
                             
                         </div>
                     </div>
                 </div>
           </div>:''}
         
          </div>
          </div>
          </div>)
    }
       
    
}
export default Comp_Submit_Compliance