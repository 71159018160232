import $, { extend } from 'jquery';
import React from 'react';
import { UserToken, getUserObjData } from '../function_lib/userToken';
import { CallMapMethod, AjaxCallMethod } from '../function_lib/common_lib';
import {StateIndia} from '../function_lib/city_india';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Library_Opt_Modules from '../user_pages/library_opt_modules';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';


let loginUser = "";
let userObject = "";
let companyName = "";
let clsObject;

let stateList=[];
let appliState=[];
let nonAppliState=[];

class Pre_Professional_Tax extends React.Component {
  constructor(props) {
    super(props);
    if (localStorage.getItem("userData") != null) {
      userObject = getUserObjData();

      //alert(JSON.stringify(userObject));
      loginUser = userObject[0].first_name;
      companyName = userObject[0].first_name;

    }
    stateList=StateIndia();

  }

  getAppliandNonAppliState(){
    for(let s=0;s<stateList.length;s++){
        if(stateList[s]["applicability"].indexOf("lwf")!==-1){
            appliState.push(stateList[s]);
        }
    }
  }

  callBackNg(data, msg) {
    //  alert(data);
    if (data.trim() !== "" && data.trim() !== "0") {
      var listItems = JSON.parse(data);
      var str = "";
      var countEmp = 0;

      for (var i = 0; i < listItems.length; i++) {
        countEmp++;

        str += "<tr>" +
          "<td>" + (i + 1) + "</td>" +
          "<td>" + listItems[i]["p_details"] + "</td>" +
          "<td>" + listItems[i]["details"] + "</td>" +
          "<td>" + "" + "</td>" +
          "</tr>"
      }
      //$("#tbData").html(str);
      //$(".total_emp").html(countEmp);
    }
  }
  getCompanyMaster() {

  }
  callBackParent(data, msg) {
    // alert(data);
    var strParentId = "<option value=\"0\">" + "-Select-" + "</option>";
    if (data.trim() !== "" && data.trim() !== "0") {

      var listParent = JSON.parse(data);
      for (var p = 0; p < listParent.length; p++) {
        strParentId += "<option value=\"" + listParent[p]["id"] + "\">" + listParent[p]["details"] + "</option>";
      }
    }
    $("#parent_id").html(strParentId);
  }
  getCheckListParent() {
    var map = [];
    map.push({ name: "curl_type", value: "getCheckList" });
    map.push({ name: "res_func", value: "callBackParent" });
    map.push({ name: "local_token", value: userObject[0].app_user_id });
    map.push({ name: "parent_id", value: "0" });
    map.push({ name: "type_id", value: "10" });
    map["res_func"] = "callBackParent";
    map["curl_type"] = "getCheckList";
    CallMapMethod(this, map);
  }
  componentDidMount() {
    clsObject = this;
    // this.getCompanyMaster();
    // this.getCheckListParent();
    /*
     var empCategory=[];
     empCategory.push({name:"curl_type",value:"getEmployeeCategory"});
     empCategory.push({name:"res_func",value:"callBackEmpCate"});
     empCategory.push({name:"local_token",value:userObject[0].app_user_id});
     empCategory.push({name:"ref2_id",value:userObject[0].id});
     empCategory["res_func"]="callBackEmpCate";
     empCategory["curl_type"]="getEmployeeCategory";
     CallMapMethod(this,empCategory);
   */
    AjaxCallMethod(this, "okSuccCNOT");
    $("#app_user_id").val(userObject[0].id);
  }
  callBackEmpCate(data, msg) {
    //alert(data);
    var listEmpCategor = JSON.parse(data);
    var strTmp = "<option value=\"0\">-select-</option>";
    for (var l = 0; l < listEmpCategor.length; l++) {
      strTmp += "<option value=\"" + listEmpCategor[l]["id"] + "\">" + listEmpCategor[l]["category_name"] + "</option>"
    }
    $("#party_mapping_id").html(strTmp);

  }
  okSucc(data, msg) {
    //alert(data+"===="+msg);
    if (data === "001") {

      $("#frmCreate").find("input[type=text]").val("");
      //$("#frmCreate").find()
      $(".respSSL").html(msg);
      clsObject.getCompanyMaster();
    } else {
      $(".respSSL").html(msg);
    }
  }
  render() {
    return (<div>
      <Header />
      <div className="content">
        <div className="container-fluid mt-4">
          <div className="row">
            <div className="col-md-2">

              {/* <!-- Widget: user widget style 2 --> */}
              <div className="card card-widget widget-user-2 shadow ">
                {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}


                {/* <!-- /.widget-user -->  */}
              </div>

              {/* <!-- Widget: user widget style 2 --> */}
              <div className="card card-widget widget-user-2 shadow ">
                {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}
                <Library_Opt_Modules />

                {/* <!-- /.widget-user -->  */}
              </div>
            </div>
            <div className="col-lg-10 col-md-10 col-sm-12 col-12">
              <section className="content">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">ACTA E-Library</h3>

                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                        <i class="fas fa-minus"></i></button>
                      <button type="button" class="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                        <i class="fas fa-times"></i></button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div class="post">
                          <div className="user-block">

                            <span className="username1">
                              <a href="javascript:void(0)"><b>Hi ! {companyName},</b></a>
                            </span>

                          </div>

                          <p>
                            Welcome to ACTA,Our Automated Compliance Tracking Application.You are currently using E-Library
                          </p>
                          {/* applicable list */}
                          <div class="card">
                            <div class="card-header">
                              <h3 class="card-title">
                                <i class="fa fa-long-arrow-right"></i>
                                Applicable States for Professional Tax
                              </h3>
                            </div>

                            <div class="card-body">
                             <div className='row'>
                                
                                    {stateList.map((item,index)=>{
                                       
                                        if(item["applicability"].indexOf("pt")!==-1){
                                            return (<div className='col-3'><a href={"../professional_tax/"+item["name"]}>{item["name"]}</a></div>)
                                        }
                                    })}
                                
                             </div>
                            </div>

                          </div>

{/* non-applicable list */}
                          <div class="card">
                            <div class="card-header">
                              <h3 class="card-title">
                                <i class="fa fa-long-arrow-right"></i>
                               Non Applicable States for Professional Tax
                              </h3>
                            </div>

                            <div class="card-body">
                             <div className='row'>
                                
                                    {stateList.map((item,index)=>{
                                       
                                        if(item["applicability"].indexOf("pt")===-1){
                                            return (<div className='col-3'><a href={"../professional_tax/"+item["key"]}>{item["name"]}</a></div>)
                                        }
                                    })}
                                
                             </div>
                            </div>

                          </div>
                          {/* thumbnail */}
                        
              <p>
              <b>Labour Welfare Fund</b>
              </p>
              <p>

Labour welfare fund is a statutory contribution managed by individual state authorities. The state labour welfare board determines the amount and frequency of the contribution. The contribution and periodicity of remittance differs with every state. In some states the periodicity is annual (Andhra Pradesh, Haryana, Karnataka, Tamil Nadu etc) and in some states it is to be contributed during the month of June & December (Gujarat, Madhya Pradesh, Maharashtra etc).
</p>
<p>
    <b>
What is Labour Welfare Fund?
</b>

</p>
<p>
Labour welfare is an aid in the form of money or necessities for those in need. It provides facilities to labourers in order to improve their working conditions, provide social security, and raise their standard of living.
<br></br>
To justify the above statement, various state legislatures have enacted an Act exclusively focusing on welfare of the workers, known as the Labour Welfare Fund Act. The Labour Welfare Fund Act incorporates various services, benefits and facilities offered to the employee by the employer. Such facilities are offered by the means of contribution from the employer and the employee. However, the rate of contribution may differ from one state to another.


              </p>
<p>
<b>Scope of Labour Welfare Fund Act</b>
</p>
<p>
The scope of this Act is extended to housing, family care & worker's health service by providing medical examination, clinic for general treatment, infant welfare, women’s general education, workers activity facilities, marriage, education, funeral etc. State specific Labour Welfare Funds are funded by contributions from the employer, employee and in few states, the government also.
</p>
<p>
<b>Applicability of the Act</b>
</p>
<p>
In order to provide social security to workers, the government has introduced the Labour Welfare Fund Act. This act has been implemented only in 16 states out of 37 states including union territories.<br></br>

The Labour Welfare Fund Act is not applicable to all category of employees working in the establishment. It depends upon the wages earned and designation of the employee. Also, one needs to check the total number of employees working before extending this Act to their establishment. The applicability of the Act based on the number of employees may differ depending upon state specific Act.

</p>
<p>
<b>How Does the Process Work?</b>
</p>
<p>
The contribution in the Labour Welfare Fund may be made annually, half yearly or monthly. The frequency may differ depending upon the state specific Act. Further, if the frequency is half yearly the period of deduction shall be divided into two consecutive periods as per the date mentioned in the state specific Act. The employer needs to make the deduction from the salary of the employee and submit the same to the Labour Welfare Fund board in the prescribed form before the due date.
Labour Welfare Fund Expenditure
</p>
<p>
    <p><b>
    In general the money in the Fund may be utilized by the Board to defray expenditure on the following:
    </b>
    </p>

</p>
<p>
Educational facilities for the children of the workers.
</p>
<p>
Medical facilities for both private and public-sector employers to facilitate medical facilities for their workers and their families.
</p>
<p>Transport facilities to the workers for commuting to work.</p>
<p>Recreational facilities in form of music, dance, drama, games, sports, paintings, etc. are usually offered to the employees to build a wholesome working environment.</p>
<p>Housing facilities under this scheme offer loans to industrial workers for constructing houses at concessional rates.</p>
<p>Excursions, tours and holiday homes.</p>
<p>Home industries and subsidiary occupations for women and unemployed persons.</p>
<p>Reading rooms and libraries.</p>
<p>Vocational training.</p>
<p>Nutritious food to children of employees.</p>
<p>
<b>Disclaimer: </b>The information contained in this website is for general information purposes only. The information is provided by ACTA, a property of IndiThinkk Tech Private Limited While we endeavour to keep the information up to date, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
<br></br><br></br>
For any suggestions or feedback write to us at <b>helpdesk@indithinkk.com</b>

</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </section>
            </div>
          </div>
        </div>
      </div>
      <Footer />

    </div>)
  }
}
export default Pre_Professional_Tax;