import React from 'react';
const Comp_Consultation_Log=(props)=>{
   let appoListStatus=["New","Pending Consultation","Completed","Cancelled"];

    //alert(JSON.stringify(props));
        return(<div className="modal fade bd-example-modal-xl"  id="divConsultationLog" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Update Consultation Status</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            <form id="frmConsultationLog" name="frmConsultationLog">
            <div className="modal-body">
            <div className="row">
                      {/* <!-- left column --> */}
                      <div className="col-md-12">
                          <div className="card card-danger">
                                  <input type="hidden" name="res_func" id="res_func" value="okSuccConsultation" />
                                  <input type="hidden" name="curl_type" id="curl_type" value="updateConsultation" />
                                  <input type="hidden" name="m_type" id="m_type" value="updateConsultation" />
                                  <input type="hidden" name="type_id" id="type_id" value="2" />
                                  <input type="hidden" name="consultation_id" id="consultation_id" value={typeof props.consultationData.id!=="undefined"?btoa(props.consultationData.id):btoa("0")} />
                                  <input type="hidden" name="local_token" id="local_token" value={props.user_object[0].app_user_id} />
                                  <div className="card-body">
                                      <div className="row">
                                     
                                          <div className="col-lg-12 col-md-12">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Consultation Status</label>
                                                  <select  className="form-control form-control-sm" id="appo_consultation" name="appo_consultation">
           <option value="0">-Change Status-</option>
           {appoListStatus.map((item,index)=>{
            return (<option selected={typeof props.consultationData.status!=="undefined"?props.consultationData.status.toUpperCase():'0'===item.toUpperCase()} value={item}>{item}</option>)
           })}
           {/* <option value="1">-Reseller-</option>
           <option value="3">-User-</option> */}
         </select>
                                              </div>
                                          </div>
                                      </div>
                                    
                                      <div className="col-md-6 respSSL"></div>
                                      <div id="testing2dd2222"></div>
                                  </div>
                              
                          </div>
                      </div>
                  </div>
            </div>
            <div className="modal-footer">
            <div id="getReponse" style={{color:"red"}}></div>
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
            </div>
            </form>
          </div>
          </div>
          </div>
      );
}
export default Comp_Consultation_Log;
