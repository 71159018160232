import $ from 'jquery';
import React,{useEffect} from 'react';
import {GetImgCertUserCMS_CBack} from '../function_lib/common_cms';

export function addCMSContent(){
  GetImgCertUserCMS_CBack(this,"#frmAstroAddArticle","addNewArticle",function(checkData,checkMsg,userMsg){
      if(checkData===-10){
        alert("Kindly fill required fields");
        return;
      }else {
        //Custom_Alert("Successfully Added",checkMsg,false);
        //alert(checkMsg);
        
        $('#divCmsAddNew').modal('hide');
        window.location.reload();
      }
    });
}             
  
export const Comp_Astro_Add_Articles=(props)=>{
  // useEffect(() => {
  //   $(".select2").select2();
  // },[[]]);
    //alert(JSON.stringify(props));
        return(<div className="modal fade bd-example-modal-xl"  id="divAtroAddArticle" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Add New</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div id="errLog"></div>
            <form class="form-group11" id="frmAstroAddArticle" name="frmAstroAddArticle" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="addNewArticle" />
                            <input type="hidden" name="m_type" id="m_type" value="addNewArticle" />
                            {/* <input type="hidden" name="product_id" id="product_id" value={typeof props.editProduct.rf_id!=="undefined"?props.editProduct.rf_id:""} /> */}
                            <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={props.app_user_id}/>
                            <input type="hidden" name="lsImages" id="lsImages" />
                            <input type="hidden" name="lsOthImages" id="lsOthImages"/>
                            <input type="hidden" name="bNode" id="bNode" value={props.b_node}/>
                            <input type="hidden" name="pNode" id="pNode" value={props.p_node}/>
                            <input type="hidden" name="col20" id="col20" value={props.p_node}/>
                            <input type="hidden" name="type" id="type" value={"20"}/>
                          
                            <div class="card-body">
                                <div className="row">
                                <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Select Top</label>
                                            <div className="input-group input-group-sm">
                                            <select id="pagenameCat" name="pagenameCat" className="form-control select2 form-control-sm">
                                                  <option value={btoa("0")}>-Select Article</option>
                                                  {props.listArticles.map((item,index)=>{
                                                    return <option value={btoa(item.id)}>{item.pagename}</option>
                                                  })}
                                              </select>
                                            </div>
                                        </div>
                                  </div>
                                <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Menu Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="pagename" name="pagename" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Page Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="urlname" name="urlname" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Display Index</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="order" name="order" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-8 col-md-8">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Title</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="title" name="title" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                    
                                </div>
                                <div class="row">
                                 
    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Description 1 *</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="content" name="content"></textarea>
                                            </div>
                                        </div>
                                    </div>
                              
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Description 2 *</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="brief" name="brief"></textarea>
                                            </div>
                                        </div>
                                    </div>

{/* image upload section */}
<div className="col-md-12">
<div className="row">
{/* file image upload */}

<div className="col-md-4">
 <div className="card card-info">
<div className="card-header">
  <h3 className="card-title">Main Image </h3><small> (Max File Size 1MB, Only 1 will allow) </small>
</div>
<div className="card-body">
<div className="row audioLibThumb imgLibThumb">
                      
                      </div>
  <div className="row">
    
    <div className="col-12">
    <input type="file" ref_img="ImageThumbBack" multiple name="customFileBr" id="customFileBr" accept="image/*" size={10000000} className="updImgThumb" />
      <a href="javascript:void(0)" setTo="customFileBr" className="_brFileThumb updthumb">Click & Browse Image Files</a>
    </div>
   
   
  </div>
</div>

</div>
</div>

                               {/* end of file image upload */}

                               {/* file other image upload */}


                               {/* end of other file image upload */}
</div>
</div>
{/* end of Image Upload Section */}



                                    <div className="col-lg-8 col-md-8">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Display Type</label>
                                            <div className="input-group input-group-sm">
                                                <select className="form-control" id="display_type" name="display_type">
                                                <option value="0">Select</option>
									<option value="menu">Menu</option>
									<option value="data">Data</option>
                                                </select>
                                            </div>
                                        </div>
                                  </div>

                                 
                                 

                                </div>
                                 
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                                <div class="row">
                                    <div class="col-12">
                                      
                                        <div class="form-group">
                                        <center>
                                            <div class="input-group input-group-sm" style={{"justify-content":"center"}}>
                                                <button type="button" onClick={()=>addCMSContent()} class="btn btn-primary">Submit</button>
                                            </div>
                                        </center>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>
                            </form>
          </div>
          </div>
          </div>
          </div>);
    
}
export default Comp_Astro_Add_Articles;
