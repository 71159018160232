import $ from 'jquery';
import React from 'react';
import {GetImgCert_CBack} from '../function_lib/common_lib';

let listClientDisplay={ tt: [] };
let stateList=[];
let clsObject;
let vBranchList=[];
class Comp_Add_Client extends React.Component{
    constructor(props){
        super(props);
        clsObject=this;
   }
   setClientData(valId){
    //alert(valId);
    for(let b=0;b<vBranchList.length;b++){
        if(vBranchList[b]["party_info_id"]===valId){
            $("#frmCreateClient :input, select, hidden").each(function(){ 
                let attId=$(this).attr('id');
                if(attId==="col1"){
                    $(this).val(vBranchList[b]["company_name"]);
                }else if(attId==="col2"){
                    $(this).val(vBranchList[b]["location"]);
                }else if(attId==="col3"){
                    $(this).val(vBranchList[b]["state_id"]);
                }else if(attId==="col4"){
                    $(this).val(vBranchList[b]["address"]);
                }else if(attId==="state_id"){
                    //alert("i m in condition "+vBranchList[b]["state_id"]);
                    $(this).val(vBranchList[b]["state_id"]);
                }
             })
           
            
        }
    }
   }
    componentWillReceiveProps(props){
        this.props=props;
        stateList=this.props.stateList;
        //Required props
          //1. List of Existing Entities
          //2. Parent Entity Data
    }
   
    ac_getBranches(){
        
        this.props.ViewEntity_getBranches($("#_comp_add_client_child_entity_id").val(),function(data,msg){
            //alert(data);
            let listData=JSON.parse(data);
            vBranchList=listData;
            let strData="<option value=\"0\">-Select Branch-</option>"
            for(let i=0;i<listData.length;i++){
                strData+="<option value=\""+listData[i]["party_info_id"]+"\">"+listData[i]["company_name"]+"</option>"
            }
            $("#_comp_add_client_branch_id").html(strData);
        });
    }

    addClientToData(){
        GetImgCert_CBack(clsObject,"#frmCreateClient","addClientVendorMgmt",function(data,msg){
            if(data==="001"){
                $("#frmCreateClient").find("input[type=text]").val("");
                $("#frmCreateClient").find("input[type=number]").val("");
                $("#frmCreateClient").find("input[type=password]").val("");
                $("#getReponse_add_client").html("Client Added Successfully");
            }else{
                alert("Unable to add Record ,Kindly check record");
            }

        });
    }
    
    addToList(){
        //addToList is replaced by addClientToData;
        //let clsAddBranch=new listData("sdfsdf","sdfsdf");
       
        const array = $("#frmCreateClient").serializeArray(); // Encodes the set of form elements as an array of names and values.
        const json = {};
        $.each(array, function () {
          json[this.name] = this.value || "";
        });
        //return json;
        listClientDisplay.tt.push(json);
        //alert(JSON.stringify(listBranchDisplay));
        let strList="";
        for(let i=0;i<listClientDisplay.tt.length;i++){
           strList+="<tr>"+
                    "<td>"+listClientDisplay.tt[i]["col1"]+"</td>"+
                    "<td>"+listClientDisplay.tt[i]["col4"]+"</td>"+
                    "<td>"+listClientDisplay.tt[i]["col12"]+"</td>"+
                    "<td>"+listClientDisplay.tt[i]["col16"]+"</td>"+
                    "<td>"+listClientDisplay.tt[i]["col9"]+"/"+listClientDisplay.tt[i]["col10"]+"</td>";
        }
        $("#list_clients").html(strList);
        $("#entity_clients").val(JSON.stringify(listClientDisplay));
        $("#frmCreateClient").find("input[type=text]").val("");
        $("#frmCreateClient").find("input[type=number]").val("");
        $("#frmCreateClient").find("input[type=password]").val("");
        alert("Branch Added Successfully");
    }    
render(){
    return(<div className="modal fade bd-example-modal-xl"  id="divUpdClient" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Add Clients</h5>
          <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form method='POST' id="frmCreateClient" name="frmCreateClient">
       
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  <div className="col-md-12">
                      <div className="card card-danger">
                              <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                              <input type="hidden" name="curl_type" id="curl_type" value="createLogMaster" />
                              <input type="hidden" name="m_type" id="m_type" value="createLogMaster" />
                              <input type="hidden" name="type_id" id="type_id" value="4" />
                              <input type="hidden" name="app_user_id" id="app_user_id" value="" />
                              <input type="hidden" name="entity_id" id="entity_id" value={this.props.partyInfoData.id}/>
                              <input type='hidden' name="state_id" id="state_id" />
                              <div className="card-body">
                                <div className='row'>
                                    <div className='col-lg-3 col-md-6'>
                                    <div className="form-group">
                                              <label for="exampleInputEmail1">Select Client from List</label>
                                              <div className="input-group input-group-sm">
                                              <select onChange={()=>this.ac_getBranches()} id="_comp_add_client_child_entity_id" name="_comp_add_client_child_entity_id" className="form-control">
                                              <option value="0">Select Client</option>
                            {this.props.entityList.length>0?this.props.entityList.map((item,index)=>{
                                if(item.id!==this.props.partyInfoData.id){
                                    return (<option value={item.id}>{item.company_name}</option>)
                                }
                                
                            }):''}
                            
                          </select>
                                              </div>
                                          </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6'>
                                    <div className="form-group">
                                              <label for="exampleInputEmail1">Select Branch from List</label>
                                              <div className="input-group input-group-sm">
                                              <select onChange={(event)=>this.setClientData(event.target.value)}  id="_comp_add_client_branch_id" name="_comp_add_client_branch_id" className="form-control">
                                              </select>
                                              </div>
                                          </div>
                                    </div>

                                </div>
                                  <div className="row">
                                 
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Client Name</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" readOnly id="col1" name="col1" className="form-control" placeholder="Client Name" />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Location</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" readOnly id="col2" name="col2" className="form-control" placeholder="Location" />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">State</label>
                                              <div className="input-group input-group-sm">
                                              <select readOnly className="form-control form-control-sm" id="col3" name="col3">
                <option value="0">-Select State-</option>
                {stateList.length>0?stateList.map((item,index)=>{
                                                    return (<option value={item.location_id}>{item.name}</option>)
                                                  }):''}
              </select>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-6 col-md-12">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Registered Office Address</label>
                                              <div className="input-group input-group-sm">
                                                  <input readOnly type="text" id="col4" name="col4" className="form-control" placeholder="Address" />
                                              </div>
                                          </div>
                                      </div>
                                    
                                   
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Contact Person Name</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" id="col9" name="col9" className="form-control" placeholder="Person Name" />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Contact No.</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" id="col10" name="col10" className="form-control" placeholder="Contact No." />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Email</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" id="col11" name="col11" className="form-control" placeholder="Email" />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Nature of Service</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" id="col12" name="col12" className="form-control" placeholder="Nature of Service" />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="row">
                                <table class="table table-bordered table-sm">
                                <thead>
                                    <tr style={{backgroundColor:"#1a857a",color:"#fff"}}>
                                        <td>
                                            Men Power Deployed
                                        </td>
                                        <td>
                                            Male
                                        </td>
                                        <td>
                                            Female
                                        </td>
                                        <td>
                                            Transgender
                                        </td>
                                        <td>
                                            Total
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>
                                            Men Power Deployed
                                        </td>
                                        <td>
                                        <input type="number" id="col13" name="col13" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="number" id="col14" name="col14" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="number" id="col15" name="col15" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="number" id="col16" name="col16" className='form-control form-control-sm'/>
                                        </td>
                                    </tr>
                                    
                                    </thead>
                                </table>
                                </div>
                               
                               
                             
                             
                                <div className='row'>
                                <table class="table table-bordered table-sm">
                                <thead>
                                    <tr style={{backgroundColor:"#1a857a",color:"#fff"}}>
                                        <td>
                                            Contract Period
                                        </td>
                                        <td>
                                            From
                                        </td>
                                        <td>
                                            To
                                        </td>
                                        
                                        <td>
                                            Add
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>
                                   
                                        </td>
                                        <td>
                                        <input type="text" id="col17" name="col17" className='form-control form-control-sm' placeholder="Date" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask/>
                                        </td>
                                        <td>
                                        <input type="text" id="col18" name="col18" className='form-control form-control-sm' placeholder="Date" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask/>
                                        </td>
                                        <td>
                                        
                                        </td>
                                      
                                    </tr>
                                    
                                    </thead>
                                </table>

                                </div>
                                
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                              </div>
                          
                      </div>
                  </div>
              </div>
        </div>
        <div className="modal-footer">
        <div id="getReponse_add_client" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" onClick={()=>this.addClientToData()} className="btn btn-primary">Save changes</button>
        </div>
        </form>
      </div>
      </div>
      </div>)
}
}
export default Comp_Add_Client;