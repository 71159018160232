import $ from 'jquery';
import React from 'react';
import {getCheckRolesNew} from '../function_lib/common_lib';
import {btnSetColor_CBack} from '../function_lib/common_lib';

let strCheckRoles="";
class Comp_Accessibility extends React.Component{
    constructor(props){
        super(props);
        strCheckRoles=getCheckRolesNew();
    }
    componentWillReceiveProps(props){
        this.props=props;
        //alert(this.props.partyInfoData.id);
        //alert(JSON.stringify(props));
        if(typeof props.partyInfoId!=="undefined"){
            if(props.partyInfoId!=="" && props.partyInfoId!=="0"){
            //    this.setState({partyInfoId:props.partyInfoId});
            //    this.forceUpdateHandler();
            }
            
        }
    }
    addToAccessibilityData(frmName){
        btnSetColor_CBack(this,frmName,"assignColorsSetting",function(data,msg){
            if(data==="001"){
                alert("Accessibility Updated Successfully");
                
            }else{
                alert("Unable to Update Accessibility Record !kindly check and try again");
            }
            
        });
     }

    componentDidMount(){
        $("#comp_access_roles").html(strCheckRoles);
    }
    render(){
        return(<div className="modal fade bd-example-modal-xl"  id="divCompAccessibility" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Set Accessbility</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            <form method='POST' id="frmCompAccessibility" name="frmCompAccessibility">
           
            <div className="modal-body">
            <div className="row">
                      {/* <!-- left column --> */}
                      <div className="col-md-12">
                          <div className="card card-danger">
                                  <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                                  <input type="hidden" name="curl_type" id="curl_type" value="assignColorsSetting" />
                                  <input type="hidden" name="m_type" id="m_type" value="assignColorsSetting" />
                                  <input type="hidden" name="app_user_id" id="app_user_id" value="" />
                                  <input type="hidden" name="entity_id" id="entity_id" value={(typeof this.props.partyInfoData.id==="undefined")?"0":this.props.partyInfoData.id} />
                                  
                                  <div className="card-body">
                                    <div id="comp_access_roles"></div>
                                      <div className="col-md-6 respSSL"></div>
                                      <div id="testing2dd2222"></div>
                                  </div>
                              
                          </div>
                      </div>
                  </div>
            </div>
            <div className="modal-footer">
            <div id="getReponse_add_client" style={{color:"red"}}></div>
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" onClick={()=>this.addToAccessibilityData("frmCompAccessibility")} className="btn btn-primary">Save changes</button>
            </div>
            </form>
          </div>
          </div>
          </div>)
    }
}
export default Comp_Accessibility;