import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod} from '../function_lib/common_lib';
import Header from './header';
import Footer from './footer';
import Option_Module from './option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';


let loginUser="";
let userObject="";
let companyName="";
let clsObject;
class Abstrack_Notices extends React.Component{
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      }
      callBackNg(data,msg){
        //  alert(data);
        if(data.trim()!=="" && data.trim()!=="0"){
          var listItems=JSON.parse(data);
          var str="";
          var countEmp=0;
        
          for(var i=0;i<listItems.length;i++){
              countEmp++;
             
            str+="<tr>"+
            "<td>"+(i+1)+"</td>"+
             "<td>"+listItems[i]["p_details"]+"</td>"+
             "<td>"+listItems[i]["details"]+"</td>"+
             "<td>"+""+"</td>"+
             "</tr>"
          }
          //$("#tbData").html(str);
          //$(".total_emp").html(countEmp);
        }
    }
 getCompanyMaster(){
    var map=[];
    map.push({name:"curl_type",value:"getCheckList"});
    map.push({name:"res_func",value:"callBackNg"});
    map.push({name:"local_token",value:userObject[0].app_user_id});
    //map.push({name:"type_id",value:"3"});
    map["res_func"]="callBackNg";
    map["curl_type"]="getCheckList";
    CallMapMethod(this,map);
 }
 callBackParent(data,msg){
    // alert(data);
    var strParentId="<option value=\"0\">"+"-Select-"+"</option>";
    if(data.trim()!=="" && data.trim()!=="0"){
       
        var listParent=JSON.parse(data);
        for(var p=0;p<listParent.length;p++){
            strParentId+="<option value=\""+listParent[p]["id"]+"\">"+listParent[p]["details"]+"</option>";
        }
    }
    $("#parent_id").html(strParentId);
 }
 getCheckListParent(){
    var map=[];
    map.push({name:"curl_type",value:"getCheckList"});
    map.push({name:"res_func",value:"callBackParent"});
    map.push({name:"local_token",value:userObject[0].app_user_id});
    map.push({name:"parent_id",value:"0"});
    map.push({name:"type_id",value:"10"});
    map["res_func"]="callBackParent";
    map["curl_type"]="getCheckList";
    CallMapMethod(this,map);
 }   
componentDidMount(){
    clsObject=this;
 //this.getCompanyMaster();
 //this.getCheckListParent();
 /*
  var empCategory=[];
  empCategory.push({name:"curl_type",value:"getEmployeeCategory"});
  empCategory.push({name:"res_func",value:"callBackEmpCate"});
  empCategory.push({name:"local_token",value:userObject[0].app_user_id});
  empCategory.push({name:"ref2_id",value:userObject[0].id});
  empCategory["res_func"]="callBackEmpCate";
  empCategory["curl_type"]="getEmployeeCategory";
  CallMapMethod(this,empCategory);
*/
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
}
callBackEmpCate(data,msg){
    //alert(data);
    var listEmpCategor=JSON.parse(data);
    var strTmp="<option value=\"0\">-select-</option>";
    for(var l=0;l<listEmpCategor.length;l++){
        strTmp+="<option value=\""+listEmpCategor[l]["id"]+"\">"+listEmpCategor[l]["category_name"]+"</option>"
    }
    $("#party_mapping_id").html(strTmp);

}
okSucc(data,msg){
    //alert(data+"===="+msg);
    if(data==="001"){

        $("#frmCreate").find("input[type=text]").val("");
        //$("#frmCreate").find()
        $(".respSSL").html(msg);
        clsObject.getCompanyMaster();
    }else{
        $(".respSSL").html(msg);
    }
}
      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
    
<div className="row">
<div className="col-md-2"> 
        
        {/* <!-- Widget: user widget style 2 --> */}
     
        
        {/* <!-- Widget: user widget style 2 --> */}
        <div className="card card-widget widget-user-2 shadow "> 
          {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}
            <Option_Module/>
          
          {/* <!-- /.widget-user -->  */}
        </div>
      </div>
      <div className="col-lg-10 col-md-10 col-sm-12 col-12">
           {/* acta component */}
     <div class="row">
     <div class="col-md-3 col-sm-6 col-12">
<div className="card card-widget widget-user-2 shadow "> 
          {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}
          
          <div className="card-footer p-0">
            <ul className="nav flex-column  table-hover">
              <li className="nav-item"> <a href="javascript:void(0)" className="nav-link  hover"><h6><i className="fa fa-user-alt font-small text-orange"></i><span class="float-left badge bg-primary" data-toggle="modal" data-target="#divEmployee">Add New Notice</span></h6> </a> </li>
            </ul>
          </div>
          
          {/* <!-- /.widget-user -->  */}
        </div>
</div>   
<div class="col-md-3 col-sm-6 col-12">
  <div class="info-box">
    <span class="info-box-icon bg-info">1</span>
    <div class="info-box-content">
      <span class="info-box-text">Open Notice</span>
      
    </div>
  </div>
</div>

<div class="col-md-3 col-sm-6 col-12">
  <div class="info-box">
    <span class="info-box-icon bg-success">1</span>
    <div class="info-box-content">
      <span class="info-box-text">Closed Notice</span>
    </div>
  </div>
</div>

<div class="col-md-3 col-sm-6 col-12">
  <div class="info-box">
    <span class="info-box-icon bg-warning">0</span>
    <div class="info-box-content">
      <span class="info-box-text">Notice Overdue</span>
    </div>
  </div>
</div>


</div>
    {/* end of acta component */}
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Notice List</b></h3>
              </div>
                <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap table-responsive">
                  <thead>
                    <tr class="border">
                        <th>S.No.</th>
                      
                      <th>Entity Name</th>
                      <th>Download Notice</th>
                      <th>Download Abstrack</th>
                    </tr>
                  </thead>
                  <tbody id="tbData">
                      <tr>
                          <td>1</td>
                          <td>Indithinkk Tech Pvt. Ltd.-Gurgaon</td>
                          <td><a href="javascript:void(0)" data-toggle="modal" data-target="#divEmpViewDoc1"><span class="float-right badge bg-danger dash_rejected_claim1">Download Notice</span></a></td>
                    <td><a href="javascript:void(0)" data-toggle="modal" data-target="#divEmpViewDoc1"><span class="float-right badge bg-success dash_approved_claim1">Download Abstract</span></a></td>
                    
                      </tr>
                      <tr>
                          <td>1</td>
                          <td>Indithinkk Tech Pvt. Ltd.-Jodhpur</td>
                          <td><a href="javascript:void(0)" data-toggle="modal" data-target="#divEmpViewDoc1"><span class="float-right badge bg-danger dash_rejected_claim1">Download Notice</span></a></td>
                    <td><a href="javascript:void(0)" data-toggle="modal" data-target="#divEmpViewDoc1"><span class="float-right badge bg-success dash_approved_claim1">Download Abstract</span></a></td>
                    
                      </tr>
                      <tr>
                          <td>1</td>
                          <td>Indithinkk Tech Pvt. Ltd.-Maharashtra</td>
                    <td><a href="javascript:void(0)" data-toggle="modal" data-target="#divEmpViewDoc1"><span class="float-right badge bg-danger dash_rejected_claim1">Download Notice</span></a></td>
                    <td><a href="javascript:void(0)" data-toggle="modal" data-target="#divEmpViewDoc1"><span class="float-right badge bg-success dash_approved_claim1">Download Abstract</span></a></td>
                    
                      </tr>
                    
                    
                  </tbody>
                </table>
             
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>
{/* view document */}
<div className="modal fade bd-example-modal-xl"  id="divEmpViewDoc1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">View</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmC" name="frmC">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                    <embed width="100%" height="500px" src={"https://acta.indithinkk.com/acta_21.pdf"} toolbar="0"></embed>
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
      </form>
    </div>
    </div>
    </div>
    {/* end of view */}
<div className="modal fade bd-example-modal-xl"  id="divEmployee" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Add New</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmCreate" name="frmCreate">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="createLogMaster" />
                            <input type="hidden" name="m_type" id="m_type" value="createLogMaster" />
                            <input type="hidden" name="type_id" id="type_id" value="2" />
                            <input type="hidden" name="app_user_id" id="app_user_id" value="" />
                            <div className="card-body">
                                <div className="row">
                               
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Notice Received Under Act</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="details" name="details" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Entity Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="details" name="details" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Location</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="details" name="details" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">State</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="details" name="details" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
<label for="exampleInputFile">Upload Proof(*img,pdf,xlx,xlsx)</label>
<div className="input-group">
  <div className="custom-file">
    <input type="file" className="custom-file-input" name="exampleInputFile" id="exampleInputFile" />
    <label className="custom-file-label" for="exampleInputFile">Choose file</label>
  </div>
  {/* <div className="input-group-append">
  <button type="button" className="btn btn-primary">Upload Now</button>
  </div> */}
</div>
</div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Notice Ref No</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="details" name="details" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Type *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="parent_id_reg" name="parent_id_reg" className="form-control">
                                                  <option value="0">-Select-</option>
                                                  <option value="entity">Entity</option>
                                                  <option value="contractor">Contractor</option>
                                                  <option value="client">Client</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Notice Date</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col98" name="col98" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted"></small> </div>
          </div>
                                    <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Notice Received Date</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col98" name="col98" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted"></small> </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Notice Reply Expected</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col98" name="col98" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted"></small> </div>
          </div>
                                  
                                    <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                            <label for="exampleInputEmail1">Responsible(Entity/Client/Contractor) *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="ref1_id" name="ref1_id" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>
          </div>)
      }
}
export default Abstrack_Notices;