import { render } from '@testing-library/react';
import $ from 'jquery';
import React from 'react';
import {appGetUrlPath} from '../function_lib/common_lib';
import UserThum from '../assets/images/user_thumb.png';
export function Comp_Chat_Log(compLog)
{
  let compAppPath=appGetUrlPath();
  //alert("i m in logschat=="+JSON.stringify(compLog));
    return(<div class="card direct-chat direct-chat-warning">
    <div class="card-header">
      <h3 class="card-title">Log Histroy</h3>

      <div class="card-tools">
        <span data-toggle="tooltip" title="3 New Messages" class="badge badge-warning">3</span>
        <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fa fa-minus"></i>
        </button>
        <button type="button" class="btn btn-tool" data-toggle="tooltip" title="Contacts"
                data-widget="chat-pane-toggle">
          <i class="fas fa-comments"></i></button>
        <button type="button" class="btn btn-tool" data-card-widget="remove"><i class="fa fa-times"></i>
        </button>
      </div>
    </div>
    
    <div class="card-body">
      
      <div class="direct-chat-messages">
        
        {typeof compLog.complianceLog!=="undefined"?compLog.complianceLog.map((item,index)=>{
           let rRight="";
           let fRight="";
           let dRight="";
           if(compLog.appUser===item.p1_enc){
            rRight="right";
            fRight="float-right";
            dRight="float-left"
           }else{
            rRight="";
            fRight="float-left";
            dRight="float-right"
           }
           return <div className={"direct-chat-msg "+rRight}>
           <div class="direct-chat-infos clearfix">
             <span className={"direct-chat-name "+fRight}>{item.p1_company_name}</span>
             <span className={"direct-chat-timestamp "+dRight}>{item.c_date}</span>
           </div>
         
           <img class="direct-chat-img" src={UserThum} alt="message user image" />
           {item.details!==""?<div class="direct-chat-text">
             {"Message:"+item.details}
             {item.col1!==""?
                                                 <p><label for="exampleInputEmail1"><a target="_blank"  href={compAppPath+"compliance_dfile/"+item.col1} download={item.col1}><i class="fa fa-paperclip fa-1x"></i>Download Attachement</a></label><br></br>
                                                 Statue:<label>{item.col2}</label> 
                                                 </p>
                                                 :''} 
           </div>:''}
           
         
         </div>
            
           
        }):''
}      
        

        
      
      

      </div>
      
     
    
    </div>
    {/* below section commented becoz send not required here */}
    {/* <div class="card-footer">
      <form action="#" method="post">
        <div class="input-group">
          <input type="text" name="message" placeholder="Type Message ..." class="form-control" />
          <span class="input-group-append">
            <button type="button" class="btn btn-warning">Send</button>
          </span>
        </div>
      </form>
    </div> */}
    
  </div>)
}