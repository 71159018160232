import $, { extend } from 'jquery';
import React from 'react';
import { UserToken, getUserObjData } from '../function_lib/userToken';
import { CallMapMethod, AjaxCallMethod } from '../function_lib/common_lib';
import {StateIndia} from '../function_lib/city_india';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Library_Opt_Modules from '../user_pages/library_opt_modules';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';


let loginUser = "";
let userObject = "";
let companyName = "";
let clsObject;

let stateList=[];
let appliState=[];
let nonAppliState=[];

class Pre_Leave_Working_Hr extends React.Component {
  constructor(props) {
    super(props);
    if (localStorage.getItem("userData") != null) {
      userObject = getUserObjData();

      //alert(JSON.stringify(userObject));
      loginUser = userObject[0].first_name;
      companyName = userObject[0].first_name;

    }
    stateList=StateIndia();

  }

  getAppliandNonAppliState(){
    for(let s=0;s<stateList.length;s++){
        if(stateList[s]["applicability"].indexOf("lwf")!==-1){
            appliState.push(stateList[s]);
        }
    }
  }

  callBackNg(data, msg) {
    //  alert(data);
    if (data.trim() !== "" && data.trim() !== "0") {
      var listItems = JSON.parse(data);
      var str = "";
      var countEmp = 0;

      for (var i = 0; i < listItems.length; i++) {
        countEmp++;

        str += "<tr>" +
          "<td>" + (i + 1) + "</td>" +
          "<td>" + listItems[i]["p_details"] + "</td>" +
          "<td>" + listItems[i]["details"] + "</td>" +
          "<td>" + "" + "</td>" +
          "</tr>"
      }
      //$("#tbData").html(str);
      //$(".total_emp").html(countEmp);
    }
  }
  getCompanyMaster() {

  }
  callBackParent(data, msg) {
    // alert(data);
    var strParentId = "<option value=\"0\">" + "-Select-" + "</option>";
    if (data.trim() !== "" && data.trim() !== "0") {

      var listParent = JSON.parse(data);
      for (var p = 0; p < listParent.length; p++) {
        strParentId += "<option value=\"" + listParent[p]["id"] + "\">" + listParent[p]["details"] + "</option>";
      }
    }
    $("#parent_id").html(strParentId);
  }
  getCheckListParent() {
    var map = [];
    map.push({ name: "curl_type", value: "getCheckList" });
    map.push({ name: "res_func", value: "callBackParent" });
    map.push({ name: "local_token", value: userObject[0].app_user_id });
    map.push({ name: "parent_id", value: "0" });
    map.push({ name: "type_id", value: "10" });
    map["res_func"] = "callBackParent";
    map["curl_type"] = "getCheckList";
    CallMapMethod(this, map);
  }
  componentDidMount() {
    clsObject = this;
    // this.getCompanyMaster();
    // this.getCheckListParent();
    /*
     var empCategory=[];
     empCategory.push({name:"curl_type",value:"getEmployeeCategory"});
     empCategory.push({name:"res_func",value:"callBackEmpCate"});
     empCategory.push({name:"local_token",value:userObject[0].app_user_id});
     empCategory.push({name:"ref2_id",value:userObject[0].id});
     empCategory["res_func"]="callBackEmpCate";
     empCategory["curl_type"]="getEmployeeCategory";
     CallMapMethod(this,empCategory);
   */
    AjaxCallMethod(this, "okSuccCNOT");
    $("#app_user_id").val(userObject[0].id);
  }
  callBackEmpCate(data, msg) {
    //alert(data);
    var listEmpCategor = JSON.parse(data);
    var strTmp = "<option value=\"0\">-select-</option>";
    for (var l = 0; l < listEmpCategor.length; l++) {
      strTmp += "<option value=\"" + listEmpCategor[l]["id"] + "\">" + listEmpCategor[l]["category_name"] + "</option>"
    }
    $("#party_mapping_id").html(strTmp);

  }
  okSucc(data, msg) {
    //alert(data+"===="+msg);
    if (data === "001") {

      $("#frmCreate").find("input[type=text]").val("");
      //$("#frmCreate").find()
      $(".respSSL").html(msg);
      clsObject.getCompanyMaster();
    } else {
      $(".respSSL").html(msg);
    }
  }
  render() {
    return (<div>
      <Header />
      <div className="content">
        <div className="container-fluid mt-4">
          <div className="row">
            <div className="col-md-2">

              {/* <!-- Widget: user widget style 2 --> */}
              <div className="card card-widget widget-user-2 shadow ">
                {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}


                {/* <!-- /.widget-user -->  */}
              </div>

              {/* <!-- Widget: user widget style 2 --> */}
              <div className="card card-widget widget-user-2 shadow ">
                {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}
                <Library_Opt_Modules />

                {/* <!-- /.widget-user -->  */}
              </div>
            </div>
            <div className="col-lg-10 col-md-10 col-sm-12 col-12">
              <section className="content">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">ACTA E-Library</h3>

                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                        <i class="fas fa-minus"></i></button>
                      <button type="button" class="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                        <i class="fas fa-times"></i></button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div class="post">
                          <div className="user-block">

                            <span className="username1">
                              <a href="javascript:void(0)"><b>Hi ! {companyName},</b></a>
                            </span>

                          </div>

                          <p>
                            Welcome to ACTA,Our Automated Compliance Tracking Application.You are currently using E-Library
                          </p>
                          {/* applicable list */}
                          <div class="card">
                            <div class="card-header">
                              <h3 class="card-title">
                                <i class="fa fa-long-arrow-right"></i>
                                Applicable States                               </h3>
                            </div>

                            <div class="card-body">
                             <div className='row'>
                                
                                    {stateList.map((item,index)=>{
                                       
                                        if(item["applicability"].indexOf("leave_working_hr")!==-1){
                                            return (<div className='col-3'><a className='link-color' href={"../leave_working_hours/"+item["name"]}>{item["name"]}</a></div>)
                                        }
                                    })}
                                
                             </div>
                            </div>

                          </div>
             <p>
             <b>Leave</b>
             </p>
             <p>
The Shops and Establishments Act, is enacted by every state in India. The Act is designed to regulate payment of wages, hours of work, interval for rest, opening and closing hours, holidays, overtime work, annual leave, sick leave, casual leave, condition of the employment etc.
             </p>
     <p>
    <b>Concept of Leaves</b>
    </p>
    <p>
Employees are entitled to a certain number of days of leave per year aside from the holidays and days off. Generally three types of leave are mentioned under the Shops and Establishments Act i.e. Privilege leave, Sick leave and Casual leave. This varies from state to state. So, the number of leave entitled to an employee depends upon the state in which the establishment is located in. The leave policy of an establishment should be in consonance with the leave provision of the respective state’s Shops and Establishments Act. The said leave policy cannot be less beneficial than that mentioned by the respective state’s Shops and Establishments Act.
</p>
<p>
Generally, the framework of the Act is similar throughout. However, the leave provision under the Act varies for every state.
        </p>        
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div class="post">
                          <div class="card">
                            <div class="card-header">
                              <h3 class="card-title">
                                <i class="fa fa-long-arrow-right"></i>
                                <b>Types of Leave</b>
                              </h3>
                            </div>

                            <div class="card-body">
                             <p>
                             <b>Earned leave / Privilege leave</b>
                             </p>
                             <p>
These are the additional leave of absence awarded to an employee with wages for the day’s work performed by him for specific number of days as prescribed under the Shops and Establishments Act. The condition to avail the Earned / Privilege leave may differ in every establishment. The total number of days of Earned / Privileged leave that may be carried forward to a succeeding year is prescribed under the Shops and Establishments Act. The employee is entitled for encashment of Earned leave / Privileged leave as per the respective state's Shops and Establishments Act and Rule.
                             </p>
                             <p>
                             <b>Casual Leave:</b>
                             </p>
                             <p>
The Casual Leave is granted for an unanticipated event or when an employee is unable to attend the office for a day or two due to any personal exigency. The quantum varies from state to state, as per Shops and Establishments Act. Normally companies restrict Casual leave to a short duration. However, if an employee wishes to avail Casual Leave for a longer period then prior permission is required or a plausible reason needs to be provided on re-joining. Casual leave is normally not clubbed with other leave, but can be availed along with other leave in case of insufficient leave balance. Generally Casual leave is not cashable nor it can be carried forward.

                             </p>
        <p>
        <b>Sick leave:</b>
        </p>
        <p>
An employee is entitled to avail Sick leave in case of sickness. During Sick leave, wages are paid to the employees when they are out of work due to illness. The number of Sick leave are fixed by the establishment in accordance with the provisions of the respective state’s Shops and Establishments Act. Sick leave accumulated in a year, may be carried forward and availed in the next year in certain states.

        </p>
        <p>
        <b>Overtime Work</b>
        </p>
        <p>
Overtime refers to the time worked in excess of one's regular working hours which, in India, is eight to nine hours per day and forty-eight to fifty hours per week, depending upon the establishment one is employed with. Overtime wages are to be paid at the rate of twice the ordinary rates of wages by the employer, if an employee works more than the working hours as prescribed under the Act.
Several statutes regulate overtime and overtime payment, and different legal acts provide for different periods of working hours.

        </p>
        <p>
        <b>Spread Over Hours</b>
        </p>
        <p>
Spread Over hours mean, total working hour of an employee, inclusive of rest breaks and overtime.
        </p>

        <p>
        <b>Interval for rest</b>
        </p>
        <p>
Interval for rest is a break taken during working hours.

        </p>
                            </div>

                          </div>
                          {/* thumbnail */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </section>
            </div>
          </div>
        </div>
      </div>
      <Footer />

    </div>)
  }
}
export default Pre_Leave_Working_Hr;