import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {GetImgCert,customAlert,AjaxCallMethod} from '../function_lib/common_lib';
import defaultPic from '../assets/images/9.png';
import TkMeter from '../assets/images/camera.svg';
import {TableDatePicker} from '../function_lib/common_lib';

let loginUser="";
let accountNo="";
let userObject="";
let clsObject;
class AddLeaveRequest extends React.Component{
  constructor(props){
    super(props);
    if(localStorage.getItem("userData")!=null){
      UserToken.setUserObject(localStorage.getItem("userData"));
      userObject= UserToken.getUserObject();
      loginUser=userObject[0].person_name;
      accountNo=userObject[0].account_no;
    }
  }
  okSuccCNOT(data,datares){
    //alert(datares);
    if(data!="0"){
      $(".addLeaveClose").trigger('click');
      $("#frmAddLeaveRequest").find("input[type=text]").val("");
      $("#getReponse").html("Request Submitted Successfully!");
      customAlert("Leave Request","Submitted!Kindly wait for approval from Admin","./Dashboard",1);
    }else{
      $("#getReponse").html("Could Not Submit Request !");
    }
  }
  componentDidMount(){
    clsObject=this;
    AjaxCallMethod(this,"okSuccCNOT");
  }
  render(){
    return(
      <div className="modal fade bd-example-modal-xl"  id="divLeaveRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
        <div className="modal-header bg-primary">
            <h6 className="modal-title text-white" id="exampleModalLabel">Leave Request</h6>
            <button type="button" className="close addLeaveClose  text-white" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" className="text-white">&times;</span>
            </button>
          </div>
  
  <div className="container">
  <form id="frmAddLeaveRequest" action="#" method="post">
              <input type="hidden" id="curl_type" name="curl_type" value="submitLeaveReq" />
              <input type="hidden" name="res_func" id="res_func" value="okSuccCNOT" />
              <input type="hidden" name="m_type" id="m_type" value="submitLeaveReq" />
              <input type="hidden" name="parent_id" id="parent_id" value={userObject[0].app_user_id} />
              <input type="hidden" name="ref2_id" id="ref2_id" value={userObject[0].id} />
              <div className="mb-3">
                <div className="title mb-2"><i className="lni lni-map-marker"></i><span>Sutaible Leave</span></div>
                <select id="col1" name="col1" className="form-control">
                    <option value="0">Sutaible Option</option>
                    <option value="EL">Earn Leave(EL)</option>
                    <option value="CL">Casual Leave(CL)</option>
                    <option value="SL">Sick Leave(SL)</option>
                    <option value="ML">Maternity Leave(ML)</option>
                    <option value="HL">Hospitalization Leave</option>
                    <option value="LWP">Leave Without Pay</option>
                    <option value="PTL">Paternity Leave</option>
                </select>
              </div>
              <div className="mb-3">
                <div className="title mb-2"><i className="lni lni-map-marker"></i><span>From Date</span></div>
                <TableDatePicker refName="req_from"/>
                
              </div>
              <div className="mb-3">
                <div className="title mb-2"><i className="lni lni-target-customer"></i><span>To Date</span></div>
                <TableDatePicker refName="req_to"/>
              </div>
              <div className="mb-3">
                <div className="title mb-2"><i className="lni lni-target-customer"></i><span>Reason</span></div>
                <textarea id="details" name="details" className="form-control">

                </textarea>
              </div>
              <div id="getReponse" style={{color:"red"}}></div>
             
              <button className="btn btn-success w-100 _btnSetColor" type="button">Submit</button>
            </form>
 
</div>
</div>
</div>
</div>)
  }
}

export default AddLeaveRequest;