import $ from 'jquery';
import axios from "axios";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
let jQuery=$;
export const ash_data_url="https://app.advaiet.com/upd_support_tick/";
//const ash_base_url="https://localhost:44339/appSupportService.asmx/"
const ash_base_url="https://app.advaiet.com/appSupportService.asmx/"
export const ListTicketPriority=()=>{
    return [
        {label:"Low",value:"Low"},
        {label:"Medium",value:"Medium"},
        {label:"High",value:"High"},
    ]
}
export const TicketStatus=()=>{
    return[
        {label:"Open",value:"Open"},
        {label:"Hold",value:"Hold"},
        {label:"Customer Replied",value:"Customer Replied"},
        {label:"Support Replied",value:"Support Replied"},
        {label:"Closed",value:"Closed"}
    ]
}

export const RemoveExUpdFile=(obCls,htCls,cBack)=>{
   
      $("."+htCls).click(function(){
        var refId=$(this).attr("param1");
        var refId2=$(this).attr("param2");
        
        if(typeof refId2!=="undefined" && typeof refId2!==false){
          
        }else{
          refId2="0";
        }
  
        let refId3="0";
        if(typeof $(this).attr("param3")!=="undefined"){
          refId3=$(this).attr("param3");
        }
  
  
        return cBack(refId,refId2,refId3);
     });
    
}

export function UploadImageSetups(_callBack){
    $("._brFileThumb").unbind('click');
    $(".updImgThumb").unbind('change');
  
    $("._brFileThumb").click(function(){
      var getNm=$(this).attr("setTo");
      document.getElementById(getNm).click();
    });
    $(".updImgThumb").change(function(){
        var currentObj=$(this);
        var refBack=$(this).attr("ref_img");
        var refFlName=this.value.split('\\').pop();
    
        let isMultiple=false;
        let checkMultiple=$(this).attr("multiple");
        //alert(checkMultiple);
        if(typeof checkMultiple!=="undefined"){
          isMultiple=true;
        }
    
    
        let checkSize=$(this).attr("size");
        //alert();
        //alert(refBack);
        if (this.files && this.files[0]) {
          //alert(this.files[0].size);
          //check size
          if (typeof checkSize !== 'undefined' && checkSize !== false) {
            if(this.files[0].size>checkSize){
              return _callBack("-10","Size larger then validated!",currentObj);
            }
        }
    
        let jvThis=$(this)[0];
        
        let thumbnail="";
        if(jvThis.hasAttribute("thumbnail")){
          thumbnail=jvThis.getAttribute("thumbnail");
        }
        //alert(thumbnail+" = "+refBack)
    
        let lsElement="";
        if(jvThis.hasAttribute("lsElement")){
          lsElement=jvThis.getAttribute("lsElement");
        }
    
    
          
          //alert("i am read url");
            var reader = new FileReader();
            reader.onload = function (e) {
              //var refBack=$(this).attr("ref_img");
              //alert(refBack);
              //alert(e.target.name);
              return _callBack(e.target.result,refFlName,currentObj,"",lsElement,thumbnail,isMultiple);
              //alert($(input).attr("ref_img"));
               // $('.'+$(input).attr("ref_img")).attr('src', e.target.result);
            }
     
            reader.readAsDataURL(this.files[0]);
        }
      });
}

export const getMultiPartData=async(frmName,uriCuri)=>{
    var ssFrm=$(frmName);
  
  var getCallFunc=$(ssFrm).find('input[name="res_func"]').val();
   var ss = ssFrm.serializeArray();

   let obj={objs:ss};
   //alert(JSON.stringify(obj));
   
   //var xs = "{'objs':" + JSON.stringify(ss) + "}";
   //alert(xs);
  if(!getCallFunc){
getCallFunc="";

   }
   //alert(ash_base_url+uriCuri);

    try {
      ajaxindicatorstart("loading data..");
        const data = await axios.post(ash_base_url + uriCuri,obj);
        ajaxindicatorstop();
        //alert(JSON.stringify(data.data.d));
        //alert(JSON.stringify(data));
        //var checkData=$($($.parseHTML(data.data)).filter("#resP")).text();
       // var checkMsg=$($($.parseHTML(data.data)).filter("#resPS")).text();
        //let response={data:checkData,msg:checkMsg};
        return data.data.d;
    } catch (error) {
        //alert(error);
        if (error.response && error.response.status === 401) {
            // Throw a custom error with a message
            throw new Error('Unauthorized access.');
        } else {
            // Throw the original error to be caught by the calling code
            throw error;
        }
    }
}


export const GetCondidatesView=async(map,uriCuri)=>{
    try {
        ajaxindicatorstart("Loading data..");
        //alert()
         //alert(JSON.stringify(frmData));
        //alert(ash_base_url);
        let obj={objs:map};
        const data = await axios.post(ash_base_url + uriCuri,obj);
        // alert(data.data.d);
        // var checkData=$($($.parseHTML(data.data)).filter("#resP")).text();
        // var checkMsg=$($($.parseHTML(data.data)).filter("#resPS")).text();
        // let response={data:checkData,msg:checkMsg};

         ajaxindicatorstop();
         let response=data.data.d;
        return response;
    } catch (error) {
        ajaxindicatorstop();
        //alert(error);
        if (error.response && error.response.status === 401) {
            // Throw a custom error with a message
            throw new Error('Unauthorized access.');
        } else {
            // Throw the original error to be caught by the calling code
            throw error;
        }
    }
}
export function ajaxindicatorstart(text) {
    if (jQuery('body').find('#resultLoading').attr('id') != 'resultLoading') {
        jQuery('body').append('<div id="resultLoading" style="display:none"><div><img src="../ajax-loader.gif"><div>' + text + '</div></div><div class="bg"></div></div>');
    }
  
    jQuery('#resultLoading').css({
        'width': '100%',
        'height': '100%',
        'position': 'fixed',
        'z-index': '10000000',
        'top': '0',
        'left': '0',
        'right': '0',
        'bottom': '0',
        'margin': 'auto'
    });
  
    jQuery('#resultLoading .bg').css({
        'background': '#000000',
        'opacity': '0.7',
        'width': '100%',
        'height': '100%',
        'position': 'fixed',
        'top': '0',
        'margin-left': '0'
    });
  
    jQuery('#resultLoading>div:first').css({
        'width': '250px',
        'height': '75px',
        'text-align': 'center',
        'position': 'fixed',
        'top': '0',
        'left': '0',
        'right': '0',
        'bottom': '0',
        'margin': 'auto',
        'font-size': '16px',
        'z-index': '99999999999',
        'color': '#ffffff'
  
    });
  
    jQuery('#resultLoading .bg').height('100%');
    jQuery('#resultLoading').fadeIn(300);
    jQuery('body').css('cursor', 'wait');
  }
  
  export function ajaxindicatorstop() {
    jQuery('#resultLoading .bg').height('100%');
    jQuery('#resultLoading').fadeOut(300);
    jQuery('body').css('cursor', 'default');
  }
  export default GetCondidatesView
