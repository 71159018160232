import $, { extend } from 'jquery';
import 'react-bootstrap/dropdown';
import Popper from 'popper.js';
//import {Popper} from '@popperjs/core';

import React,{ useState } from 'react';

import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";

import 'react-bootstrap/modal';

import 'react-bootstrap/tooltip';
import {ReactSummernote} from  'react-summernote';
import 'react-summernote/dist/react-summernote.css';


import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,GetImgCert,appGetUrlPath,RespBkMthdMapp,getSearchOutPut,CallMapMethod_CBack} from '../function_lib/common_lib';
import {StateMaster} from '../function_lib/city_india';
import {Comp_View_Document} from '../components/comp_view_document';


import Comp_Add_Branches from '../components/comp_add_branches';
import Comp_Add_Client from '../components/comp_add_client';
import Comp_Add_Vendor from '../components/comp_add_vendor';
import Comp_Accessibility from '../components/comp_accessibility';
import Comp_Edit_Entity from '../components/comp_edit_entity';
import Comp_Entity_Settings from '../components/comp_entity_settings';

import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Entity_Opt_Modules from '../user_pages/entity_opt_modules';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';


let loginUser="";
let userObject="";
let companyName="";
let clsObject;
let stateList=[];
let appUrtPath;
window.Popper = Popper;

class View_Entity extends React.Component{
  
forceUpdateHandler(){
  this.forceUpdate();
};

    constructor(props){
        super(props);
        //const [patyInfoId, setPartyInfoId] = useState(0);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      stateList=StateMaster();
      this.state={ listLib:[],partyInfoId:"0",entityInfoData:[]};
      this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      appUrtPath=appGetUrlPath();
      //alert(stateList.length);
      }
     
      setDocumentView(docName){
        //alert(appUrtPath+"/library_dfile/"+docName);
        document.getElementById("samp_comp_pa").src=appUrtPath+"library_dfile/"+docName;
      }
      
      okSuccLib(data,msg){
        //alert(data+" "+msg);
        if(data==="011"){
          $(".respSSL").html("Data Created Successfully");
          window.location.reload();
          //clsObject.getLibraryByType();
        }
      }

  getEntityList(){
  var libType=[];
  libType.push({name:"curl_type",value:"getEntityList"});
  libType.push({name:"res_func",value:"callBackEntityList"});
  //libType.push({name:"local_token",value:userObject[0].app_user_id});
  //libType.push({name:"ref2_id",value:userObject[0].id});
  libType.push({name:"category_id",value:"1"});
  libType["res_func"]="callBackEntityList";
  libType["curl_type"]="getEntityList";
  CallMapMethod(this,libType);
}
callBackEntityList(data,msg){
  //alert(data);
 // alert(msg);
//  $("#desk_thumb").html(data+msg);
//  return;
  if(data!=="0" && data!==""){
    let listLib=JSON.parse(data);
    
    this.setState({listLib:listLib},()=>{
      RespBkMthdMapp(clsObject, "_setbackparam", "rmLibraryRec");
    });
    this.forceUpdateHandler();
  }
}
//Function for Delete
rmLibraryRec(data){
  if(window.confirm("Do you want to Delete ?")===true){
  var libType=[];
  libType.push({name:"curl_type",value:"deleteFromLibList"});
  libType.push({name:"res_func",value:"callBackDeleteList"});
  //libType.push({name:"local_token",value:userObject[0].app_user_id});
  //libType.push({name:"ref2_id",value:userObject[0].id});
  libType.push({name:"p_ref",value:data});
  libType.push({name:"type_id",value:"10"});
  libType["res_func"]="callBackDeleteList";
  libType["curl_type"]="deleteFromLibList";
  CallMapMethod(this,libType);
  }
}
callBackDeleteList(data,msg){
  if(data==="001"){
    alert("Deleted Successfully");
    clsObject.getLibraryByType();
  }
}

//Function for Edit
editLibraryRec(data){
  alert("Unable to process Edit "+data);
}


 createMarkup(data) {
  return {__html: data};
}

getBranches(entityId,callBack){
  //alert(entityId);
  var libType=[];
  //libType.push({name:"curl_type",value:"getEntityList"});
  libType.push({name:"curl_type",value:"getPartyInfo_MD"});
  libType.push({name:"res_func",value:"callBackEntityList"});
  libType.push({name:"app_user_id",value:entityId});
  libType.push({name:"category_id",value:"2"});
  libType["res_func"]="callBackEntityList";
  libType["curl_type"]="getPartyInfo_MD";
  CallMapMethod_CBack(this,libType,function(data,msg){
    //alert(msg);
    callBack(data,msg);
  });
}

componentDidMount(){
    clsObject=this;
    this.getEntityList();
  //AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].id);
$(".cleditor").summernote();
//$(".dropdown-toggle").dropdown();
RespBkMthdMapp(clsObject,"_setSearchKeyUp","getSearch");
}
getSearch(data){
  if(data==="0"){
    data="";
  }
  getSearchOutPut("desk_thumb","child_desk_thumb",data);
 }
 setPartyInfoId(partyId){
  //alert(partyId);
  this.setState({partyInfoId:partyId})
  this.forceUpdateHandler();
 }
 setEntityInfoData(data){
  //alert(JSON.stringify(data));
  this.setState({entityInfoData:data});
  this.forceUpdateHandler();
 }

      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row">
<div className="col-md-2"> 
        
        {/* <!-- Widget: user widget style 2 --> */}
        <div className="card card-widget widget-user-2 shadow "> 
          {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}
          
          <div className="card-footer p-0">
            <ul className="nav flex-column  table-hover">
              <li className="nav-item"> <a href="../entity_management" className="nav-link  hover"><h6><i className="fa fa-user-alt font-small text-orange"></i><span class="float-left badge bg-primary" >Add New Entity</span></h6> </a> </li>
            </ul>
          </div>
          
          {/* <!-- /.widget-user -->  */}
        </div>
        
        {/* <!-- Widget: user widget style 2 --> */}
        <div className="card card-widget widget-user-2 shadow "> 
          {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}
         <Entity_Opt_Modules/>
          
          {/* <!-- /.widget-user -->  */}
        </div>
      </div>
      <div className="col-lg-10 col-md-10 col-sm-12 col-12">
      <div class="card" id="desk_thumb">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
           <div className="row">
             <div className="col-lg-3 col-md-3 col-sm-4">
             <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Entity List </b></h3>
             </div>
   
  
  
    <div className="col-lg-3 col-md-3 col-sm-4">
    <select onChange={(event)=>this.getSearch(event.target.value)} className="form-control form-control-sm" id="ser_state" name="ser_state">
                <option value="0">-All State-</option>
                {stateList.length>0?stateList.map((item,index)=>{
                                                    return (<option value={item.name}>{item.name}</option>)
                                                  }):''}
              </select>
    </div>
    <div className="col-lg-5 col-md-5 col-sm-5">
    <div className="input-group">
                    <input autoComplete="off" type="text" id="searchTxt" name="searchTxt" className="form-control form-control-sm _setSearchKeyUp"   placeholder="Search" />
                    <div className="input-group-prepend">
                      <span className="input-group-text form-control-sm currency"><i className="fa fa-search" aria-hidden="true"></i></span>
                    </div>
                  </div>
     
    </div>
  </div>
              </div>
               

 {this.state.listLib.length>0?this.state.listLib.map((item,index)=>{
                        return (<div class="callout callout-danger child_desk_thumb">
                        
                        <h5><img className='entity_icon_img' src={item["icon_img"]!==""?appUrtPath+"app_user_img/"+item["icon_img"]:appUrtPath+"app_user_img/user_thumb.png"}/>{item.company_name}
                        <a href="javascript:void(0)" onClick={()=>this.rmLibraryRec(item._id)} class="float-right btn-tool"><i class="fa fa-times"></i></a>
                        <a href="javascript:void(0)" data-toggle="modal" data-target="#divCompEditEntity" onClick={()=>this.setEntityInfoData(item)}  class="float-right btn-tool"><i class="fa fa-pencil"></i></a>
                        <span data-toggle="modal" data-target="#divCompAccessibility" onClick={()=>this.setEntityInfoData(item)}><a href="javascript:void(0)" data-toggle="tooltip" data-placement="top" title="Set Accessibility"    class="float-right btn-tool"><i class="fa fa-sitemap"></i></a></span>
                        <span data-toggle="modal" data-target="#divEntitySettings" onClick={()=>this.setEntityInfoData(item)}><a href="javascript:void(0)" data-toggle="tooltip" data-placement="top" title="Entity Settings"    class="float-right btn-tool"><i class="fa fa-cog"></i></a></span>

                        </h5>
                        
                      <p>
                      <a href="#" class="link-black text-sm">Contact Person:<b>{item.person_name}</b> </a>
                      <span className='float-right'><b>{item.user_name}</b> : {item.password}</span>
                      </p>
                      <p>
                        <div className='row'>
                          <div className='col-md-6'>
                            <p>Registration Date:</p>
                            <p>Expiry:</p>
                            <p>Last Login:</p>
                            <p></p>
                          </div>
                          <div className='float-right col-md-6'>
                          <table style={{"tableLayout":"auto"}} className="dtgrid table table-bordered text-sm table-responsive">
                          <thead>
                            <tr>
                              <td scope="col">Entity- {item.no_of_entities}</td>
                              <td scope="col">Branches- {item.no_of_branches}</td>
                              <td scope="col">Clients- {item.no_of_clients}</td>
                              <td scope="col">Vendor- {item.no_of_vendors}</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><span class="float-left badge bg-primary" ><a href="javascript:void(0)">Add New</a></span></td>
                              <td><span class="float-left badge bg-primary" ><a onClick={()=>this.setEntityInfoData(item)} data-toggle="modal" data-target="#divUpdBranches" href="javascript:void(0)">Add New</a></span></td>
                              <td><span class="float-left badge bg-primary" ><a onClick={()=>this.setEntityInfoData(item)} data-toggle="modal" data-target="#divUpdClient" href="javascript:void(0)">Add New</a></span></td>
                              <td><span class="float-left badge bg-primary" ><a onClick={()=>this.setEntityInfoData(item)} data-toggle="modal" data-target="#divUpdVendor" href="javascript:void(0)">Add New</a></span></td>
                            </tr>
                          </tbody>
                        </table>
                          </div>
                        </div>
                     
                      </p>
                      </div>)
                    }):''}
             
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>
<Comp_View_Document/>
<div className="modal fade bd-example-modal-xl"  id="divEmployee" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Acts Master</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmLibCreate" name="frmLibCreate" enctype="multipart/form-data" method="POST" action="#">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="createLogMaster" />
                            <input type="hidden" name="m_type" id="m_type" value="createLogMaster" />
                            <input type="hidden" name="type_id" id="type_id" value="10" />
                            <input type="hidden" name="app_user_id" id="app_user_id" value="" />
                            <div className="card-body">
                                <div className="row">
                                <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">State *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="state_name" name="state_name" className="form-control">
                                                  <option value="0">-Select-</option>
                                                  {stateList.length>0?stateList.map((item,index)=>{
                                                    return (<option value={item.name}>{item.name}</option>)
                                                  }):''}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Act Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="title_name" name="title_name" className="form-control" placeholder="Act Name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Year *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="year" name="year" className="form-control" placeholder="year" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                      {/* <input type='file' id="fl_name" name="fl_name" /> */}
<label for="upload_path">Upload Proof(.jpg,png,jpeg,pdf,doc,docx,zip allowed)</label>
<div className="input-group">
  <div className="custom-file">
    <input type="file" className="custom-file-input1" name="upload_path" id="upload_path" />
    {/* <label className="custom-file-label" for="upload_path">Choose file</label> */}
  </div>
  {/* <div className="input-group-append">
  <button type="button" className="btn btn-primary">Upload Now</button>
  </div> */}
</div>
</div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                            <label for="exampleInputEmail1">Remarks if Any</label>
                                            <div className="input-group input-group-sm">
                                                <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="description" name="description"></textarea>
                                            </div>
                                        </div>
                                    </div>  
                                   
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" onClick={()=>this.saveLibrary()} className="btn btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>
<Comp_Add_Branches partyInfoData={this.state.entityInfoData}  list_display={"list_branches"}/>
<Comp_Add_Vendor stateList={stateList} ViewEntity_getBranches={this.getBranches} entityList={this.state.listLib} partyInfoData={this.state.entityInfoData} list_display={"list_vendors"}/>
<Comp_Add_Client stateList={stateList} ViewEntity_getBranches={this.getBranches} entityList={this.state.listLib} partyInfoData={this.state.entityInfoData} list_display={"list_clients"}/>
<Comp_Accessibility partyInfoData={this.state.entityInfoData}/>
<Comp_Edit_Entity partyInfoData={this.state.entityInfoData} />
<Comp_Entity_Settings partyInfoData={this.state.entityInfoData} />
          </div>)
      }
}
export default View_Entity;