import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,GetImgCertUser_CBack,RespBkMthdMapp,detectEmojisAndSpecialCharacters,CallMapMethod_CBack} from '../function_lib/common_lib';
//import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';


import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';
import pdfIcon from '../assets/images/pdf_icon.png';
import mp3Icon from '../assets/images/mp3_icon.png';
import mp4Icon from '../assets/images/mp4_icon.png';

let loginUser="";
let userObject="";
let companyName="";
let clsWappCamp;


let totalFileAllow=5;

let objImages = [];
let indexExisting=0;
let arrExistingFiles=[];


let objVideo=[];
let vidIndexExisting=0;
let arrVidExistingFiles=[];

let objPdf=[];
let pdfIndexExisting=0;
let arrPdfExistingFiles=[];
class Add_Product_Master extends React.Component{
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={listCategory:[],stVendoList:[]};
      clsWappCamp=this;
      }
      getCategories(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getCategoriesMaster"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_userType",value:$("#ser_userType").val()});
        map.push({name:"ser_text",value:$("#ser_text").val()});
        map.push({name:"get_vendor",value:"yes"});
        //alert(status);
        /*
        if(status!=="0"){
          map.push({name:"status",value:status});
        }
        not required as of now
        */
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getCategoriesMaster";
        CallMapMethod_CBack(clsWappCamp,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                //alert(JSON.stringify(listData.vendor_list));
                clsWappCamp.setState({listCategory:listData.category_list,
                  stVendoList:listData.vendor_list},()=>{
                
                //  clsWappCamp.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
              }
        });
      }

//  check file validation
      fileValidation(){
        let allowFile=true;
     let imgCount=objImages.length;
     let vidCount=objVideo.length;
     let pdfCount=objPdf.length;

     let vTotalCount=imgCount+vidCount+pdfCount;
    
     if(vTotalCount>=totalFileAllow){
      alert("Only "+totalFileAllow+" to be allowed .");
      allowFile=false;
     }
     return allowFile;
 }
 //  check file validation     
  rmPdfProc(data){
    
        if(document.getElementById("ref_pdf_"+data)!=null){
          document.getElementById("ref_pdf_"+data).remove();
        }
        
        this.lcRemoveJSON(data,"pdf");
      }    

PdfThumbBack(flImg,flName,fObject,othInfo=""){
  if(!this.fileValidation()){
    return;
  }
  if(flImg==="-10"){
    alert(flName);
    return;
  }
   if(othInfo===""){
    othInfo="nm"+pdfIndexExisting+","+pdfIndexExisting;
  }
  pdfIndexExisting++;
  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrPdfExistingFiles[arrNmValue[1]]=arrNmValue[0];
  var strSet = "<div id=\"ref_pdf_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"fa fa-close icon-close _removeBtn"+pdfIndexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src="+pdfIcon+" /></a>" +
    "</div>";
  
    
  //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
  objPdf.push({ name: arrNmValue[1], value: flImg });
  $("#lsPdf").val("{\"objs\":" + JSON.stringify(objPdf) + "}");
  //alert(strSet);            
  $(".pdfLibThumb").append(strSet);
  if(fObject!==null){
    fObject.val('');
  }

RespBkMthdMapp(clsWappCamp, "_removeBtn"+pdfIndexExisting, "rmPdfProc", "2");
  //imgLibThumb
}      
rmVideoProc(data){
  if(document.getElementById("ref_vid_"+data)!=null){
    document.getElementById("ref_vid_"+data).remove();
  }
  
  this.lcRemoveJSON(data,"video");
}
VideoThumbBack(flImg,flName,fObject,othInfo=""){
  if(!this.fileValidation()){
    return;
  }
  if(flImg==="-10"){
    alert(flName);
    return;
  }

  if(othInfo===""){
    othInfo="nm"+vidIndexExisting+","+vidIndexExisting;
  }
  vidIndexExisting++;
  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrVidExistingFiles[arrNmValue[1]]=arrNmValue[0];
  var strSet = "<div id=\"ref_vid_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"fa fa-close icon-close _removeBtn"+vidIndexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + mp3Icon + "\"/></a>" +
    "</div>";
    if(flImg.indexOf("data:video/webm")!=-1 || flImg.indexOf("data:video/mp4")!=-1){
     strSet= "<div id=\"ref_vid_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close icon-close _removeBtn"+vidIndexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+flImg+"\"><img src="+mp4Icon+" /></a>" +
        "</div>";
    }
    
  //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
  objVideo.push({ name: arrNmValue[1], value: flImg });
  $("#lsVideo").val("{\"objs\":" + JSON.stringify(objVideo) + "}");
  //alert(strSet);            
  $(".vidLibThumb").append(strSet);
  if(fObject!==null){
    fObject.val('');
  }

RespBkMthdMapp(clsWappCamp, "_removeBtn"+vidIndexExisting, "rmVideoProc", "2");
  //imgLibThumb
}      
rmFileProc(data){
  
    if(document.getElementById("ref_div_"+data)!=null){
      document.getElementById("ref_div_"+data).remove();
    }
    
    this.lcRemoveJSON(data,"image");
}
/* Loc */
lcRemoveJSON(refKey,fType){
if(fType==="image"){
  var jsonP=JSON.parse($("#lsImages").val());
  var arrJson=jsonP.objs;
  for(var ai=0;ai<arrJson.length;ai++){
    if(arrJson[ai]["name"]===refKey){
      arrJson.splice(ai,1);
      $("#lsImages").val(JSON.stringify(jsonP));
      
      break;
    }
  }
  objImages=arrJson;
  
}else if(fType==="video"){
  var jsonP=JSON.parse($("#lsVideo").val());
  var arrJson=jsonP.objs;
  for(var ai=0;ai<arrJson.length;ai++){
    if(arrJson[ai]["name"]===refKey){
      arrJson.splice(ai,1);
      $("#lsVideo").val(JSON.stringify(jsonP));
      
      break;
    }
  }
  objVideo=arrJson;
}else if(fType==="pdf"){
  var jsonP=JSON.parse($("#lsPdf").val());
  var arrJson=jsonP.objs;
  for(var ai=0;ai<arrJson.length;ai++){
    if(arrJson[ai]["name"]===refKey){
      arrJson.splice(ai,1);
      $("#lsPdf").val(JSON.stringify(jsonP));
      
      break;
    }
  }
  objPdf=arrJson;
}


 
}     
ImageThumbBack(flImg, flName,fObject,othInfo="") {
  
  if(!this.fileValidation()){
    return;
  }

  if(flImg==="-10"){
    alert(flName);
    return;
  }

  if(othInfo===""){
    othInfo="nm"+indexExisting+","+indexExisting;
  }
  indexExisting++;
  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrExistingFiles[arrNmValue[1]]=arrNmValue[0];
  var strSet = "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
    "</div>";
    if(flImg.indexOf("data:video/webm")!==-1 || flImg.indexOf("data:video/mp4")!==-1){
     strSet= "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+flImg+"\"><video controls type=\"video/mp4\" src=\"" + flImg + "\"/></a>" +
        "</div>";
    }
    
  //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
  objImages.push({ name: arrNmValue[1], value: flImg });
 // alert(objImages.length);
  $("#lsImages").val("{\"objs\":" + JSON.stringify(objImages) + "}");
  //alert(strSet);            
  $(".imgLibThumb").append(strSet);
  if(fObject!==null){
    fObject.val('');
  }
//   $('.gallery-item').magnificPopup({
//     type: 'image',
//     gallery: {
//         enabled: true
//     }
// });
RespBkMthdMapp(clsWappCamp, "_removeBtn"+indexExisting, "rmFileProc", "2");
  //imgLibThumb
      }


saveWappCompaign(){
  //alert("Sending WAPP");
  //alert($("#contact_no").val());
/*
  let checkChar= $("#msg_text").val();
   let result=detectEmojisAndSpecialCharacters(checkChar);
  
if(result.emojis.length!==0 || result.specialCharacters.length!==0){
   alert("Special Characters and Emojis not allowd in text Message");
   return; 
}
*/


  
  GetImgCertUser_CBack(clsWappCamp,"#frmWappCamp","addProductMast",function(checkData,checkMsg,userMsg){
    
    if(checkData===-10){
      alert("Kindly fill required fields");
      return;
    }else if(checkData==="001"){
      Custom_Alert("Successfully Added",checkMsg,true);
        
    }else if(checkData==="01") {
      Custom_Alert("Not added",checkMsg,false);
      //alert(checkMsg);
    }
    
  });

  
// Custom_Alert("WAPP CAMPAIGN","<span>Test1</span><span>Test2</span>")
  
 
}

 isFloat(n) {
  let checkNum = false;
  if (Number(n)) {
      checkNum = true;
  } else if (n % 1 !== 0) {
      checkNum = true;
  } else {
      checkNum = false;
  }

  return checkNum;
}
 
componentDidMount(){
    
  this.getCategories();
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].id);

$(".cleditor").summernote();
$(".cd_input_change").change(function () {
  let objPreCost = document.getElementById("pre_cost");
  let objOfferPer = document.getElementById("offer_per");
  let objFCost = document.getElementById("txt_cost");

    let valPreCost = 0;
    let valOfferPer = 0;
    let valFCost = 0;

    if (clsWappCamp.isFloat(objPreCost.value.trim())) {
      valPreCost = objPreCost.value.trim();
    }
    if (clsWappCamp.isFloat(objOfferPer.value.trim())) {
      valOfferPer = objOfferPer.value.trim();
    }
    if (clsWappCamp.isFloat(objFCost.value.trim())) {
      valFCost = objFCost.value.trim();
    }

    if(valOfferPer!==0){
      objFCost.value = valPreCost-(valPreCost * valOfferPer / 100).toFixed(2);
    }else{
      objFCost.value=objPreCost.value;
    }



})
}

   render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row">
<div className="col-md-12 col-lg-12"> 
        
<section class="content">
<div class="container-fluid">
<div class="card card-secondary">
<div class="card-header">
                                <h3 class="card-title">Add New Product</h3>
                                <a className="btn btn-sm btn-danger float-right" href="../view_product_master">View Products</a>
                            </div>
                            <form class="form-group" id="frmWappCamp" name="frmWappCamp" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="addProductMast" />
                            <input type="hidden" name="m_type" id="m_type" value="addProductMast" />
                            <input type="hidden" name="wa_msg_ty_ma_id" id="wa_msg_ty_ma_id" value={"1"} />
                            <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={userObject[0].app_user_id}/>
                          
                            <input type="hidden" name="lsImages" id="lsImages" />
                            <input type="hidden" name="lsVideo" id="lsVideo"/>
                            <input type="hidden" name="lsPdf" id="lsPdf"/>
                            <div class="card-body">
                                <div className="row">
                                <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Product Category *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="cmbCategory" name="cmbCategory" className="form-control required">
                                                  <option value="0">-Select Category-</option>
                                                  {this.state.listCategory.map((item,index)=>{
                                                    return(<option value={item.id}>{item.category_name}</option>)
                                                  })}
                                                </select>
                                            </div>
                                        </div>
                                  </div>

                                <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Product Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="txt_name" name="txt_name" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div>

                                  <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Product Code</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="txt_code" name="txt_code" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  
                                  <div className="col-md-3">
                                    <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    {/* <label class="btn btn-danger">Product Cost</label> */}
                    <button class="btn btn-danger">Product Cost</button>
                  </div>
                 
                  <input id="pre_cost" name="pre_cost" type="text" maxlength={10} autoComplete={"off"} class="form-control required cd_input_change" />
                </div>
                                    </div>

                                    <div className="col-md-3">
                                    <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-secondary">Discount(%)</button>
                  </div>
                 
                  <input id="offer_per" name="offer_per" type="text" autoComplete={"off"} maxlength={3} class="form-control cd_input_change" />
                </div>
                                    </div>

                                    <div className="col-md-3">
                                    <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-danger">Final Cost</button>
                  </div>
                 
                  <input id="txt_cost" readOnly={true} name="txt_cost" type="text" autoComplete={"off"}  maxlength={10} class="form-control required" />
                </div>
                                    </div>                
                                 

                                    <div className="col-md-3">
                                    <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-secondary">Product Weight</button>
                  </div>
                 
                  <input id="txt_weight" name="txt_weight" type="text" maxlength={10} class="form-control required" />
                </div>
                                    </div>

                            <div className="col-md-3">
                                    <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-secondary">Product Unit</button>
                  </div>
                 
                  <select id="cmbUnit" name="cmbUnit" className="form-control required">
                  <option>Select</option>
<option value="ML">ML</option>
<option value="GM">GM</option>
<option value="KG">KG</option>
<option value="NO">NO</option>
<option value="LTR">LTR</option>
                                                  
                                                </select>
                </div>
                                    </div>

                                    <div className="col-md-3">
                                    <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-secondary">Available Stock</button>
                  </div>
                 
                  <input id="txt_unitquantity_max" name="txt_unitquantity_max" type="text" maxlength={10} class="form-control required" />
                </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            {/* <label for="exampleInputEmail1">Vendor (if any)</label> */}
                                            <div className="input-group input-group-sm">
                                                <select id="vendor_id" name="vendor_id" className="form-control required11">
                                                  <option value="0">-Select Vendor-</option>
                                                  {this.state.stVendoList.map((item,index)=>{
                                                    return(<option value={item.id}>{item.first_name}</option>)
                                                  })}
                                                </select>
                                            </div>
                                        </div>
                                  </div>
                                    
                                </div>
                                <div class="row">
                                <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Tax Slab</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="tax_slab" name="tax_slab" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Package Length</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="p_length" name="p_length" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Package Width</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="p_width" name="p_width" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>                              
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Package Height</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="p_height" name="p_height" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div> 

 {/* file image upload */}
 <div className="col-md-12">
 <div className="card card-info">
<div className="card-header">
  <h3 className="card-title">Image Upload </h3><small> (Max File Size 3MB) </small>
</div>
<div className="card-body">
<div className="row audioLibThumb imgLibThumb">
                      
                      </div>
  <div className="row">
    
    <div className="col-12">
    <input type="file" ref_img="ImageThumbBack" multiple name="customFileBr" id="customFileBr" accept="image/*" size={10000000} className="updImgThumb" />
      <a href="javascript:void(0)" setTo="customFileBr" className="_brFileThumb updthumb">Click & Browse Image Files</a>
    </div>
   
   
  </div>
</div>

</div>
</div>

                               {/* end of file image upload */}
                                    
                               
<div className="col-md-8">
    <div className="row">
    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Short Description *</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="txtProDes" name="txtProDes"></textarea>
                                            </div>
                                        </div>
                                    </div>
                              
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">OverView *</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="txtUsa" name="txtUsa"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Features *</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="txtCom" name="txtCom"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Specifications *</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="txtFAQ" name="txtFAQ"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                </div>  
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                                <div class="row">
                                    <div class="col-12">
                                      
                                        <div class="form-group">
                                        <center>
                                            <div class="input-group input-group-sm" style={{"justify-content":"center"}}>
                                                <button type="button" onClick={()=>this.saveWappCompaign()} class="btn btn-primary">Save and Review</button>
                                            </div>
                                        </center>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>
                            </form>
    </div>
    </div>
</section>
      
</div>
    
</div>
</div>
</div>
<Alert_Content/>
<Footer/>

          </div>)
      }
}
export default Add_Product_Master;