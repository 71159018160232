import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack} from '../function_lib/common_lib';
import Header from './header';
import Footer from './footer';
import Comp_Submit_Compliance from '../components/comp_submit_compliance';
import Option_Module from './option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';
import ImgCompNotific from '../assets/images/compl_notific.png';


let loginUser="";
let userObject="";
let companyName="";
let clsObject;
class Notification_Updates extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        clsObject=this;
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        this.state={stNotifications:[],stCompData:[]};

        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      }
 getNotification(){
  let libType=[];
    libType.push({name:"curl_type",value:"getNotifications"});
    libType.push({name:"res_func",value:"callBackEntityList"});
    libType.push({name:"app_user_id",value:userObject[0].app_user_id});
    libType.push({name:"status",value:"allow"});
    libType["res_func"]="callBackEntityList";
    libType["curl_type"]="getNotifications";
    CallMapMethod_CBack(this,libType,function(data,msg){
      //alert(data);
      if(data!=="0" && data!==""){
        let listNotifications=JSON.parse(data);
        clsObject.setState({stNotifications:listNotifications});
      }
    });
}
setComplianceFillingData(complianceId){
  $("#divUploadDoc").modal();
  //$('.modal-backdrop').appendTo('.display_details_noti');
  let libType=[];
  libType.push({name:"curl_type",value:"getIndividualCompliance"});
  libType.push({name:"res_func",value:"callBackEntityList"});
  libType.push({name:"app_user_id",value:userObject[0].app_user_id});
  libType.push({name:"compliance_id",value:complianceId});
  libType.push({name:"status",value:"allow"});
  libType["res_func"]="callBackEntityList";
  libType["curl_type"]="getIndividualCompliance";
  CallMapMethod_CBack(this,libType,function(data,msg){
    //alert(msg);
    //alert(data);
    if(data!=="0" && data!==""){
      let listCompData=JSON.parse(data);
      if(listCompData.length>0)
      clsObject.setState({stCompData:listCompData[0]});
    }
  });
}
componentDidMount(){
   
  this.getNotification();
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
}
      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
    
<div className="row">
<div className="col-md-4"> 
<div class="card-footer card-comments">
  {this.state.stNotifications.map((item,index)=>{
return(<div class="card-comment">
        <a onClick={()=>this.setComplianceFillingData(item.encKey)}  href='javascript:void(0)'>          
<img class="img-circle img-sm" src={ImgCompNotific} alt="User Image" />

<div class="comment-text">
  <span class="username">
    {item.sender}
    <span class="text-muted float-right">{item.f_date}-{item.date_diff.trim()==="0"?"Today":item.date_diff+" Days Ago"}</span>
  </span>
  {item.details}
</div>
</a>
</div>)
  })}

  </div>
</div>
      <div className="col-lg-8 col-md-8 col-sm-12 col-12">
           {/* acta component */}
    
    {/* end of acta component */}
      <div id="display_details_noti" className="card display_details_noti" style={{height:"50vh",overflow:"scroll"}}>
              {/* <!-- /.card-header --> */}
           {/* <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Notice List</b></h3>
              </div> */}
             
              <Comp_Submit_Compliance compData={this.state.stCompData}  />
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>

          </div>)
      }
}
export default Notification_Updates;