import React from 'react';
const Manage_Vendor=(props)=>{
    //alert(JSON.stringify(props));
        return(<div className="modal fade bd-example-modal-xl"  id="divManageVendor" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Manage Vendor</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            <form id="frmManageVendor" name="frmManageVendor">
            <div className="modal-body">
            <div className="row">
                      {/* <!-- left column --> */}
                      <div className="col-md-12">
                          <div className="card card-danger">
                                  <input type="hidden" name="res_func" id="res_func" value="okSuccVendor" />
                                  <input type="hidden" name="curl_type" id="curl_type" value="editProductVendor" />
                                  <input type="hidden" name="m_type" id="m_type" value="editProductVendor" />
                                  <input type="hidden" name="type_id" id="type_id" value="2" />
                                  <input type="hidden" name="product_id" id="product_id" value={typeof props.editProduct.rf_id!=="undefined"?props.editProduct.rf_id:""} />
                                  <input type="hidden" name="local_token" id="local_token" value={props.user_object[0].app_user_id} />
                                  <div className="card-body">
                                      <div className="row">
                                     
                                          <div className="col-lg-12 col-md-12">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Select Vendor</label>
                                                  <select  className="form-control form-control-sm" id="mgm_Vendor" name="mgm_Vendor">
           <option value="0">-Vendor List-</option>
           {props.ListVendors.map((item,index)=>{
            return (<option selected={item.id==props.editProduct.vendor_id} value={item.id}>{item.first_name}</option>)
           })}
           {/* <option value="1">-Reseller-</option>
           <option value="3">-User-</option> */}
          
         </select>
                                              </div>
                                          </div>
                                      </div>
                                    
                                      <div className="col-md-6 respSSL"></div>
                                      <div id="testing2dd2222"></div>
                                  </div>
                              
                          </div>
                      </div>
                  </div>
            </div>
            <div className="modal-footer">
            <div id="getReponse" style={{color:"red"}}></div>
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
            </div>
            </form>
          </div>
          </div>
          </div>
      );
}
export default Manage_Vendor;
