import $ from 'jquery';
import React from 'react';
import Chart from "react-google-charts";
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod, GetDateDifferecec} from '../function_lib/common_lib';
import Header from './header';
import Footer from './footer';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';

import Upcoming_Events from '../components/upcoming_events';
import Emp_On_Leave from '../components/emp_on_leave';
import Broadcast from '../components/broadcast';
import Mobile_Widgets from '../components/mobile_widgets';

//import UserManual from '../assets/Operational_Instructions.pdf';

let loginUser="";
let userObject="";
let companyName="";
let userType="";
class User_Dashboard extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        this.state={country:[],line_of_business:[],type_of_clain:[],company:[],group_name:[] };
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        userType="0";
        
        if(localStorage.getItem("userData")!=null){
            userObject= getUserObjData();
            //alert(JSON.stringify(userObject));
            loginUser=userObject[0].first_name;
            companyName=userObject[0].first_name;
        }
      }

      componentDidMount(){
        $("#divEmployee").show();
        var arrCountry=new Array();
        arrCountry.push(["Complaint","Non Compliant"])
        arrCountry.push(["Compliant",parseInt("90")]);
        arrCountry.push(["Non Compliant",parseInt("10")]);

        var arrGroupName=new Array();
        arrGroupName.push(["Month",""]);
        arrGroupName.push(["Jan-2022",parseInt("99")]);
        arrGroupName.push(["Feb-2022",parseInt("90")]);
        arrGroupName.push(["Mar-2022",parseInt("95")]);
        arrGroupName.push(["Apr-2022",parseInt("99")]);
        arrGroupName.push(["May-2022",parseInt("90")]);
        arrGroupName.push(["Jun-2022",parseInt("0")]);
        arrGroupName.push(["July-2022",parseInt("0")]);
        arrGroupName.push(["Aug-2022",parseInt("0")]);
        arrGroupName.push(["Sept-2022",parseInt("0")]);
        arrGroupName.push(["Oct-2022",parseInt("0")]);
        arrGroupName.push(["Nov-2022",parseInt("0")]);
        arrGroupName.push(["Dec-2022",parseInt("0")]);

        this.setState({country:arrCountry});
        this.setState({group_name:arrGroupName});
        this.forceUpdateHandler();
       
      }
     
   
     

    render(){
        
      
        return(<div className="wrapper">
            <Header/>
            {/* <Mobile_Widgets/> */}
            <div class="content">
            <div class="container-fluid mt-4">
                   {/* new acta component */}
                   <div className="row">
<div className="col-lg-4 col-6">
<a href='../entity_module'>
  <div className="small-box bg-info">
    <div className="inner">
      <h3>150</h3>

      <p>Entity Module</p>
    </div>
    <div className="icon">
      <i className="fas fa-shopping-cart"></i>
    </div>
    <a href="../entity_module" className="small-box-footer">
      More info <i className="fas fa-arrow-circle-right"></i>
    </a>
  </div>
  </a>
</div>

<div className="col-lg-4 col-6">
<a href="../vendor_audit">
  <div className="small-box bg-danger">
    <div className="inner">
      <h3>65</h3>

      <p>Vendor Audit Module</p>
    </div>
    <div className="icon">
      <i className="fas fa-chart-pie"></i>
    </div>
    <a href="../vendor_audit" className="small-box-footer">
      More info <i className="fas fa-arrow-circle-right"></i>
    </a>
  </div>
  </a>
</div>

<div className="col-lg-4 col-6">
<a href='../client_site'>
  <div className="small-box bg-warning">
    <div className="inner">
      <h3>44</h3>
      <p>Client Site Compliance Module</p>
    </div>
    <div className="icon">
      <i className="fas fa-user-plus"></i>
    </div>
    <a href="../client_site" className="small-box-footer">
      More info <i className="fas fa-arrow-circle-right"></i>
    </a>
  </div>
  </a>
</div>


</div>
                {/* end of new acta component */}
<div class="row">
<div class="col-md-2">
              {/* <!-- Widget: user widget style 2 --> */}
              <div class="card card-widget widget-user-2 shadow ">
                  {/* <!-- Add the bg color to the header using any of the bg-* classes --> */}
                  <div class="widget-user-header" style={{backgroundColor:"#B82D17"}}>
                  <ul class="nav flex-column">
                  <li class="nav-item">
                              <a href="#" class="nav-link text-white">
                                   <h6>Calender Complaince</h6>
                              </a>
                          </li>
                        
                  </ul>
                  </div>
                  <div class="card-footer p-0">
                      <ul class="nav flex-column">
         
                   
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                 <h6> <i class="fa fa-align-left1 text-orange"></i>Due Till Date<span class="float-right badge bg-primary dash_no_claim1">12</span></h6>
                              </a>
                          </li>
                         
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-allergies text-orange"></i>Upcoming<span class="float-right badge bg-success dash_approved_claim1">0</span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i>Overdue<span class="float-right badge bg-danger dash_rejected_claim1">0</span></h6>
                              </a>
                          </li>
                      </ul>
                  </div>
             
              {/* <!-- /.widget-user --> */}
          </div>
</div>
<div class="col-md-2">
              {/* <!-- Widget: user widget style 2 --> */}
              <div class="card card-widget widget-user-2 shadow ">
                  {/* <!-- Add the bg color to the header using any of the bg-* classes --> */}
                  <div class="widget-user-header bg-info">
                  <ul class="nav flex-column">
                  <li class="nav-item">
                              <a href="#" class="nav-link text-white">
                                   <h6>Score</h6>
                              </a>
                          </li>
                        
                  </ul>
                  </div>
                  <div class="card-footer p-0">
                      <ul class="nav flex-column">
         
                   
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                 <h6> <i class="fa fa-align-left1 text-orange"></i>Entity<span class="float-right badge bg-primary dash_no_claim1">86%</span></h6>
                              </a>
                          </li>
                         
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-allergies text-orange"></i>Client Site<span class="float-right badge bg-success dash_approved_claim1">20%</span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i>Contractor<span class="float-right badge bg-danger dash_rejected_claim1">80%</span></h6>
                              </a>
                          </li>
                      </ul>
                  </div>
             
              {/* <!-- /.widget-user --> */}
          </div>
</div>

<div class="col-md-2">
              {/* <!-- Widget: user widget style 2 --> */}
              <div class="card card-widget widget-user-2 shadow ">
                  {/* <!-- Add the bg color to the header using any of the bg-* classes --> */}
                  <div class="widget-user-header bg-info">
                  <ul class="nav flex-column">
                  <li class="nav-item">
                              <a href="#" class="nav-link text-white">
                                   <h6>Department Notice</h6>
                              </a>
                          </li>
                        
                  </ul>
                  </div>
                  <div class="card-footer p-0">
                      <ul class="nav flex-column">
         
                   
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                 <h6> <i class="fa fa-align-left1 text-orange"></i>Open<span class="float-right badge bg-primary dash_no_claim1">1</span></h6>
                              </a>
                          </li>
                         
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-allergies text-orange"></i>Under Process<span class="float-right badge bg-success dash_approved_claim1">1</span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i>Closed<span class="float-right badge bg-danger dash_rejected_claim1">0</span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i>Overdues<span class="float-right badge bg-danger dash_rejected_claim1">0</span></h6>
                              </a>
                          </li>
                      </ul>
                  </div>
             
              {/* <!-- /.widget-user --> */}
          </div>
</div>
 {/*New Logics0901 */}
 <div className="col-md-5">
     
     <div className="card">
      <div className="card-header bg-gradient-cyan">
      <i className="fa fa-address-card"></i> <b>Overall Preformance</b>
      
      
      
      
      </div>
      <div className="card-body1" style={{height:"200px",overflow:"scroll"}}>
          {this.state.country.length>0?<Chart
  width={'100%'}
  height={'50vh'}
  chartType="PieChart"
  loader={<div>Loading Chart</div>}
  data={this.state.country}
  options={{
    title: 'Performance',
    // Just add this option
    is3D: true,
  }}
  rootProps={{ 'data-testid': '2' }}
/>
          :''}
      
          </div>
          </div>
          </div>
      
          <div class="col-md-5">
              {/* <!-- Widget: user widget style 2 --> */}
              <div class="card card-widget widget-user-2 shadow ">
                  {/* <!-- Add the bg color to the header using any of the bg-* classes --> */}
                  <div class="widget-user-header" style={{backgroundColor:"#B82D17"}}>
                  <ul class="nav flex-column">
                  <li class="nav-item">
                              <a href="#" class="nav-link text-white">
                                   <h6>Compliance by Type - Completed/Pending</h6>
                              </a>
                          </li>
                        
                  </ul>
                  </div>
                  <div class="card-footer p-0">
                      <ul class="nav flex-column">
         
                   
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                 <h6> <i class="fa fa-align-left1 text-orange"></i>Registration<span class="float-right badge bg-primary dash_no_claim1">10/0</span></h6>
                              </a>
                          </li>
                         
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-allergies text-orange"></i>License<span class="float-right badge bg-success dash_approved_claim1">1/0</span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i>Rule<span class="float-right badge bg-danger dash_rejected_claim1">3/0</span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i>Returns<span class="float-right badge bg-danger dash_rejected_claim1">4/0</span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i>Remittance<span class="float-right badge bg-danger dash_rejected_claim1">24/0</span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i>Notice & Abstract<span class="float-right badge bg-danger dash_rejected_claim1">30/0</span></h6>
                              </a>
                          </li>
                      </ul>
                  </div>
             
              {/* <!-- /.widget-user --> */}
          </div>
</div>
        
<div class="col-md-3">
              {/* <!-- Widget: user widget style 2 --> */}
              <div class="card card-widget widget-user-2 shadow ">
                  {/* <!-- Add the bg color to the header using any of the bg-* classes --> */}
                  <div class="widget-user-header bg-info">
                  <ul class="nav flex-column">
                  <li class="nav-item">
                              <a href="#" class="nav-link text-white">
                                   <h6>Entity Registration/License</h6>
                              </a>
                          </li>
                        
                  </ul>
                  </div>
                  <div class="card-footer p-0">
                      <ul class="nav flex-column">
         
                   
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                 <h6> <i class="fa fa-align-left1 text-orange"></i>Total<span class="float-right badge bg-primary dash_no_claim1">10</span></h6>
                              </a>
                          </li>
                         
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-allergies text-orange"></i>Valid<span class="float-right badge bg-success dash_approved_claim1">9</span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i>Expired<span class="float-right badge bg-danger dash_rejected_claim1">1</span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i>Coming Renewals<span class="float-right badge bg-danger dash_rejected_claim1">4</span></h6>
                              </a>
                          </li>
                      </ul>
                  </div>
             
              {/* <!-- /.widget-user --> */}
          </div>
</div>
<div class="col-md-2">
              {/* <!-- Widget: user widget style 2 --> */}
              <div class="card card-widget widget-user-2 shadow ">
                  {/* <!-- Add the bg color to the header using any of the bg-* classes --> */}
                  <div class="widget-user-header" style={{backgroundColor:"#B82D17"}}>
                  <ul class="nav flex-column">
                  <li class="nav-item">
                              <a href="#" class="nav-link text-white">
                                   <h6>Vendor License</h6>
                              </a>
                          </li>
                        
                  </ul>
                  </div>
                  <div class="card-footer p-0">
                      <ul class="nav flex-column">
         
                   
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                 <h6> <i class="fa fa-align-left1 text-orange"></i>Total<span class="float-right badge bg-primary dash_no_claim1">10</span></h6>
                              </a>
                          </li>
                         
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-allergies text-orange"></i>Valid<span class="float-right badge bg-success dash_approved_claim1">9</span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i>Expired<span class="float-right badge bg-danger dash_rejected_claim1">1</span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i>Coming Renewals<span class="float-right badge bg-danger dash_rejected_claim1">4</span></h6>
                              </a>
                          </li>
                      </ul>
                  </div>
             
              {/* <!-- /.widget-user --> */}
          </div>
</div>

<div class="col-md-2">
              {/* <!-- Widget: user widget style 2 --> */}
              <div class="card card-widget widget-user-2 shadow ">
                  {/* <!-- Add the bg color to the header using any of the bg-* classes --> */}
                  <div class="widget-user-header" style={{backgroundColor:"#B82D17"}}>
                  <ul class="nav flex-column">
                  <li class="nav-item">
                              <a href="#" class="nav-link text-white">
                                   <h6>Client Site License</h6>
                              </a>
                          </li>
                        
                  </ul>
                  </div>
                  <div class="card-footer p-0">
                      <ul class="nav flex-column">
         
                   
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                 <h6> <i class="fa fa-align-left1 text-orange"></i>Total<span class="float-right badge bg-primary dash_no_claim1">10</span></h6>
                              </a>
                          </li>
                         
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-allergies text-orange"></i>Valid<span class="float-right badge bg-success dash_approved_claim1">9</span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i>Expired<span class="float-right badge bg-danger dash_rejected_claim1">1</span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i>Coming Renewals<span class="float-right badge bg-danger dash_rejected_claim1">4</span></h6>
                              </a>
                          </li>
                      </ul>
                  </div>
             
              {/* <!-- /.widget-user --> */}
          </div>
</div>



   
        
          <div className="col-md-7">
     
     <div className="card">
      <div className="card-header bg-gradient-cyan">
      <i className="fa fa-address-card"></i> <b>Year 2022</b>
      </div>
      <div className="card-body" style={{height:"400px",overflow:"scroll"}}>
      <Chart
    width={'100%'}
    height={300}
    chartType="ColumnChart"
    loader={<div>Loading Chart</div>}
    data={this.state.group_name}
    options={{
      title: 'Approved vs Rejected',
      chartArea: { width: '100%' },
      hAxis: {
        title: 'Group Name',
        minValue: 0,
      },
      vAxis: {
        title: 'Amount',
      },
    }}
    legendToggle
  />
          </div>
          </div>
          </div>
        
   
 
{/* <!-- /.container-fluid --> */}
</div>
{/* <!-- /.content --> */}
</div>
      </div> 
        <Footer/>
        
      </div>)
    }
}
export default User_Dashboard