import React from 'react';
const Wa_Credit_Balance_Comp=(props)=>{
    
        return(<div className="modal fade bd-example-modal-xl"  id="divWaCreditBalance" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Credit Balance</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            <form id="frmWaCreditBalance" name="frmWaCreditBalance">
           
            <div className="modal-body">
            <div className="row">
                      {/* <!-- left column --> */}
                      <div className="col-md-12">
                          <div className="card card-danger">
                                  <input type="hidden" name="res_func" id="res_func" value="okSuccCreditB" />
                                  <input type="hidden" name="curl_type" id="curl_type" value="addCreditBalance" />
                                  <input type="hidden" name="m_type" id="m_type" value="addCreditBalance" />
                                  <input type="hidden" name="type_id" id="type_id" value="2" />
                                  <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={props.creditUser.id} />
                                  <input type="hidden" name="added_by_id" id="added_by_id" value={props.app_user_id}/>
                                  <div className="card-body">
                                      <div className="row">
                                     
                                          <div className="col-lg-2 col-md-2">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Business/Person Name</label>
                                                  <div className="input-group input-group-sm">
                                                      <input type="text" readonly={"true"} id="first_name" name="first_name" className="form-control" value={typeof props.creditUser.first_name!=="undefined"?props.creditUser.first_name:''}/>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="col-lg-2 col-md-2">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">User Name</label>
                                                  <div className="input-group input-group-sm">
                                                      <input type="text" readonly={"true"} id="user_name" name="user_name" className="form-control" value={typeof props.creditUser.user_name!=="undefined"?props.creditUser.user_name:''} />
                                                  </div>
                                              </div>
                                          </div>
                                         
                                          <div className="col-lg-2 col-md-2">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Add/Deduct *</label>
                                                  <div className="input-group input-group-sm">
                                                      <select id="trans_type" name="trans_type" className="form-control required">
                                                        <option value="0">-User Type-</option>
                                                        <option value="CREDIT">Add</option>
                                                        <option value="DEBIT">Deduct</option>
                                                      </select>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="col-lg-2 col-md-2">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Route Name *</label>
                                                  <div className="input-group input-group-sm">
                                                      <select id="wa_msg_type_ma_id" name="wa_msg_type_ma_id" className="form-control required">
                                                        <option value="0">-Route-</option>
                                                        {props.masterBalance.map((item,index)=>{
                                                          let vBalance=0;
                                                          if(item.balance){
                                                            vBalance=item.balance;
                                                          }
                                                          return (<option value={item.id}>{item.route_name+" - "+vBalance}</option>)
                                                        })}
                                                      </select>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="col-lg-2 col-md-2">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Balance *</label>
                                                  <div className="input-group input-group-sm">
                                                  <input type="number" id="trans_value" name="trans_value" className="form-control required" placeholder="" />
                                                  </div>
                                              </div>
                                          </div>
                                   <div className="col-lg-12 col-md-12">
                                   <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>User</th>
                      <th>Route</th>
                      <th>Credit Balance</th>
                      <th>Available Balance</th>
                      <th>Resent Date</th>
                      
                    </tr>
                  </thead>
                  <tbody id="tbData">
                  {(props.creditBalance.map((item,index)=>{
                       return(<tr>
                        <td>{index+1}</td>
                    <td>{item.user_name}</td>
                  <td>{item.route_name}</td>
                  <td>{item.trans_value}</td>
                  <td>{item.balance}</td>
                  <td>{item.c_update_date}</td>
                    </tr>)
                  }))}
                  </tbody>
                </table>

                                   </div>
                                      </div>
                                    
                                      <div className="col-md-6 respSSL"></div>
                                      <div id="testing2dd2222"></div>
                                  </div>
                              
                          </div>
                      </div>
                  </div>
            </div>
            <div className="modal-footer">
            <div id="getReponse" style={{color:"red"}}></div>
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
            </div>
            </form>
          </div>
          </div>
          </div>);
    
}
export default Wa_Credit_Balance_Comp;
