import $ from 'jquery';
import React from 'react';
import {StateMaster} from '../function_lib/city_india';
import {btnSetColor_CBack} from '../function_lib/common_lib';
//import {listData} from '../function_lib/customeData';
let listBranchDisplay={ tt: [] };
let stateList=[];

class Comp_Add_Branches extends React.Component{
    

    constructor(props){
        super(props);
        stateList=StateMaster();
      
      
    }
    
    componentWillReceiveProps(props){
        this.props=props;
        //alert(this.props.partyInfoData.id);
        //alert(JSON.stringify(props));
        if(typeof props.partyInfoId!=="undefined"){
            if(props.partyInfoId!=="" && props.partyInfoId!=="0"){
            //    this.setState({partyInfoId:props.partyInfoId});
            //    this.forceUpdateHandler();
            }
            
        }
    }
 addToBranchData(frmName){
    btnSetColor_CBack(this,frmName,"addNewBranche",function(data,msg){
        if(data==="001"){
            alert("Branch Added Successfully");
            $("#frmCreateBranch").find("input[type=text]").val("");
        }else{
            alert("Unable to Add Record !kindly check and try again");
        }
        
    });
 }   
addToList(){
    //let clsAddBranch=new listData("sdfsdf","sdfsdf");
   
    const array = $("#frmCreateBranch").serializeArray(); // Encodes the set of form elements as an array of names and values.
    const json = {};
    $.each(array, function () {
      json[this.name] = this.value || "";
    });
    //return json;
    listBranchDisplay.tt.push(json);
    //alert(JSON.stringify(listBranchDisplay));
    let strList="";
    for(let i=0;i<listBranchDisplay.tt.length;i++){
       strList+="<tr>"+
                "<td>"+listBranchDisplay.tt[i]["col1"]+"</td>"+
                "<td>"+listBranchDisplay.tt[i]["col7"]+"</td>"+
                "<td>"+listBranchDisplay.tt[i]["col9"]+"</td>"+
                "<td>"+listBranchDisplay.tt[i]["col10"]+"</td>"+
                "<td>"+listBranchDisplay.tt[i]["col15"]+"</td>";
    }
    $("#list_branches").html(strList);
    $("#entity_branches").val(JSON.stringify(listBranchDisplay));
    $("#frmCreateBranch").find("input[type=text]").val("");
    alert("Branch Added Successfully");


}    
render(){
    return(<div className="modal fade bd-example-modal-xl"  id="divUpdBranches" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Add Branches {(typeof this.props.partyInfoData.company_name!=="undefined")?" : To"+this.props.partyInfoData.company_name:''}</h5>
          <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmCreateBranch" name="frmCreateBranch">
        <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                              <input type="hidden" name="curl_type" id="curl_type" value="addNewBranche" />
                              <input type="hidden" name="m_type" id="m_type" value="addNewBranche" />
                              <input type="hidden" name="type_id" id="type_id" value="2" />
                              <input type="hidden" name="app_user_id" id="app_user_id" value={(typeof this.props.partyInfoData.id==="undefined")?"0":this.props.partyInfoData.id} />
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  <div className="col-md-12">
                      <div className="card card-danger">
                             
                              <div className="card-body">
                                  <div className="row">
                                 
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Branch Name</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" id="col1" name="col1" className="form-control" placeholder="Branch Name" />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Location</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" id="col2" name="col2" className="form-control" placeholder="Location" />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Date of Opening</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" id="col3" name="col3" className="form-control" placeholder="Date" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Closing Date</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" id="col4" name="col4" className="form-control" placeholder="Closing Date" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">State</label>
                                              <div className="input-group input-group-sm">
                                              <select className="form-control form-control-sm" id="col5" name="col5">
                <option value="0">-Select State-</option>
                {stateList.length>0?stateList.map((item,index)=>{
                                                    return (<option value={item.location_id}>{item.name}</option>)
                                                  }):''}
              </select>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">District</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" id="col6" name="col6" className="form-control" placeholder="District" />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-6 col-md-12">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Address</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" id="col7" name="col7" className="form-control" placeholder="Address" />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Police Station</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" id="col8" name="col8" className="form-control" placeholder="Police Station" />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Nature of Service</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" id="col9" name="col9" className="form-control" placeholder="Nature of Service" />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Covered Under</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" id="col10" name="col10" className="form-control" placeholder="Covered Under" />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Opening Hours (24Hr Clock)</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" id="col11" name="col11" className="form-control" placeholder="Opening Hours" data-inputmask-alias="datetime" data-inputmask-inputformat="HH:MM" data-mask  />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="row">
                                <table class="table table-bordered table-sm">
                                <thead>
                                    <tr style={{backgroundColor:"#1a857a",color:"#fff"}}>
                                        <td>
                                            Count of Employee
                                        </td>
                                        <td>
                                            Male
                                        </td>
                                        <td>
                                            Female
                                        </td>
                                        <td>
                                            Transgender
                                        </td>
                                        <td>
                                            Total
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>
                                            Count of Employee
                                        </td>
                                        <td>
                                            <input type="number" id="col12" name="col12" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="number" id="col13" name="col13" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="number" id="col14" name="col14" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="number" id="col15" name="col15" className='form-control form-control-sm'/>
                                        </td>
                                    </tr>
                                    
                                    </thead>
                                </table>
                                </div>
                                <div class="row">
                                <table class="table table-bordered table-sm">
                                <thead>
                                    <tr style={{backgroundColor:"#1a857a",color:"#fff"}}>
                                        <td>
                                            Working Hours
                                        </td>
                                        <td>
                                            Opening
                                        </td>
                                        <td>
                                            Closing
                                        </td>
                                        <td>
                                            Lunch
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>
                                        Working Hours
                                        </td>
                                        <td>
                                        <input type="text" id="col16" name="col16" placeholder="Opening Time" data-inputmask-alias="datetime" data-inputmask-inputformat="HH:MM" data-mask   className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="text" id="col17" name="col17" placeholder="Closing Time" data-inputmask-alias="datetime" data-inputmask-inputformat="HH:MM" data-mask   className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="text" id="col18" name="col18" placeholder="Closing Time" data-inputmask-alias="datetime" data-inputmask-inputformat="HH:MM" data-mask className='form-control form-control-sm'/>
                                        </td>
                                    </tr>
                                    
                                    </thead>
                                </table>
                                </div>
                                <div class="row">
                                <div className="col-lg-3 col-md-6">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Weather works in shift</label>
                                              <div className="input-group input-group-sm">
                                                  <input type="text" id="col19" name="col19" className="form-control" placeholder="Opening Hours" />
                                              </div>
                                          </div>
                                      </div>

                                </div>
                                <div className='row'>
                                <table class="table table-bordered table-sm">
                                <thead>
                                    <tr style={{backgroundColor:"#1a857a",color:"#fff"}}>
                                        <td>
                                            Name of Employer
                                        </td>
                                        <td>
                                            Mobile No.
                                        </td>
                                        <td>
                                            Email
                                        </td>
                                        <td>
                                            Residential Address
                                        </td>
                                        <td>
                                            Aadhar
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>
                                    <input type="text" id="col20" name="col20" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="number" id="col21" name="col21" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="text" id="col22" name="col22" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="text" id="col23" name="col23" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="number" id="col24" name="col24" className='form-control form-control-sm'/>
                                        </td>
                                    </tr>
                                    
                                    </thead>
                                </table>

                                </div>
                                <div className='row'>
                                <table class="table table-bordered table-sm">
                                <thead>
                                    <tr style={{backgroundColor:"#1a857a",color:"#fff"}}>
                                        <td>
                                            Name of Manager
                                        </td>
                                        <td>
                                            Mobile No.
                                        </td>
                                        <td>
                                            Email
                                        </td>
                                        <td>
                                            Residential Address
                                        </td>
                                        <td>
                                            Aadhar
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>
                                    <input type="text" id="col25" name="col25" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="number" id="col26" name="col26" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="text" id="col27" name="col27" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="text" id="col28" name="col28" className='form-control form-control-sm'/>
                                        </td>
                                        <td>
                                        <input type="number" id="col29" name="col29" className='form-control form-control-sm'/>
                                        </td>
                                    </tr>
                                    
                                    </thead>
                                </table>

                                </div>
                               
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                              </div>
                          
                      </div>
                  </div>
              </div>
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
          {(this.props.partyInfoId==="0" || this.props.partyInfoId===0)?<button type="button" onClick={()=>this.addToList()} className="btn btn-primary">Save changes</button>:
          <button type="button" onClick={()=>this.addToBranchData("frmCreateBranch")}  className="btn btn-primary ">Save changes</button>}
          
        </div>
        </form>
      </div>
      </div>
      </div>)
}
}
export default Comp_Add_Branches;