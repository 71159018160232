import { type } from 'jquery';
import React from 'react';
export const Comp_View_Document=(docPath)=>{
 
  
    return(<div className="modal fade bd-example-modal-xl"  id="divEmpViewLibDoc" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Document Compliance</h5>
          <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmC" name="frmC">
       
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  <div className="col-md-12">
                      <div className="card card-danger">
                        <embed id="samp_comp_pa" width="100%" height="500px" src={docPath.docPath} toolbar="0"></embed>
                      </div>
                  </div>
              </div>
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
        </form>
      </div>
      </div>
      </div>)
}
export default Comp_View_Document;