import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,appGetUrlPath,CallMapMethod_CBack,getAppUrl} from '../function_lib/common_lib';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Option_Module from '../user_pages/option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';
import Comp_View_Media from '../components/comp_view_media';


let loginUser="";
let userObject="";
let companyName="";
let appUrtPath;

let clsPushMessages;
let wAppUrl="";
class Push_Messages extends React.Component{
  
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      clsPushMessages=this;
      appUrtPath=appGetUrlPath();
      this.state={ listMessagesShoot:[],media:""};
      this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      wAppUrl=getAppUrl();
      }
      setMedia(strMedia){
        //alert(strMedia);
        this.setState({media:strMedia},()=>{
          //clsPushMessages.forceUpdateHandler();
        });
      }
      getMsgTypeMaster(){
        
        var map=[];
        map.push({name:"curl_type",value:"getWaMsgTypeMaster"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getWaMsgTypeMaster";
        CallMapMethod_CBack(clsPushMessages,map,function(data,msg){
          
          if(data!=="0"){
               let lsMaster=JSON.parse(data);
               let strMaster="<option value=\"0\">-Camp Type-</option>";
               for(let i=0;i<lsMaster.length;i++){
                  strMaster+="<option value=\""+lsMaster[i]["route_name"]+"\">"+
                                lsMaster[i]["route_name"]+"</option>";
               }
               $("#ser_msg_type").html(strMaster);
          }
        })
      }

      relCron(shoot_id){
        var map=[];
        map.push({name:"curl_type",value:"relCron"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"shoot_id",value:shoot_id});
        map.push({name:"deli_status",value:"QUEUED"});
        map.push({name:"nos_queued",value:$("#ns_queued"+shoot_id).val()})
        map["res_func"]="callBackrrr";
        map["curl_type"]="relCron";
        CallMapMethod_CBack(clsPushMessages,map,function(data,msg){
          alert(data);
        })
      }

      submitToQueue(shoot_id){
        var map=[];
        map.push({name:"curl_type",value:"pushToCronQueue"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"shoot_id",value:shoot_id});
        map.push({name:"deli_status",value:"PENDING"});
        map.push({name:"nos_queued",value:$("#ns_queued"+shoot_id).val()})
        map["res_func"]="callBackrrr";
        map["curl_type"]="pushToCronQueue";
        CallMapMethod_CBack(clsPushMessages,map,function(data,msg){
          alert(data);
        })
      }

      submitNonWaNumber(shoot_id){
        if($("#non_wa"+shoot_id).val().trim()===""){
          alert("Add Non Whats App Number First!..");
          return;
        }
        var map=[];
        map.push({name:"curl_type",value:"submitNonWhatsApp"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"shoot_id",value:shoot_id});
        map.push({name:"contacts",value:$("#non_wa"+shoot_id).val()});
        map.push({name:"group",value:"1"});
        map["res_func"]="callBackrrr";
        map["curl_type"]="submitNonWhatsApp";
        CallMapMethod_CBack(clsPushMessages,map,function(data,msg){
        
          if(data!=="0" && data!==""){
            let listData=JSON.parse(data);
            $("#cpy_number").text(listData[0].contacts);
          }
        })
      }

      getMSGContent(msg_content){
        $("#cpy_number").text(msg_content);
      }

      getExpContacts(shoot_id,type){
        let expUri=wAppUrl+"getCopyContactsCSV_TXT&local_token="+userObject[0].app_user_id+
                            "&shoot_id="+shoot_id+"&exp_type="+type;
                       // alert(expUri);    
                            window.location.href=expUri;
      }

      getCopyContacts(shoot_id){
         var map=[];
        map.push({name:"curl_type",value:"getCopyContacts"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"shoot_id",value:shoot_id});
        map.push({name:"group",value:"1"});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getCopyContacts";
        CallMapMethod_CBack(clsPushMessages,map,function(data,msg){
          //alert(data);
          if(data!=="0" && data!==""){
            let listData=JSON.parse(data);
            let commStr="";
            for(let i=0;i<listData.length;i++){
               if(commStr!==""){
                commStr+=",";
               }
               commStr+=listData[i].contact_no.trim();
            }
            $("#cpy_number").text(commStr);
          }
        })
      }

      getMessagesGroup(){
        var map=[];
        map.push({name:"curl_type",value:"getWappShootPush"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        // search parameters
        map.push({name:"ser_shoot_status",value:$("#ser_shoot_status").val()});
        map.push({name:"ser_msg_type",value:$("#ser_msg_type").val()});
        map.push({name:"ser_duration",value:$("#ser_duration").val()});
        map.push({name:"ser_txt",value:$("#ser_txt").val()});

        map.push({name:"group",value:"1"});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getWappShootPush";
        CallMapMethod_CBack(clsPushMessages,map,function(data,msg){
          //alert(data);
          if(data!=="0" && data!==""){
            let listData=JSON.parse(data);
            clsPushMessages.setState({listMessagesShoot:listData},()=>{
              clsPushMessages.forceUpdateHandler();
            })
          }
        })
      }    
componentDidMount(){
  
  clsPushMessages.getMessagesGroup();
  clsPushMessages.getMsgTypeMaster();
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row content12 mt-2 mb-2">

<div className="col-lg-2 col-md-2 col-sm-4 float-right">
<select  className="form-control form-control-sm" id="ser_shoot_status" name="ser_shoot_status">
           <option value="0">-Cron Status-</option>
           <option value="QUEUED">-QUEUED-</option>
           <option selected value="PENDING">-PENDING-</option>
           <option value="SENT">-SENT-</option>
           <option value="DELIVERED">-DELIVERED-</option>
           <option value="COMPLETED">-COMPLETED-</option>
         </select>
</div>

<div className="col-lg-2 col-md-2 col-sm-4 float-right">
<select  className="form-control form-control-sm" id="ser_msg_type" name="ser_msg_type">
           <option value="0">-Camp Type-</option>
         </select>
</div>
<div className="col-lg-2 col-md-2 col-sm-4 float-right">
<select  className="form-control form-control-sm" id="ser_duration" name="ser_duration">
           <option value="Today">-Today-</option>
           <option value="Yesterday">-Yesterday-</option>
           <option value="Last 7 Days">-Last 7 Days-</option>
         </select>
</div>

<div className='col-lg-4 col-md-4 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_txt" name="ser_txt" className="_setSearchKeyUp form-control form-control-sm" placeholder='UserName/ShootId' />
             </div>
         </div>
</div>

<div className="col-lg-2 col-md-2 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div onClick={()=>this.getMessagesGroup()}  style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <div  className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>

</div>
    </div>
<div className="row">

      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Push Messages</b></h3>
              </div>
             {this.state.listMessagesShoot.map((item,index)=>{
              
              return <div class="callout callout-danger child_desk_thumb">
            <div className="row">
              <div className="col-md-4">
              <h5>{item.first_name+"/"+item.last_name}</h5>
              <p>{item.user_name}</p>
              <span className="badge bg-danger"> {item.route_name}</span>
              <p>Campaign DateTime:{item.entry_date}</p>
              <a href="#" class="link-black text-sm">Contact Person:<b>{item.last_name}</b> </a>
              <p>shootId:<b>{item.shoot_id}</b></p>
              [No of Message : <b>{item.total}</b>]
              [Client Nos : <b>{item.client_no}</b>]
              <table style={{"tableLayout":"auto"}} className="dtgrid table table-bordered text-sm table-responsive">
                <thead>
                  <tr>
                    <td scope="col"><a href="javascript:void(0)" onClick={()=>this.getMSGContent(item.msg_text)} data-toggle="modal" data-target="#divPushMessages">Read Msg</a></td>
                    <td scope="col"><a href="javascript:void(0)" onClick={()=>this.setMedia(item.img_files.trim())} data-toggle="modal" data-target="#divViewMedia">Images- {item.img_files.trim()!==""?item.img_files.split(",").length:0}</a></td>
                    <td scope="col"><a href="javascript:void(0)" onClick={()=>this.setMedia(item.vid_files.trim())} data-toggle="modal" data-target="#divViewMedia">Videos- {item.vid_files.trim()!==""?item.vid_files.split(",").length:0}</a></td>
                    <td scope="col"><a href="javascript:void(0)" onClick={()=>this.setMedia(item.pdf_files.trim())} data-toggle="modal" data-target="#divViewMedia">Pdf- {item.pdf_files.trim()!==""?item.pdf_files.split(",").length:0}</a></td>
                  </tr>
                </thead>
              </table>
              
              </div>
              <div className="col-md-6">
              <p>
                
                  {/* drop down */}
                  <div class="input-group-prepend">
                  <a data-toggle="modal" data-target="#divPushMessages" onClick={()=>this.getCopyContacts(item.shoot_id)}  className='btn btn-sm btn-primary'>get Copy Contacts</a>
                    <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
                      Export Contacts
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" onClick={()=>this.getExpContacts(item.shoot_id,"csv")} href="javascript:void(0)">CSV</a>
                      <a class="dropdown-item" onClick={()=>this.getExpContacts(item.shoot_id,"txt")} href="javascript:void(0)">Text</a>
                     
                     
                     
                    </div>
                  </div>
 {/* end of drop down */}
              </p>
            
              <form id="frmNonWhatsApp" name="frmNonWhatsApp">
              <div className="form-group">
                <lable></lable>
                <textarea className="boxsizingBorder" rows="5" id={"non_wa"+item.shoot_id} name={"non_wa"+item.shoot_id}>

                </textarea>
                </div>
                <p><a onClick={()=>this.submitNonWaNumber(item.shoot_id)}  className='btn btn-sm btn-danger'>Submit NonWa</a></p>
                
              </form>
              </div>
              <div className="col-md-2">
              {/* for final sms push */}
              <form id="frmPushMessages" name="frmPushMessages">
                <div className="form-group">
                  {item.dlr_status==="QUEUED"?
                  <a href="javascript:void(0)" onClick={()=>this.relCron(item.shoot_id)}  className='btn btn-sm btn-danger float-right'>Run Cron</a>
                  :''}
                </div>
              Shoot Status:<span className="badge bg-success">{item.dlr_status}</span>
              <div className="form-group">
                
                                            <label for="exampleInputEmail1">Push (No.) *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id={"ns_queued"+item.shoot_id} name={"ns_queued"+item.shoot_id} className="form-control" placeholder="No." />
                                            </div>
                                            
                                        </div>
            <a href="javascript:void(0)" onClick={()=>this.submitToQueue(item.shoot_id)}  className='btn btn-sm btn-danger float-right'>Push Now</a>
            </form>
              </div>
            </div>     
          
          
            </div>
   
    {/* <!-- /.card-body --> */}
  

             })} 
           </div>   
      </div>
</div>
</div>
</div>
<Footer/>
<Comp_View_Media media={this.state.media}/>
<div className="modal fade bd-example-modal-xl"  id="divPushMessages" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Push Message</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmCreate" name="frmCreate">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="createLogMaster" />
                            <input type="hidden" name="m_type" id="m_type" value="createLogMaster" />
                            <input type="hidden" name="type_id" id="type_id" value="2" />
                            <input type="hidden" name="app_user_id" id="app_user_id" value="" />
                            <div className="card-body">
                                <div className="row">
                               
                                    <div className="col-lg-12 col-md-12">
                                        <div id="cpy_number" className="form-group">
                                          
                                        </div>
                                    </div>
                                
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>
          </div>)
      }
}
export default Push_Messages;