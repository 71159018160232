import $ from 'jquery';
import React from 'react';
import {getWaUrlPath} from '../function_lib/common_lib';
       
  
const Manage_Related_Products=(props)=>{
    let proImages=[];
    let _imgPath=getWaUrlPath();
    if(typeof props.editProduct.img_path!=="undefined"){
        proImages=props.editProduct.img_path.split(',');
    }
    //alert(JSON.stringify(props));
        return(<div className="modal fade bd-example-modal-xl"  id="divManageRelatedProducts" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Manage Related Products</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            <div className="modal-body">
          
                            
                          
                            <div class="card-body">
                                <div className="row">
                                
                                  

{/* place of Image logic */}
 

<div className="col-md-6">
                                <div className="card card-danger">
                                <div className="card-header">
  <h3 className="card-title">List of Existing Related Products </h3><small></small>
</div>
<div className="card-body">
    <table className="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
      <thead>
        <th>Image</th>
        <th>Product Code</th>
        <th>Product Name</th>
        <th></th>
      </thead>
      <tbody>
        {props.lsRelatedProducts.map((item,index)=>{
          let arrSpImg=item.img_path.split(',');
          let spImg="";
          if(arrSpImg[0]!==""){
            spImg=_imgPath+arrSpImg[0];
          }
          return (<tr>
            <td>{spImg!==""?<img src={spImg} height="50px" width="50px"/>:''}</td>
            <td>{item.product_code}</td>
            <td>{item.product_name}</td>
            <td><a href="javascript:void(0)" className="mr-20"><i class="fa fa-trash-o fa-2x _setbackparam" param2={typeof props.editProduct.rf_id!=="undefined"?props.editProduct.rf_id:""} param1={item.rf_id} resback={"onDelRelatedProduct"} aria-hidden="true"></i></a></td>
          </tr>)
        })}
        </tbody>
    </table>
    </div>
    </div>
                                </div>   

                                    {/* end of image logic */}

{/* file image upload */}
<div className="col-md-6">
 <div className="card card-info">
<div className="card-header">
  <h3 className="card-title">Manage Related Products</h3><small></small>
</div>
<div className="card-body">
  <div className="row">
  <form class="form-group" id="frmManageRelatProducts" name="frmManageRelatProducts" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                            <input type="hidden" name="res_func" id="res_func" value="okSuccRelatProd" />
                            <input type="hidden" name="curl_type" id="curl_type" value="addSpareProducts" />
                            <input type="hidden" name="m_type" id="m_type" value="addSpareProducts" />
                            <input type="hidden" name="product_id" id="product_id" value={typeof props.editProduct.rf_id!=="undefined"?props.editProduct.rf_id:""} />
                            <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={props.app_user_id}/>
                            <input type="hidden" name="is_related" id="is_related" value={"true"}/>
                          
                                  <div className="col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Select Product *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="cmbReltProd" name="cmbReltProd[]" multiple="multiple" className="clsRelateProducts form-control multiset w-100 select2 required11 ">
                                                  <option value="0">-Select Category-</option>
                                                  {props.allProducts.map((item,index)=>{
                                                    if(item.id!==props.editProduct.id){
                                                      return(<option value={item.id}>{item.product_name+" ["+item.product_code+"]"}</option>)
                                                    }
                                                    
                                                  })}
                                                </select>
                                            </div>
                                        </div>
                                  </div>
                                  <div class="col-lg-12 col-md-12">
                                      
                                      <div class="form-group">
                                      <center>
                                          <div class="input-group input-group-sm" style={{"justify-content":"left"}}>
                                              <button type="button" class="btn btn-primary _btnSetColor">Add Related Products</button>
                                          </div>
                                      </center>
                                      </div>
                                  </div>          
                                  </form>
                </div>                  
                  
</div>

</div>

</div>

                                </div>
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                                
                                </div>
                            
          </div>
          </div>
          </div>
          </div>);
    
}
export default Manage_Related_Products;
