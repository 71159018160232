import $, { extend } from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import QueryString from 'query-string';
import {CallMapMethod} from '../function_lib/common_lib';
import Header from './header';
import Footer from './footer';

let queries;
let node;
let loginUser="";
let userObject="";
let companyName="";
class Masters extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        this.state={ stMasters:[],isMaster:false };
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        if(props !=null){
            queries = QueryString.parse(this.props.location.search);
            if(queries.node!=null){
              node=queries.node;
            }
    }
    if(localStorage.getItem("userData")!=null){
        UserToken.setUserObject(localStorage.getItem("userData"));
        userObject= UserToken.getUserObject();
        loginUser=userObject[0].person_name;
        companyName=userObject[0].company_name;
        //window.location.href="./Dashboard";
      }
}
    componentDidMount(){
        var map=[];
        map.push({name:"curl_type",value:"Masters"});
        map.push({name:"res_func",value:"callBackNg"});
        map.push({name:"parent_id",value:userObject[0].app_user_id});
        map.push({name:"ref2_id",value:userObject[0].id});
        map.push({name:"node",value:node});
        map["res_func"]="callBackNg";
        map["curl_type"]="Masters";
        CallMapMethod(this,map);
    }
    callBackNg(data,msg){
       //alert(data+msg);
       if(data.trim()!=="" && data.trim()!=="0"){
        var listItems=JSON.parse(data);
        this.setState({stMasters:listItems,isMaster:true},()=>{
          //RespBkMthdMapp(clsObject,"getEmpAttendance","shCollapse");
        });
        //alert(JSON.stringify(this.state.stListItems));
        this.forceUpdateHandler();
     }
    }
    render(){
        return(<div>
            <Header/>
    <section className="content">
        <div className="container-fluid">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Masters</h3>
                        </div>
                     
                        <div className="card-body">
                            <div id="accordion">
                                <div className="row">
                                  {(this.state.isMaster)?this.state.stMasters.map((item,index)=>{
                                      return <div className="col-md-4">
                                       <div className="card card-primary">
                                                <div className="card-header">
                                                    <h4 className="card-title">
                                                        <a className="onClickData text-white" data-toggle="collapse"  data-parent="#accordion" href="#cl_@item[id]">
                                                          {item["col1"]}
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="item[id]" className="panel-collapse collapse in">
                                                    <div className="card-body" id="dt_@item[id]">

                                                    </div>
                                                </div>
                                            </div>
                                      </div>

                                  }):''}
                                </div>
                            </div>
                        </div>
                      
                    </div>
                
             
            </div>
         

        </div>
    </section>
    



        </div>)
    }
}
export default Masters;