import React from 'react';
const Comp_Update_Ratings=(props)=>{
    //alert(JSON.stringify(props));
        return(<div className="modal fade bd-example-modal-xl"  id="divUpdateRatings" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Update Ratings</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            <form id="frmUpdateRatings" name="frmUpdateRatings">
            <div className="modal-body">
            <div className="row">
                      {/* <!-- left column --> */}
                      <div className="col-md-12">
                          <div className="card card-danger">
                                  <input type="hidden" name="res_func" id="res_func" value="okUpdRatingStatus" />
                                  <input type="hidden" name="curl_type" id="curl_type" value="updateRatings" />
                                  <input type="hidden" name="m_type" id="m_type" value="updateRatings" />
                                  <input type="hidden" name="type_id" id="type_id" value="2" />
                                  <input type="hidden" name="p_rating_id" id="p_rating_id" value={typeof props.lsRatings.id!=="undefined"?btoa(props.lsRatings.id):btoa("")} />
                                  <input type="hidden" name="local_token" id="local_token" value={props.user_object[0].app_user_id} />
                                  <div className="card-body">
                                      <div className="row">
                                     
                                          <div className="col-lg-12 col-md-12">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Set Status</label>
                                                  <select  className="form-control form-control-sm" id="rat_status" name="rat_status">
           <option value="0">-Select Approve/Reject-</option>
           <option value="Approved">-Approve-</option>
           <option value="Rejected">-Reject-</option>
          
         </select>
                                              </div>
                                          </div>
                                      </div>
                                    
                                      <div className="col-md-6 respSSL"></div>
                                      <div id="testing2dd2222"></div>
                                  </div>
                              
                          </div>
                      </div>
                  </div>
            </div>
            <div className="modal-footer">
            <div id="getReponse" style={{color:"red"}}></div>
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
            </div>
            </form>
          </div>
          </div>
          </div>
      );
}
export default Comp_Update_Ratings;
