import React from 'react';
import {GetImgCertUser_CBack,getWaUrlPath} from '../function_lib/common_lib';

export function editProductImages(){
    
    GetImgCertUser_CBack(this,"#frmProductImages","updateImages",function(checkData,checkMsg,userMsg){
      if(checkData===-10){
        alert("Kindly fill required fields");
        return;
      }else {
        //Custom_Alert("Successfully Added",checkMsg,false);
        alert(checkMsg);
        window.location.reload();
      }
    });
    
}             
  
const Edit_Product_Images=(props)=>{
    let proImages=[];
    let _imgPath=getWaUrlPath();
    if(typeof props.editProduct.img_path!=="undefined"){
        proImages=props.editProduct.img_path.split(',');
    }
    //alert(JSON.stringify(props));
        return(<div className="modal fade bd-example-modal-xl"  id="divProductImages" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Product Images</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            <form class="form-group" id="frmProductImages" name="frmProductImages" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="editProductMast" />
                            <input type="hidden" name="m_type" id="m_type" value="editProductMast" />
                            <input type="hidden" name="product_id" id="product_id" value={typeof props.editProduct.rf_id!=="undefined"?props.editProduct.rf_id:""} />
                            <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={props.app_user_id}/>
                          
                            <input type="hidden" name="lsImages" id="lsImages" />
                          
                            <div class="card-body">
                                <div className="row">
                                <div className="col-md-12">
                                <div className="card card-danger">
                                <div className="card-header">
  <h3 className="card-title">Existing Uploaded Images </h3><small> () </small>
</div>
<div className="card-body">
    <div className="row">
      {proImages.map((item,index)=>{
        if(item.trim()!==""){
            return (<div className="col-md-2 image_thumb_parent">
              <a href="javascript:void(0)" className="_setbackparam" param1={item} param2={props.editProduct.rf_id} resback={"onDelProImage"}><i className="fa fa-close icon-close"></i></a>
            <img className="img-responsive" src={_imgPath+item} />
        </div>)
        }
       
      })}
    </div>
    </div>
                                </div>    
                                </div>    

{/* place of Image logic */}
 {/* file image upload */}
 <div className="col-md-12">
 <div className="card card-info">
<div className="card-header">
  <h3 className="card-title">Upload New Images </h3><small> (Max File Size 3MB) </small>
</div>
<div className="card-body">
<div className="row audioLibThumb imgLibThumb">
                      
                      </div>
  <div className="row">
    
    <div className="col-12">
    <input type="file" ref_img="ImageThumbBack" multiple name="customFileBr" id="customFileBr" accept="image/*" size={10000000} className="updImgThumbMulti" />
      <a href="javascript:void(0)" setTo="customFileBr" className="_brFileThumb updthumb">Click & Browse Image Files</a>
    </div>
   
   
  </div>
</div>

</div>
</div>

                                    {/* end of image logic */}
                                </div>
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                                <div class="row">
                                    <div class="col-12">
                                      
                                        <div class="form-group">
                                        <center>
                                            <div class="input-group input-group-sm" style={{"justify-content":"center"}}>
                                                <button type="button" onClick={()=>editProductImages()} class="btn btn-primary">Save Edit</button>
                                            </div>
                                        </center>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </form>
          </div>
          </div>
          </div>
          </div>);
    
}
export default Edit_Product_Images;
