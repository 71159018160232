import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,getAppUrl} from '../function_lib/common_lib';
import Comp_View_Media from '../components/comp_view_media';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Option_Module from '../user_pages/option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';
//import '../assets/css/paggination.css';


let loginUser="";
let userObject="";
let companyName="";
let clsWappReports;
let wAppUrl="";

let dtTableObject=null;
class Wapp_Reports extends React.Component{

  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ listShoots:[],listDelivery:[],listMedia:{}};
      this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
  clsWappReports=this;
  wAppUrl=getAppUrl();
      }

      getExportReport(shoot_id){
        window.location.href=wAppUrl+"getWappShootReportsCSV&local_token="+userObject[0].app_user_id+
                                   "&shoot_id="+shoot_id;
      }

      getDeliveryShoots(shoot_id){
        var map=[];
        map.push({name:"curl_type",value:"getWappShootReports"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"shoot_id",value:shoot_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getWappShootReports";
        CallMapMethod_CBack(clsWappReports,map,function(data,msg){
          if(data!=="0" && data!==""){
            let listData=JSON.parse(data);
            clsWappReports.setState({listDelivery:listData},()=>{
              clsWappReports.forceUpdateHandler();
            })
          }
        })
      }
      getShoots(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getWappShoots"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_msg_type",value:$("#ser_msg_type").val()});
        map.push({name:"ser_duration",value:$("#ser_duration").val()});
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getWappShoots";
        CallMapMethod_CBack(clsWappReports,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                 
                
                clsWappReports.setState({listShoots:listData},()=>{
                  //clsWappReports.forceUpdateHandler();
                  window.getPagination("#tbData");
                 
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
                
                
              }
        });
      }
      getMsgTypeMaster(){
        
        var map=[];
        map.push({name:"curl_type",value:"getWaMsgTypeMaster"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getWaMsgTypeMaster";
        CallMapMethod_CBack(clsWappReports,map,function(data,msg){
          
          if(data!=="0"){
               let lsMaster=JSON.parse(data);
               let strMaster="<option value=\"0\">-Camp Type-</option>";
               for(let i=0;i<lsMaster.length;i++){
                  strMaster+="<option value=\""+lsMaster[i]["route_name"]+"\">"+
                                lsMaster[i]["route_name"]+"</option>";
               }
               $("#ser_msg_type").html(strMaster);
          }
        })
      }      
 
setMedia(listMedia){
  //alert(strMedia);
  this.setState({listMedia:listMedia},()=>{
    //clsPushMessages.forceUpdateHandler();
  });
}     
componentDidMount(){

  this.getShoots();
  this.getMsgTypeMaster();
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);

}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row content12 mt-2 mb-2">


<div className="col-lg-2 col-md-2 col-sm-4 float-right">
<select  className="form-control form-control-sm" id="ser_msg_type" name="ser_msg_type">
           <option value="0">-Camp Type-</option>
         </select>
</div>
<div className="col-lg-2 col-md-2 col-sm-4 float-right">
<select  className="form-control form-control-sm" id="ser_duration" name="ser_duration">

           <option value="Today">-Today-</option>
           <option value="Yesterday">-Yesterday-</option>
           <option value="Last 7 Days">-Last 7 Days-</option>
</select>
</div>


<div className="col-lg-2 col-md-2 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div onClick={()=>this.getShoots()}  style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <div  className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>

</div>
    </div>
<div className="row">

      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
      
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Wapp Reports</b></h3>
                {/* <select class="form-control" name="state" id="maxRows">
						 <option value="5000">Show ALL Rows</option>
						 <option value="5">5</option>
						 <option value="10">10</option>
						 <option value="15">15</option>
						 <option value="20">20</option>
						 <option value="50">50</option>
						 <option value="70">70</option>
						 <option value="100">100</option>
						</select> */}
            <input type="hidden" id="maxRows" name="state" value={10}/>
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Sender User</th>
                      <th>Camp. Name</th>
                      <th>Shoot ID</th>
                      <th>Route</th>
                      <th>Smg</th>
                      <th>Shoot ID Status</th> 
                      <th>Date Time</th>
                      <th>View Media</th>
                     <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.listShoots.map((item,index)=>{
                      return(
                        <tr>
                        <td>{index+1}</td>
                    <td>{item.first_name}</td>
                    <td>{item.camp_name}</td>
                  <td>{item.shoot_id}</td>
                  <td>{item.route_name}</td>
                  <td>{item.msg_no}</td>
                  <td>{item.dlr_status}</td>
                  <td>{item.entry_date}</td>
                  <td><a data-toggle="modal" onClick={()=>this.setMedia(item)} data-target="#divViewMedia" href="javascript:void(0)">View</a></td>
                  <td><a data-toggle="modal" onClick={()=>this.getDeliveryShoots(item.shoot_id)} data-target="#divWappReportShoot" href="javascript:void(0)">View Report</a> | <a href="javascript:void(0)" onClick={()=>this.getExportReport(item.shoot_id)}>Download Report</a>|</td>
                    </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>
<Comp_View_Media listMedia={this.state.listMedia} />
<div className="modal fade bd-example-modal-xl"  id="divWappReportShoot" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">{"Cap_Name"}</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmCreate" name="frmCreate">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <div className="card-body">
                                {/* display table row */}
                  <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Route</th>
                      <th>Contact No</th>
                      <th>Delivery Status</th>
                      <th>Date Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.listDelivery.map((item,index)=>{
                      return(
                        <tr>
                        <td>{index+1}</td>
                        <td>{item.route_name}</td>
                    <td>{item.contact_no}</td>
                    <td>{item.deli_status}</td>
                  <td>{item.entry_date}</td>
                  
                    </tr>
                      )
                    })}
                  </tbody>
                </table>
             
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>
          </div>)
      }
}
export default Wapp_Reports;