import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {GetImgCert,customAlert,AjaxCallMethod} from '../function_lib/common_lib';
import defaultPic from '../assets/images/9.png';
import TkMeter from '../assets/images/camera.svg';

let clsObject;
let loginUser="";
let userObject="";
let companyName="";
class Create_Trip extends React.Component{
  constructor(props){
    super(props);
    if(localStorage.getItem("userData")!=null){
      UserToken.setUserObject(localStorage.getItem("userData"));
      userObject= UserToken.getUserObject();
      loginUser=userObject[0].person_name;
      companyName=userObject[0].company_name;
      //window.location.href="./Dashboard";
  }
  }
submitTrip()
{
  GetImgCert(clsObject,"#uploadformPost","tripModule","okSuccCNOT");
}

okSuccCNOT(data,datares)
{
  $(".createTripClose").trigger('click');
  $("#uploadformPost").find("input[type=text]").val("");
  customAlert("Trip Started","Succcessfully Submitted","./Dashboard",1);
}
componentDidMount(){
  clsObject=this;
  AjaxCallMethod(this,"okSuccCNOT");
}

  render(){
    return(
      <div className="modal fade bd-example-modal-xl"  id="divCreateTrip" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
        <div className="modal-header bg-primary">
            <h6 className="modal-title text-white" id="exampleModalLabel">Trip Request</h6>
            <button type="button" className="close createTripClose text-white" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" className="text-white">&times;</span>
            </button>
          </div>
  
  <div className="container">
  <form id="uploadformPost" name="uploadformPost" enctype="multipart/form-data" method="POST" action="#">
            <input type="hidden" name="curl_type" id="curl_type" value="tripModule" />  
              <input type="hidden" name="m_type" id="m_type" value="tripModule" />
              <input type="hidden" name="res_func" id="res_func" value="okSuccCNOT" />
              <input type="hidden" name="company_id" id="company_id" value={userObject[0].app_user_id} />
              <input type="hidden" name="emp_id" id="emp_id" value={userObject[0].id}/>
              <input type="hidden" name="tip_status" name="tip_status" />
              <div className="mb-3 form-group">
              <div className="title mb-2"><i className="lni lni-map-marker"></i><span>Mode of Travels</span></div>
              <select className="form-control">
                <option value="0">-Select-</option>
                <option value="2 Wheeler">2 Wheeler</option>
                <option value="4 Wheeler">4 Wheeler</option>
                <option value="Public Transport">Public Transport</option>
              </select>
              </div>
              <div className="mb-3 form-group">
                <div className="title mb-2"><i className="lni lni-map-marker"></i><span>Trip From</span></div>
                <input className="form-control" name="trip_start_from" id="trip_start_from" type="text" />
              </div>
              <div className="mb-3">
                <div className="title mb-2"><i className="lni lni-map-marker"></i><span>Trip To</span></div>
                <input className="form-control" name="trip_desti_to" id="trip_desti_to" type="text"  />
              </div>
              <div className="mb-3">
                <div className="title mb-2"><i className="lni lni-target-customer"></i><span>Initial Km. Reading</span></div>
                <input className="form-control" id="init_km_read" name="init_km_read" type="text" />
              </div>
              <div className="user-profile  mb-3">
              <div className="title mb-2"><i className="lni lni-target-customer"></i><span>Take a Photo Km. Reading</span></div>
              <img id="logoImg" name="logoImg"  setTo="init_img_me" className="_brFile logoImg" src={TkMeter} alt={"df"}/>
              <input id="init_img_me" name="init_img_me" ref_img="logoImg" className="updImg" type="file" />
              </div>
              <button className="btn btn-success w-80" onClick={()=>this.submitTrip()} type="button">Start</button>
            </form>
 
</div>
</div>
</div>
</div>)
  }
}

export default Create_Trip;