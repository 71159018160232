import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,RespBkMthdMapp,CallMapMethod_CBack} from '../function_lib/common_lib';
import Header from './header';
import Footer from './footer';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';


let loginUser="";
let userObject="";
let companyName="";
let listItems;
let clsObject;
let userType="";
class Vendor_Audit extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        this.state={isAllow:false,stVendorList:[] };
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        userType="0";
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      }

      getVendorList(){
        var libType=[];
        libType.push({name:"curl_type",value:"getEntVenCliMgmt"});
        libType.push({name:"res_func",value:"callBackEntityList"});
        libType.push({name:"id",value:userObject[0].id});
        libType.push({name:"app_user_id",value:userObject[0].app_user_id});
        libType.push({name:"type_id",value:"3"});
        libType.push({name:"category_id",value:1});
        libType["res_func"]="callBackEntityList";
        libType["curl_type"]="getEntVenCliMgmt";
        CallMapMethod_CBack(this,libType,function(data,msg){
           //alert(msg);
          // $("#tbData").html(msg);
          // return;
          if(data!=="0"){
            let listD=JSON.parse(data);
            if(listD.length>0){
                clsObject.setState({stVendorList:listD})
           
            }
            
          }
          
        });
       }  
               
  
  
componentDidMount(){
  clsObject=this;
  this.getVendorList();
  RespBkMthdMapp(clsObject,"getClaimData","_setSearchKeyUp");
}

      render(){
          return(<div>
<Header/>
<div className="content">
  <div id="tmpRespt"></div>
<div className="container-fluid mt-4">
    {/* acta component */}
    <div class="row">
<div class="col-md-3 col-sm-6 col-12">
  <div class="info-box">
    <span class="info-box-icon bg-info">1</span>
    <div class="info-box-content">
      <span class="info-box-text">Entities Non Compliant</span>
      <span class="info-box-number">Major</span>
    </div>
  </div>
</div>

<div class="col-md-3 col-sm-6 col-12">
  <div class="info-box">
    <span class="info-box-icon bg-success">0</span>
    <div class="info-box-content">
      <span class="info-box-text">Entities Non Complaint</span>
      <span class="info-box-number">Moderate</span>
    </div>
  </div>
</div>

<div class="col-md-3 col-sm-6 col-12">
  <div class="info-box">
    <span class="info-box-icon bg-warning">0</span>
    <div class="info-box-content">
      <span class="info-box-text">Entities Non Complaint</span>
      <span class="info-box-number">Minor</span>
    </div>
  </div>
</div>

<div class="col-md-3 col-sm-6 col-12">
  <div class="info-box">
    <span class="info-box-icon bg-danger">1</span>
    <div class="info-box-content">
      <span class="info-box-text">Entities</span>
      <span class="info-box-number">Compliant</span>
    </div>
  </div>
</div>

</div>
    {/* end of acta component */}
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
           <div className="row">
             <div className="col-lg-2 col-md-2 col-sm-4">
             <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Search</b></h3>
             </div>
   
  
 
    <div className="col-lg-6 col-md-6 col-sm-6">
    <div className="input-group">
                    <input autoComplete="off" type="text" id="searchTxt" name="searchTxt" className="form-control form-control-sm _setSearchKeyUp"   placeholder="Search" />
                    <div className="input-group-prepend">
                      <span className="input-group-text form-control-sm currency"><i className="fa fa-search" aria-hidden="true"></i></span>
                    </div>
                  </div>
     
    </div>
  </div>
              </div>
                <table class="table table-bordered table-hover table-sm text-sm text-wrap table-responsive">
                  <thead>
                  <tr class="border">
                        <th>S.No.</th>
                        <th>Creation Date</th>
                        <th>Vendor Name</th>
                        <th>Branch Name</th>
                        <th>State</th>
                        <th>Compliance Score</th>
                        <th>Status</th>
                        <th>Action</th>
                 </tr>
                  </thead>
                  <tbody id="tbData">
                  {this.state.stVendorList.map((item,index)=>{
                      
                      return( <tr>
                        <td>{index+1}</td>
                   <td>{item.date}</td>
                   <td><a href={"../UserDashboard?rf="+item.vendor_enc_id}>{item.vendor}</a></td>
                   <td>{item.branch}</td>
                   <td>{item.state}</td>
                   <td><span class="badge badge-danger">50%</span></td>
                   <td>Active</td>
                   <td><a href={"../vendor_audit/indi_menpower_ltd?rf="+item.vendor_enc_id}>View</a></td>
 
                     </tr>)
                    
                    
                  })}
                  </tbody>
                </table>
             
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>

          </div>)
      }
}
export default Vendor_Audit;