import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,appGetUrlPath} from '../function_lib/common_lib';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Option_Module from '../user_pages/option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';


let loginUser="";
let userObject="";
let companyName="";
let appUrtPath;

let clsObject;

class Slot_Size extends React.Component{
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      appUrtPath=appGetUrlPath();
      
      }
      
componentDidMount(){
    clsObject=this;

  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
    
<div className="row">

      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Push Messages</b></h3>
              </div>
              <div class="callout callout-danger child_desk_thumb">
                        
                        <h5><img className='entity_icon_img' src={appUrtPath+"app_user_img/user_thumb.png"}/>{"Company/Business Name"}
                        <br></br>
                        {"user_name"}
                        
                        {/* <a href="javascript:void(0)" data-toggle="modal" data-target="#divCompEditEntity" onClick={()=>this.setEntityInfoData(item)}  class="float-right btn-tool"><i class="fa fa-pencil"></i></a> */}
                        {/* <span data-toggle="modal" data-target="#divCompAccessibility" onClick={()=>this.setEntityInfoData(item)}><a href="javascript:void(0)" data-toggle="tooltip" data-placement="top" title="Set Accessibility"    class="float-right btn-tool"><i class="fa fa-sitemap"></i></a></span> */}
                        {/* <span data-toggle="modal" data-target="#divEntitySettings" onClick={()=>this.setEntityInfoData(item)}><a href="javascript:void(0)" data-toggle="tooltip" data-placement="top" title="Entity Settings"    class="float-right btn-tool"><i class="fa fa-cog"></i></a></span> */}

                        </h5>
                        
                      <p>
                      <a href="#" class="link-black text-sm">Contact Person:<b>{"Shailendra Prajapati"}</b> </a>
                      <span className='float-right'><b>shootId:st_ashtech_1234 </b>|<b>{"No of Message : 2,000"}</b></span>
                      </p>
                      <p>
                      <a data-toggle="modal" data-target="#divPushMessages"  className='btn btn-sm btn-danger float-right'>Push Now</a>
                      </p>
                      <p>
                        <div className='row'>
                          <div className='col-md-6'>
                            <p>Campaign DateTime:16/02/2023 18:23</p>
                          </div>
                          <div className='float-right col-md-6'>
                          <table style={{"tableLayout":"auto"}} className="dtgrid table table-bordered text-sm table-responsive">
                          <thead>
                            <tr>
                              <td scope="col">Images- {"2"}</td>
                              <td scope="col">Videos- {"1"}</td>
                              <td scope="col">Pdf- {"0"}</td>
                            </tr>
                          </thead>
                        </table>
                          </div>
                        </div>
                     
                      </p>
                      </div>
             
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>
<div className="modal fade bd-example-modal-xl"  id="divPushMessages" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Push Message</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmCreate" name="frmCreate">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="createLogMaster" />
                            <input type="hidden" name="m_type" id="m_type" value="createLogMaster" />
                            <input type="hidden" name="type_id" id="type_id" value="2" />
                            <input type="hidden" name="app_user_id" id="app_user_id" value="" />
                            <div className="card-body">
                                <div className="row">
                               
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Company Name: {"Ash Technologies"}</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">User Name: {"ashtech"}</label>||
                                            <label for="exampleInputEmail1">Shoot Id: {"st_ashtech_1234"}</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Message Date: {"02/02/2023"}</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">No of Messages: {"2,000"}</label>
                                        </div>
                                    </div>
                                    
                                   
                                    <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                            <label for="exampleInputEmail1">Push (No.) *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="ref1_id" name="ref1_id" className="form-control" placeholder="No." />
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>
          </div>)
      }
}
export default Slot_Size;