import React,{useState,useEffect} from 'react';
import {useLocation} from  'react-router-dom';
import {Client_Create_Ticket} from './client_create_ticket';
import {Client_Reply_Thread} from './client_reply_thread';
import {GetCondidatesView} from './default_data_ticket';
import {ConvertDateFormat_Time} from '../function_lib/common_lib';
import './support_ticket.css';

export const Client_Interface=(props)=>{
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const _authToken = queryParams.get('authTocken'); 
  const _logToken=queryParams.get('logToken');
  //alert(_authToken);
  const [getTickets,setTicketsdata]=useState([]);
  const [getTicketItem,setTicketItem]=useState({});
  const [getTktDisc,setTktDisc]=useState([]);
  useEffect(()=>{
    
    getClientTickets();

  },[]);

  const getClientTickets=()=>{

    let map=[];
    map.push({name:"auth_token",value:_authToken});
    map.push({name:"log_token",value:_logToken});

   
    GetCondidatesView(map,"getClientTickets").then((res)=>{
      //alert(res);
      setTicketsdata(JSON.parse(res));
    });

  }

 const getTicketDisc=(item)=>{
    //alert(JSON.stringify(item));
    //return;
    setTicketItem(item);
    let map=[];
    map.push({name:"auth_token",value:_authToken});
    map.push({name:"log_token",value:_logToken});
    map.push({name:"ticket_id",value:item.encKey});
    GetCondidatesView(map,"getClientTicketDisc").then((res)=>{
      //alert(JSON.stringify(res));
      setTktDisc(JSON.parse(res));
    })


  }

    return(<div>
        
        <div className="content">
        <div className="container-fluid mt-4">
        <div className="row content12 mt-2 mb-2">
        
        
            </div>    
        <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                 
              <div class="card">
                      {/* <!-- /.card-header --> */}
                   <div class="card-header bg-adv">
                        <h3 class="card-title white"><i class="fa fa-arrow-circle-right white"></i> <b>Support Tickets</b></h3>
                        
                        {/* option of page sections */}
                        <div class="input-group-prepend float-right">
                            <button type="button" class="btn btn-danger dropdown-toggle" data-toggle="dropdown">
                              Create New
                            </button>
                            <div class="dropdown-menu">
                              <a class="dropdown-item" data-toggle="modal" data-target="#divCreateSupportTicket" href="javascript:void(0)">Create New Ticket</a>
                              {/* <a class="dropdown-item" data-toggle="modal" data-target="#divAddHeader" href="javascript:void(0)">New Section to Menu</a>
                              <a class="dropdown-item" data-toggle="modal" data-target="#divCmsAddNew" href="javascript:void(0)">Add New Data</a> */}
                              {/* <a class="dropdown-item" onClick={()=>this.setSelectedForMapp(item)} href="javascript:void(0)">Edit</a>
                              <a class="dropdown-item" onClick={()=>this.setSelectedForMapp(item)} data-toggle="modal" data-target="#divPtrInvoice" href="javascript:void(0)">Delete</a> */}
                             
                            </div>
                          </div>
                        {/* end of page sections */}
        
                      </div>
                        <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                          <thead>
                            <tr class="border">
                              <th>S.No.</th>
                              <th>Ticket Id</th>
                              <th>Subject</th>
                              <th>Created By</th>
                              <th>Creation Date</th>
                              <th>Last Replier</th>
                              <th>Last Activity</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                          {getTickets.map((item,index)=>{
                            return <tr>
                              <td>{index+1}</td>
                              <td>{item["ticket_no"]}</td>
                              <td>{item["col2"]}</td>
                              <td>{item["user_first"]}</td>
                              <td>{ConvertDateFormat_Time(item["entry_date"])}</td>
                              <td>{item["user_replied"]}</td>
                              <td>{ConvertDateFormat_Time(item["upd_date"])}</td>
                              <td>{item["status"]}</td>
                              <td><a href={"javascript:void(0)"} onClick={()=>getTicketDisc(item)} data-toggle="modal" data-target="#divReplyClientThread">View Reply</a></td>
                            </tr>
                          })}
                          
                          </tbody>
                        </table>
                        <div class='pagination-container' >
                        <nav>
                          <ul class="pagination">
                    
                    <li data-page="prev" >
                                             <span> {"<"} <span class="sr-only">(window.current)</span></span>
                                            </li>
                          
                <li data-page="next" id="prev">
                                               <span> {">"} <span class="sr-only">(window.current)</span></span>
                                            </li>
                          </ul>
                        </nav>
                    </div>
                      {/* <!-- /.card-body --> */}
                    </div>
              </div>
        </div>
        </div>
        </div>
        <Client_Create_Ticket callBackonCreate={getClientTickets} auth_token={_authToken} log_token={_logToken}/>
        <Client_Reply_Thread view_ticket={getTicketItem} view_disc={getTktDisc} auth_token={_authToken} log_token={_logToken} />
           </div>)
}

export default Client_Interface;