import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker,
  RespBkMthdMapp} from '../function_lib/common_lib';
import {CallCMSMapMethod_CBack} from '../function_lib/common_cms';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Option_Module from '../user_pages/option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';

import Comp_Astro_Add_Articles from './comp_astro_add_articles';
import 'select2/dist/js/select2.full.min.js';
import 'select2/dist/css/select2.min.css';


let loginUser="";
let userObject="";
let companyName="";
let clsAtroArticles;

let rf="0";


class Astro_Contacts extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ stlistContacts:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsAtroArticles=this;
      }
      resetFilters(){
        $("#ser_text").val("");
        //clsCategoryMaster.getCategories();
      }

     
getContacts(){
   //child component use this method loan_document_upload_comp
   let map=[];
   map.push({name:"curl_type",value:"getContacts"});
   map.push({name:"res_func",value:"callBackrrr"});
   map.push({name:"local_token",value:userObject[0].app_user_id});
   map.push({name:"user_type",value:userObject[0].type});
   map.push({name:"upper_id",value:userObject[0].upper_id});
   map["res_func"]="callBackrrr";
   map["curl_type"]="getContacts";
   CallCMSMapMethod_CBack(clsAtroArticles,map,function(data,msg){
     //alert(data);
     
       if(data!=="0" && data!==""){
        let jsData=JSON.parse(data);
         //let jsAllData=JSON.parse(data);
          //let strTreeData=JSON.stringify(jsAllData.all_list);
          $(".outstanding_rpt").html(" Total Contact Request :<b>"+jsData.all_data.length+"</b>");
          clsAtroArticles.setState({stlistContacts:jsData.all_data},()=>{
            $("#tbData").DataTable({
                "responsive": true,
                "autoWidth": false,
                "searching": true,
                //"dom": 'lrtip'
                //"lengthMenu": []
            });
          });
         }
   });
}

         
      
componentDidMount(){
 
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.getContacts();

}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row content12 mt-2 mb-2">

{/* 
<div className='col-lg-4 col-md-4 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_text" name="ser_text" rf="#btnCatSearch" className="_setSearchKeyUp keyEvent_btn form-control form-control-sm" placeholder='search' />
             </div>
         
         </div>

</div> */}
{/* 
<div className="col-lg-2 col-md-2 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div onClick={()=>this.getCategories()}  style={{marginRight:"10px"}} id="btnCatSearch" name="btnCatSearch" className="btn btn-sm btn-danger">Search</div>
                 <div onClick={()=>this.resetFilters()} className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>

</div> */}
    </div>    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Contact Requests</b></h3>
                <div className="outstanding_rpt float-left pl-50 px-10"></div>
                {/* <a className="btn btn-sm btn-danger float-right" data-toggle="modal" data-target="#divAtroAddArticle" href="javascript:void(0)">Add New Article</a> */}
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Date</th>
								  <th>Name</th>
								   <th>Email</th>
								   <th>Contact</th>
								<th>Message</th>
								 <th>Subject</th>
								  <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.stlistContacts.map((item,index)=>{
                       return(<tr>
                        <td>{index+1}</td>
                    
                  <td>{item.date}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.contactno}</td>
                  <td>{item.subject}</td>
                  <td>{item.message}</td>
                  <td><span className="badge bg-danger dash_rejected_claim1">{item.status}</span></td>
                  {/* <td><a data-toggle="modal" data-target="#divEmployee" href="javascript:void(0)">View</a> | <a  data-toggle="modal11" data-target="#divWaCreditBalance11" onClick={()=>this.onCreditBalanceSel(item)}  href="javascript:void(0)">Action</a> |</td>*/}
                    </tr>)
                  }))}
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
{/* <Comp_Astro_Add_Articles listArticles={this.state.stlistArticles} app_user_id={userObject[0].app_user_id} /> */}
   
          </div>)
      }
}
export default Astro_Contacts;