import $, { extend } from 'jquery';
import React from 'react';
class E_Library_Options extends React.Component{
    componentDidMount()
    {
        let strMonth="<option value=\"0\">-Month-</option>";
        for(var i=1;i<=12;i++){
            strMonth+="<option value=\""+i+"\">"+i+"</option>";
        }
        $("#cmb_month_pay").html(strMonth);

        let strYear="<option value=\"0\">-Year-</option>";
        for(var i=2022;i>=2012;i--){
            strYear+="<option value=\""+i+"\">"+i+"</option>";
        }
        $("#cmb_year_pay").html(strYear);
    }
    render(){
        return(<div>
             <div className="card-footer p-0">
            <ul className="nav flex-column">
              <li className="nav-item"><h6> <a href="javascript:void(0)" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i>Acts</a></h6> </li>
              <li className="nav-item"><h6> <a href="javascript:void(0)" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i> Forms </a></h6> </li>
              <li className="nav-item"><h6> <a href="javascript:void(0)" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i>Gazette Notification</a></h6> </li>
              <li className="nav-item"><h6> <a href="javascript:void(0)" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i>Holiday List</a></h6> </li>
              <li className="nav-item"><h6> <a href="javascript:void(0)" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i> Labour Welfare Fund </a></h6> </li>
              <li className="nav-item"><h6> <a href="javascript:void(0)" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i>Leave & Working Hours</a></h6> </li>
              <li className="nav-item"><h6> <a href="javascript:void(0)"  className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i> Minimum Wages </a></h6> </li>
              <li className="nav-item"><h6> <a href="javascript:void(0)"  className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i>Professional Tax</a></h6> </li>
              <li className="nav-item"><h6> <a href="javascript:void(0)"  className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i>Rules</a></h6> </li>
              <li className="nav-item"><h6> <a href="javascript:void(0)"  className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i>Abstracts</a></h6> </li>
              <li className="nav-item"><h6> <a href="javascript:void(0)"  className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i>Display Notices</a></h6> </li>

            </ul>
          </div>

          <div className="modal fade bd-example-modal-xl"  id="divUploadSingature" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Signature Upload</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmUploadSingature" name="frmUploadSingature">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="createLogMaster" />
                            <input type="hidden" name="m_type" id="m_type" value="createLogMaster" />
                            <input type="hidden" name="type_id" id="type_id" value="2" />
                            <input type="hidden" name="app_user_id" id="app_user_id" value="" />
                            <div className="card-body">
                                <div className="row">
                                <div className="col-lg-12 col-md-6">
                                    <div className="form-group">
<label for="exampleInputFile">Upload Image of Signature</label>
<div className="input-group">
  <div className="custom-file">
    <input type="file" className="custom-file-input" name="exampleInputFile" id="exampleInputFile" />
    <label className="custom-file-label" for="exampleInputFile">Choose file</label>
  </div>
  {/* <div className="input-group-append">
  <button type="button" className="btn btn-primary">Upload Now</button>
  </div> */}
</div>
</div>
                                    </div>
                                <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Select Entity *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="cmb_entity_sign" name="cmb_entity_sign" className="form-control">
                                                  <option value="0">-Select-</option>
                                                  <option value="1">Indithinkk Tech Pvt. Ltd</option>
                                                  <option value="2">Random Tech Ltd.</option>
                                                  <option value="3">XYZ Outsourcing Ltd.</option>
                                                  <option value="3">ABC Technologies Ltd.</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Location *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="details" name="details" className="form-control" placeholder="Location" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Effective From</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col98" name="col98" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Proposal Form Sign Date</small> </div>
          </div>
                                 
                                  
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn  btn-primary">Upload Now</button>
      </div>
      </form>
    </div>
    </div>
    </div>

          <div className="modal fade bd-example-modal-xl"  id="divUploadPayroll" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Monthly Master DataUpload</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmUploadPayroll" name="frmUploadPayroll">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="createLogMaster" />
                            <input type="hidden" name="m_type" id="m_type" value="createLogMaster" />
                            <input type="hidden" name="type_id" id="type_id" value="2" />
                            <input type="hidden" name="app_user_id" id="app_user_id" value="" />
                            <div className="card-body">
                                <div className="row">
                                <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Month/Year *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="cmb_month_pay" name="cmb_month_pay" className="form-control">
                                                  <option value="0">-Select-</option>
                                                </select>
                                                <select id="cmb_year_pay" name="cmb_year_pay" className="form-control">
                                                  <option value="0">-Select-</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
<label for="exampleInputFile">Attendace Data</label>
<div className="input-group">
  <div className="custom-file">
    <input type="file" className="custom-file-input" name="exampleInputFile" id="exampleInputFile" />
    <label className="custom-file-label" for="exampleInputFile">Choose file</label>
  </div>
  {/* <div className="input-group-append">
  <button type="button" className="btn btn-primary">Upload Now</button>
  </div> */}
</div>
</div>
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                    <div className="form-group">
<label for="exampleInputFile">Payroll Data</label>
<div className="input-group">
  <div className="custom-file">
    <input type="file" className="custom-file-input" name="exampleInputFile" id="exampleInputFile" />
    <label className="custom-file-label" for="exampleInputFile">Choose file</label>
  </div>
  {/* <div className="input-group-append">
  <button type="button" className="btn btn-primary">Upload Now</button>
  </div> */}
</div>
</div>
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                    <div className="form-group">
<label for="exampleInputFile">Employee HRD Master</label>
<div className="input-group">
  <div className="custom-file">
    <input type="file" className="custom-file-input" name="exampleInputFile" id="exampleInputFile" />
    <label className="custom-file-label" for="exampleInputFile">Choose file</label>
  </div>
  {/* <div className="input-group-append">
  <button type="button" className="btn btn-primary">Upload Now</button>
  </div> */}
</div>
</div>
                                    </div>
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn  btn-primary">Upload Now</button>
      </div>
      </form>
    </div>
    </div>
    </div>
        </div>)
    }
}
export default E_Library_Options;