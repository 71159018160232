import $ from 'jquery';
import React,{useEffect,useState} from 'react';
import {GetImgCertUserCMS_CBack,appGetLoanUrlPath} from '../function_lib/common_cms';
import Select from 'react-select';
export function editCMSContent(){
    //alert("i m in click");
  GetImgCertUserCMS_CBack(this,"#frmAstroEditArticle","editDataArticle",function(checkData,checkMsg,userMsg){
  
      if(checkData===-10){
        alert("Kindly fill required fields");
        return;
      }else {
        //Custom_Alert("Successfully Added",checkMsg,false);
        //alert(checkMsg);
        alert("updated successfully");
        $('#divCmsAddNew').modal('hide');
        window.location.reload();
      }
    });
}             
  
export const Comp_Astro_Edit_Articles=(props)=>{
  
  

    let _imgPath=appGetLoanUrlPath();
    //alert(_imgPath);
    let proMainImages=[];
    if(typeof props.editArticleData.image!=="undefined" && props.editArticleData.image.trim()!==""){
        proMainImages=props.editArticleData.image.split(',');
    }
    
    const [getArticles,setArticles]=useState([]);
    const [getSelected,setSelected]=useState(null);
    const handleSelectChange=(data)=>{
      //alert(data);
      setSelected(data);
    }
    useEffect(()=>{
      let arrArtTmp=[];
      for(let i=0;i<props.listArticles.length;i++){
        arrArtTmp.push({label:props.listArticles[i]["pagename"],value:btoa(props.listArticles[i]["id"])});
      }
      setArticles(arrArtTmp);
      if(typeof props.editArticleData.topid!=="undefined"){
        //alert(props.editArticleData["topid"]);
        setSelected({"value":btoa(props.editArticleData["topid"]),"label":props.editArticleData["upper_page_title"]})
        $("#edit_content").summernote('code', props.editArticleData["content"]);
        $("#edit_brief").summernote('code', props.editArticleData["brief"]);
      }else{
        setSelected(null);
      }
    },[props.listArticles,props.editArticleData])

        return(<div className="modal fade bd-example-modal-xl"  id="divAtroEditArticle" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Add New</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div id="errLog"></div>
            <form class="form-group11" id="frmAstroEditArticle" name="frmAstroEditArticle" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="editDataArticle" />
                            <input type="hidden" name="m_type" id="m_type" value="editDataArticle" />
                            {/* <input type="hidden" name="product_id" id="product_id" value={typeof props.editProduct.rf_id!=="undefined"?props.editProduct.rf_id:""} /> */}
                            <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={props.app_user_id}/>
                            
                            <input type="hidden" name="bNode" id="bNode" value={props.b_node}/>
                            <input type="hidden" name="pNode" id="pNode" value={props.p_node}/>
                            <input type="hidden" name="col20" id="col20" value={props.p_node}/>
                            <input type="hidden" name="edit_lsImages" id="edit_lsImages"/>
                            <input type="hidden" name="editArticleId" id="editArticleId" value={btoa(props.editArticleData["id"])}/>
                            <input type="hidden" name="type" id="type" value={"20"}/>
                          
                            <div class="card-body">
                                <div className="row">
                                <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Select Top</label>
                                            <div className="input-group1 input-group-sm1">
                                            <Select
                                            className="form-control"
                                            classNamePrefix="select"
                                            placeholder="Select Article"
                                            isSearchable={true}
                                            name="pagenameCat"
                                            value={getSelected}
                                            onChange={handleSelectChange}
                                            options={getArticles}
                                        />
                                            {/* <select id="pagenameCat" name="pagenameCat" className="form-control select2 form-control-sm">
                                                  <option value={btoa("0")}>-Select Article</option>
                                                  {props.listArticles.map((item,index)=>{
                                                    return <option selected={props.editArticleData.topid===item.id} value={btoa(item.id)}>{item.pagename}</option>
                                                  })}
                                              </select> */}
                                            </div>
                                        </div>
                                  </div>
                                <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Menu Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="edit_pagename" name="edit_pagename" className="form-control required11" defaultValue={props.editArticleData["pagename"]} placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Page Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="edit_urlname" name="edit_urlname" className="form-control required11" defaultValue={props.editArticleData["pageurlname"]} placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Display Index</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="edit_order" name="edit_order" className="form-control required11" defaultValue={props.editArticleData["order"]} placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-8 col-md-8">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Title</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="edit_title" name="edit_title" className="form-control required11" defaultValue={props.editArticleData["title"]} placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                    
                                </div>
                                <div class="row">
                                 
    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Description 1 *</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="edit_content" name="edit_content">
                                                
                                            </textarea>
                                            </div>
                                        </div>
                                    </div>
                              
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Description 2 *</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="edit_brief" name="edit_brief"></textarea>
                                            </div>
                                        </div>
                                    </div>


                                  {/* existing images slot */}
                                  <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4">
                                            <div className="row">
      {proMainImages.map((item,index)=>{
        if(item.trim()!==""){
            return (<div className="col-md-4 image_thumb_parent">
              <a href="javascript:void(0)" className="_setbackparam" param1={item} param2={btoa(props.editArticleData.id)} resback={"onDelMainImage"}><i className="fa fa-close icon-close"></i></a>
            <img className="img-responsive" src={_imgPath+item} />
        </div>)
        }
       
      })}
    </div>
                                             </div>   
                                             </div>
                                             </div>    

            {/* end of existing images slot */}

{/* image upload section */}
<div className="col-md-12">
<div className="row">
{/* file image upload */}

<div className="col-md-4">
 <div className="card card-info">
<div className="card-header">
  <h3 className="card-title">Main Image </h3><small> (Max File Size 1MB, Only 1 will allow) </small>
</div>
<div className="card-body">
<div className="row audioLibThumb eximgLibThumb">
                      
                      </div>
  <div className="row">
    
    <div className="col-12">
    <input type="file" ref_img="ImageThumbBack" thumbnail="eximgLibThumb" lsElement="edit_lsImages" multiple name="editCustomFileBr" id="editCustomFileBr" accept="image/*" size={10000000} className="updImgThumb" />
      <a href="javascript:void(0)" setTo="editCustomFileBr" className="_brFileThumb updthumb">Click & Browse Image Files</a>
    </div>
   
   
  </div>
</div>

</div>
</div>

</div>
</div>


                               {/* end of file image upload */}

                               {/* file other image upload */}                                          

                                </div>
                                 
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                                <div class="row">
                                    <div class="col-12">
                                      
                                        <div class="form-group">
                                        <center>
                                            <div class="input-group input-group-sm" style={{"justify-content":"center"}}>
                                                <button type="button" onClick={()=>editCMSContent()} class="btn btn-primary">Submit</button>
                                            </div>
                                        </center>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>
                            </form>
          </div>
          </div>
          </div>
          </div>);
    
}
export default Comp_Astro_Edit_Articles;
