import React from 'react';
import { findDOMNode } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';

import Pages from './pages';

import DashboardNew from './user_pages/dashboard_new';
import ListEmployee from './user_pages/list_employes';
import ListTrips from './user_pages/trip_list';
import ListLeave from './user_pages/leave_list';
import ListAttend from './user_pages/attendance_list';

import Attendace_List from './user_pages/attendance_list';
import Leave_Request_Mgmt from './user_pages/leave_request_mgmt';
import Trip_Request_Mgmt from './user_pages/trip_request_mgmt';




import Masters from './user_pages/masters';
// import Check_List_Master from './user_pages/check_list_master';
// import Company_Master from './user_pages/company_master';
// import Group_Name_Master from './user_pages/group_name_master';
// import Cause_of_Event from './user_pages/cause_of_event';
// import Cause_Of_Death_Master from './user_pages/cause_of_death_master';

//Remove after done
import TestWhat from './function_lib/testwhats';

// soft eCommerce controls
import Add_Product_Master from './softwa/add_product_master';
import View_Product_Master from './softwa/view_product_master';
import Category_Master from './softwa/category_master';
import Online_Orders from './softwa/online_orders';
import Purchase_Product_Analytics from './softwa/purchase_product_analytics';
import Contact_Request from './softwa/contact_request';
import View_Consultation_Request from './softwa/view_consultation_request';
import CMS_Contact_Request from './softwa/cms_contact_request';
import View_Ratings from './softwa/view_ratings';
import Product_Vendors from './softwa/product_vendors';
import Coupon_Master from './softwa/coupon_master';

import Wapp_Button_Campaign from './softwa/wapp_button_campaign';

import Wapp_Reports from './softwa/wapp_reports';
import Wapp_Button_Reports from './softwa/wapp_button_reports';
import User_Management from './softwa/user_management';
import Queue_Monitor from './softwa/queue_monitor';
import Push_Messages from './softwa/push_messages';
import Thrashold_Limit from './softwa/thrashold_limit';
import Slot_Size from './softwa/slot_size';
import User_Settings from './softwa/user_settings';
import User_Profile from './softwa/user_profile';
import Sync_Product_Data from './softwa/sync_product_data';

// end of softwa controls


// acta list
import Entity_Module from './user_pages/entity_module';
import Vendor_Audit from './user_pages/vendor_audit';
import Client_Site from './user_pages/client_site';
import Client_Site_V_Audit from './user_pages/client_site_v_audit';
import Branches_List from './user_pages/branches_list';
import Company_V_Audit from './user_pages/company_v_audit';
import Registration_Licenses from './user_pages/registration_licenses';
import Notices from './user_pages/notices';
import Compliance_List from './user_pages/compliance_list';
import Comp_Dashboard from './user_pages/comp_dashboard';
import Abstrack_Notices from './user_pages/abstrack_notices';
import User_Dashboard from './user_pages/user_dashboard';
import E_Library from './user_pages/e_library';
// Library Acta
import Library_Master from './user_pages/library_master';
import Entity_Management from './user_pages/entity_management';
import View_Entity from './user_pages/view_entity';

//import User_Management from './user_pages/user_management';

import Library_Acts from './masters/library_acts';
import Library_Forms from './masters/library_forms';
import Library_Gazette_Notification from './masters/gazette_notification';

import Pre_Holiday_List from './masters/pre_holiday_list';
import Library_Holiday_List from './masters/library_holiday_list';

import Pre_Lwf from './masters/pre_lwf';
import Labour_Welfare_Fund from './masters/labour_welfare_fund';

import Pre_Leave_Working_Hr from './masters/pre_leave_working_hr';
import Leave_Working_Hours from './masters/leave_working_hours';

import Pre_Minimum_Wages from './masters/pre_minimum_wages';
import Library_Minimum_Wages from './masters/library_minimum_wages';

import Pre_Professional_Tax from './masters/pre_professional_tax';
import Library_Professional_Tax from './masters/library_professional_tax';

import Library_Rules from './masters/library_rules';
import Library_Abstracts from './masters/library_abstracts';
import Library_Display_Notices from './masters/library_display_notices';
import Library_Important_Judgments from './masters/library_important_judgments';
import Compliance_List_Mgmt from './user_pages/compliance_list_mgmt';
import View_Admin_Branches from './user_pages/view_admin_branches';
import View_Admin_Vendor from './user_pages/view_admin_vendor';
import View_Admin_Client from './user_pages/view_admin_client';
import Notification_Updates from './user_pages/notification_updates';

import CMS_View from './cms_ash/cms_view';
import Cms_Data from './cms_ash/cms_data';
import List_Nomination_Req from './cms_ash/list_nomination_req';
//----astro 
import Astro_Articles from './astro/astro_articles';
import Astro_Comment from './astro/astro_comment';
import Astro_Subscriber from './astro/astro_subscriber';
import Astro_Contacts from './astro/astro_contacts';

// ----support ticket
import Client_Interface from './support_tickeet/client_interface';
import Support_Admin_Interface from './support_tickeet/support_admin_interface';

class App extends React.Component{
  componentDidMount() {
    // const script = document.createElement("script");    
    // script.async = true;    
    // script.src = "./assets/js/default/active.js"; 
    // document.body.appendChild(script);   
      }
  render(){
   
    return <Router>
      <Route exact path="/" component={Pages} />
      {/* ASTRO */}
      <Route exact path="/astro_articles" component={Astro_Articles}/>
      <Route exact path="/astro_messages" component={Astro_Comment} />
      <Route exact path="/astro_subscriber" component={Astro_Subscriber}/>
      <Route exact path="/astro_contacts" component={Astro_Contacts} />
      {/* END ASTRO */}
      <Route exact path="/Masters" component={Masters}/>
      {/* CMS View Routes */}
      <Route exact path="/cms_view" component={CMS_View} />
      <Route exact path="/cms_data" component={Cms_Data}/>
      <Route exact path="/list_nomination_req" component={List_Nomination_Req}/>
      {/* End of CMS View Routes */}
{/* soft wa routes */}
      <Route exact path="/add_product_master" component={Add_Product_Master}/>
      <Route exact path="/view_product_master" component={View_Product_Master}/>
      <Route exact path="/category_master" component={Category_Master}/>
      <Route exact path="/wapp_button_campaign" component={Wapp_Button_Campaign} />
      <Route exact path="/online_orders" component={Online_Orders} />
      <Route exact path="/product_analytics" component={Purchase_Product_Analytics} />
      <Route exact path="/view_messages" component={Contact_Request} />
      <Route exact path="/view_consultation" component={View_Consultation_Request}/>
      <Route exact path="/view_contacts" component={CMS_Contact_Request}/>
      <Route exact path="/view_ratings" component={View_Ratings}/>
      <Route exact path="/user_mgmt" component={User_Management} />
      <Route extact path="/product_vendors" component={Product_Vendors}/>
      <Route exact path="/stock_update" component={Sync_Product_Data}/>
      <Route exact path="/coupon_master" component={Coupon_Master}/>


      <Route exact path="/wapp_reports" component={Wapp_Reports}/>
      <Route exact path="/wapp_button_reports" component={Wapp_Button_Reports}/>
      
      <Route exact path="/queue_monitor" component={Queue_Monitor}/>
      <Route exact path="/push_messages" component={Push_Messages}/>
      <Route exact path="/thrashold_limit" component={Thrashold_Limit}/>
      <Route exact path="/slot_size" component={Slot_Size}/>
      <Route exact path="/user_settings" component={User_Settings} />
      <Route exact path="/user_profile" component={User_Profile}/>

{/* end of soft wa routes  */}


      <Route exact path="/Dashboard" component={DashboardNew}/>
      <Route exact path="/UserDashboard" component={User_Dashboard}/>
      <Route exact path="/ListEmployee" component={ListEmployee}/>
      <Route exact path="/ListTrips" component={ListTrips} />
      <Route exact path="/ListLeave" component={ListLeave} />
      <Route exact path="/ListAttend" component={ListAttend} />
     
      <Route exact path="/viewAttendance" component={Attendace_List}/>
      <Route exact path="/LeaveRequest" component={Leave_Request_Mgmt} />
      <Route exact path="/TripRequest" component={Trip_Request_Mgmt}/>
      
      <Route exact path="/TestWhat" component={TestWhat} />
      


      {/* acta list */}
      <Route exact path="/entity_module" component={Entity_Module} />
      <Route exact path="/vendor_audit" component={Vendor_Audit} />
      <Route exact path="/vendor_audit/:name" component={Company_V_Audit}/>
      <Route exact path="/client_site" component={Client_Site} />
      <Route exact path="/client_site/:name" component={Client_Site_V_Audit}/>
      <Route exact path="/branches_list" component={Branches_List}/>
      <Route exact path="/registration_licenses" component={Registration_Licenses} />
      <Route exact path="/notices" component={Notices} />
      <Route exact path="/compliance_list" component={Compliance_List}/>
      <Route exact path="/compliance_list/:name" component={Compliance_List}/>

      <Route exact path="/comp_dashboard" component={Comp_Dashboard} />
      <Route exact path="/abstract_notices" component={Abstrack_Notices} />
      <Route exact path="/e_library" component={E_Library} />
      <Route exact path="/library_master" component={Library_Master}/>
      <Route exact path="/entity_management" component={Entity_Management} />
      
      <Route exact path="/view_entity" component={View_Entity}/>
      <Route exact path="/view_branches" component={View_Admin_Branches}/>
      <Route exact path="/view_vendors" component={View_Admin_Vendor}/>
      <Route exact path="/view_clients" component={View_Admin_Client}/>

      <Route exact path="/compliance_list_mgmt" component={Compliance_List_Mgmt} />
      <Route exact path="/user_management" component={User_Management} />
      {/* Acta List Library */}
      <Route exact path="/acts" component={Library_Acts} />
      <Route exact path="/forms" component={Library_Forms}/>
      <Route exact path="/gazette_notification" component={Library_Gazette_Notification}/>
      
      <Route exact path="/holiday_list" component={Pre_Holiday_List}/>
      <Route exact path="/holiday_list/:name" component={Library_Holiday_List} />
      
      <Route exact path="/labour_welfare_fund" component={Pre_Lwf} />
      <Route exact path="/labour_welfare_fund/:name" component={Labour_Welfare_Fund} />
      
      <Route exact path="/leave_working_hours" component={Pre_Leave_Working_Hr} />
      <Route exact path="/leave_working_hours/:name" component={Leave_Working_Hours} />

      <Route exact path="/minimum_wages" component={Pre_Minimum_Wages} />
      <Route exact path="/minimum_wages/:name" component={Library_Minimum_Wages} />
      
      <Route exact path="/professional_tax" component={Pre_Professional_Tax}/>
      <Route exact path="/professional_tax/:name" component={Library_Professional_Tax}/>
      <Route exact path="/rules" component={Library_Rules} />
      <Route exact path="/abstracts" component={Library_Abstracts} />
      <Route exact path="/display_notices" component={Library_Display_Notices}/>
      <Route exact path="/important_judgment" component={Library_Important_Judgments} />
      <Route exact path="/recent_notification" component={Notification_Updates}/>
      
      {/* support ticket */}
      <Route exact path="/client_support" component={Client_Interface}/>
      <Route exact path="/client_support_ticket" component={Client_Interface}/>
      <Route exact path="/client_ticket_gen" component={Client_Interface}/>
      <Route exact path="/admin_support" component={Support_Admin_Interface}/>

    </Router>
  }
  
}
export default App;