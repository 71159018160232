import React from 'react';
import {GetImgCertUser_CBack,getWaUrlPath} from '../function_lib/common_lib';

export function editProductCategories(){
    /*
    GetImgCertUser_CBack(this,"#frmProductImages","updateImages",function(checkData,checkMsg,userMsg){
      if(checkData===-10){
        alert("Kindly fill required fields");
        return;
      }else {
        //Custom_Alert("Successfully Added",checkMsg,false);
        alert(checkMsg);
      }
    });
    */
    
}             
  
const Edit_Product_Categories=(props)=>{
    let proImages=[];
    //let _imgPath=getWaUrlPath();
   //alert(JSON.stringify(props));

    //alert(JSON.stringify(props));
        return(<div className="modal fade bd-example-modal-xl"  id="divProductCategories" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Map Product Categories</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            
                            
                          
                            <div class="card-body">
                                <div className="row">
                                <div className="col-md-6">
                                <div className="card card-danger">
                                <div className="card-header">
  <h3 className="card-title">Existing Mapped Categories </h3><small> () </small>
</div>
<div className="card-body">
    {props.listexCategories.map((item,index)=>{
      return (<li>{item.category_name} <a href="javascript:void(0)" className="mr-20"><i class="fa fa-trash-o fa-2x _setbackparam" param1={item.rel_id} resback={"onDelProCatRel"} aria-hidden="true"></i></a></li>)
    })}
    </div>
    </div>
                                </div>    
                                  

{/* place of Image logic */}
 {/* file image upload */}
 <div className="col-md-6">
 <div className="card card-info">
<div className="card-header">
  <h3 className="card-title">Map New Category</h3><small></small>
</div>
<div className="card-body">
<form class="form-group" id="frmProductCategories" name="frmProductCategories" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                            <input type="hidden" name="res_func" id="res_func" value="okSuccCatMap" />
                            <input type="hidden" name="curl_type" id="curl_type" value="addProductCategory" />
                            <input type="hidden" name="m_type" id="m_type" value="addProductCategory" />
                            <input type="hidden" name="product_id" id="product_id" value={typeof props.editProduct.rf_id!=="undefined"?props.editProduct.rf_id:""} />
                            <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={props.app_user_id}/>
                          
  <div className="row">
                                  <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Product Category *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="epc_category" name="epc_category" className="form-control required11">
                                                  <option value="0">-Select Category-</option>
                                                  {props.lsCategories.map((item,index)=>{
                                                    return(<option value={item.id}>{item.category_name}</option>)
                                                  })}
                                                </select>
                                            </div>
                                        </div>
                                  </div>
                                  <div class="col-lg-12 col-md-12">
                                      
                                      <div class="form-group">
                                      <center>
                                          <div class="input-group input-group-sm" style={{"justify-content":"left"}}>
                                              <button type="button" class="btn btn-primary _btnSetColor">Add Category</button>
                                          </div>
                                      </center>
                                      </div>
                                  </div>          
                </div>                  
                </form>
</div>

</div>
</div>

                                    {/* end of image logic */}
                                </div>
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                                
                                </div>
                            
          </div>
          </div>
          </div>
          </div>);
    
}
export default Edit_Product_Categories;
