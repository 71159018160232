import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker,
  RespBkMthdMapp} from '../function_lib/common_lib';
import {CallCMSMapMethod_CBack} from '../function_lib/common_cms';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Option_Module from '../user_pages/option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';

import Comp_Astro_Add_Articles from './comp_astro_add_articles';
import Comp_Astro_Edit_Articles from './comp_astro_edit_article';
import 'select2/dist/js/select2.full.min.js';
import 'select2/dist/css/select2.min.css';



let loginUser="";
let userObject="";
let companyName="";
let clsAtroArticles;

let rf="0";


let totalFileAllow=8;

let objImages = [];
let indexExisting=0;
let arrExistingFiles=[];


let objOthFiles=[];
let othIndexExisting=0;
let arrOthExistingFiles=[];
class Astro_Articles extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ stlistArticles:[],stTopArticlesList:[],stEditArticle:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsAtroArticles=this;
      }
      resetFilters(){
        $("#ser_text").val("");
        $("#ser_top_article").val("0");

        clsAtroArticles.getArticles();
      }

     
getArticles(){
   //child component use this method loan_document_upload_comp
   let map=[];
   map.push({name:"curl_type",value:"getArticles"});
   map.push({name:"res_func",value:"callBackrrr"});
   map.push({name:"local_token",value:userObject[0].app_user_id});
   map.push({name:"user_type",value:userObject[0].type});
   map.push({name:"upper_id",value:userObject[0].upper_id});
   map.push({name:"ser_text",value:$("#ser_text").val()});
   map.push({name:"ser_top_article",value:$("#ser_top_article").val()});
   map["res_func"]="callBackrrr";
   map["curl_type"]="getArticles";
   CallCMSMapMethod_CBack(clsAtroArticles,map,function(data,msg){
     //alert(data);
     
       if(data!=="0" && data!==""){
        let jsData=JSON.parse(data);
         //let jsAllData=JSON.parse(data);
          //let strTreeData=JSON.stringify(jsAllData.all_list);
          $(".outstanding_rpt").html(" Total Articles :<b>"+jsData.all_data.length+"</b>");
          clsAtroArticles.setState({stlistArticles:jsData.all_data,
            stTopArticlesList:jsData.top_data},()=>{
            });
         }
   });
}

// file upload section
fileValidation(){
  let allowFile=true;
let imgCount=objImages.length;
let othFileCount=objOthFiles.length;


let vTotalCount=imgCount+othFileCount;

if(vTotalCount>=totalFileAllow){
alert("Only "+totalFileAllow+" to be allowed .");
allowFile=false;
}
return allowFile;
}

ImageOthThumbBack(flImg,flName,fObject,othInfo="",lsElement="",thumbnail=""){
  alert(thumbnail);
  if(!this.fileValidation()){
    return;
  }

  if(flImg==="-10"){
    alert(flName);
    return;
  }
  if(othInfo===""){
    othInfo="nm"+othIndexExisting+","+othIndexExisting;
  }
  othIndexExisting++;

  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrExistingFiles[arrNmValue[1]]=arrNmValue[0];
  var strSet = "<div id=\"refoth_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"fa fa-close icon-close _removeBtn"+othIndexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
    "</div>";
    if(flImg.indexOf("data:video/webm")!==-1 || flImg.indexOf("data:video/mp4")!==-1){
     strSet= "<div id=\"refoth_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close icon-close _removeBtn"+othIndexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+flImg+"\"><video controls type=\"video/mp4\" src=\"" + flImg + "\"/></a>" +
        "</div>";
    }
    
  //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
  objOthFiles.push({ name: arrNmValue[1], value: flImg });
 // alert(objImages.length);
 if(lsElement===""){
  $("#lsOthImages").val("{\"objs\":" + JSON.stringify(objOthFiles) + "}");
 }else{
  $("#"+lsElement).val("{\"objs\":" + JSON.stringify(objOthFiles) + "}");
 }
  
  if(thumbnail===""){
    $(".imgOthLibThumb").append(strSet);
  }else{
    $("."+thumbnail).append(strSet);
  }            
  
  if(fObject!==null){
    fObject.val('');
  }
//   $('.gallery-item').magnificPopup({
//     type: 'image',
//     gallery: {
//         enabled: true
//     }
// });
RespBkMthdMapp(clsAtroArticles, "_removeBtn"+othIndexExisting, "rmOthFileProc", "2");
  //imgLibThumb

}
rmOthFileProc(data){
  
  if(document.getElementById("refoth_div_"+data)!=null){
    document.getElementById("refoth_div_"+data).remove();
  }
  
  this.lcRemoveJSON(data,"oth_image");
}

rmFileProc(data){
  
  if(document.getElementById("ref_div_"+data)!=null){
    document.getElementById("ref_div_"+data).remove();
  }
  
  this.lcRemoveJSON(data,"image");
}
lcRemoveJSON(refKey,fType){
  //alert(refKey+"== "+fType);
  if(fType==="image"){
    var jsonP=JSON.parse($("#lsImages").val());
    var arrJson=jsonP.objs;
    for(var ai=0;ai<arrJson.length;ai++){
      if(arrJson[ai]["name"]===refKey){
        arrJson.splice(ai,1);
        $("#lsImages").val(JSON.stringify(jsonP));
        
        break;
      }
    }
    objImages=arrJson;
    
  }else if(fType==="oth_image"){
    var jsonP=JSON.parse($("#lsOthImages").val());
    var arrJson=jsonP.objs;
    for(var ai=0;ai<arrJson.length;ai++){
      if(arrJson[ai]["name"]===refKey){
        arrJson.splice(ai,1);
        $("#lsOthImages").val(JSON.stringify(jsonP));
        
        break;
      }
    }
    objOthFiles=arrJson;
  }
  
  
   
  }
       

ImageThumbBack(flImg, flName,fObject,othInfo="",lsElement="",thumbnail="") {
  
  if(!this.fileValidation()){
    return;
  }

  if(flImg==="-10"){
    alert(flName);
    return;
  }

  if(othInfo===""){
    othInfo="nm"+indexExisting+","+indexExisting;
  }
  indexExisting++;
  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrExistingFiles[arrNmValue[1]]=arrNmValue[0];
  var strSet = "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
    "</div>";
    if(flImg.indexOf("data:video/webm")!==-1 || flImg.indexOf("data:video/mp4")!==-1){
     strSet= "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+flImg+"\"><video controls type=\"video/mp4\" src=\"" + flImg + "\"/></a>" +
        "</div>";
    }
    
  //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
  objImages.push({ name: arrNmValue[1], value: flImg });
 // alert(objImages.length);
 if(lsElement===""){
  $("#lsImages").val("{\"objs\":" + JSON.stringify(objImages) + "}");
 }else{
  $("#"+lsElement).val("{\"objs\":" + JSON.stringify(objImages) + "}");
 }
  
  
  //alert(strSet);
if(thumbnail===""){
  $(".imgLibThumb").append(strSet);
}else{
  $("."+thumbnail).append(strSet);
}
  
  
  if(fObject!==null){
    fObject.val('');
  }
//   $('.gallery-item').magnificPopup({
//     type: 'image',
//     gallery: {
//         enabled: true
//     }
// });
RespBkMthdMapp(clsAtroArticles, "_removeBtn"+indexExisting, "rmFileProc", "2");
  //imgLibThumb
      }
// end of file upload section
         
      
componentDidMount(){
  $(".select2").select2();
  $(".cleditor").summernote();
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.getArticles();

}
onDeleteArticle(data){
alert("under process to deploye");
}
onEditArticle(data){

   //child component use this method loan_document_upload_comp
   let map=[];
   map.push({name:"curl_type",value:"getArticleById"});
   map.push({name:"res_func",value:"callBackrrr"});
   map.push({name:"local_token",value:userObject[0].app_user_id});
   map.push({name:"user_type",value:userObject[0].type});
   map.push({name:"upper_id",value:userObject[0].upper_id});
   map.push({name:"article_id",value:btoa(data["id"])});
   
   map["res_func"]="callBackrrr";
   map["curl_type"]="getArticleById";
   CallCMSMapMethod_CBack(clsAtroArticles,map,function(data,msg){
       if(data!=="0" && data!==""){
        let jsData=JSON.parse(data);
         jsData=jsData.all_data;
        if(jsData.length>0){
          clsAtroArticles.setState({stEditArticle:jsData[0]});
        }
         }
   });


  //clsAtroArticles.setState({stEditArticle:data});
 
}
onEditArticleImage(data){
  alert("under process to deploye");
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row content12 mt-2 mb-2">

<div className="col-lg-2 col-md-2 col-sm-4 float-right">
<select  className="form-control  select2 form-control-sm" id="ser_top_article" name="ser_top_article">
           <option value="0">-Top Articles-</option>
           {this.state.stTopArticlesList.map((item,index)=>{
            return (<option value={item.id}>{item.title}</option>)
           })}
          
          
         </select>
</div>
<div className='col-lg-4 col-md-4 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_text" name="ser_text" rf="#btnCatSearch" className="_setSearchKeyUp keyEvent_btn form-control form-control-sm" placeholder='search' />
             </div>
         
         </div>

</div>

<div className="col-lg-2 col-md-2 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div onClick={()=>this.getArticles()}  style={{marginRight:"10px"}} id="btnCatSearch" name="btnCatSearch" className="btn btn-sm btn-danger">Search</div>
                 <div onClick={()=>this.resetFilters()} className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>

</div>
    </div>    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Article Listing</b></h3>
                <div className="outstanding_rpt float-left pl-50 px-10"></div>
                <a className="btn btn-sm btn-danger float-right" data-toggle="modal" data-target="#divAtroAddArticle" href="javascript:void(0)">Add New Article</a>
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Action</th>
                      <th>Page Category</th>
                      <th>Title</th>
                      <th>Page Name</th>
                      <th>Page Url</th>
                      <th>Order</th>
                      <th>Entry Data</th>
                      <th>Status</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.stlistArticles.map((item,index)=>{
                       return(<tr>
                        <td>{index+1}</td>
                        <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    <div class="dropdown-menu">
                   
                     <a class="dropdown-item" onClick={()=>this.onEditArticle(item)} data-toggle="modal" data-target="#divAtroEditArticle" href="javascript:void(0)">Edit</a>
                     {/* <a class="dropdown-item" onClick={()=>this.onEditArticleImage(item)} data-toggle="modal" data-target="#divEditStudentAuth" href="javascript:void(0)">Update Image</a> */}
                     <a class="dropdown-item" onClick={()=>this.onDeleteArticle(item)} data-toggle="modal"  data-target="#divEditStudentFamily" href="javascript:void(0)">Delete</a>
                     
                    </div>
                  </div>
                  </td>
                  <td>{item.upper_page_title}</td>
                  <td>{item.title}</td>
                  <td>{item.pagename}</td>
                  <td><a href={"https://www.astrosaxena.com/articles/"+item.pageurlname} target="blank">{item.pageurlname}</a></td>
                  <td>{item.order}</td>
                  <td>{item.ent_date}</td>
                  <td><span className="badge bg-danger dash_rejected_claim1">{item.status}</span></td>
                 
                    </tr>)
                  }))}
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
<Comp_Astro_Add_Articles listArticles={this.state.stlistArticles} app_user_id={userObject[0].app_user_id} />
<Comp_Astro_Edit_Articles editArticleData={this.state.stEditArticle} listArticles={this.state.stlistArticles} app_user_id={userObject[0].app_user_id}/>
    {/* <Wa_Credit_Balance_Comp creditUser={this.state.crediUser} creditBalance={this.state.creditBalance} masterBalance={this.state.masterBalance} app_user_id={userObject[0].app_user_id} /> */}
          </div>)
      }
}
export default Astro_Articles;