import $, { extend } from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {GetCurrentDtTime,CallMapMethod,customAlert,} from '../function_lib/common_lib';
import Geocode from "react-geocode";
import {setApiKey,setLanguage,setRegion,enableDebug,setLocationType,fromLatLng} from './google_api';
import defaultPic from '../assets/images/9.png';


let loginUser="";
let accountNo="";

let userObject="";

let late;
let longe;
let clsObject;

let attState="in";
let attStateMsg="Submit (Time In)";
let presentId=0;
let locAddress="";

let tmpMessage="Please wait..";



class SubmitTimeIn_Out extends React.Component
{
  closeMe(clName){
    // $(clName).modal("hide");
    $(clName).hide();
  }
  showMe(clName){
    $(clName).show();
    $(clName).addClass("show");
    // $(clName).modal("show");
  }
  
  callBackAtt(data,msg){
    //alert(data+" == "+msg);
    $(".attmodel_close").trigger('click');
    //this.closeMe("")
    if(data!="0" && data!="-1"){
      localStorage.setItem("timein",data);
      customAlert("Time In","Succcessfully Submitted","./Dashboard",1);
    }else if(data=="-1"){
      localStorage.removeItem("timein");
      customAlert("Time Out","Succcessfully Submitted","./Dashboard",1);
      
    }
 }
 submitAtten(){
   var timeLoc= document.getElementById("loc_address").innerText;
   //alert(timeLoc);
   //return;
   //alert("submit=="+late+","+longe);
   var map=[];
   map.push({name:"company_id",value:userObject[0].app_user_id});
   map.push({name:"emp_id",value:userObject[0].id});
   map.push({name:"att_status",value:"present"});
   map.push({name:"att_dt_time",value:GetCurrentDtTime()});
   map.push({name:"lat",value:late});
   map.push({name:"long",value:longe});
   map.push({name:"status",value:"accepted"});
   map.push({name:"ins_date",value:GetCurrentDtTime()});
   map.push({name:"update_date",value:GetCurrentDtTime()});
   map.push({name:"oth_emp_name",value:userObject[0].person_name});
   map.push({name:"oth_emp_code",value:userObject[0].account_no});
   map.push({name:"time_in_loc",value:timeLoc});
  map.push({name:"id",value:presentId});
  
   map["res_func"]="callBackAtt";
   map["curl_type"]="attendanceModule";
  // alert("get test== "+clsObject);
   CallMapMethod(clsObject,map);
   //alert("Submitted Successfully");
 }

 constructor(props){
   super(props);
   //setApiKey("AIzaSyAPKk-JAQ_hsBww8o9puo2LZBIu6EPlu2A");

   Geocode.setApiKey("AIzaSyAUh6l697EGIKixRkjscZIkcS-d6XK1Ggw");
   Geocode.setLanguage("EN");
   Geocode.setRegion("IN");
   Geocode.setLocationType("ROOFTOP");
   Geocode.enableDebug();
   if(localStorage.getItem("timein")!=null){
     attState="out";
     attStateMsg="Submit (Time Out)";
     presentId=localStorage.getItem("timein");
   }

   if(localStorage.getItem("userData")!=null){
     
     UserToken.setUserObject(localStorage.getItem("userData"));
     userObject= UserToken.getUserObject();
     loginUser=userObject[0].person_name;
     accountNo=userObject[0].account_no;
   }
 }
 showPosition(position){
   var latitude_val ,longitude_val ;
   latitude_val = position.coords.latitude ;
   longitude_val = position.coords.longitude ;
   late=latitude_val;
   longe=longitude_val;
  
   //Geocode.geocode()
   //alert(late+","+longe);
   Geocode.fromLatLng(late,longe).then(
     (response) => {
      
       const address = response.results[0].formatted_address;
       locAddress=address;
       $("#loc_address").html(locAddress);
       console.log(address);
     },
     (error) => {
      locAddress= "Unable to Get LocationName1";
      $("#loc_address").html(locAddress);
       console.error(error);
     }
   );
   //alert(locAddress);
  

}

showError(error) {
 var error;
 switch(error.code) {
     case error.PERMISSION_DENIED:
     error = "User denied the request for Geolocation."
     break;
     case error.POSITION_UNAVAILABLE:
     error = "Location information is unavailable."
     break;
     case error.TIMEOUT:
     error = "The request to get user location timed out."
     break;
     case error.UNKNOWN_ERROR:
     error = "An unknown error occurred."
     break;
 }
}

 componentDidMount(){
   clsObject=this;
   //SetDate(".curDtTime")

   if(navigator.geolocation){
     //alert("i am in navifation");
     $("#latlongm").html("Please wait..");
     navigator.geolocation.getCurrentPosition(this.showPosition,this.showError);
     $("#latlongm").html("Submit Now..");
     $(".altSubmit").html(attStateMsg);
    
   }else{
     alert("Geolocation Permission Not Alloted to this device");
   }
   
 }
  
 render(){
  return(
    <div className="modal fade bd-example-modal-xl"  id="divAttend" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h6 className="modal-title text-white" id="exampleModalLabel">Attendance</h6>
          <button type="button" className="close attmodel_close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>

<div className="container">
    
<div className="checkout-wrapper-area py-3">
  <div className="credit-card-info-wrapper"><h1 className="text-center"><img className="mb-4 center" style={{width:"100%"}} src={defaultPic} alt=""/></h1>
    <div className="bank-ac-info">
   
      <ul className="list-group mb-3">
        <li className="list-group-item d-flex justify-content-between align-items-center">Employee Name<span>{loginUser}</span></li>
        <li className="list-group-item d-flex justify-content-between align-items-center">Employee ID<span>{accountNo}</span></li>
        <li className="list-group-item d-flex justify-content-between align-items-center">Date/Time<span className="curDtTime">{GetCurrentDtTime()}</span></li>
      </ul>
      <div class="single-profile-data d-flex align-items-center justify-content-between">
          <div class="title d-flex align-items-center"><i class="lni lni-map-marker"></i><span>Location:</span></div>
          <div class="data-content" id="loc_address"></div>

        </div>
      <ul className="list-group mb-3">
        <li className="list-group-item d-flex justify-content-between align-items-center">Click Here <span>to Accurate Current Location</span></li>
        </ul>
     
    </div>
    <div id="latlongm">Please wait..</div>
    <a className="btn altSubmit btn-warning btn-lg w-80" onClick={this.submitAtten} href="#">{attStateMsg}</a>
  </div>
</div>
</div>
</div>
</div>
</div>)
 }
   
}
export default SubmitTimeIn_Out;