import $, { extend } from 'jquery';
import React from 'react';
class Entity_Opt_Modules extends React.Component{
    componentDidMount()
    {
       
    }
    render(){
        return(<div>
             <div className="card-footer p-0">
            <ul className="nav flex-column">
              <li className="nav-item"><h6> <a href="../view_entity" className="nav-link hover"><i className="fa fa-globe text-orange"></i>View Entity</a></h6> </li>
              <li className="nav-item"><h6> <a href="../view_branches" className="nav-link hover"><i className="fa fa-building-o text-orange"></i> View Branches </a></h6> </li>
              <li className="nav-item"><h6> <a href="../view_vendors" className="nav-link hover"><i className="fa fa-address-card-o text-orange"></i>View Vendors</a></h6> </li>
              <li className="nav-item"><h6> <a href="../view_clients" className="nav-link hover"><i className="fa fa-user-circle-o text-orange"></i>View Clients</a></h6> </li>
            </ul>
          </div>
        </div>)
    }
}
export default Entity_Opt_Modules;