import $ from 'jquery';
import React,{useState,useEffect} from 'react';
import {getMultiPartData,ListTicketPriority,UploadImageSetups,RemoveExUpdFile,ash_data_url} from './default_data_ticket';
import {RespBkMthdMapp,ConvertDateFormat_Time} from '../function_lib/common_lib';



let indexExisting=0;
let arrExistingFiles=[];

  
export const Client_Reply_Thread=(props)=>{
  
const replyNewTicket=()=>{
  getMultiPartData("#frmReplyClientThread","createClientDisc").then((res)=>{
    //alert(res);
    if(res==="001"){
      alert("Submitted Successfully..");
      window.location.reload();
    }
  })
}

 const fileValidation=()=>{
  
    let allowFile=true;
  /*
    let imgCount=objImages.length;
  let othFileCount=objOthFiles.length;
  
  
  let vTotalCount=imgCount+othFileCount;
  
  if(vTotalCount>=totalFileAllow){
  alert("Only "+totalFileAllow+" to be allowed .");
  allowFile=false;
  }
  */
  return allowFile;
  
  }

 const lcRemoveJSON=(refKey,fType,lsImages,thumbDiv)=>{
    var jsonP=JSON.parse($("#"+lsImages).val());
    var arrJson=jsonP.objs;
    for(var ai=0;ai<arrJson.length;ai++){
      if(arrJson[ai]["name"]===refKey){
        arrJson.splice(ai,1);
        $("#"+lsImages).val(JSON.stringify(jsonP));
        break;
      }
    }
    //objImages=arrJson;
}
  //alert(JSON.stringify(props.listMenus));
  const [getCompList,setCompList]=useState([]);
    // const handleMapMenuChg=(event)=>{
    //     let tarValue=event.target.value;
    //     if(tarValue!=="0" && tarValue!==0){
    //         tarValue=atob(tarValue);
    //     }
    //     const listFltr = d =>d.parent_id===tarValue;
    //     let listComps=props.listAllComp.filter(listFltr);
    //     setCompList(listComps);
    // }
    const listPriority=ListTicketPriority();
    useEffect(()=>{

      UploadImageSetups(function(flImg, flName,fObject,othInfo="",lsElement="",thumbnail="",isMultiple=false){
        //alert(isMultiple);
  if(!fileValidation()){
    return;
  }

  if(flImg==="-10"){
    alert(flName);
    return;
  }
  if(lsElement===""){
    lsElement="lsImages"
  }
  let currentObject=null;
  let thumDiv="ref_div_";
  if(fObject){
    currentObject=fObject[0];
    if(currentObject.hasAttribute("thumbnail_div")){
      thumDiv=currentObject.getAttribute("thumbnail_div");
    }

  }

  if(othInfo===""){
    othInfo="nm"+indexExisting+","+indexExisting;
  }
  indexExisting++;
  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrExistingFiles[arrNmValue[1]]=arrNmValue[0];
  var strSet = "<div id=\""+thumDiv+arrNmValue[1]+"\" class=\"col-lg-1 col-md-1 col-sm-1 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\" param3=\""+thumDiv+"\" param2=\""+lsElement+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
    "</div>";
    if(flImg.indexOf("data:video/webm")!==-1 || flImg.indexOf("data:video/mp4")!==-1){
     strSet= "<div id=\""+thumDiv+arrNmValue[1]+"\" class=\"col-lg-1 col-md-1 col-sm-1 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\"  param3=\""+thumDiv+"\" param2=\""+lsElement+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+flImg+"\"><video controls type=\"video/mp4\" src=\"" + flImg + "\"/></a>" +
        "</div>";
    }else if(flImg.indexOf("wordprocessingml")!==-1){
      strSet= "<div id=\""+thumDiv+arrNmValue[1]+"\" class=\"col-lg-1 col-md-1 col-sm-1 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\"  param3=\""+thumDiv+"\" param2=\""+lsElement+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+flImg+"\"><i class=\"fa fa-file-word-o fa-3x\" aria-hidden=\"true\"></i></a>" +
        "</div>";
    }else if(flImg.indexOf("data:application/pdf")!==-1){
      strSet= "<div id=\""+thumDiv+arrNmValue[1]+"\" class=\"col-lg-1 col-md-1 col-sm-1 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\"  param3=\""+thumDiv+"\" param2=\""+lsElement+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+flImg+"\"><i class=\"fa fa-file-pdf-o fa-3x\" aria-hidden=\"true\"></i></a>" +
        "</div>";
    }



 let tmpObjImages=[];
 if($("#"+lsElement).val()!==""){
  if(isMultiple===true){
    //alert("i min multiple");
    tmpObjImages=JSON.parse($("#"+lsElement).val());
    tmpObjImages=tmpObjImages.objs;
  }
 }
 tmpObjImages.push({ name: arrNmValue[1], value: flImg });
 $("#"+lsElement).val("{\"objs\":" + JSON.stringify(tmpObjImages) + "}");
  
  //alert(tmpObjImages.length);
  //alert(strSet);
if(thumbnail===""){
  thumbnail="imgLibThumb";
}
//alert(isMultiple);
if(isMultiple===false){
  $("."+thumbnail).html(strSet);
}else{
  //alert("i m in append");
  $("."+thumbnail).append(strSet);
}

  
  
  if(fObject!==null){
    fObject.val('');
  }
//   $('.gallery-item').magnificPopup({
//     type: 'image',
//     gallery: {
//         enabled: true
//     }
// });
RemoveExUpdFile(this, "_removeBtn"+indexExisting,function(data,lsImages,thumbDiv){
//alert(lsImages);
if(lsImages==="" || lsImages==="0"){
  lsImages="lsImages"
}
if(thumbDiv==="" || thumbDiv==="0"){
  thumbDiv="ref_div_";
}

if(document.getElementById(thumbDiv+data)!=null){
  document.getElementById(thumbDiv+data).remove();
}

lcRemoveJSON(data,"image",lsImages,thumbDiv);
});
  //imgLibThumb
      })
      
    },[])
    //alert(JSON.stringify(props));
        return(<div className="modal fade bd-example-modal-xl"  id="divReplyClientThread" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
          <div className="modal-header bg-adv">
              <h5 className="modal-title text-white" id="exampleModalLabel">{props.view_ticket.ticket_no}, {props.view_ticket.col2}</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            <div class="row">
    <div class="col-12">
    <div class="card-footer card-comments">
        {props.view_disc.map((item,index)=>{
          let arrFiles=[];
          if(item.file_att!==""){
            arrFiles=item.file_att.split(',');

          }
            return <div class="card-comment">
                  <i class="fa fa-user-circle-o img-circle img-sm fa-2x" aria-hidden="true"></i>
            {/* <img class="img-circle img-sm" src="../dist/img/user3-128x128.jpg" alt="User Image" /> */}

            <div class="comment-text">
              <span class="username">
                {item.disc_user}
                <span class="text-muted float-right">{ConvertDateFormat_Time(item.entry_date)}</span>
              </span>
             {item.col4}
             <span class="comment-text">
             {arrFiles.map((itemFl,flIndex)=>{
              let flType="img";
              if(itemFl.indexOf(".doc")!==-1 || itemFl.indexOf(".docx")!==-1){
                flType="word";
              }else if(itemFl.indexOf(".pdf")!==-1){
                flType="pdf";
              }
              return <>
              {(flType==="word")?<a href={ash_data_url+itemFl} target="blank" download><i class="fa fa-file-word-o fa-3x" aria-hidden="true"></i></a>:''}
              {(flType==="pdf")?<a href={ash_data_url+itemFl} target="blank" download><i class="fa fa-file-pdf-o fa-3x" aria-hidden="true"></i></a>:''}
              {(flType==="img")?<a href={ash_data_url+itemFl} target="blank" download><i class="fa fa-file-image-o  fa-3x" aria-hidden="true"></i></a>:''}
              </>
             })}
             </span>
            </div>
           
          </div>
        })}
    

    </div>
    {/* list of reply generated */}
</div>
 </div>       
            <form class="form-group" id="frmReplyClientThread" name="frmReplyClientThread" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="createClientDisc" />
                            <input type="hidden" name="m_type" id="m_type" value="createClientDisc" />
                            {/* <input type="hidden" name="product_id" id="product_id" value={typeof props.editProduct.rf_id!=="undefined"?props.editProduct.rf_id:""} /> */}
                            <input type="hidden" name="auth_token" id="auth_token" value={props.auth_token}/>
                            <input type="hidden" name="lsImages" id="lsImages" />
                            <input type="hidden" name="lsReplyImages" id="lsReplyImages"/>
                            <input type="hidden" name="log_token" id="log_token" value={props.log_token}/>
                            <input type="hidden" name="ticket_enc" id="ticket_enc" value={props.view_ticket.encKey}/>
                            
                            <div class="card-body">
                              
                                <div class="row">
                                 
    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Description</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="5" id="col4" name="col4"></textarea>
                                            </div>
                                        </div>
                                    </div>
                              
                                  
{/* image upload section */}
<div className="col-md-12">
<div className="row">
    

                               {/* file other image upload */}

<div className="col-md-8">
 <div className="card card-info">
<div className="card-header bg-adv">
  <h3 className="card-title">Images or Other Attachment </h3><small> (Max File Size 1MB, Only 7 will allow) </small>
</div>
<div className="card-body">
<div className="row audioLibThumbReply imgOthLibThumbReply">
                      
                      </div>
  <div className="row">
    
    <div className="col-12">
    <input type="file" ref_img="ImageThumbBackNewReply" thumbnail_div={"oth_img_reply"} lsElement={"lsReplyImages"} thumbnail={"imgOthLibThumbReply"} multiple name="replyOthFileBr" id="replyOthFileBr" accept="*/*" size={100000000} className="updImgThumb" />
      <a href="javascript:void(0)" setTo="replyOthFileBr" className="_brFileThumb updthumb">Click & Browse Image Files</a>
    </div>
   
   
  </div>
</div>

</div>
</div>

                               {/* end of other file image upload */}
</div>
</div>
{/* end of Image Upload Section */}

                                </div>
                                 
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                                <div class="row">
                                    <div class="col-12">
                                      
                                        <div class="form-group">
                                        <center>
                                            <div class="input-group input-group-sm" style={{"justify-content":"center"}}>
                                                <button type="button" onClick={()=>replyNewTicket()} class="btn btn-primary">Submit</button>
                                            </div>
                                        </center>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>
                            </form>
                     
          </div>
          </div>
          </div>
          </div>);
    
}
export default Client_Reply_Thread;
