export function getMonths(){
    let monthNames = [ {value:"01",display:"January"},
    {value:"02",display:"February"},
    {value:"03",display:"March"},
    {value:"04",display:"April"},
    {value:"05",display:"May"},
    {value:"06",display:"June"},
{value:"07",display:"July"},
{value:"08",display:"August"},
{value:"09",display:"September"},
{value:"10",display:"October"},
{value:"11",display:"November"},
{value:"12",display:"December"}];
return monthNames;
}
export function getComplianceCategory(){
    return([
        {
            value:"Major",
            display:"Major"
        },
        {
            value:"Moderate",
            display:"Moderate"
        },
        {
            value:"",
            display:"None"
        }
    ])
}

export function getResponsibility(){
    return([
        {
            value:"All",
            display:"All"
        },
        {
            value:"Contractor",
            display:"Contractor"
        },
        {
            value:"Establishment",
            display:"Establishment"
        }
    ])
}
export function getPeriodicity(){
    return([
        {
            value:"Annual",
            display:"Annual"
        },
        {
            value:"Half Yearly",
            display:"Half Yearly"
        },
        {
            value:"Monthly",
            display:"Monthly"
        },
        {
            value:"Quaterly",
            display:"Quaterly"
        }
    ])
}

export function getComplianceType(){
return([{
    value:"Notification",
    display:"Notification"
},
{
    value:"Registers",
    display:"Registers"
},
{
    value:"Return",
    display:"Return"
},
{
    value:"Self Certification",
    display:"Self Certification"
},
{
    value:"Saturatory Payments",
    display:"Saturatory Payments"
},
{
    value:"Saturatory Payments and Return",
    display:"Saturatory Payments and Return"
},
{
    value:"",
    display:"None"
}
]);
}