import $ from 'jquery'
import React from 'react'

import {
  btnSetColor_CBack,
  GetImgCert_CBack,
  AjaxCallMethod,
  RespBkMthdMapp,
  FormatDate
} from '../function_lib/common_lib'
import { Cls_Common_Lib } from '../function_lib/cls_common_lib'
import { StateIndia } from '../function_lib/city_india'
import TkMeter from '../assets/images/camera.svg'

let strCheckRoles = ''
let clsObjectEnt
let objImages = []
let indexExisting = 0
let arrExistingFiles = []
let clsEntitySetting
let stateList = []
class Set_Pre_Date_Library extends React.Component {
  constructor (props) {
    super(props)
    clsObjectEnt = this;
    
    clsEntitySetting = new Cls_Common_Lib();
    stateList = StateIndia()
  }
  componentWillReceiveProps (props) {
    this.props = props
    //alert(JSON.stringify(this.props));
    if(typeof this.props.listPreDate.effective_date!=="undefined"){
      if(this.props.listPreDate.effective_date.trim()!==""){
        //alert("date in = set data=="+this.props.listPreDate.effective_date);
        //let fmDate=FormatDate(this.props.listPreDate.effective_date);
        //$("#effective_date").val(this.props.listPreDate.effective_date);
      }
      
    }
  }
  saveLibrary () {
    // GetImgCert(clsObject,"#frmLibCreate","addLibraryList","okSuccLib");
  }

  addPreDateLibrary (frmName) {
    GetImgCert_CBack(this, frmName, 'addPreDateLibrary', function (data, msg) {
      //alert(msg)
      if (data === '001') {
        alert('Updated Successfully')
      } else {
        alert(
          'Unable to Update Record !kindly check and try again'
        )
      }
    })
  }
  
 
  componentDidMount () {
    clsEntitySetting.AjaxCallMethod(clsObjectEnt, 'okSuccCNOT')
  }
  render () {
    return (
      <div
        className='modal fade bd-example-modal-xl'
        id='divSetPreDateLibrary'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-xl' role='document'>
          <div className='modal-content'>
            <div className='modal-header bg-primary'>
              <h5 className='modal-title text-white' id='exampleModalLabel'>
                Entity Settings
              </h5>
              <button
                type='button'
                className='close text-white'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true' className='text-white'>
                  &times;
                </span>
              </button>
            </div>
            <form
              method='POST'
              id='frmSetPreDateLibrary'
              name='frmSetPreDateLibrary'
            >
              <div className='modal-body'>
                <div className='row'>
                  {/* <!-- left column --> */}
                  <div className='col-md-12'>
                    <div className='card card-danger'>
                      <input
                        type='hidden'
                        name='res_func'
                        id='res_func'
                        value='okSucc'
                      />
                      <input
                        type='hidden'
                        name='curl_type'
                        id='curl_type'
                        value='addPreDateLibrary'
                      />
                      <input
                        type='hidden'
                        name='m_type'
                        id='m_type'
                        value='addPreDateLibrary'
                      />
                      <input
                        type='hidden'
                        name='app_user_id'
                        id='app_user_id'
                        value=''
                      />
                      <input 
                       type="hidden"
                       name="edit_lib_id"
                       id="edit_lib_id"
                       value={typeof this.props.listPreDate._id!=="undefined"?this.props.listPreDate._id:"0"}
                      />
                      <input
                        type="hidden"
                        name="type_id"
                        id="type_id"
                        value={this.props.type_id}
                      />
                     
                  
                      <div className='card-body'>
                        <div className='row audioLibThumb imgLibThumb'>
                          <div className='col-lg-4 col-md-4'>
                            <div className='form-group'>
                              <div className='input-group input-group-sm'>
                                <div className='form-group'>
                                  <label for='exampleInputEmail1'>
                                    State *
                                  </label>
                                  <div className='input-group input-group-sm'>
                                    <select
                                      id='state_name'
                                      name='state_name'
                                      className='form-control'
                                    >
                                      <option value='0'>-Select-</option>
                                      {stateList.length > 0
                                        ? stateList.map((item, index) => {
                                            return (
                                              <option selected={item.name==this.props.listPreDate.name} value={item.name}>
                                                {item.name}
                                              </option>
                                            )
                                          })
                                        : ''}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                           <div className="col-lg-4 col-md-4">
                                    <div className='form-group'>
                                    <label for="exampleInputEmail1">Date of Enactment *</label>
                                    
                                            <div className="input-group input-group-sm">
                                              {/* <TableDatePicker refName="enactment_date"/> */}
                                              <input type="text" id="effective_date" name="effective_date" className="form-control" placeholder="Effective Date" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/MM/yyyy" data-mask /> 
                                                {/* <input type="text" id="enactment_date" name="enactment_date" className="form-control" placeholder="Enactment Date" /> */}
                                            </div>
                                    </div>

                                   </div>
                                      
                                  
                        </div>
                        {/* <img setTo="customFileBr" className="_brFileThumb updthumb entity_icon_img" src={TkMeter} alt=""/> */}

                        <div className='col-md-6 respSSL'></div>
                        <div id='testing2dd2222'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <div id='getReponse_add_client' style={{ color: 'red' }}></div>
                <button
                  type='button'
                  className='btn btn-secondary'
                  data-dismiss='modal'
                >
                  Close
                </button>
                <button
                  type='button'
                  onClick={() => this.addPreDateLibrary('#frmSetPreDateLibrary')}
                  className='btn btn-primary'
                >
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
export default Set_Pre_Date_Library
