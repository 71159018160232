import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack} from '../function_lib/common_lib';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Option_Module from '../user_pages/option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';

import Wa_Credit_Balance_Comp from '../components/wa_credit_balance';


let loginUser="";
let userObject="";
let companyName="";
let clsUserMgmt;

let rf="0";
class User_Settings extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ listUsers:[],crediUser:{},creditBalance:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      clsUserMgmt=this;
      }

      getUsers(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getUsers"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        //alert(status);
        /*
        if(status!=="0"){
          map.push({name:"status",value:status});
        }
        not required as of now
        */
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getUsers";
        CallMapMethod_CBack(clsUserMgmt,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                clsUserMgmt.setState({listUsers:listData},()=>{
                  clsUserMgmt.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                 
                  if(rf!=="0"){
                    clsUserMgmt.getSearch(rf);
                    //alert("i m in setup "+rf);
                    $("#serLoanStatus").val(rf);
                  }
                });
                
               
              }
        });
      }
      okSucc(data,smg){
        if(data==="001"){
          alert("record added successfully");
          clsUserMgmt.getUsers();
          $("#frmCreate").find("input[type=text]").val("");
        }else{
          alert(smg);
        }
        // alert(data);
      }
okSuccCreditB(data,msg){
    alert(data+msg);
}

getCreditBalance(acc_inf_id){
  var map=[];
  map.push({name:"curl_type",value:"getCreditBalance"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"acc_inf_id",value:acc_inf_id});
  
  map["res_func"]="callBackrrr";
  map["curl_type"]="getCreditBalance";
  CallMapMethod_CBack(clsUserMgmt,map,function(data,msg){
    //alert(data);
      if(data!=="0" && data!==""){
          let listData=JSON.parse(data);
          var strData="";
          clsUserMgmt.setState({creditBalance:listData},()=>{
            clsUserMgmt.forceUpdateHandler();
          })
          
          
         
        }
  });
}
 onCreditBalanceSel(userCredit){
  // method will work on click on Credit Button Link to set State
  clsUserMgmt.setState({crediUser:userCredit},()=>{
    clsUserMgmt.getCreditBalance(userCredit["id"]);
  })

 }             
      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.getUsers();
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row content12 mt-2 mb-2">

<div className="col-lg-2 col-md-2 col-sm-4 float-right">
<select  className="form-control form-control-sm" id="ser_month" name="ser_month">
           <option value="0">-User Type-</option>
           <option value="reseller">-Reseller-</option>
           <option value="user">-User-</option>
          
         </select>
</div>

<div className='col-lg-4 col-md-4 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="col78" name="col78" className="_setSearchKeyUp form-control form-control-sm" placeholder='search' />
             </div>
         
         </div>

</div>

<div className="col-lg-2 col-md-2 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div onClick={()=>this.getSerComplianceList()}  style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <div  className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>

</div>
    </div>    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>User List</b></h3>
                
                <a className="btn btn-sm btn-danger float-right" data-toggle="modal" data-target="#divCreateUser" href="javascript:void(0)">Add New</a>
              </div>
                <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>User Name</th>
                      <th>Entity Name</th>
                      <th>Contact Person</th>
                      <th>Contact Number</th>
                      <th>User Type</th> 
                      <th>Enrollment Date</th>
                      <th>Expiry Date</th>
                      <th>Created By</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="tbData">
                  {(this.state.listUsers.map((item,index)=>{
                       return(<tr>
                        <td>1</td>
                    <td>{item.user_name}</td>
                  <td>{item.first_name}</td>
                  <td>{item.last_name}</td>
                  <td>{item.contact_no}</td>
                  <td>{item.type_name}</td>
                  <td>{item.date}</td>
                  <td>01-Jan-2025</td>
                  <td><a data-toggle="modal" data-target="#divEmployee" href="javascript:void(0)">{item.upper_user}</a></td>
                  <td><span className="badge bg-danger dash_rejected_claim1">{item.status}</span></td>
                  <td><a data-toggle="modal" data-target="#divEmployee" href="javascript:void(0)">View</a> | <a  data-toggle="modal" data-target="#divWaCreditBalance" onClick={()=>this.onCreditBalanceSel(item)}  href="javascript:void(0)">Credit Balance</a> |</td>
                    </tr>)
                  }))}
                  </tbody>
                </table>
             
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>
<div className="modal fade bd-example-modal-xl"  id="divCreateUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Add New</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmCreate" name="frmCreate">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="registration" />
                            <input type="hidden" name="m_type" id="m_type" value="registration" />
                            <input type="hidden" name="type_id" id="type_id" value="2" />
                            <input type="hidden" name="upper_id" id="upper_id" value={userObject[0].app_user_id} />
                            <div className="card-body">
                                <div className="row">
                               
                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Business/Person Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="first_name" name="first_name" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Contact Person Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="last_name" name="last_name" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Contact No.</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="contact_no" name="contact_no" className="form-control" placeholder="10 Digit No." />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Make As *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="role_id" name="role_id" className="form-control">
                                                  <option value="0">-User Type-</option>
                                                  <option value="3">User</option>
                                                  <option value="1">Reseller</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">User Name.(space not allowd)</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="user_name" name="user_name" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Password</label>
                                            <div className="input-group input-group-sm">
                                                <input type="password" id="password" name="password" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Valid Till (expiry)</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col98" name="col98" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">User Account Expiry Date</small> </div>
          </div>
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>
    <Wa_Credit_Balance_Comp creditUser={this.state.crediUser} creditBalance={this.state.creditBalance} app_user_id={userObject[0].app_user_id} />
          </div>)
      }
}
export default User_Settings;