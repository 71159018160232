import $, { extend } from 'jquery';
import React from 'react';
import QueryString from 'query-string';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack} from '../function_lib/common_lib';
import Header from './header';
import Footer from './footer';
import Option_Module from './option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';


let loginUser="";
let userObject="";
let companyName="";
let clsObject;
let rf=0;
let queries;
class Client_Site_V_Audit extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };
    constructor(props){
        super(props);
        clsObject=this;
        this.state={isAllow:false,stBranchList:[]};
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      if(props !=null){
        queries = QueryString.parse(this.props.location.search);
        if(queries.rf!=null){
          rf=queries.rf;
        }
       
        //alert(vComplianceFor);
      }
      }
      getBranchList(){
        var libType=[];
        libType.push({name:"curl_type",value:"getBranchesMgmt"});
        libType.push({name:"res_func",value:"callBackEntityList"});
        libType.push({name:"id",value:userObject[0].id});
        libType.push({name:"app_user_id",value:userObject[0].app_user_id});
        libType.push({name:"type_id",value:"4"});
        libType.push({name:"client_id",value:rf});
        libType.push({name:"category_id",value:1});
        libType["res_func"]="callBackEntityList";
        libType["curl_type"]="getBranchesMgmt";
        CallMapMethod_CBack(this,libType,function(data,msg){
          //alert(msg);
          // $("#tbData").html(msg);
          // return;
          if(data!=="0"){
            let listD=JSON.parse(data);
            if(listD.length>0){
                clsObject.setState({stBranchList:listD})
           
            }
            
          }
          
        });
       }     
 
  
componentDidMount(){
  this.getBranchList();
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">


<div className="row">
<div className="col-md-2"> 
        
        {/* <!-- Widget: user widget style 2 --> */}
        <div className="card card-widget widget-user-2 shadow "> 
          {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}
          
          <div className="card-footer p-0">
            <ul className="nav flex-column  table-hover">
              <li className="nav-item"> <a href="javascript:void(0)" className="nav-link  hover"><h6><i className="fa fa-user-alt font-small text-orange"></i><span class="float-left badge bg-primary" data-toggle="modal" data-target="#divEmployee">Import List</span></h6> </a> </li>
            </ul>
          </div>
          
          {/* <!-- /.widget-user -->  */}
        </div>
        
        {/* <!-- Widget: user widget style 2 --> */}
        <div className="card card-widget widget-user-2 shadow "> 
          {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}
          
         <Option_Module/>
          
          {/* <!-- /.widget-user -->  */}
        </div>
      </div>
      <div className="col-lg-10 col-md-10 col-sm-12 col-12">
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Branches</b></h3>
              </div>
                <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap table-responsive">
                  <thead>
                    <tr class="border">
                        {/* <th>S.No.</th> */}
                      <th>Branch Name</th>
                      <th>Company Name</th>
                       <th>Location</th> 
                      <th>State</th>
                      <th>Month</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="tbData">
                    {(this.state.stBranchList.map((item,index)=>{
                      return( <tr>
                        <td>{item["branch_name"]}</td>
                        <td>{item["entity"]}</td>
                        <td>{item["branch_state"]}</td>
                          <td>{item["branch_state"]}</td>
                          <td>{"22-April"}</td>
                      <td><a href={"../compliance_list/client?rf="+item["branch_enc_id"]+"&rf_e="+item["client_enc_id"]}>View Compliance</a></td>
                        </tr> )
                       }
                    ))}
                  </tbody>
                </table>
             
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>
<div className="modal fade bd-example-modal-xl"  id="divEmployee" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Check List Master</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmCreate" name="frmCreate">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="createLogMaster" />
                            <input type="hidden" name="m_type" id="m_type" value="createLogMaster" />
                            <input type="hidden" name="type_id" id="type_id" value="2" />
                            <input type="hidden" name="app_user_id" id="app_user_id" value="" />
                            <div className="card-body">
                                <div className="row">
                                <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Parent Title *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="parent_id" name="parent_id" className="form-control">
                                                  <option value="0">-Select-</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Check List *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="details" name="details" className="form-control" placeholder="Check List" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                    <div className="form-group">
                                            <label for="exampleInputEmail1">Priority Index *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="ref1_id" name="ref1_id" className="form-control" placeholder="Priority Index" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>
          </div>)
      }
}
export default Client_Site_V_Audit;