export function getUserObjData(){
  var cObject="";
  if(localStorage.getItem("userData")!=null){
    var tmpObject=JSON.parse("{\"access\":"+localStorage.getItem("userData")+"}");
    cObject=tmpObject.access['user'];
  }
  return cObject;
}
export function getAccessRoleNew(){
  let cObject=[];
  if(localStorage.getItem("userData")!=null){
    let tmpObject=JSON.parse(localStorage.getItem("userData"));
    cObject=tmpObject.role;
  }
  return cObject;
}

export function getAccessFunctions(){
let ret="";
if(localStorage.getItem("userData")!=null){
  let tmpObject=JSON.parse(localStorage.getItem("userData"));
  let cObject=tmpObject.role;
  if(cObject.length===1){
    ret=cObject[0]["col1"];
    
   }
   if(cObject.length===2){
    ret="all";
   }
  
}
return ret;
}

var UserProfile = (function() {
    let userObject="";
    let accessRoles="";  
    var getUserObject = function() {
      var cObject=userObject;
      if(cObject!=""){
       var tmpObject=JSON.parse("{\"access\":"+userObject+"}");
        cObject=tmpObject.access['user'];
        //alert(cObject.length);
      }
      return cObject;    // Or pull this from cookie/localStorage
    };

    var getAccessRoles=function(){
      var cObject=userObject;
      if(cObject!=""){
       var tmpObject=JSON.parse("{\"access\":"+userObject+"}");
        cObject=tmpObject.access['role'];
        //alert(cObject.length);
      }
      return cObject;    // Or pull this from cookie/localStorage
    };
  
    var setUserObject = function(userObj) {
        userObject = userObj;     
      // Also set this in cookie/localStorage
    };
  
    return {
        getUserObject: getUserObject,
        setUserObject: setUserObject,
        getAccessRoles:getAccessRoles
       
    }
  
  })();
  
  export default UserProfile;