import $ from 'jquery';
import React from 'react';
import Chart from "react-google-charts";
import UserToken from '../function_lib/userToken';
import {CallMapMethod, GetDateDifferecec} from '../function_lib/common_lib';
import Header from './header';
import Footer from './footer';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';

import Upcoming_Events from '../components/upcoming_events';
import Emp_On_Leave from '../components/emp_on_leave';
import Broadcast from '../components/broadcast';
import Mobile_Widgets from '../components/mobile_widgets';
import SubmitTimeIn_Out from '../components/submit_timein_out';
//import UserManual from '../assets/Operational_Instructions.pdf';

let loginUser="";
let userObject="";
let companyName="";
let userType="";
class Comp_Dashboard extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        this.state={country:[],line_of_business:[],type_of_clain:[],company:[],group_name:[] };
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        userType="0";
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          companyName=userObject[0].company_name;
          //window.location.href="./Dashboard";
        }
      }

     
     

      componentDidMount(){
        $("#divEmployee").show();
        var arrCountry=new Array();
        arrCountry.push(["Complaint","Non Compliant"])
        arrCountry.push(["Compliant",parseInt("90")]);
        arrCountry.push(["Non Compliant",parseInt("10")]);

        var arrGroupName=new Array();
        arrGroupName.push(["Month",""]);
        arrGroupName.push(["Jan-2022",parseInt("99")]);
        arrGroupName.push(["Feb-2022",parseInt("90")]);
        arrGroupName.push(["Mar-2022",parseInt("95")]);
        arrGroupName.push(["Apr-2022",parseInt("99")]);
        arrGroupName.push(["May-2022",parseInt("90")]);
        arrGroupName.push(["Jun-2022",parseInt("0")]);
        arrGroupName.push(["July-2022",parseInt("0")]);
        arrGroupName.push(["Aug-2022",parseInt("0")]);
        arrGroupName.push(["Sept-2022",parseInt("0")]);
        arrGroupName.push(["Oct-2022",parseInt("0")]);
        arrGroupName.push(["Nov-2022",parseInt("0")]);
        arrGroupName.push(["Dec-2022",parseInt("0")]);

        this.setState({country:arrCountry});
        this.setState({group_name:arrGroupName});
        this.forceUpdateHandler();
       
      }
     
   
     

    render(){
        
      
        return(<div className="wrapper">
            <Header/>
            {/* <Mobile_Widgets/> */}
            <div class="content">
            <div class="container-fluid mt-4">
               
<div class="row">
<div class="col-md-4">
              {/* <!-- Widget: user widget style 2 --> */}
              <div class="card card-widget widget-user-2 shadow ">
                  {/* <!-- Add the bg color to the header using any of the bg-* classes --> */}
                  <div class="widget-user-header" style={{backgroundColor:"#B82D17"}}>
                  <ul class="nav flex-column">
                  <li class="nav-item">
                              <a href="#" class="nav-link text-white">
                                   <h6>Calender Complaince</h6>
                              </a>
                          </li>
                        
                  </ul>
                  </div>
                  <div class="card-footer p-0">
                      <ul class="nav flex-column">
         
                   
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                 <h6> <i class="fa fa-align-left text-orange"></i>Due Till Date<span class="float-right badge bg-primary dash_no_claim1">12</span></h6>
                              </a>
                          </li>
                         
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-allergies text-orange"></i>Upcoming<span class="float-right badge bg-success dash_approved_claim1">0</span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i>Overdue<span class="float-right badge bg-danger dash_rejected_claim1">0</span></h6>
                              </a>
                          </li>
                      </ul>
                  </div>
             
              {/* <!-- /.widget-user --> */}
          </div>
</div>
<div class="col-md-4">
              {/* <!-- Widget: user widget style 2 --> */}
              <div class="card card-widget widget-user-2 shadow ">
                  {/* <!-- Add the bg color to the header using any of the bg-* classes --> */}
                  <div class="widget-user-header" style={{backgroundColor:"#B82D17"}}>
                  <ul class="nav flex-column">
                  <li class="nav-item">
                              <a href="#" class="nav-link text-white">
                                   <h6>Score</h6>
                              </a>
                          </li>
                        
                  </ul>
                  </div>
                  <div class="card-footer p-0">
                      <ul class="nav flex-column">
         
                   
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                 <h6> <i class="fa fa-align-left text-orange"></i>Entity<span class="float-right badge bg-primary dash_no_claim1">86%</span></h6>
                              </a>
                          </li>
                         
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-allergies text-orange"></i>Client Side<span class="float-right badge bg-success dash_approved_claim1">20%</span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i>Contractor<span class="float-right badge bg-danger dash_rejected_claim1">80%</span></h6>
                              </a>
                          </li>
                      </ul>
                  </div>
             
              {/* <!-- /.widget-user --> */}
          </div>
</div>

<div class="col-md-4">
              {/* <!-- Widget: user widget style 2 --> */}
              <div class="card card-widget widget-user-2 shadow ">
                  {/* <!-- Add the bg color to the header using any of the bg-* classes --> */}
                  <div class="widget-user-header" style={{backgroundColor:"#B82D17"}}>
                  <ul class="nav flex-column">
                  <li class="nav-item">
                              <a href="#" class="nav-link text-white">
                                   <h6>Department Notice</h6>
                              </a>
                          </li>
                        
                  </ul>
                  </div>
                  <div class="card-footer p-0">
                      <ul class="nav flex-column">
         
                   
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                 <h6> <i class="fa fa-align-left text-orange"></i>Open<span class="float-right badge bg-primary dash_no_claim1">1</span></h6>
                              </a>
                          </li>
                         
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-allergies text-orange"></i>Under Process<span class="float-right badge bg-success dash_approved_claim1">1</span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i>Closed<span class="float-right badge bg-danger dash_rejected_claim1">0</span></h6>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a href="#" class="nav-link">
                                   <h6> <i class="fa fa-ankh text-orange"></i>Overdues<span class="float-right badge bg-danger dash_rejected_claim1">0</span></h6>
                              </a>
                          </li>
                      </ul>
                  </div>
             
              {/* <!-- /.widget-user --> */}
          </div>
</div>


    {/*New Logics0901 */}
    <div className="col-md-3">
     
     <div className="card">
      <div className="card-header bg-gradient-cyan">
      <i className="fa fa-address-card"></i> <b>Overall Preformance</b>
      
      
      
      
      </div>
      <div className="card-body1" style={{height:"300px",overflow:"scroll"}}>
          {this.state.country.length>0?<Chart
  width={'100%'}
  height={'50vh'}
  chartType="PieChart"
  loader={<div>Loading Chart</div>}
  data={this.state.country}
  options={{
    title: 'Performance',
    // Just add this option
    is3D: true,
  }}
  rootProps={{ 'data-testid': '2' }}
/>
          :''}
      
          </div>
          </div>
          </div>
      
        
        
          <div className="col-md-6">
     
     <div className="card">
      <div className="card-header bg-gradient-cyan">
      <i className="fa fa-address-card"></i> <b>Year 2022</b>
      </div>
      <div className="card-body" style={{height:"400px",overflow:"scroll"}}>
      <Chart
    width={'100%'}
    height={300}
    chartType="ColumnChart"
    loader={<div>Loading Chart</div>}
    data={this.state.group_name}
    options={{
      title: 'Approved vs Rejected',
      chartArea: { width: '100%' },
      hAxis: {
        title: 'Group Name',
        minValue: 0,
      },
      vAxis: {
        title: 'Amount',
      },
    }}
    legendToggle
  />
          </div>
          </div>
          </div>
        
   
 
{/* <!-- /.container-fluid --> */}
</div>
{/* <!-- /.content --> */}
</div>
      </div> 
        <Footer/>
        <SubmitTimeIn_Out/>
      </div>)
    }
}
export default Comp_Dashboard