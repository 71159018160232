export function ComplianceList(){
   return [
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Assam"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Assam"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Payment of Wages Act",
            "Act Name": "The Payment of Wages Act, 1936",
            "Rule / Form": "The Payment of Wages Act, 1936",
            "Compliance Type": "Return",
            "Fine": "Fine which shall not be less than one thousand five hundred rupees but which may extend to seven thousand five hundred rupees. ",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Tamil Nadu"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "The Odisha Shops And Commercial Establishments Act, 1956",
            "Rule / Form": "The Odisha Shops And Commercial Establishments Act, 1956",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Orissa"
        },
        {
            "Due Date": "28-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Rule / Form": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Compliance Type": "Statutory Payments & Return",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Assam"
        },
        {
            "Due Date": "28-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Rule / Form": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Compliance Type": "Statutory Payments & Return",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Assam"
        },
        {
            "Due Date": "28-Mar",
            "Compliance Month": "3",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Rule / Form": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Compliance Type": "Statutory Payments & Return",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Assam"
        },
        {
            "Due Date": "28-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Rule / Form": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Compliance Type": "Statutory Payments & Return",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Assam"
        },
        {
            "Due Date": "28-May",
            "Compliance Month": "5",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Rule / Form": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Compliance Type": "Statutory Payments & Return",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Assam"
        },
        {
            "Due Date": "28-Jun",
            "Compliance Month": "6",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Rule / Form": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Compliance Type": "Statutory Payments & Return",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Assam"
        },
        {
            "Due Date": "28-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Rule / Form": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Compliance Type": "Statutory Payments & Return",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Assam"
        },
        {
            "Due Date": "28-Aug",
            "Compliance Month": "8",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Rule / Form": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Compliance Type": "Statutory Payments & Return",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Assam"
        },
        {
            "Due Date": "28-Sep",
            "Compliance Month": "9",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Rule / Form": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Compliance Type": "Statutory Payments & Return",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Assam"
        },
        {
            "Due Date": "28-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Rule / Form": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Compliance Type": "Statutory Payments & Return",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Assam"
        },
        {
            "Due Date": "28-Nov",
            "Compliance Month": "11",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Rule / Form": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Compliance Type": "Statutory Payments & Return",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Assam"
        },
        {
            "Due Date": "28-Dec",
            "Compliance Month": "12",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Rule / Form": "The Assam Professions, Trades, Callings And Employments Taxation Act, 1947 and  \r\nThe Assam Professions, Trades, Callings And Employments Taxation Rules, 1947",
            "Compliance Type": "Statutory Payments & Return",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Assam"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Bihar "
        },
        {
            "Due Date": "31-Dec",
            "Compliance Month": "12",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Rule / Form": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Compliance Type": "Statutory Payments",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "30-Nov",
            "Compliance Month": "11",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Rule / Form": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Compliance Type": "Statutory Payments",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "31-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Rule / Form": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Compliance Type": "Statutory Payments",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "30-Sep",
            "Compliance Month": "9",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Rule / Form": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Compliance Type": "Statutory Payments",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "31-Aug",
            "Compliance Month": "8",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Rule / Form": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Compliance Type": "Statutory Payments",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Rule / Form": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Compliance Type": "Statutory Payments",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "30-Jun",
            "Compliance Month": "6",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Rule / Form": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Compliance Type": "Statutory Payments",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "31-May",
            "Compliance Month": "5",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Rule / Form": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Compliance Type": "Statutory Payments",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Chattisgarh"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Rule / Form": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Compliance Type": "Statutory Payments",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "31-Mar",
            "Compliance Month": "3",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Rule / Form": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Compliance Type": "Statutory Payments",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "28-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Rule / Form": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Compliance Type": "Statutory Payments",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Rule / Form": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Compliance Type": "Statutory Payments",
            "Periodicity": "Monthly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "31-Mar",
            "Compliance Month": "3",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Rule / Form": " \r\nThe Maharashtra State Tax On Professions, Trade, Callings And Employments Act, 1975",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Assam"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Assam"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Payment of Wages Act",
            "Act Name": "The Payment of Wages Act, 1936",
            "Rule / Form": "The Payment of Wages Act, 1936",
            "Compliance Type": "Return",
            "Fine": "Fine which shall not be less than one thousand five hundred rupees but which may extend to seven thousand five hundred rupees. ",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "West Bengal"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Payment of Wages Act",
            "Act Name": "The Payment of Wages Act, 1936",
            "Rule / Form": "The Payment of Wages Act, 1936",
            "Compliance Type": "Return",
            "Fine": "Fine which shall not be less than one thousand five hundred rupees but which may extend to seven thousand five hundred rupees. ",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Assam"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Assam"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Assam"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Bihar "
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Bihar "
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Payment of Wages Act",
            "Act Name": "The Payment of Wages Act, 1936",
            "Rule / Form": "The Payment of Wages Act, 1936",
            "Compliance Type": "Return",
            "Fine": "Fine which shall not be less than one thousand five hundred rupees but which may extend to seven thousand five hundred rupees. ",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Chattisgarh"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Bihar "
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Goa"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Bihar "
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Chattisgarh"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Chattisgarh"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Chattisgarh"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Chattisgarh"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Goa"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Goa"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Gujarat"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Goa"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Goa"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Gujarat"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Gujarat"
        },
        {
            "Due Date": "10-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "The Goa Shops And Establishments Act, 1973",
            "Rule / Form": "The Goa Shops And Establishments Act, 1973",
            "Compliance Type": "Return",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Goa"
        },
        {
            "Due Date": "10-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "The Goa Shops And Establishments Act, 1973",
            "Rule / Form": "The Goa Shops And Establishments Act, 1973",
            "Compliance Type": "Return",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Goa"
        },
        {
            "Due Date": "10-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "The Goa Shops And Establishments Act, 1973",
            "Rule / Form": "The Goa Shops And Establishments Act, 1973",
            "Compliance Type": "Return",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Goa"
        },
        {
            "Due Date": "10-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "The Goa Shops And Establishments Act, 1973",
            "Rule / Form": "The Goa Shops And Establishments Act, 1973",
            "Compliance Type": "Return",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Goa"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Gujarat"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Gujarat"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Gujarat"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Haryana"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Himachal Pradesh"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Himachal Pradesh"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Himachal Pradesh"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Himachal Pradesh"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Himachal Pradesh"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Jammu & Kashmir"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Jammu & Kashmir"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Jammu & Kashmir"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Jammu & Kashmir"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Jharkhand"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Jharkhand"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Jharkhand"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Jharkhand"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Karnataka"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Karnataka"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Karnataka"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Karnataka"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Kerala"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Kerala"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Kerala"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Kerala"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Jammu & Kashmir"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Mizoram"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Nagaland"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Nagaland"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Nagaland"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Nagaland"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "New Delhi"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "New Delhi"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Payment of Wages Act",
            "Act Name": "The Payment of Wages Act, 1936",
            "Rule / Form": "The Payment of Wages Act, 1936",
            "Compliance Type": "Return",
            "Fine": "Fine which shall not be less than one thousand five hundred rupees but which may extend to seven thousand five hundred rupees. ",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Jammu & Kashmir"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Jharkhand"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "New Delhi"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "New Delhi"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Orissa"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Orissa"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Orissa"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Orissa"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Punjab"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Punjab"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Punjab"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Punjab"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Rajasthan"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Karnataka"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Rajasthan"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Rajasthan"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Rajasthan"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Tamil Nadu"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Tamil Nadu"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Tamil Nadu"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Tamil Nadu"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Telangana"
        },
        {
            "Due Date": "5-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Rule / Form": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing one or more employees/persons",
            "Periodicity": "Monthly",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Telangana"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Telangana"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Telangana"
        },
        {
            "Due Date": "31-Dec",
            "Compliance Month": "12",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Telangana"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Uttar Pradesh "
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Uttar Pradesh "
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Uttar Pradesh "
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Uttar Pradesh "
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Uttarakhand"
        },
        {
            "Due Date": "5-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Rule / Form": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing one or more employees/persons",
            "Periodicity": "Monthly",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "10-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "The kerala shops and commercial establishments act, 1960",
            "Rule / Form": "The kerala shops and commercial establishments act, 1960",
            "Compliance Type": "Return",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Uttarakhand"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Uttarakhand"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Uttarakhand"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "West Bengal"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Payment of Wages Act",
            "Act Name": "The Payment of Wages Act, 1936",
            "Rule / Form": "The Payment of Wages Act, 1936",
            "Compliance Type": "Return",
            "Fine": "Fine which shall not be less than one thousand five hundred rupees but which may extend to seven thousand five hundred rupees. ",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "10-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "The kerala shops and commercial establishments act, 1960",
            "Rule / Form": "The kerala shops and commercial establishments act, 1960",
            "Compliance Type": "Return",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "West Bengal"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "West Bengal"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "West Bengal"
        },
        {
            "Due Date": "10-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "The kerala shops and commercial establishments act, 1960",
            "Rule / Form": "The kerala shops and commercial establishments act, 1960",
            "Compliance Type": "Return",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Assam"
        },
        {
            "Due Date": "31-Dec",
            "Compliance Month": "12",
            "Simplified Name of Compliance": "N&F Act",
            "Compliance Type": "Return",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Rajasthan"
        },
        {
            "Due Date": "10-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "The kerala shops and commercial establishments act, 1960",
            "Rule / Form": "The kerala shops and commercial establishments act, 1960",
            "Compliance Type": "Return",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Bihar "
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Chattisgarh"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Nagaland"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Goa"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Gujarat"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "New Delhi"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Himachal Pradesh"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Jammu & Kashmir"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Orissa"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Jharkhand"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Karnataka"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Punjab"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Payment of Wages Act",
            "Act Name": "The Payment of Wages Act, 1936",
            "Rule / Form": "The Payment of Wages Act, 1936",
            "Compliance Type": "Return",
            "Fine": "Fine which shall not be less than one thousand five hundred rupees but which may extend to seven thousand five hundred rupees. ",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Rajasthan"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Mizoram"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Nagaland"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Tamil Nadu"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "New Delhi"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Punjab"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Telangana"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Rajasthan"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Tamil Nadu"
        },
        {
            "Due Date": "15-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Chhattisgarh Shram Kalyan Nidhi Adhiniyam, 1982 and The Chhattisgarh Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Rule / Form": "The Chhattisgarh Shram Kalyan Nidhi Adhiniyam, 1982 and The Chhattisgarh Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing one or more employees/persons",
            "Periodicity": "Half Yearly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Chattisgarh"
        },
        {
            "Due Date": "15-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Chhattisgarh Shram Kalyan Nidhi Adhiniyam, 1982 and The Chhattisgarh Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Rule / Form": "The Chhattisgarh Shram Kalyan Nidhi Adhiniyam, 1982 and The Chhattisgarh Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing one or more employees/persons",
            "Periodicity": "Half Yearly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Chattisgarh"
        },
        {
            "Due Date": "15-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Gujarat Labour Welfare Fund Act, 1953 and The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Rule / Form": "The Gujarat Labour Welfare Fund Act, 1953 and The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing ten or more employees/persons",
            "Periodicity": "Half Yearly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Gujarat"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Gujarat Labour Welfare Fund Act, 1953 and The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Rule / Form": "The Gujarat Labour Welfare Fund Act, 1953 and The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing ten or more employees/persons",
            "Periodicity": "Half Yearly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Gujarat"
        },
        {
            "Due Date": "15-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Gujarat Labour Welfare Fund Act, 1953 and The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Rule / Form": "The Gujarat Labour Welfare Fund Act, 1953 and The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing ten or more employees/persons",
            "Periodicity": "Half Yearly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Gujarat"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "The Maternity Benefit Act, 1961",
            "Rule / Form": "The Maternity Benefit Act, 1961",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Tamil Nadu"
        },
        {
            "Due Date": "21-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "The Maternity Benefit Act, 1961",
            "Rule / Form": "The Maternity Benefit Act, 1961",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Assam"
        },
        {
            "Due Date": "21-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "The Maternity Benefit Act, 1961",
            "Rule / Form": "The Maternity Benefit Act, 1961",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Bihar "
        },
        {
            "Due Date": "21-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "The Maternity Benefit Act, 1961",
            "Rule / Form": "The Maternity Benefit Act, 1961",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Chattisgarh"
        },
        {
            "Due Date": "21-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "The Maternity Benefit Act, 1961",
            "Rule / Form": "The Maternity Benefit Act, 1961",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Goa"
        },
        {
            "Due Date": "21-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "The Maternity Benefit Act, 1961",
            "Rule / Form": "The Maternity Benefit Act, 1961",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Jharkhand"
        },
        {
            "Due Date": "21-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "The Maternity Benefit Act, 1961",
            "Rule / Form": "The Maternity Benefit Act, 1961",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "21-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "The Maternity Benefit Act, 1961",
            "Rule / Form": "The Maternity Benefit Act, 1961",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Punjab"
        },
        {
            "Due Date": "21-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "The Maternity Benefit Act, 1961",
            "Rule / Form": "The Maternity Benefit Act, 1961",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "West Bengal"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "The Maternity Benefit Act, 1961",
            "Rule / Form": "The Maternity Benefit Act, 1961",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "The Maternity Benefit Act, 1961",
            "Rule / Form": "The Maternity Benefit Act, 1961",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Gujarat"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "The Maternity Benefit Act, 1961",
            "Rule / Form": "The Maternity Benefit Act, 1961",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Karnataka"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Bihar "
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Chattisgarh"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Goa"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Uttar Pradesh "
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Gujarat"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Telangana"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Himachal Pradesh"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Jammu & Kashmir"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Jharkhand"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Uttar Pradesh "
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Uttarakhand"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Karnataka"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Uttarakhand"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Mizoram"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "West Bengal"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "West Bengal"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Nagaland"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "New Delhi"
        },
        {
            "Due Date": "31-Dec",
            "Compliance Month": "12",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Telangana"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Orissa"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Contractor",
            "Weightage": "10",
            "Compliance Catageory": "Major"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Punjab"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Rajasthan"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Tamil Nadu"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Telangana"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Uttar Pradesh "
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Goa"
        },
        {
            "Due Date": "31-Dec",
            "Compliance Month": "12",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "Telangana Contract Labour. (Prohibition and. Regulation) Rules 1971",
            "Compliance Type": "Self Certification",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Telangana"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Uttarakhand"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "West Bengal"
        },
        {
            "Due Date": "31-Dec",
            "Compliance Month": "12",
            "Simplified Name of Compliance": "Prevention of Sexual Harrasment",
            "Act Name": "The Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013",
            "Rule / Form": "The Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Annual Information Submission Under POSH Act",
            "Act Name": "The Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013",
            "Rule / Form": "The Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Rajasthan"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Goa Labour Welfare Fund Act, 1986 and Goa Labour Welfare Fund, Rules 1990",
            "Rule / Form": "The Goa Labour Welfare Fund Act, 1986 and Goa Labour Welfare Fund, Rules 1990",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing one or more employees/persons",
            "Periodicity": "Half Yearly",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Goa"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Goa Labour Welfare Fund Act, 1986 and Goa Labour Welfare Fund, Rules 1990",
            "Rule / Form": "The Goa Labour Welfare Fund Act, 1986 and Goa Labour Welfare Fund, Rules 1990",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing one or more employees/persons",
            "Periodicity": "Half Yearly",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Goa"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Assam"
        },
        {
            "Due Date": "15-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Karnataka Labour Welfare Fund Act, 1965 and The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "The Karnataka Labour Welfare Fund Act, 1965 and The Karnataka Labour Welfare Fund Rules, 1968",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing fifty or more employees/persons",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Karnataka"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "West Bengal"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Tamil Nadu Labour Welfare Fund Act, 1972 and The Tamil Nadu Labour Welfare Fund Rules, 1973",
            "Rule / Form": "The Tamil Nadu Labour Welfare Fund Act, 1972 and The Tamil Nadu Labour Welfare Fund Rules, 1973",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing five or more employees/persons",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Tamil Nadu"
        },
        {
            "Due Date": "15-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Madhya Pradesh Shram Kalyan Nidhi Adhiniyam, 1982 and The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Rule / Form": "The Madhya Pradesh Shram Kalyan Nidhi Adhiniyam, 1982 and The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing one or more employees/persons",
            "Periodicity": "Half Yearly",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Assam"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Bihar "
        },
        {
            "Due Date": "15-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Madhya Pradesh Shram Kalyan Nidhi Adhiniyam, 1982 and The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Rule / Form": "The Madhya Pradesh Shram Kalyan Nidhi Adhiniyam, 1982 and The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing one or more employees/persons",
            "Periodicity": "Half Yearly",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "15-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Bombay Labour Welfare Fund Act, 1953 and The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "The Bombay Labour Welfare Fund Act, 1953 and The Delhi Labour Welfare Fund Rules, 1997",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing five or more employees/persons",
            "Periodicity": "Half Yearly",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "New Delhi"
        },
        {
            "Due Date": "15-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Bombay Labour Welfare Fund Act, 1953 and The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "The Bombay Labour Welfare Fund Act, 1953 and The Delhi Labour Welfare Fund Rules, 1997",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing five or more employees/persons",
            "Periodicity": "Half Yearly",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "New Delhi"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Chattisgarh"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Gujarat"
        },
        {
            "Due Date": "15-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 and The West Bengal Labour Welfare Fund Rules, 1976",
            "Rule / Form": "The West Bengal Labour Welfare Fund Act, 1974 and The West Bengal Labour Welfare Fund Rules, 1976",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing ten or more employees/persons",
            "Periodicity": "Half Yearly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "West Bengal"
        },
        {
            "Due Date": "15-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 and The West Bengal Labour Welfare Fund Rules, 1976",
            "Rule / Form": "The West Bengal Labour Welfare Fund Act, 1974 and The West Bengal Labour Welfare Fund Rules, 1976",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing ten or more employees/persons",
            "Periodicity": "Half Yearly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "West Bengal"
        },
        {
            "Due Date": "15-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Punjab Labour Welfare Fund Act,1965 and The Punjab Labour Welfare Fund Rules,1966",
            "Rule / Form": "The Punjab Labour Welfare Fund Act,1965 and The Punjab Labour Welfare Fund Rules,1966",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing twenty or more employees/persons",
            "Periodicity": "Half Yearly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Punjab"
        },
        {
            "Due Date": "15-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Punjab Labour Welfare Fund Act,1965 and The Punjab Labour Welfare Fund Rules,1966",
            "Rule / Form": "The Punjab Labour Welfare Fund Act,1965 and The Punjab Labour Welfare Fund Rules,1966",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing twenty or more employees/persons",
            "Periodicity": "Half Yearly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Punjab"
        },
        {
            "Due Date": "15-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "The Maternity Benefit Act, 1961 and Maharashtra Maternity Benefit Rules, 1965",
            "Rule / Form": "The Maternity Benefit Act, 1961 and Maharashtra Maternity Benefit Rules, 1965",
            "Compliance Type": "REturn",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "Telangana Labour Welfare Fund Act, 1987 and Telangana Labour Welfare Fund Rules, 1988",
            "Rule / Form": "Telangana Labour Welfare Fund Act, 1987 and Telangana Labour Welfare Fund Rules, 1988",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing twenty or more employees/persons",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Telangana"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Bihar "
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Chattisgarh"
        },
        {
            "Due Date": "31-Dec",
            "Compliance Month": "12",
            "Simplified Name of Compliance": "N&F Act",
            "Act Name": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Rule / Form": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Telangana"
        },
        {
            "Due Date": "31-Dec",
            "Compliance Month": "12",
            "Simplified Name of Compliance": "N&F Act",
            "Rule / Form": "0",
            "Compliance Type": "Return",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Goa"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "N&F Act",
            "Rule / Form": "0",
            "Compliance Type": "Return",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Kerala"
        },
        {
            "Due Date": "30-Nov",
            "Compliance Month": "11",
            "Simplified Name of Compliance": "N&F Act",
            "Rule / Form": "0",
            "Compliance Type": "Return",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Kerala"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "N&F Act",
            "Rule / Form": "0",
            "Compliance Type": "Return",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Goa"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Himachal Pradesh"
        },
        {
            "Due Date": "31-Dec",
            "Compliance Month": "12",
            "Simplified Name of Compliance": "N&F Act",
            "Act Name": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Rule / Form": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Tamil Nadu"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Himachal Pradesh"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Jammu & Kashmir"
        },
        {
            "Due Date": "21-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": "The West Bengal State Tax On Professions, Trades, Callings And Employments Act, 1979",
            "Rule / Form": "The West Bengal State Tax On Professions, Trades,Callings And Employments Rules,1976",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "West Bengal"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Jharkhand"
        },
        {
            "Due Date": "30-Mar",
            "Compliance Month": "3",
            "Simplified Name of Compliance": "Professional Tax",
            "Act Name": "The Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
            "Rule / Form": "The Orissa State Tax On Professions, Trades, Callings And Employments Act, 2000",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Orissa"
        },
        {
            "Due Date": "15-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Professional Tax",
            "Rule / Form": "0",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Jharkhand"
        },
        {
            "Due Date": "10-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Professional Tax",
            "Rule / Form": "0",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Telangana"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "Karnataka Shops and Commercial Establishments Act, 1961",
            "Rule / Form": "Karnataka Shops and Commercial Establishments Act, 1961. S & E Act - Combined Annual Return(CLRA Act, MW Act, POW Act, MB Act)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Karnataka"
        },
        {
            "Due Date": "31-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "Tamil Nadu Shops and Establishment Rules, 1948",
            "Rule / Form": "Rule 16-C of Tamil Nadu Shops and Establishment Rules, 1948, Self Certification under S&E Act, MW, PoW, PoG, CLRA Act (for IT Industries)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Tamil Nadu"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Karnataka"
        },
        {
            "Due Date": "31-Dec",
            "Compliance Month": "12",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "The Orissa Shops and Commercial Establishments Act, 1956",
            "Rule / Form": "The Orissa Shops and Commercial Establishments Act, 1956. S & E Act - Combined Annual Return(CLRA Act, MW Act, POW Act, MB Act)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Orissa"
        },
        {
            "Due Date": "31-Dec",
            "Compliance Month": "12",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "TELANGANA SHOPS AND ESTABLISHMENTS ACT, 1988",
            "Rule / Form": "TELANGANA SHOPS AND ESTABLISHMENTS ACT, 1988. S & E Act - Combined Annual Return(CLRA Act, MW Act, POW Act, MB Act)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Telangana"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "Tamil Nadu Shops and Establishment Rules, 1948",
            "Rule / Form": "Rule 16-C of Tamil Nadu Shops and Establishment Rules, 1948, Self Certification under S&E Act, MW, PoW, PoG, CLRA Act (for IT Industries)",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Tamil Nadu"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "Bihar Shops and Establishment Act, 1953 and Jharkhand Shops & Establishment Rule, 2001",
            "Rule / Form": "Bihar Shops and Establishment Act, 1953 and Jharkhand Shops & Establishment Rule, 2001",
            "Compliance Type": "REturn",
            "Applicability": "Employing 10 or more employees",
            "Periodicity": "Quarterly",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Jharkhand"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "Bihar Shops and Establishment Act, 1953 and Jharkhand Shops & Establishment Rule, 2001",
            "Rule / Form": "Bihar Shops and Establishment Act, 1953 and Jharkhand Shops & Establishment Rule, 2001",
            "Compliance Type": "REturn",
            "Applicability": "Employing 10 or more employees",
            "Periodicity": "Quarterly",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Jharkhand"
        },
        {
            "Due Date": "10-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "TELANGANA SHOPS AND ESTABLISHMENTS ACT, 1988",
            "Rule / Form": "TELANGANA SHOPS AND ESTABLISHMENTS ACT, 1988",
            "Compliance Type": "Return",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Telangana"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Jammu & Kashmir"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "Bihar Shops and Establishment Act, 1953 and Jharkhand Shops & Establishment Rule, 2001",
            "Rule / Form": "Bihar Shops and Establishment Act, 1953 and Jharkhand Shops & Establishment Rule, 2001",
            "Compliance Type": "REturn",
            "Applicability": "Employing 10 or more employees",
            "Periodicity": "Quarterly",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Jharkhand"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "15-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Payment of Subsistence Allowance",
            "Act Name": "The Tamil Nadu Payment of Subsistence Allowance Act, 1981",
            "Rule / Form": "The Tamil Nadu Payment of Subsistence Allowance Act, 1981",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Tamil Nadu"
        },
        {
            "Due Date": "10-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "TELANGANA SHOPS AND ESTABLISHMENTS ACT, 1988",
            "Rule / Form": "TELANGANA SHOPS AND ESTABLISHMENTS ACT, 1988",
            "Compliance Type": "Return",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Telangana"
        },
        {
            "Due Date": "10-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "TELANGANA SHOPS AND ESTABLISHMENTS ACT, 1988",
            "Rule / Form": "TELANGANA SHOPS AND ESTABLISHMENTS ACT, 1988",
            "Compliance Type": "Return",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Telangana"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "10-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "TELANGANA SHOPS AND ESTABLISHMENTS ACT, 1988",
            "Rule / Form": "TELANGANA SHOPS AND ESTABLISHMENTS ACT, 1988",
            "Compliance Type": "Return",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Telangana"
        },
        {
            "Due Date": "15-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Payment of Subsistence Allowance",
            "Act Name": "The Tamil Nadu Payment of Subsistence Allowance Act, 1981",
            "Rule / Form": "The Tamil Nadu Payment of Subsistence Allowance Act, 1981",
            "Compliance Type": "Return",
            "Periodicity": "Half Yearly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Tamil Nadu"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "Bihar Shops and Establishment Act, 1953 and Jharkhand Shops & Establishment Rule, 2001",
            "Rule / Form": "Bihar Shops and Establishment Act, 1953 and Jharkhand Shops & Establishment Rule, 2001",
            "Compliance Type": "REturn",
            "Applicability": "Employing 10 or more employees",
            "Periodicity": "Quarterly",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Jharkhand"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Payment of Wages Act",
            "Act Name": "The Payment of Wages Act, 1936",
            "Rule / Form": "The Payment of Wages Act, 1936",
            "Compliance Type": "Return",
            "Fine": "Fine which shall not be less than one thousand five hundred rupees but which may extend to seven thousand five hundred rupees. ",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Rajasthan"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Mizoram"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Return",
            "Applicability": "All Establishments in Public Sector and such establishments in private Sector excluding Agriculture, where ordinarily 25 or more persons are employed come within the purview of the Act",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Nagaland"
        },
        {
            "Due Date": "30-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Return",
            "Applicability": "All Establishments in Public Sector and such establishments in private Sector excluding Agriculture, where ordinarily 25 or more persons are employed come within the purview of the Act",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "New Delhi"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Orissa"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Jharkhand"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Return",
            "Applicability": "All Establishments in Public Sector and such establishments in private Sector excluding Agriculture, where ordinarily 25 or more persons are employed come within the purview of the Act",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Return",
            "Applicability": "All Establishments in Public Sector and such establishments in private Sector excluding Agriculture, where ordinarily 25 or more persons are employed come within the purview of the Act",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "15-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "The Maharashtra Labour Welfare Fund Act, 1953 and The Maharashtra Labour Welfare Fund Rules, 1953",
            "Rule / Form": "The Maharashtra Labour Welfare Fund Act, 1953 and The Maharashtra Labour Welfare Fund Rules, 1953",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing five or more employees/persons",
            "Periodicity": "Half Yearly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "15-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "S&E Act",
            "Act Name": "The Maharashtra Labour Welfare Fund Act, 1953 and The Maharashtra Labour Welfare Fund Rules, 1953",
            "Rule / Form": "The Maharashtra Labour Welfare Fund Act, 1953 and The Maharashtra Labour Welfare Fund Rules, 1953",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing five or more employees/persons",
            "Periodicity": "Half Yearly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Punjab"
        },
        {
            "Due Date": "21-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "The Maternity Benefit Act, 1961 and RAJASTHAN MATERNITY BENEFIT RULES, 1967",
            "Rule / Form": "The Maternity Benefit Act, 1961 and RAJASTHAN MATERNITY BENEFIT RULES, 1967",
            "Compliance Type": "REturn",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Rajasthan"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "5-Mar",
            "Compliance Month": "3",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Rule / Form": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing one or more employees/persons",
            "Periodicity": "Monthly",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "5-Apr",
            "Compliance Month": "4",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Rule / Form": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing one or more employees/persons",
            "Periodicity": "Monthly",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "5-May",
            "Compliance Month": "5",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Rule / Form": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing one or more employees/persons",
            "Periodicity": "Monthly",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Employee Compensation",
            "Act Name": "THE EMPLOYEE’S COMPENSATION ACT,1923",
            "Rule / Form": "THE EMPLOYEE’S COMPENSATION ACT,1923",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Telangana"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Rajasthan"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Tamil Nadu"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Telangana"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Employee Compensation",
            "Act Name": "THE EMPLOYEE’S COMPENSATION ACT,1923",
            "Rule / Form": "THE EMPLOYEE’S COMPENSATION ACT,1923",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Chattisgarh"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Uttar Pradesh "
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Employee Compensation",
            "Act Name": "THE EMPLOYEE’S COMPENSATION ACT,1923",
            "Rule / Form": "THE EMPLOYEE’S COMPENSATION ACT,1923",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Employee Compensation",
            "Act Name": "THE EMPLOYEE’S COMPENSATION ACT,1923",
            "Rule / Form": "THE EMPLOYEE’S COMPENSATION ACT,1923",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Bihar "
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Employee Compensation",
            "Act Name": "THE EMPLOYEE’S COMPENSATION ACT,1923",
            "Rule / Form": "THE EMPLOYEE’S COMPENSATION ACT,1923",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Jharkhand"
        },
        {
            "Due Date": "5-Jun",
            "Compliance Month": "6",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Rule / Form": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing one or more employees/persons",
            "Periodicity": "Monthly",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "5-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Rule / Form": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing one or more employees/persons",
            "Periodicity": "Monthly",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Mizoram"
        },
        {
            "Due Date": "5-Aug",
            "Compliance Month": "8",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Rule / Form": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing one or more employees/persons",
            "Periodicity": "Monthly",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "5-Sep",
            "Compliance Month": "9",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Rule / Form": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing one or more employees/persons",
            "Periodicity": "Monthly",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "5-Nov",
            "Compliance Month": "11",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Rule / Form": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing one or more employees/persons",
            "Periodicity": "Monthly",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "5-Dec",
            "Compliance Month": "12",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Rule / Form": "The Kerala Shops And Commercial Establishments Workers Welfare Fund Act,2006 and The Kerala Shops And Commercial Establishments Workers Welfare Fund Scheme, 2007",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing one or more employees/persons",
            "Periodicity": "Monthly",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Uttarakhand"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Employee Compensation",
            "Act Name": "THE EMPLOYEE’S COMPENSATION ACT,1923",
            "Rule / Form": "THE EMPLOYEE’S COMPENSATION ACT,1923",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Uttar Pradesh "
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Maharashtra"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Employee Compensation",
            "Act Name": "THE EMPLOYEE’S COMPENSATION ACT,1923",
            "Rule / Form": "THE EMPLOYEE’S COMPENSATION ACT,1923",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Uttarakhand"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "The Minimum Wages Act, 1948",
            "Rule / Form": "The Minimum Wages Act, 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Gujarat"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Punjab"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Uttar Pradesh "
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Uttarakhand"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "West Bengal"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Kerala"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Nagaland"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "New Delhi"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Tamil Nadu"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Goa"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-IX (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-X (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XII (See Rule 77(I) (a)(i)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII [See Rule 78(1)(a)(ii)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XV {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVI [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVII [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVIII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-IX (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-X (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XII (See Rule 77(I) (a)(i)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII [See Rule 78(1)(a)(ii)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XV {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVI [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVII [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVIII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-IX (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-X (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XII (See Rule 77(I) (a)(i)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII [See Rule 78(1)(a)(ii)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XV {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVI [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVII [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVIII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-IX (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-X (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XII (See Rule 77(I) (a)(i)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII [See Rule 78(1)(a)(ii)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XV {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVI [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVII [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVIII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-IX (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-X (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XII (See Rule 77(I) (a)(i)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII [See Rule 78(1)(a)(ii)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XV {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVI [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVII [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVIII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-IX (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-X (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XII (See Rule 77(I) (a)(i)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII [See Rule 78(1)(a)(ii)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XV {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVI [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVII [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVIII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-IX (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-X (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XII (See Rule 77(I) (a)(i)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII [See Rule 78(1)(a)(ii)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XV {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVI [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVII [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVIII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-IX (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-X (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XII (See Rule 77(I) (a)(i)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII [See Rule 78(1)(a)(ii)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XV {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVI [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVII [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVIII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-IX (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-X (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XII (See Rule 77(I) (a)(i)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII [See Rule 78(1)(a)(ii)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XV {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVI [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVII [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVIII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-IX (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-X (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XII (See Rule 77(I) (a)(i)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII [See Rule 78(1)(a)(ii)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XV {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVI [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVII [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVIII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-IX (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-X (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XII (See Rule 77(I) (a)(i)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII [See Rule 78(1)(a)(ii)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XV {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVI [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVII [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVIII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-IX (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-X (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XII (See Rule 77(I) (a)(i)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII [See Rule 78(1)(a)(ii)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XV {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVI [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVII [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XVIII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "15-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Contract Labour Act (Principal Employer)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Rule / Form": "The Contract Labour (Regulation And Abolition) Act, 1970 (Principal Employer)",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "Establishment",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Haryana"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Labour Welfare Fund",
            "Act Name": "The Punjab Labour Welfare Fund Act,1965 and The Punjab Labour Welfare Fund Rules,1966",
            "Rule / Form": "The Punjab Labour Welfare Fund Act,1965 and The Punjab Labour Welfare Fund Rules,1966",
            "Compliance Type": "Statutory Payments",
            "Applicability": "Any Employer/Establishment employing ten or more employees/persons",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 76) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVI [See Rule 78(1)(a)(i)] Muster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII [See Rule 78(1)(a)(i)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 78(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 78(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 78(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 78(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form 11, Regulation 66 Accident Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 76) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVI [See Rule 78(1)(a)(i)] Muster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII [See Rule 78(1)(a)(i)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 78(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 78(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 78(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 78(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form 11, Regulation 66 Accident Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 76) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVI [See Rule 78(1)(a)(i)] Muster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII [See Rule 78(1)(a)(i)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 78(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 78(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 78(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 78(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form 11, Regulation 66 Accident Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 76) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVI [See Rule 78(1)(a)(i)] Muster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII [See Rule 78(1)(a)(i)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 78(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 78(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 78(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 78(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form 11, Regulation 66 Accident Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 76) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVI [See Rule 78(1)(a)(i)] Muster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII [See Rule 78(1)(a)(i)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 78(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 78(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 78(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 78(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form 11, Regulation 66 Accident Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 76) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVI [See Rule 78(1)(a)(i)] Muster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII [See Rule 78(1)(a)(i)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 78(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 78(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 78(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 78(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form 11, Regulation 66 Accident Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 76) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVI [See Rule 78(1)(a)(i)] Muster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII [See Rule 78(1)(a)(i)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 78(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 78(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 78(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 78(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form 11, Regulation 66 Accident Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 76) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVI [See Rule 78(1)(a)(i)] Muster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII [See Rule 78(1)(a)(i)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 78(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 78(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 78(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 78(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form 11, Regulation 66 Accident Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 76) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVI [See Rule 78(1)(a)(i)] Muster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII [See Rule 78(1)(a)(i)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 78(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 78(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 78(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 78(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form 11, Regulation 66 Accident Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 76) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVI [See Rule 78(1)(a)(i)] Muster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII [See Rule 78(1)(a)(i)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 78(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 78(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 78(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 78(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form 11, Regulation 66 Accident Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 76) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVI [See Rule 78(1)(a)(i)] Muster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII [See Rule 78(1)(a)(i)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 78(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 78(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 78(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 78(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form 11, Regulation 66 Accident Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 76) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVI [See Rule 78(1)(a)(i)] Muster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII [See Rule 78(1)(a)(i)] REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 78(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 78(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 78(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 78(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form 11, Regulation 66 Accident Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM No. XXVI (See Rule 75), Register of Employment of Contract Labour",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVII [See Rule 78 (1) (a)] Register of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVIII [ See rule 78 (1) (b)] Wage Slip",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM NO. XXIX (See Rule 78 (1) (d) REGISTER OF ADVANCES, DEDUCTIONS FOR DAMAGE OR LOSS AND FINES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Equal Remuneration Rules, 1976",
            "Rule / Form": "FORM D {See Rule 6 of the Equal Remuneration Rules, 1976)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM  No. 15  LEAVE BOOK (Prescribed under Rule 121) (Shall be the same as “Register of Leave with Wages” (Form No. 14), but shall be made out separately for each worker on a thick bound sheet).",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Maternity Benefit Rules, 1967",
            "Rule / Form": "FORM A [See Sub Rule (1) of the Rule 3 of the Tamil Nadu Maternity Benefit Rules, 1967] MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM No. XXVI (See Rule 75), Register of Employment of Contract Labour",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVII [See Rule 78 (1) (a)] Register of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVIII [ See rule 78 (1) (b)] Wage Slip",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM NO. XXIX (See Rule 78 (1) (d) REGISTER OF ADVANCES, DEDUCTIONS FOR DAMAGE OR LOSS AND FINES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Equal Remuneration Rules, 1976",
            "Rule / Form": "FORM D {See Rule 6 of the Equal Remuneration Rules, 1976)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM  No. 15  LEAVE BOOK (Prescribed under Rule 121) (Shall be the same as “Register of Leave with Wages” (Form No. 14), but shall be made out separately for each worker on a thick bound sheet).",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Maternity Benefit Rules, 1967",
            "Rule / Form": "FORM A [See Sub Rule (1) of the Rule 3 of the Tamil Nadu Maternity Benefit Rules, 1967] MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM No. XXVI (See Rule 75), Register of Employment of Contract Labour",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVII [See Rule 78 (1) (a)] Register of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVIII [ See rule 78 (1) (b)] Wage Slip",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM NO. XXIX (See Rule 78 (1) (d) REGISTER OF ADVANCES, DEDUCTIONS FOR DAMAGE OR LOSS AND FINES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Equal Remuneration Rules, 1976",
            "Rule / Form": "FORM D {See Rule 6 of the Equal Remuneration Rules, 1976)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM  No. 15  LEAVE BOOK (Prescribed under Rule 121) (Shall be the same as “Register of Leave with Wages” (Form No. 14), but shall be made out separately for each worker on a thick bound sheet).",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Maternity Benefit Rules, 1967",
            "Rule / Form": "FORM A [See Sub Rule (1) of the Rule 3 of the Tamil Nadu Maternity Benefit Rules, 1967] MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM No. XXVI (See Rule 75), Register of Employment of Contract Labour",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVII [See Rule 78 (1) (a)] Register of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVIII [ See rule 78 (1) (b)] Wage Slip",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM NO. XXIX (See Rule 78 (1) (d) REGISTER OF ADVANCES, DEDUCTIONS FOR DAMAGE OR LOSS AND FINES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Equal Remuneration Rules, 1976",
            "Rule / Form": "FORM D {See Rule 6 of the Equal Remuneration Rules, 1976)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM  No. 15  LEAVE BOOK (Prescribed under Rule 121) (Shall be the same as “Register of Leave with Wages” (Form No. 14), but shall be made out separately for each worker on a thick bound sheet).",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Maternity Benefit Rules, 1967",
            "Rule / Form": "FORM A [See Sub Rule (1) of the Rule 3 of the Tamil Nadu Maternity Benefit Rules, 1967] MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM No. XXVI (See Rule 75), Register of Employment of Contract Labour",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVII [See Rule 78 (1) (a)] Register of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVIII [ See rule 78 (1) (b)] Wage Slip",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM NO. XXIX (See Rule 78 (1) (d) REGISTER OF ADVANCES, DEDUCTIONS FOR DAMAGE OR LOSS AND FINES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Equal Remuneration Rules, 1976",
            "Rule / Form": "FORM D {See Rule 6 of the Equal Remuneration Rules, 1976)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM  No. 15  LEAVE BOOK (Prescribed under Rule 121) (Shall be the same as “Register of Leave with Wages” (Form No. 14), but shall be made out separately for each worker on a thick bound sheet).",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Maternity Benefit Rules, 1967",
            "Rule / Form": "FORM A [See Sub Rule (1) of the Rule 3 of the Tamil Nadu Maternity Benefit Rules, 1967] MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM No. XXVI (See Rule 75), Register of Employment of Contract Labour",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVII [See Rule 78 (1) (a)] Register of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVIII [ See rule 78 (1) (b)] Wage Slip",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM NO. XXIX (See Rule 78 (1) (d) REGISTER OF ADVANCES, DEDUCTIONS FOR DAMAGE OR LOSS AND FINES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Equal Remuneration Rules, 1976",
            "Rule / Form": "FORM D {See Rule 6 of the Equal Remuneration Rules, 1976)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM  No. 15  LEAVE BOOK (Prescribed under Rule 121) (Shall be the same as “Register of Leave with Wages” (Form No. 14), but shall be made out separately for each worker on a thick bound sheet).",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Maternity Benefit Rules, 1967",
            "Rule / Form": "FORM A [See Sub Rule (1) of the Rule 3 of the Tamil Nadu Maternity Benefit Rules, 1967] MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM No. XXVI (See Rule 75), Register of Employment of Contract Labour",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVII [See Rule 78 (1) (a)] Register of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVIII [ See rule 78 (1) (b)] Wage Slip",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM NO. XXIX (See Rule 78 (1) (d) REGISTER OF ADVANCES, DEDUCTIONS FOR DAMAGE OR LOSS AND FINES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Equal Remuneration Rules, 1976",
            "Rule / Form": "FORM D {See Rule 6 of the Equal Remuneration Rules, 1976)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM  No. 15  LEAVE BOOK (Prescribed under Rule 121) (Shall be the same as “Register of Leave with Wages” (Form No. 14), but shall be made out separately for each worker on a thick bound sheet).",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Maternity Benefit Rules, 1967",
            "Rule / Form": "FORM A [See Sub Rule (1) of the Rule 3 of the Tamil Nadu Maternity Benefit Rules, 1967] MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM No. XXVI (See Rule 75), Register of Employment of Contract Labour",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVII [See Rule 78 (1) (a)] Register of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVIII [ See rule 78 (1) (b)] Wage Slip",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM NO. XXIX (See Rule 78 (1) (d) REGISTER OF ADVANCES, DEDUCTIONS FOR DAMAGE OR LOSS AND FINES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Equal Remuneration Rules, 1976",
            "Rule / Form": "FORM D {See Rule 6 of the Equal Remuneration Rules, 1976)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM  No. 15  LEAVE BOOK (Prescribed under Rule 121) (Shall be the same as “Register of Leave with Wages” (Form No. 14), but shall be made out separately for each worker on a thick bound sheet).",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Maternity Benefit Rules, 1967",
            "Rule / Form": "FORM A [See Sub Rule (1) of the Rule 3 of the Tamil Nadu Maternity Benefit Rules, 1967] MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM No. XXVI (See Rule 75), Register of Employment of Contract Labour",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVII [See Rule 78 (1) (a)] Register of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVIII [ See rule 78 (1) (b)] Wage Slip",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM NO. XXIX (See Rule 78 (1) (d) REGISTER OF ADVANCES, DEDUCTIONS FOR DAMAGE OR LOSS AND FINES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Equal Remuneration Rules, 1976",
            "Rule / Form": "FORM D {See Rule 6 of the Equal Remuneration Rules, 1976)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM  No. 15  LEAVE BOOK (Prescribed under Rule 121) (Shall be the same as “Register of Leave with Wages” (Form No. 14), but shall be made out separately for each worker on a thick bound sheet).",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Maternity Benefit Rules, 1967",
            "Rule / Form": "FORM A [See Sub Rule (1) of the Rule 3 of the Tamil Nadu Maternity Benefit Rules, 1967] MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM No. XXVI (See Rule 75), Register of Employment of Contract Labour",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVII [See Rule 78 (1) (a)] Register of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVIII [ See rule 78 (1) (b)] Wage Slip",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM NO. XXIX (See Rule 78 (1) (d) REGISTER OF ADVANCES, DEDUCTIONS FOR DAMAGE OR LOSS AND FINES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Equal Remuneration Rules, 1976",
            "Rule / Form": "FORM D {See Rule 6 of the Equal Remuneration Rules, 1976)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM  No. 15  LEAVE BOOK (Prescribed under Rule 121) (Shall be the same as “Register of Leave with Wages” (Form No. 14), but shall be made out separately for each worker on a thick bound sheet).",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Maternity Benefit Rules, 1967",
            "Rule / Form": "FORM A [See Sub Rule (1) of the Rule 3 of the Tamil Nadu Maternity Benefit Rules, 1967] MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM No. XXVI (See Rule 75), Register of Employment of Contract Labour",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVII [See Rule 78 (1) (a)] Register of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVIII [ See rule 78 (1) (b)] Wage Slip",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM NO. XXIX (See Rule 78 (1) (d) REGISTER OF ADVANCES, DEDUCTIONS FOR DAMAGE OR LOSS AND FINES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Equal Remuneration Rules, 1976",
            "Rule / Form": "FORM D {See Rule 6 of the Equal Remuneration Rules, 1976)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM  No. 15  LEAVE BOOK (Prescribed under Rule 121) (Shall be the same as “Register of Leave with Wages” (Form No. 14), but shall be made out separately for each worker on a thick bound sheet).",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Maternity Benefit Rules, 1967",
            "Rule / Form": "FORM A [See Sub Rule (1) of the Rule 3 of the Tamil Nadu Maternity Benefit Rules, 1967] MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM No. XXVI (See Rule 75), Register of Employment of Contract Labour",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVII [See Rule 78 (1) (a)] Register of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM XXVIII [ See rule 78 (1) (b)] Wage Slip",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM NO. XXIX (See Rule 78 (1) (d) REGISTER OF ADVANCES, DEDUCTIONS FOR DAMAGE OR LOSS AND FINES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Equal Remuneration Rules, 1976",
            "Rule / Form": "FORM D {See Rule 6 of the Equal Remuneration Rules, 1976)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Contract Labour (Regulation and Abolition) Rules,1975",
            "Rule / Form": "FORM  No. 15  LEAVE BOOK (Prescribed under Rule 121) (Shall be the same as “Register of Leave with Wages” (Form No. 14), but shall be made out separately for each worker on a thick bound sheet).",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "Tamil Nadu Maternity Benefit Rules, 1967",
            "Rule / Form": "FORM A [See Sub Rule (1) of the Rule 3 of the Tamil Nadu Maternity Benefit Rules, 1967] MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "21-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "The Maternity Benefit Act, 1961",
            "Rule / Form": "The Maternity Benefit Act, 1961",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Minumum Wages Act",
            "Act Name": "Minimum Wages Act 1948",
            "Rule / Form": "Minimum Wages Act 1948",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "All",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "30-Dec",
            "Compliance Month": "12",
            "Simplified Name of Compliance": "N&F Act",
            "Act Name": "The Punjab Industrial Establishments (National and Festival Holidays and\r\nCasual and Sick Leave) Act, 1965 ",
            "Rule / Form": "The Punjab Industrial Establishments (National and Festival Holidays and\r\nCasual and Sick Leave) Act, 1965 ",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Haryana"
        },
        {
            "Due Date": "30-Oct",
            "Compliance Month": "10",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Haryana"
        },
        {
            "Due Date": "30-Jul",
            "Compliance Month": "7",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Haryana"
        },
        {
            "Due Date": "30-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Notification of Vacancies",
            "Act Name": "THE EMPLOYMENT EXCHANGES (COMPULSORY NOTIFICATION OF VACANCIES) ACT, 1959",
            "Rule / Form": "Employment Exchanges (CNV) Act,1959 & Rules - CNV",
            "Compliance Type": "Notification",
            "Periodicity": "Quarterly",
            "Responsibility": "ALL",
            "Weightage": "10",
            "State": "Haryana"
        },
        {
            "Due Date": "31-Jan",
            "Compliance Month": "1",
            "Simplified Name of Compliance": "Payment of Wages Act",
            "Act Name": "The Payment of Wages Act, 1936",
            "Rule / Form": "The Payment of Wages Act, 1936",
            "Compliance Type": "Return",
            "Fine": "Fine which shall not be less than one thousand five hundred rupees but which may extend to seven thousand five hundred rupees. ",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Haryana"
        },
        {
            "Due Date": "31-Mar",
            "Compliance Month": "3",
            "Simplified Name of Compliance": "Prevention of Sexual Harrasment",
            "Act Name": "The Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013",
            "Rule / Form": "The Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Haryana"
        },
        {
            "Due Date": "1-Feb",
            "Compliance Month": "2",
            "Simplified Name of Compliance": "Statutory Bonus",
            "Act Name": "The Payment of Bonus Act, 1965",
            "Rule / Form": "The Payment of Bonus Act, 1965",
            "Compliance Type": "Return",
            "Periodicity": "Annual",
            "Responsibility": "ALL",
            "Weightage": "10",
            "Compliance Catageory": "Major",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XVI (See Rule 79(I) (a)(I)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII (See Rule 78(1)(a)(ii) REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XVI (See Rule 79(I) (a)(I)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII (See Rule 78(1)(a)(ii) REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XVI (See Rule 79(I) (a)(I)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII (See Rule 78(1)(a)(ii) REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XVI (See Rule 79(I) (a)(I)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII (See Rule 78(1)(a)(ii) REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XVI (See Rule 79(I) (a)(I)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII (See Rule 78(1)(a)(ii) REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XVI (See Rule 79(I) (a)(I)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII (See Rule 78(1)(a)(ii) REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XVI (See Rule 79(I) (a)(I)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII (See Rule 78(1)(a)(ii) REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XVI (See Rule 79(I) (a)(I)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII (See Rule 78(1)(a)(ii) REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XVI (See Rule 79(I) (a)(I)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII (See Rule 78(1)(a)(ii) REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XVI (See Rule 79(I) (a)(I)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII (See Rule 78(1)(a)(ii) REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XVI (See Rule 79(I) (a)(I)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII (See Rule 78(1)(a)(ii) REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIII (See Rule 75) REGISTER OF WORKMEN EMPLOYED BY THE CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM-XIV (See Rules 75) EMPLOYMENT CARD",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM -XVI (See Rule 79(I) (a)(I)) MUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XVII (See Rule 78(1)(a)(ii) REGISTER OF WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": " Form No 15 (Prescribed under Rule 84) REGISTER OF LEAVE WITH WAGES",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX {See Rule 77(1)(b)} WAGES SLIP",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XX [(See Rule 77(1)(a)(ii)] Register of Deduction for Damages or Loss",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXI [(See Rule 77(1)(a)(ii)] Register of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXII [(See Rule 77(1)(a)(ii)] Register of Advances",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XXIII [(See Rule 77(1)(a)(iii)] Register of Overtime",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIII   [See Rule 75] REGISTER OF WORKMEN EMPLOYED BY CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIV [See Rule 76], Employment Card",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM – XV [See Rule 77], Service Certificate",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Karnataka Shops & Commercial Establishments Rules, 1963",
            "Rule / Form": "Form T, [See Rule 24(9-B) of the Karnataka Shops & Commercial Establishments Rules, 1963] Attandance and Wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX -  [See Rule 78(1)(b)] Wages slip   ",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'F' [See Rule 8] Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'H' [See Rule 8] Leave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIII   [See Rule 75] REGISTER OF WORKMEN EMPLOYED BY CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIV [See Rule 76], Employment Card",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM – XV [See Rule 77], Service Certificate",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Karnataka Shops & Commercial Establishments Rules, 1963",
            "Rule / Form": "Form T, [See Rule 24(9-B) of the Karnataka Shops & Commercial Establishments Rules, 1963] Attandance and Wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX -  [See Rule 78(1)(b)] Wages slip   ",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'F' [See Rule 8] Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'H' [See Rule 8] Leave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIII   [See Rule 75] REGISTER OF WORKMEN EMPLOYED BY CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIV [See Rule 76], Employment Card",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM – XV [See Rule 77], Service Certificate",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Karnataka Shops & Commercial Establishments Rules, 1963",
            "Rule / Form": "Form T, [See Rule 24(9-B) of the Karnataka Shops & Commercial Establishments Rules, 1963] Attandance and Wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX -  [See Rule 78(1)(b)] Wages slip   ",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'F' [See Rule 8] Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'H' [See Rule 8] Leave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIII   [See Rule 75] REGISTER OF WORKMEN EMPLOYED BY CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIV [See Rule 76], Employment Card",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM – XV [See Rule 77], Service Certificate",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Karnataka Shops & Commercial Establishments Rules, 1963",
            "Rule / Form": "Form T, [See Rule 24(9-B) of the Karnataka Shops & Commercial Establishments Rules, 1963] Attandance and Wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX -  [See Rule 78(1)(b)] Wages slip   ",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'F' [See Rule 8] Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'H' [See Rule 8] Leave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIII   [See Rule 75] REGISTER OF WORKMEN EMPLOYED BY CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIV [See Rule 76], Employment Card",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM – XV [See Rule 77], Service Certificate",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Karnataka Shops & Commercial Establishments Rules, 1963",
            "Rule / Form": "Form T, [See Rule 24(9-B) of the Karnataka Shops & Commercial Establishments Rules, 1963] Attandance and Wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX -  [See Rule 78(1)(b)] Wages slip   ",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'F' [See Rule 8] Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'H' [See Rule 8] Leave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIII   [See Rule 75] REGISTER OF WORKMEN EMPLOYED BY CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIV [See Rule 76], Employment Card",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM – XV [See Rule 77], Service Certificate",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Karnataka Shops & Commercial Establishments Rules, 1963",
            "Rule / Form": "Form T, [See Rule 24(9-B) of the Karnataka Shops & Commercial Establishments Rules, 1963] Attandance and Wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX -  [See Rule 78(1)(b)] Wages slip   ",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'F' [See Rule 8] Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'H' [See Rule 8] Leave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIII   [See Rule 75] REGISTER OF WORKMEN EMPLOYED BY CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIV [See Rule 76], Employment Card",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM – XV [See Rule 77], Service Certificate",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Karnataka Shops & Commercial Establishments Rules, 1963",
            "Rule / Form": "Form T, [See Rule 24(9-B) of the Karnataka Shops & Commercial Establishments Rules, 1963] Attandance and Wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX -  [See Rule 78(1)(b)] Wages slip   ",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'F' [See Rule 8] Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'H' [See Rule 8] Leave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIII   [See Rule 75] REGISTER OF WORKMEN EMPLOYED BY CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIV [See Rule 76], Employment Card",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM – XV [See Rule 77], Service Certificate",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Karnataka Shops & Commercial Establishments Rules, 1963",
            "Rule / Form": "Form T, [See Rule 24(9-B) of the Karnataka Shops & Commercial Establishments Rules, 1963] Attandance and Wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX -  [See Rule 78(1)(b)] Wages slip   ",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'F' [See Rule 8] Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'H' [See Rule 8] Leave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIII   [See Rule 75] REGISTER OF WORKMEN EMPLOYED BY CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIV [See Rule 76], Employment Card",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM – XV [See Rule 77], Service Certificate",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Karnataka Shops & Commercial Establishments Rules, 1963",
            "Rule / Form": "Form T, [See Rule 24(9-B) of the Karnataka Shops & Commercial Establishments Rules, 1963] Attandance and Wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX -  [See Rule 78(1)(b)] Wages slip   ",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'F' [See Rule 8] Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'H' [See Rule 8] Leave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIII   [See Rule 75] REGISTER OF WORKMEN EMPLOYED BY CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIV [See Rule 76], Employment Card",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM – XV [See Rule 77], Service Certificate",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Karnataka Shops & Commercial Establishments Rules, 1963",
            "Rule / Form": "Form T, [See Rule 24(9-B) of the Karnataka Shops & Commercial Establishments Rules, 1963] Attandance and Wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX -  [See Rule 78(1)(b)] Wages slip   ",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'F' [See Rule 8] Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'H' [See Rule 8] Leave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIII   [See Rule 75] REGISTER OF WORKMEN EMPLOYED BY CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIV [See Rule 76], Employment Card",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM – XV [See Rule 77], Service Certificate",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Karnataka Shops & Commercial Establishments Rules, 1963",
            "Rule / Form": "Form T, [See Rule 24(9-B) of the Karnataka Shops & Commercial Establishments Rules, 1963] Attandance and Wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX -  [See Rule 78(1)(b)] Wages slip   ",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'F' [See Rule 8] Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'H' [See Rule 8] Leave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIII   [See Rule 75] REGISTER OF WORKMEN EMPLOYED BY CONTRACTOR",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form-XIV [See Rule 76], Employment Card",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM – XV [See Rule 77], Service Certificate",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Karnataka Shops & Commercial Establishments Rules, 1963",
            "Rule / Form": "Form T, [See Rule 24(9-B) of the Karnataka Shops & Commercial Establishments Rules, 1963] Attandance and Wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "Form XIX -  [See Rule 78(1)(b)] Wages slip   ",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'F' [See Rule 8] Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The karnataka Shops & Establishments Act,1961 & Rules 1963",
            "Rule / Form": "FORM  'H' [See Rule 8] Leave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Contract Labour Act (Contractor)",
            "Act Name": "The Contract Labour (Regulation And Abolition) Act, 1970 (Contractor)",
            "Rule / Form": "FORM 11  Regulation 66, Accident Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Equal Remuneration Rule, 1976",
            "Act Name": "The Equal Remuneration Rule, 1976",
            "Rule / Form": "Form D, Rule 6, Equal Remuneration Act",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Maternity Benefit Act",
            "Act Name": "Maternity Benefit Rules",
            "Rule / Form": "FORM A [See Rule 3] MUSTER-ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Contractor",
            "Compliance Catageory": "Moderate",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The West Bengal Payment of Wages Rules, 1958",
            "Act Name": "The West Bengal Payment of Wages Rules, 1958",
            "Rule / Form": "Form III\r\n[Rule 18 (3)]\r\nRegister of advances made on employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of deductions for damage or loss caused to the employer, by the neglect or default of the employed person",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Rule / Form": "FORM G\r\n[See clause (a) of sub-rule 1 of Rule 30]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "FORM I (1)\r\n[See Rule 13]\r\nRegister of Daily Hours of Work and Rest Intervals of persons employed",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form U\r\n[See Rule 40]\r\nRegister of Overtime Work",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 10 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form M\r\n[See Rule 30]\r\nPay Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form J\r\n[See Sub-rule (2) of Rule 18 and Rule 21]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Rule / Form": "FORM-H\r\n[See clause (b) of sub-rule (1) of Rule 30]\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The West Bengal Maternity Benefit Rules, 1965",
            "Act Name": "The West Bengal Maternity Benefit Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form W\r\n[See Rule 52]\r\nRegister of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The West Bengal Payment of Wages Rules, 1958",
            "Act Name": "The West Bengal Payment of Wages Rules, 1958",
            "Rule / Form": "Form III\r\n[Rule 18 (3)]\r\nRegister of advances made on employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of deductions for damage or loss caused to the employer, by the neglect or default of the employed person",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Rule / Form": "FORM G\r\n[See clause (a) of sub-rule 1 of Rule 30]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "FORM I (1)\r\n[See Rule 13]\r\nRegister of Daily Hours of Work and Rest Intervals of persons employed",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form U\r\n[See Rule 40]\r\nRegister of Overtime Work",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 10 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form M\r\n[See Rule 30]\r\nPay Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form J\r\n[See Sub-rule (2) of Rule 18 and Rule 21]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Rule / Form": "FORM-H\r\n[See clause (b) of sub-rule (1) of Rule 30]\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The West Bengal Maternity Benefit Rules, 1965",
            "Act Name": "The West Bengal Maternity Benefit Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form W\r\n[See Rule 52]\r\nRegister of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The West Bengal Payment of Wages Rules, 1958",
            "Act Name": "The West Bengal Payment of Wages Rules, 1958",
            "Rule / Form": "Form III\r\n[Rule 18 (3)]\r\nRegister of advances made on employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of deductions for damage or loss caused to the employer, by the neglect or default of the employed person",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Rule / Form": "FORM G\r\n[See clause (a) of sub-rule 1 of Rule 30]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "FORM I (1)\r\n[See Rule 13]\r\nRegister of Daily Hours of Work and Rest Intervals of persons employed",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form U\r\n[See Rule 40]\r\nRegister of Overtime Work",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 10 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form M\r\n[See Rule 30]\r\nPay Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form J\r\n[See Sub-rule (2) of Rule 18 and Rule 21]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Rule / Form": "FORM-H\r\n[See clause (b) of sub-rule (1) of Rule 30]\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The West Bengal Maternity Benefit Rules, 1965",
            "Act Name": "The West Bengal Maternity Benefit Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form W\r\n[See Rule 52]\r\nRegister of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The West Bengal Payment of Wages Rules, 1958",
            "Act Name": "The West Bengal Payment of Wages Rules, 1958",
            "Rule / Form": "Form III\r\n[Rule 18 (3)]\r\nRegister of advances made on employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of deductions for damage or loss caused to the employer, by the neglect or default of the employed person",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Rule / Form": "FORM G\r\n[See clause (a) of sub-rule 1 of Rule 30]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "FORM I (1)\r\n[See Rule 13]\r\nRegister of Daily Hours of Work and Rest Intervals of persons employed",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form U\r\n[See Rule 40]\r\nRegister of Overtime Work",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 10 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form M\r\n[See Rule 30]\r\nPay Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form J\r\n[See Sub-rule (2) of Rule 18 and Rule 21]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Rule / Form": "FORM-H\r\n[See clause (b) of sub-rule (1) of Rule 30]\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The West Bengal Maternity Benefit Rules, 1965",
            "Act Name": "The West Bengal Maternity Benefit Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form W\r\n[See Rule 52]\r\nRegister of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The West Bengal Payment of Wages Rules, 1958",
            "Act Name": "The West Bengal Payment of Wages Rules, 1958",
            "Rule / Form": "Form III\r\n[Rule 18 (3)]\r\nRegister of advances made on employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of deductions for damage or loss caused to the employer, by the neglect or default of the employed person",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Rule / Form": "FORM G\r\n[See clause (a) of sub-rule 1 of Rule 30]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "FORM I (1)\r\n[See Rule 13]\r\nRegister of Daily Hours of Work and Rest Intervals of persons employed",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form U\r\n[See Rule 40]\r\nRegister of Overtime Work",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 10 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form M\r\n[See Rule 30]\r\nPay Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form J\r\n[See Sub-rule (2) of Rule 18 and Rule 21]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Rule / Form": "FORM-H\r\n[See clause (b) of sub-rule (1) of Rule 30]\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The West Bengal Maternity Benefit Rules, 1965",
            "Act Name": "The West Bengal Maternity Benefit Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form W\r\n[See Rule 52]\r\nRegister of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The West Bengal Payment of Wages Rules, 1958",
            "Act Name": "The West Bengal Payment of Wages Rules, 1958",
            "Rule / Form": "Form III\r\n[Rule 18 (3)]\r\nRegister of advances made on employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of deductions for damage or loss caused to the employer, by the neglect or default of the employed person",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Rule / Form": "FORM G\r\n[See clause (a) of sub-rule 1 of Rule 30]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "FORM I (1)\r\n[See Rule 13]\r\nRegister of Daily Hours of Work and Rest Intervals of persons employed",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form U\r\n[See Rule 40]\r\nRegister of Overtime Work",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 10 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form M\r\n[See Rule 30]\r\nPay Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form J\r\n[See Sub-rule (2) of Rule 18 and Rule 21]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Rule / Form": "FORM-H\r\n[See clause (b) of sub-rule (1) of Rule 30]\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The West Bengal Maternity Benefit Rules, 1965",
            "Act Name": "The West Bengal Maternity Benefit Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form W\r\n[See Rule 52]\r\nRegister of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The West Bengal Payment of Wages Rules, 1958",
            "Act Name": "The West Bengal Payment of Wages Rules, 1958",
            "Rule / Form": "Form III\r\n[Rule 18 (3)]\r\nRegister of advances made on employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of deductions for damage or loss caused to the employer, by the neglect or default of the employed person",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Rule / Form": "FORM G\r\n[See clause (a) of sub-rule 1 of Rule 30]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "FORM I (1)\r\n[See Rule 13]\r\nRegister of Daily Hours of Work and Rest Intervals of persons employed",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form U\r\n[See Rule 40]\r\nRegister of Overtime Work",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 10 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form M\r\n[See Rule 30]\r\nPay Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form J\r\n[See Sub-rule (2) of Rule 18 and Rule 21]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Rule / Form": "FORM-H\r\n[See clause (b) of sub-rule (1) of Rule 30]\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The West Bengal Maternity Benefit Rules, 1965",
            "Act Name": "The West Bengal Maternity Benefit Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form W\r\n[See Rule 52]\r\nRegister of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The West Bengal Payment of Wages Rules, 1958",
            "Act Name": "The West Bengal Payment of Wages Rules, 1958",
            "Rule / Form": "Form III\r\n[Rule 18 (3)]\r\nRegister of advances made on employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of deductions for damage or loss caused to the employer, by the neglect or default of the employed person",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Rule / Form": "FORM G\r\n[See clause (a) of sub-rule 1 of Rule 30]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "FORM I (1)\r\n[See Rule 13]\r\nRegister of Daily Hours of Work and Rest Intervals of persons employed",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form U\r\n[See Rule 40]\r\nRegister of Overtime Work",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 10 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form M\r\n[See Rule 30]\r\nPay Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form J\r\n[See Sub-rule (2) of Rule 18 and Rule 21]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Rule / Form": "FORM-H\r\n[See clause (b) of sub-rule (1) of Rule 30]\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The West Bengal Maternity Benefit Rules, 1965",
            "Act Name": "The West Bengal Maternity Benefit Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form W\r\n[See Rule 52]\r\nRegister of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The West Bengal Payment of Wages Rules, 1958",
            "Act Name": "The West Bengal Payment of Wages Rules, 1958",
            "Rule / Form": "Form III\r\n[Rule 18 (3)]\r\nRegister of advances made on employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of deductions for damage or loss caused to the employer, by the neglect or default of the employed person",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Rule / Form": "FORM G\r\n[See clause (a) of sub-rule 1 of Rule 30]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "FORM I (1)\r\n[See Rule 13]\r\nRegister of Daily Hours of Work and Rest Intervals of persons employed",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form U\r\n[See Rule 40]\r\nRegister of Overtime Work",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 10 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form M\r\n[See Rule 30]\r\nPay Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form J\r\n[See Sub-rule (2) of Rule 18 and Rule 21]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Rule / Form": "FORM-H\r\n[See clause (b) of sub-rule (1) of Rule 30]\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The West Bengal Maternity Benefit Rules, 1965",
            "Act Name": "The West Bengal Maternity Benefit Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form W\r\n[See Rule 52]\r\nRegister of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The West Bengal Payment of Wages Rules, 1958",
            "Act Name": "The West Bengal Payment of Wages Rules, 1958",
            "Rule / Form": "Form III\r\n[Rule 18 (3)]\r\nRegister of advances made on employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of deductions for damage or loss caused to the employer, by the neglect or default of the employed person",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Rule / Form": "FORM G\r\n[See clause (a) of sub-rule 1 of Rule 30]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "FORM I (1)\r\n[See Rule 13]\r\nRegister of Daily Hours of Work and Rest Intervals of persons employed",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form U\r\n[See Rule 40]\r\nRegister of Overtime Work",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 10 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form M\r\n[See Rule 30]\r\nPay Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form J\r\n[See Sub-rule (2) of Rule 18 and Rule 21]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Rule / Form": "FORM-H\r\n[See clause (b) of sub-rule (1) of Rule 30]\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The West Bengal Maternity Benefit Rules, 1965",
            "Act Name": "The West Bengal Maternity Benefit Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form W\r\n[See Rule 52]\r\nRegister of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The West Bengal Payment of Wages Rules, 1958",
            "Act Name": "The West Bengal Payment of Wages Rules, 1958",
            "Rule / Form": "Form III\r\n[Rule 18 (3)]\r\nRegister of advances made on employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of deductions for damage or loss caused to the employer, by the neglect or default of the employed person",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Rule / Form": "FORM G\r\n[See clause (a) of sub-rule 1 of Rule 30]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "FORM I (1)\r\n[See Rule 13]\r\nRegister of Daily Hours of Work and Rest Intervals of persons employed",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form U\r\n[See Rule 40]\r\nRegister of Overtime Work",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 10 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form M\r\n[See Rule 30]\r\nPay Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form J\r\n[See Sub-rule (2) of Rule 18 and Rule 21]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Rule / Form": "FORM-H\r\n[See clause (b) of sub-rule (1) of Rule 30]\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The West Bengal Maternity Benefit Rules, 1965",
            "Act Name": "The West Bengal Maternity Benefit Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form W\r\n[See Rule 52]\r\nRegister of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The West Bengal Payment of Wages Rules, 1958",
            "Act Name": "The West Bengal Payment of Wages Rules, 1958",
            "Rule / Form": "Form III\r\n[Rule 18 (3)]\r\nRegister of advances made on employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of deductions for damage or loss caused to the employer, by the neglect or default of the employed person",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The West Bengal Minimum Wages Rules, 1951",
            "Act Name": "The West Bengal Minimum Wages Rules, 1951",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 & Rules, 1976",
            "Rule / Form": "FORM G\r\n[See clause (a) of sub-rule 1 of Rule 30]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "FORM I (1)\r\n[See Rule 13]\r\nRegister of Daily Hours of Work and Rest Intervals of persons employed",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form U\r\n[See Rule 40]\r\nRegister of Overtime Work",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The West Bengal Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 10 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form M\r\n[See Rule 30]\r\nPay Register",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form J\r\n[See Sub-rule (2) of Rule 18 and Rule 21]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Act Name": "The West Bengal Labour Welfare Fund Act, 1974 and Rules, 1976",
            "Rule / Form": "FORM-H\r\n[See clause (b) of sub-rule (1) of Rule 30]\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The West Bengal Maternity Benefit Rules, 1965",
            "Act Name": "The West Bengal Maternity Benefit Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The West Bengal Shops and Establishment Rules, 1964",
            "Act Name": "The West Bengal Shops and Establishment Rules, 1964",
            "Rule / Form": "Form W\r\n[See Rule 52]\r\nRegister of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "West Bengal"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Act Name": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Rule / Form": "Form III\r\n[See rule 17(3)]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form D\r\n[See Rules 12(8) & 18(1)(c)]\r\nRegister of Deduction From Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form E\r\n[ See Rule 13]\r\nRegister of Fines and Realization Thereof",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "FORM G\r\n[ See rule 18 (1) (b) and (c) ]\r\nRegister of Attendance and Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Act Name": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Rule / Form": "Form XVII\r\n(Rule 25-A)\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form 'H'\r\n[See Rule 18(1) (b) and (c)]\r\n(Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963)\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Act Name": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Act Name": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Rule / Form": "Form II\r\n(Under Rule 5)\r\nRegister of National Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Act Name": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Rule / Form": "Form III\r\n[See rule 17(3)]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form D\r\n[See Rules 12(8) & 18(1)(c)]\r\nRegister of Deduction From Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form E\r\n[ See Rule 13]\r\nRegister of Fines and Realization Thereof",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "FORM G\r\n[ See rule 18 (1) (b) and (c) ]\r\nRegister of Attendance and Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Act Name": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Rule / Form": "Form XVII\r\n(Rule 25-A)\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form 'H'\r\n[See Rule 18(1) (b) and (c)]\r\n(Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963)\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Act Name": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Act Name": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Rule / Form": "Form II\r\n(Under Rule 5)\r\nRegister of National Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Act Name": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Rule / Form": "Form III\r\n[See rule 17(3)]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form D\r\n[See Rules 12(8) & 18(1)(c)]\r\nRegister of Deduction From Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form E\r\n[ See Rule 13]\r\nRegister of Fines and Realization Thereof",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "FORM G\r\n[ See rule 18 (1) (b) and (c) ]\r\nRegister of Attendance and Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Act Name": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Rule / Form": "Form XVII\r\n(Rule 25-A)\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form 'H'\r\n[See Rule 18(1) (b) and (c)]\r\n(Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963)\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Act Name": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Act Name": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Rule / Form": "Form II\r\n(Under Rule 5)\r\nRegister of National Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Act Name": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Rule / Form": "Form III\r\n[See rule 17(3)]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form D\r\n[See Rules 12(8) & 18(1)(c)]\r\nRegister of Deduction From Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form E\r\n[ See Rule 13]\r\nRegister of Fines and Realization Thereof",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "FORM G\r\n[ See rule 18 (1) (b) and (c) ]\r\nRegister of Attendance and Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Act Name": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Rule / Form": "Form XVII\r\n(Rule 25-A)\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form 'H'\r\n[See Rule 18(1) (b) and (c)]\r\n(Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963)\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Act Name": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Act Name": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Rule / Form": "Form II\r\n(Under Rule 5)\r\nRegister of National Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Act Name": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Rule / Form": "Form III\r\n[See rule 17(3)]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form D\r\n[See Rules 12(8) & 18(1)(c)]\r\nRegister of Deduction From Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form E\r\n[ See Rule 13]\r\nRegister of Fines and Realization Thereof",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "FORM G\r\n[ See rule 18 (1) (b) and (c) ]\r\nRegister of Attendance and Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Act Name": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Rule / Form": "Form XVII\r\n(Rule 25-A)\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form 'H'\r\n[See Rule 18(1) (b) and (c)]\r\n(Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963)\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Act Name": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Act Name": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Rule / Form": "Form II\r\n(Under Rule 5)\r\nRegister of National Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Act Name": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Rule / Form": "Form III\r\n[See rule 17(3)]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form D\r\n[See Rules 12(8) & 18(1)(c)]\r\nRegister of Deduction From Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form E\r\n[ See Rule 13]\r\nRegister of Fines and Realization Thereof",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "FORM G\r\n[ See rule 18 (1) (b) and (c) ]\r\nRegister of Attendance and Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Act Name": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Rule / Form": "Form XVII\r\n(Rule 25-A)\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form 'H'\r\n[See Rule 18(1) (b) and (c)]\r\n(Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963)\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Act Name": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Act Name": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Rule / Form": "Form II\r\n(Under Rule 5)\r\nRegister of National Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Act Name": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Rule / Form": "Form III\r\n[See rule 17(3)]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form D\r\n[See Rules 12(8) & 18(1)(c)]\r\nRegister of Deduction From Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form E\r\n[ See Rule 13]\r\nRegister of Fines and Realization Thereof",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "FORM G\r\n[ See rule 18 (1) (b) and (c) ]\r\nRegister of Attendance and Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Act Name": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Rule / Form": "Form XVII\r\n(Rule 25-A)\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form 'H'\r\n[See Rule 18(1) (b) and (c)]\r\n(Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963)\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Act Name": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Act Name": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Rule / Form": "Form II\r\n(Under Rule 5)\r\nRegister of National Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Act Name": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Rule / Form": "Form III\r\n[See rule 17(3)]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form D\r\n[See Rules 12(8) & 18(1)(c)]\r\nRegister of Deduction From Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form E\r\n[ See Rule 13]\r\nRegister of Fines and Realization Thereof",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "FORM G\r\n[ See rule 18 (1) (b) and (c) ]\r\nRegister of Attendance and Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Act Name": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Rule / Form": "Form XVII\r\n(Rule 25-A)\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form 'H'\r\n[See Rule 18(1) (b) and (c)]\r\n(Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963)\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Act Name": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Act Name": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Rule / Form": "Form II\r\n(Under Rule 5)\r\nRegister of National Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Act Name": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Rule / Form": "Form III\r\n[See rule 17(3)]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form D\r\n[See Rules 12(8) & 18(1)(c)]\r\nRegister of Deduction From Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form E\r\n[ See Rule 13]\r\nRegister of Fines and Realization Thereof",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "FORM G\r\n[ See rule 18 (1) (b) and (c) ]\r\nRegister of Attendance and Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Act Name": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Rule / Form": "Form XVII\r\n(Rule 25-A)\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form 'H'\r\n[See Rule 18(1) (b) and (c)]\r\n(Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963)\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Act Name": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Act Name": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Rule / Form": "Form II\r\n(Under Rule 5)\r\nRegister of National Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Act Name": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Rule / Form": "Form III\r\n[See rule 17(3)]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form D\r\n[See Rules 12(8) & 18(1)(c)]\r\nRegister of Deduction From Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form E\r\n[ See Rule 13]\r\nRegister of Fines and Realization Thereof",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "FORM G\r\n[ See rule 18 (1) (b) and (c) ]\r\nRegister of Attendance and Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Act Name": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Rule / Form": "Form XVII\r\n(Rule 25-A)\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form 'H'\r\n[See Rule 18(1) (b) and (c)]\r\n(Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963)\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Act Name": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Act Name": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Rule / Form": "Form II\r\n(Under Rule 5)\r\nRegister of National Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Act Name": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Rule / Form": "Form III\r\n[See rule 17(3)]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form D\r\n[See Rules 12(8) & 18(1)(c)]\r\nRegister of Deduction From Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form E\r\n[ See Rule 13]\r\nRegister of Fines and Realization Thereof",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "FORM G\r\n[ See rule 18 (1) (b) and (c) ]\r\nRegister of Attendance and Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Act Name": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Rule / Form": "Form XVII\r\n(Rule 25-A)\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form 'H'\r\n[See Rule 18(1) (b) and (c)]\r\n(Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963)\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Act Name": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Act Name": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Rule / Form": "Form II\r\n(Under Rule 5)\r\nRegister of National Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Act Name": "The Uttar Pradesh Payment of Wages Rules, 1936",
            "Rule / Form": "Form III\r\n[See rule 17(3)]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form D\r\n[See Rules 12(8) & 18(1)(c)]\r\nRegister of Deduction From Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form E\r\n[ See Rule 13]\r\nRegister of Fines and Realization Thereof",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "FORM G\r\n[ See rule 18 (1) (b) and (c) ]\r\nRegister of Attendance and Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Act Name": "The Uttar Pradesh Minimum Wages Rules, 1952",
            "Rule / Form": "Form XVII\r\n(Rule 25-A)\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Act Name": "Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963",
            "Rule / Form": "Form 'H'\r\n[See Rule 18(1) (b) and (c)]\r\n(Uttar Pradesh Dookan Aur Vanijya Adhishthan Niyamavali, 1963)\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Act Name": "The Uttar Pradesh Maternity Benefit Rules, 1983",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Act Name": "The Uttar Pradesh Industrial Establishments (National Holidays) Rules, 1965",
            "Rule / Form": "Form II\r\n(Under Rule 5)\r\nRegister of National Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Uttar Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XII\r\n[See Rule 18 (4)]\r\nRegister of advances of wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - II\r\nINTEGRATED REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XI\r\n[See Rule 17(4)]\r\nRegister of Deductions for the Damage or Loss caused to the employer by the neglect or default of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form - X\r\n[See Rule 17(3) (a)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Act Name": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Rule / Form": "Form - D\r\n[See Sub-Rule (1) of Rule 4]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER PART 1",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER\r\nMUSTER ROLL-CUM REGISTER OF WAGES/DEDUCTIONS/OVERTIME/ADVANCES\r\n(Part II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form XXII\r\n[See Rule 29 (1)]\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The A.P Minimum Wages Rules, 1960",
            "Act Name": "The A.P Minimum Wages Rules, 1960",
            "Rule / Form": "Form IV\r\n[Rule 29(2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form XXIII\r\n[See Rule 29 (2)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form - XXV\r\n[See Rule 29(6)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Act Name": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Rule / Form": "Form - E\r\n[See sub-rule (2) of Rule 4]\r\nRegister of Fines and Unpaid Accumulations for the Year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Act Name": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Rule / Form": "FORM-III\r\n[See sub-rule (1) of Rule 6]\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XII\r\n[See Rule 18 (4)]\r\nRegister of advances of wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - II\r\nINTEGRATED REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XI\r\n[See Rule 17(4)]\r\nRegister of Deductions for the Damage or Loss caused to the employer by the neglect or default of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form - X\r\n[See Rule 17(3) (a)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Act Name": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Rule / Form": "Form - D\r\n[See Sub-Rule (1) of Rule 4]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER PART 1",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER\r\nMUSTER ROLL-CUM REGISTER OF WAGES/DEDUCTIONS/OVERTIME/ADVANCES\r\n(Part II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form XXII\r\n[See Rule 29 (1)]\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The A.P Minimum Wages Rules, 1960",
            "Act Name": "The A.P Minimum Wages Rules, 1960",
            "Rule / Form": "Form IV\r\n[Rule 29(2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form XXIII\r\n[See Rule 29 (2)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form - XXV\r\n[See Rule 29(6)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Act Name": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Rule / Form": "Form - E\r\n[See sub-rule (2) of Rule 4]\r\nRegister of Fines and Unpaid Accumulations for the Year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Act Name": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Rule / Form": "FORM-III\r\n[See sub-rule (1) of Rule 6]\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XII\r\n[See Rule 18 (4)]\r\nRegister of advances of wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - II\r\nINTEGRATED REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XI\r\n[See Rule 17(4)]\r\nRegister of Deductions for the Damage or Loss caused to the employer by the neglect or default of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form - X\r\n[See Rule 17(3) (a)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Act Name": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Rule / Form": "Form - D\r\n[See Sub-Rule (1) of Rule 4]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER PART 1",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER\r\nMUSTER ROLL-CUM REGISTER OF WAGES/DEDUCTIONS/OVERTIME/ADVANCES\r\n(Part II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form XXII\r\n[See Rule 29 (1)]\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The A.P Minimum Wages Rules, 1960",
            "Act Name": "The A.P Minimum Wages Rules, 1960",
            "Rule / Form": "Form IV\r\n[Rule 29(2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form XXIII\r\n[See Rule 29 (2)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form - XXV\r\n[See Rule 29(6)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Act Name": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Rule / Form": "Form - E\r\n[See sub-rule (2) of Rule 4]\r\nRegister of Fines and Unpaid Accumulations for the Year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Act Name": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Rule / Form": "FORM-III\r\n[See sub-rule (1) of Rule 6]\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XII\r\n[See Rule 18 (4)]\r\nRegister of advances of wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - II\r\nINTEGRATED REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XI\r\n[See Rule 17(4)]\r\nRegister of Deductions for the Damage or Loss caused to the employer by the neglect or default of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form - X\r\n[See Rule 17(3) (a)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Act Name": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Rule / Form": "Form - D\r\n[See Sub-Rule (1) of Rule 4]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER PART 1",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER\r\nMUSTER ROLL-CUM REGISTER OF WAGES/DEDUCTIONS/OVERTIME/ADVANCES\r\n(Part II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form XXII\r\n[See Rule 29 (1)]\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The A.P Minimum Wages Rules, 1960",
            "Act Name": "The A.P Minimum Wages Rules, 1960",
            "Rule / Form": "Form IV\r\n[Rule 29(2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form XXIII\r\n[See Rule 29 (2)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form - XXV\r\n[See Rule 29(6)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Act Name": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Rule / Form": "Form - E\r\n[See sub-rule (2) of Rule 4]\r\nRegister of Fines and Unpaid Accumulations for the Year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Act Name": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Rule / Form": "FORM-III\r\n[See sub-rule (1) of Rule 6]\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XII\r\n[See Rule 18 (4)]\r\nRegister of advances of wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - II\r\nINTEGRATED REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XI\r\n[See Rule 17(4)]\r\nRegister of Deductions for the Damage or Loss caused to the employer by the neglect or default of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form - X\r\n[See Rule 17(3) (a)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Act Name": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Rule / Form": "Form - D\r\n[See Sub-Rule (1) of Rule 4]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER PART 1",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER\r\nMUSTER ROLL-CUM REGISTER OF WAGES/DEDUCTIONS/OVERTIME/ADVANCES\r\n(Part II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form XXII\r\n[See Rule 29 (1)]\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The A.P Minimum Wages Rules, 1960",
            "Act Name": "The A.P Minimum Wages Rules, 1960",
            "Rule / Form": "Form IV\r\n[Rule 29(2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form XXIII\r\n[See Rule 29 (2)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form - XXV\r\n[See Rule 29(6)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Act Name": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Rule / Form": "Form - E\r\n[See sub-rule (2) of Rule 4]\r\nRegister of Fines and Unpaid Accumulations for the Year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Act Name": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Rule / Form": "FORM-III\r\n[See sub-rule (1) of Rule 6]\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XII\r\n[See Rule 18 (4)]\r\nRegister of advances of wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - II\r\nINTEGRATED REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XI\r\n[See Rule 17(4)]\r\nRegister of Deductions for the Damage or Loss caused to the employer by the neglect or default of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form - X\r\n[See Rule 17(3) (a)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Act Name": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Rule / Form": "Form - D\r\n[See Sub-Rule (1) of Rule 4]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER PART 1",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER\r\nMUSTER ROLL-CUM REGISTER OF WAGES/DEDUCTIONS/OVERTIME/ADVANCES\r\n(Part II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form XXII\r\n[See Rule 29 (1)]\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The A.P Minimum Wages Rules, 1960",
            "Act Name": "The A.P Minimum Wages Rules, 1960",
            "Rule / Form": "Form IV\r\n[Rule 29(2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form XXIII\r\n[See Rule 29 (2)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form - XXV\r\n[See Rule 29(6)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Act Name": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Rule / Form": "Form - E\r\n[See sub-rule (2) of Rule 4]\r\nRegister of Fines and Unpaid Accumulations for the Year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Act Name": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Rule / Form": "FORM-III\r\n[See sub-rule (1) of Rule 6]\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XII\r\n[See Rule 18 (4)]\r\nRegister of advances of wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - II\r\nINTEGRATED REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XI\r\n[See Rule 17(4)]\r\nRegister of Deductions for the Damage or Loss caused to the employer by the neglect or default of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form - X\r\n[See Rule 17(3) (a)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Act Name": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Rule / Form": "Form - D\r\n[See Sub-Rule (1) of Rule 4]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER PART 1",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER\r\nMUSTER ROLL-CUM REGISTER OF WAGES/DEDUCTIONS/OVERTIME/ADVANCES\r\n(Part II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form XXII\r\n[See Rule 29 (1)]\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The A.P Minimum Wages Rules, 1960",
            "Act Name": "The A.P Minimum Wages Rules, 1960",
            "Rule / Form": "Form IV\r\n[Rule 29(2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form XXIII\r\n[See Rule 29 (2)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form - XXV\r\n[See Rule 29(6)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Act Name": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Rule / Form": "Form - E\r\n[See sub-rule (2) of Rule 4]\r\nRegister of Fines and Unpaid Accumulations for the Year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Act Name": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Rule / Form": "FORM-III\r\n[See sub-rule (1) of Rule 6]\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XII\r\n[See Rule 18 (4)]\r\nRegister of advances of wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - II\r\nINTEGRATED REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XI\r\n[See Rule 17(4)]\r\nRegister of Deductions for the Damage or Loss caused to the employer by the neglect or default of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form - X\r\n[See Rule 17(3) (a)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Act Name": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Rule / Form": "Form - D\r\n[See Sub-Rule (1) of Rule 4]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER PART 1",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER\r\nMUSTER ROLL-CUM REGISTER OF WAGES/DEDUCTIONS/OVERTIME/ADVANCES\r\n(Part II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form XXII\r\n[See Rule 29 (1)]\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The A.P Minimum Wages Rules, 1960",
            "Act Name": "The A.P Minimum Wages Rules, 1960",
            "Rule / Form": "Form IV\r\n[Rule 29(2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form XXIII\r\n[See Rule 29 (2)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form - XXV\r\n[See Rule 29(6)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Act Name": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Rule / Form": "Form - E\r\n[See sub-rule (2) of Rule 4]\r\nRegister of Fines and Unpaid Accumulations for the Year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Act Name": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Rule / Form": "FORM-III\r\n[See sub-rule (1) of Rule 6]\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XII\r\n[See Rule 18 (4)]\r\nRegister of advances of wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - II\r\nINTEGRATED REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XI\r\n[See Rule 17(4)]\r\nRegister of Deductions for the Damage or Loss caused to the employer by the neglect or default of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form - X\r\n[See Rule 17(3) (a)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Act Name": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Rule / Form": "Form - D\r\n[See Sub-Rule (1) of Rule 4]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER PART 1",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER\r\nMUSTER ROLL-CUM REGISTER OF WAGES/DEDUCTIONS/OVERTIME/ADVANCES\r\n(Part II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form XXII\r\n[See Rule 29 (1)]\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The A.P Minimum Wages Rules, 1960",
            "Act Name": "The A.P Minimum Wages Rules, 1960",
            "Rule / Form": "Form IV\r\n[Rule 29(2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form XXIII\r\n[See Rule 29 (2)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form - XXV\r\n[See Rule 29(6)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Act Name": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Rule / Form": "Form - E\r\n[See sub-rule (2) of Rule 4]\r\nRegister of Fines and Unpaid Accumulations for the Year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Act Name": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Rule / Form": "FORM-III\r\n[See sub-rule (1) of Rule 6]\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XII\r\n[See Rule 18 (4)]\r\nRegister of advances of wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - II\r\nINTEGRATED REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XI\r\n[See Rule 17(4)]\r\nRegister of Deductions for the Damage or Loss caused to the employer by the neglect or default of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form - X\r\n[See Rule 17(3) (a)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Act Name": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Rule / Form": "Form - D\r\n[See Sub-Rule (1) of Rule 4]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER PART 1",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER\r\nMUSTER ROLL-CUM REGISTER OF WAGES/DEDUCTIONS/OVERTIME/ADVANCES\r\n(Part II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form XXII\r\n[See Rule 29 (1)]\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The A.P Minimum Wages Rules, 1960",
            "Act Name": "The A.P Minimum Wages Rules, 1960",
            "Rule / Form": "Form IV\r\n[Rule 29(2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form XXIII\r\n[See Rule 29 (2)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form - XXV\r\n[See Rule 29(6)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Act Name": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Rule / Form": "Form - E\r\n[See sub-rule (2) of Rule 4]\r\nRegister of Fines and Unpaid Accumulations for the Year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Act Name": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Rule / Form": "FORM-III\r\n[See sub-rule (1) of Rule 6]\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XII\r\n[See Rule 18 (4)]\r\nRegister of advances of wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - II\r\nINTEGRATED REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XI\r\n[See Rule 17(4)]\r\nRegister of Deductions for the Damage or Loss caused to the employer by the neglect or default of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form - X\r\n[See Rule 17(3) (a)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Act Name": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Rule / Form": "Form - D\r\n[See Sub-Rule (1) of Rule 4]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER PART 1",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER\r\nMUSTER ROLL-CUM REGISTER OF WAGES/DEDUCTIONS/OVERTIME/ADVANCES\r\n(Part II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form XXII\r\n[See Rule 29 (1)]\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The A.P Minimum Wages Rules, 1960",
            "Act Name": "The A.P Minimum Wages Rules, 1960",
            "Rule / Form": "Form IV\r\n[Rule 29(2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form XXIII\r\n[See Rule 29 (2)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form - XXV\r\n[See Rule 29(6)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Act Name": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Rule / Form": "Form - E\r\n[See sub-rule (2) of Rule 4]\r\nRegister of Fines and Unpaid Accumulations for the Year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Act Name": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Rule / Form": "FORM-III\r\n[See sub-rule (1) of Rule 6]\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XII\r\n[See Rule 18 (4)]\r\nRegister of advances of wage",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - II\r\nINTEGRATED REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Telangana Shops and Establishment Rules, 1990",
            "Act Name": "The Telangana Shops and Establishment Rules, 1990",
            "Rule / Form": "Form - XI\r\n[See Rule 17(4)]\r\nRegister of Deductions for the Damage or Loss caused to the employer by the neglect or default of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form - X\r\n[See Rule 17(3) (a)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Act Name": "A.P. Labour Welfare Fund Act, 1987 & Telangana Rules, 1988",
            "Rule / Form": "Form - D\r\n[See Sub-Rule (1) of Rule 4]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER PART 1",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "FORM-III\r\nINTEGRATED REGISTER\r\nMUSTER ROLL-CUM REGISTER OF WAGES/DEDUCTIONS/OVERTIME/ADVANCES\r\n(Part II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Telangana Shops and Establishments Rules, 1990",
            "Act Name": "The Telangana Shops and Establishments Rules, 1990",
            "Rule / Form": "Form XXII\r\n[See Rule 29 (1)]\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The A.P Minimum Wages Rules, 1960",
            "Act Name": "The A.P Minimum Wages Rules, 1960",
            "Rule / Form": "Form IV\r\n[Rule 29(2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form XXIII\r\n[See Rule 29 (2)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Telangana Shops & Establishments Rules, 1990",
            "Act Name": "The Telangana Shops & Establishments Rules, 1990",
            "Rule / Form": "Form - XXV\r\n[See Rule 29(6)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Act Name": "The A.P Labour Welfare Fund Act, 1987 and Telangana Rules, 1988",
            "Rule / Form": "Form - E\r\n[See sub-rule (2) of Rule 4]\r\nRegister of Fines and Unpaid Accumulations for the Year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Act Name": "The Andhra Pradesh Factories and Establishments (National, Festival and other Holidays) Rules, 1974",
            "Rule / Form": "FORM-III\r\n[See sub-rule (1) of Rule 6]\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Telangana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - P\r\n(See sub rule(4) of Rule 11)\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Rule / Form": "Form - Q\r\n(See sub-rule (1) of Rule 16)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Act Name": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Rule / Form": "Form IV\r\n[Rule 26 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Act Name": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Rule / Form": "FORM 2\r\n[See Rule 4 (1)]\r\nRegisters for Child Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Act Name": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Rule / Form": "Form 1\r\n(See rule 3)\r\nRegister of employees placed under suspension",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The T.N. Shops and Establishments Rules, 1948",
            "Act Name": "The T.N. Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - R\r\n(See sub-rule (5) of rule 11)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "Form \" B \"\r\n(See rule 29)\r\nRegister of wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "FORM \" C \"\r\n(See rule 29)\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Sub-rule (1) of rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Act Name": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Rule / Form": "Form No. VI\r\n(See sub-rule (1) of rule 7)\r\nRegister of National, Festival and Special Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Act Name": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Rule / Form": "Form 1\r\n[See sub-rule (1) under rule 6 of the Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981]\r\nRegister of Workmen\r\n(To be maintained by the employer of Industrial establishment)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - P\r\n(See sub rule(4) of Rule 11)\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Rule / Form": "Form - Q\r\n(See sub-rule (1) of Rule 16)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Act Name": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Rule / Form": "Form IV\r\n[Rule 26 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Act Name": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Rule / Form": "FORM 2\r\n[See Rule 4 (1)]\r\nRegisters for Child Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Act Name": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Rule / Form": "Form 1\r\n(See rule 3)\r\nRegister of employees placed under suspension",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The T.N. Shops and Establishments Rules, 1948",
            "Act Name": "The T.N. Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - R\r\n(See sub-rule (5) of rule 11)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "Form \" B \"\r\n(See rule 29)\r\nRegister of wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "FORM \" C \"\r\n(See rule 29)\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Sub-rule (1) of rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Act Name": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Rule / Form": "Form No. VI\r\n(See sub-rule (1) of rule 7)\r\nRegister of National, Festival and Special Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Act Name": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Rule / Form": "Form 1\r\n[See sub-rule (1) under rule 6 of the Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981]\r\nRegister of Workmen\r\n(To be maintained by the employer of Industrial establishment)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - P\r\n(See sub rule(4) of Rule 11)\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Rule / Form": "Form - Q\r\n(See sub-rule (1) of Rule 16)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Act Name": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Rule / Form": "Form IV\r\n[Rule 26 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Act Name": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Rule / Form": "FORM 2\r\n[See Rule 4 (1)]\r\nRegisters for Child Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Act Name": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Rule / Form": "Form 1\r\n(See rule 3)\r\nRegister of employees placed under suspension",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The T.N. Shops and Establishments Rules, 1948",
            "Act Name": "The T.N. Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - R\r\n(See sub-rule (5) of rule 11)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "Form \" B \"\r\n(See rule 29)\r\nRegister of wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "FORM \" C \"\r\n(See rule 29)\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Sub-rule (1) of rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Act Name": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Rule / Form": "Form No. VI\r\n(See sub-rule (1) of rule 7)\r\nRegister of National, Festival and Special Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Act Name": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Rule / Form": "Form 1\r\n[See sub-rule (1) under rule 6 of the Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981]\r\nRegister of Workmen\r\n(To be maintained by the employer of Industrial establishment)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - P\r\n(See sub rule(4) of Rule 11)\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Rule / Form": "Form - Q\r\n(See sub-rule (1) of Rule 16)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Act Name": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Rule / Form": "Form IV\r\n[Rule 26 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Act Name": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Rule / Form": "FORM 2\r\n[See Rule 4 (1)]\r\nRegisters for Child Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Act Name": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Rule / Form": "Form 1\r\n(See rule 3)\r\nRegister of employees placed under suspension",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The T.N. Shops and Establishments Rules, 1948",
            "Act Name": "The T.N. Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - R\r\n(See sub-rule (5) of rule 11)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "Form \" B \"\r\n(See rule 29)\r\nRegister of wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "FORM \" C \"\r\n(See rule 29)\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Sub-rule (1) of rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Act Name": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Rule / Form": "Form No. VI\r\n(See sub-rule (1) of rule 7)\r\nRegister of National, Festival and Special Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Act Name": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Rule / Form": "Form 1\r\n[See sub-rule (1) under rule 6 of the Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981]\r\nRegister of Workmen\r\n(To be maintained by the employer of Industrial establishment)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - P\r\n(See sub rule(4) of Rule 11)\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Rule / Form": "Form - Q\r\n(See sub-rule (1) of Rule 16)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Act Name": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Rule / Form": "Form IV\r\n[Rule 26 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Act Name": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Rule / Form": "FORM 2\r\n[See Rule 4 (1)]\r\nRegisters for Child Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Act Name": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Rule / Form": "Form 1\r\n(See rule 3)\r\nRegister of employees placed under suspension",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The T.N. Shops and Establishments Rules, 1948",
            "Act Name": "The T.N. Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - R\r\n(See sub-rule (5) of rule 11)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "Form \" B \"\r\n(See rule 29)\r\nRegister of wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "FORM \" C \"\r\n(See rule 29)\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Sub-rule (1) of rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Act Name": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Rule / Form": "Form No. VI\r\n(See sub-rule (1) of rule 7)\r\nRegister of National, Festival and Special Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Act Name": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Rule / Form": "Form 1\r\n[See sub-rule (1) under rule 6 of the Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981]\r\nRegister of Workmen\r\n(To be maintained by the employer of Industrial establishment)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - P\r\n(See sub rule(4) of Rule 11)\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Rule / Form": "Form - Q\r\n(See sub-rule (1) of Rule 16)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Act Name": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Rule / Form": "Form IV\r\n[Rule 26 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Act Name": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Rule / Form": "FORM 2\r\n[See Rule 4 (1)]\r\nRegisters for Child Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Act Name": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Rule / Form": "Form 1\r\n(See rule 3)\r\nRegister of employees placed under suspension",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The T.N. Shops and Establishments Rules, 1948",
            "Act Name": "The T.N. Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - R\r\n(See sub-rule (5) of rule 11)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "Form \" B \"\r\n(See rule 29)\r\nRegister of wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "FORM \" C \"\r\n(See rule 29)\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Sub-rule (1) of rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Act Name": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Rule / Form": "Form No. VI\r\n(See sub-rule (1) of rule 7)\r\nRegister of National, Festival and Special Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Act Name": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Rule / Form": "Form 1\r\n[See sub-rule (1) under rule 6 of the Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981]\r\nRegister of Workmen\r\n(To be maintained by the employer of Industrial establishment)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - P\r\n(See sub rule(4) of Rule 11)\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Rule / Form": "Form - Q\r\n(See sub-rule (1) of Rule 16)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Act Name": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Rule / Form": "Form IV\r\n[Rule 26 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Act Name": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Rule / Form": "FORM 2\r\n[See Rule 4 (1)]\r\nRegisters for Child Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Act Name": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Rule / Form": "Form 1\r\n(See rule 3)\r\nRegister of employees placed under suspension",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The T.N. Shops and Establishments Rules, 1948",
            "Act Name": "The T.N. Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - R\r\n(See sub-rule (5) of rule 11)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "Form \" B \"\r\n(See rule 29)\r\nRegister of wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "FORM \" C \"\r\n(See rule 29)\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Sub-rule (1) of rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Act Name": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Rule / Form": "Form No. VI\r\n(See sub-rule (1) of rule 7)\r\nRegister of National, Festival and Special Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Act Name": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Rule / Form": "Form 1\r\n[See sub-rule (1) under rule 6 of the Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981]\r\nRegister of Workmen\r\n(To be maintained by the employer of Industrial establishment)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - P\r\n(See sub rule(4) of Rule 11)\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Rule / Form": "Form - Q\r\n(See sub-rule (1) of Rule 16)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Act Name": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Rule / Form": "Form IV\r\n[Rule 26 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Act Name": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Rule / Form": "FORM 2\r\n[See Rule 4 (1)]\r\nRegisters for Child Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Act Name": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Rule / Form": "Form 1\r\n(See rule 3)\r\nRegister of employees placed under suspension",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The T.N. Shops and Establishments Rules, 1948",
            "Act Name": "The T.N. Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - R\r\n(See sub-rule (5) of rule 11)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "Form \" B \"\r\n(See rule 29)\r\nRegister of wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "FORM \" C \"\r\n(See rule 29)\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Sub-rule (1) of rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Act Name": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Rule / Form": "Form No. VI\r\n(See sub-rule (1) of rule 7)\r\nRegister of National, Festival and Special Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Act Name": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Rule / Form": "Form 1\r\n[See sub-rule (1) under rule 6 of the Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981]\r\nRegister of Workmen\r\n(To be maintained by the employer of Industrial establishment)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - P\r\n(See sub rule(4) of Rule 11)\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Rule / Form": "Form - Q\r\n(See sub-rule (1) of Rule 16)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Act Name": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Rule / Form": "Form IV\r\n[Rule 26 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Act Name": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Rule / Form": "FORM 2\r\n[See Rule 4 (1)]\r\nRegisters for Child Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Act Name": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Rule / Form": "Form 1\r\n(See rule 3)\r\nRegister of employees placed under suspension",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The T.N. Shops and Establishments Rules, 1948",
            "Act Name": "The T.N. Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - R\r\n(See sub-rule (5) of rule 11)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "Form \" B \"\r\n(See rule 29)\r\nRegister of wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "FORM \" C \"\r\n(See rule 29)\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Sub-rule (1) of rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Act Name": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Rule / Form": "Form No. VI\r\n(See sub-rule (1) of rule 7)\r\nRegister of National, Festival and Special Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Act Name": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Rule / Form": "Form 1\r\n[See sub-rule (1) under rule 6 of the Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981]\r\nRegister of Workmen\r\n(To be maintained by the employer of Industrial establishment)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - P\r\n(See sub rule(4) of Rule 11)\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Rule / Form": "Form - Q\r\n(See sub-rule (1) of Rule 16)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Act Name": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Rule / Form": "Form IV\r\n[Rule 26 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Act Name": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Rule / Form": "FORM 2\r\n[See Rule 4 (1)]\r\nRegisters for Child Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Act Name": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Rule / Form": "Form 1\r\n(See rule 3)\r\nRegister of employees placed under suspension",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The T.N. Shops and Establishments Rules, 1948",
            "Act Name": "The T.N. Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - R\r\n(See sub-rule (5) of rule 11)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "Form \" B \"\r\n(See rule 29)\r\nRegister of wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "FORM \" C \"\r\n(See rule 29)\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Sub-rule (1) of rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Act Name": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Rule / Form": "Form No. VI\r\n(See sub-rule (1) of rule 7)\r\nRegister of National, Festival and Special Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Act Name": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Rule / Form": "Form 1\r\n[See sub-rule (1) under rule 6 of the Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981]\r\nRegister of Workmen\r\n(To be maintained by the employer of Industrial establishment)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - P\r\n(See sub rule(4) of Rule 11)\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Rule / Form": "Form - Q\r\n(See sub-rule (1) of Rule 16)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Act Name": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Rule / Form": "Form IV\r\n[Rule 26 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Act Name": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Rule / Form": "FORM 2\r\n[See Rule 4 (1)]\r\nRegisters for Child Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Act Name": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Rule / Form": "Form 1\r\n(See rule 3)\r\nRegister of employees placed under suspension",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The T.N. Shops and Establishments Rules, 1948",
            "Act Name": "The T.N. Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - R\r\n(See sub-rule (5) of rule 11)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "Form \" B \"\r\n(See rule 29)\r\nRegister of wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "FORM \" C \"\r\n(See rule 29)\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Sub-rule (1) of rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Act Name": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Rule / Form": "Form No. VI\r\n(See sub-rule (1) of rule 7)\r\nRegister of National, Festival and Special Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Act Name": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Rule / Form": "Form 1\r\n[See sub-rule (1) under rule 6 of the Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981]\r\nRegister of Workmen\r\n(To be maintained by the employer of Industrial establishment)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - P\r\n(See sub rule(4) of Rule 11)\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Act Name": "The Tamil Nadu Shops and Establishment Rules, 1948",
            "Rule / Form": "Form - Q\r\n(See sub-rule (1) of Rule 16)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Act Name": "The Minimum Wages (Tamil Nadu) Rules, 1953",
            "Rule / Form": "Form IV\r\n[Rule 26 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Act Name": "The Tamil Nadu Child Labour (Prohibition and Regulation) Rules, 1994",
            "Rule / Form": "FORM 2\r\n[See Rule 4 (1)]\r\nRegisters for Child Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Act Name": "The Tamil Nadu Payment of Subsistence Allowance Rules, 1981",
            "Rule / Form": "Form 1\r\n(See rule 3)\r\nRegister of employees placed under suspension",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The T.N. Shops and Establishments Rules, 1948",
            "Act Name": "The T.N. Shops and Establishments Rules, 1948",
            "Rule / Form": "Form - R\r\n(See sub-rule (5) of rule 11)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "The Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "Form \" B \"\r\n(See rule 29)\r\nRegister of wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Act Name": "Tamilnadu Labour Welfare Fund Act, 1972 and Rules, 1973",
            "Rule / Form": "FORM \" C \"\r\n(See rule 29)\r\nRegister of fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 And (Tamil Nadu) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Sub-rule (1) of rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Act Name": "The Tamil Nadu Industrial Establishments (National, Festival and Special Holidays) Act, 1958",
            "Rule / Form": "Form No. VI\r\n(See sub-rule (1) of rule 7)\r\nRegister of National, Festival and Special Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Act Name": "The Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981",
            "Rule / Form": "Form 1\r\n[See sub-rule (1) under rule 6 of the Tamil Nadu Industrial Establishments (Conferment of Permanent Status to Workmen) Rules, 1981]\r\nRegister of Workmen\r\n(To be maintained by the employer of Industrial establishment)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Tamilnadu"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Rajasthan Payment of Wages Rules, 1961",
            "Act Name": "The Rajasthan Payment of Wages Rules, 1961",
            "Rule / Form": "Form III\r\n[See Rule 18]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form II\r\n[ See Rule 21(4) ]\r\nRegister of deductions for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Rule / Form": "FORM 12\r\n(See rule 22)\r\n[Sub Rule (1)]\r\nRegister of Employment (Where opening and closing hours are ordinarily uniform)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime register of workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form X\r\n[See Rule 26 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Rule / Form": "FORM 8\r\n(See rules 13 & 14)\r\nRegister of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Rule / Form": "Muster Roll\r\n(See Rule 20)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Rajasthan Payment of Wages Rules, 1961",
            "Act Name": "The Rajasthan Payment of Wages Rules, 1961",
            "Rule / Form": "Form III\r\n[See Rule 18]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form II\r\n[ See Rule 21(4) ]\r\nRegister of deductions for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Rule / Form": "FORM 12\r\n(See rule 22)\r\n[Sub Rule (1)]\r\nRegister of Employment (Where opening and closing hours are ordinarily uniform)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime register of workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form X\r\n[See Rule 26 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Rule / Form": "FORM 8\r\n(See rules 13 & 14)\r\nRegister of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Rule / Form": "Muster Roll\r\n(See Rule 20)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Rajasthan Payment of Wages Rules, 1961",
            "Act Name": "The Rajasthan Payment of Wages Rules, 1961",
            "Rule / Form": "Form III\r\n[See Rule 18]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form II\r\n[ See Rule 21(4) ]\r\nRegister of deductions for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Rule / Form": "FORM 12\r\n(See rule 22)\r\n[Sub Rule (1)]\r\nRegister of Employment (Where opening and closing hours are ordinarily uniform)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime register of workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form X\r\n[See Rule 26 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Rule / Form": "FORM 8\r\n(See rules 13 & 14)\r\nRegister of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Rule / Form": "Muster Roll\r\n(See Rule 20)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Rajasthan Payment of Wages Rules, 1961",
            "Act Name": "The Rajasthan Payment of Wages Rules, 1961",
            "Rule / Form": "Form III\r\n[See Rule 18]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form II\r\n[ See Rule 21(4) ]\r\nRegister of deductions for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Rule / Form": "FORM 12\r\n(See rule 22)\r\n[Sub Rule (1)]\r\nRegister of Employment (Where opening and closing hours are ordinarily uniform)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime register of workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form X\r\n[See Rule 26 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Rule / Form": "FORM 8\r\n(See rules 13 & 14)\r\nRegister of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Rule / Form": "Muster Roll\r\n(See Rule 20)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Rajasthan Payment of Wages Rules, 1961",
            "Act Name": "The Rajasthan Payment of Wages Rules, 1961",
            "Rule / Form": "Form III\r\n[See Rule 18]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form II\r\n[ See Rule 21(4) ]\r\nRegister of deductions for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Rule / Form": "FORM 12\r\n(See rule 22)\r\n[Sub Rule (1)]\r\nRegister of Employment (Where opening and closing hours are ordinarily uniform)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime register of workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form X\r\n[See Rule 26 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Rule / Form": "FORM 8\r\n(See rules 13 & 14)\r\nRegister of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Rule / Form": "Muster Roll\r\n(See Rule 20)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Rajasthan Payment of Wages Rules, 1961",
            "Act Name": "The Rajasthan Payment of Wages Rules, 1961",
            "Rule / Form": "Form III\r\n[See Rule 18]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form II\r\n[ See Rule 21(4) ]\r\nRegister of deductions for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Rule / Form": "FORM 12\r\n(See rule 22)\r\n[Sub Rule (1)]\r\nRegister of Employment (Where opening and closing hours are ordinarily uniform)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime register of workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form X\r\n[See Rule 26 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Rule / Form": "FORM 8\r\n(See rules 13 & 14)\r\nRegister of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Rule / Form": "Muster Roll\r\n(See Rule 20)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Rajasthan Payment of Wages Rules, 1961",
            "Act Name": "The Rajasthan Payment of Wages Rules, 1961",
            "Rule / Form": "Form III\r\n[See Rule 18]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form II\r\n[ See Rule 21(4) ]\r\nRegister of deductions for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Rule / Form": "FORM 12\r\n(See rule 22)\r\n[Sub Rule (1)]\r\nRegister of Employment (Where opening and closing hours are ordinarily uniform)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime register of workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form X\r\n[See Rule 26 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Rule / Form": "FORM 8\r\n(See rules 13 & 14)\r\nRegister of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Rule / Form": "Muster Roll\r\n(See Rule 20)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Rajasthan Payment of Wages Rules, 1961",
            "Act Name": "The Rajasthan Payment of Wages Rules, 1961",
            "Rule / Form": "Form III\r\n[See Rule 18]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form II\r\n[ See Rule 21(4) ]\r\nRegister of deductions for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Rule / Form": "FORM 12\r\n(See rule 22)\r\n[Sub Rule (1)]\r\nRegister of Employment (Where opening and closing hours are ordinarily uniform)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime register of workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form X\r\n[See Rule 26 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Rule / Form": "FORM 8\r\n(See rules 13 & 14)\r\nRegister of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Rule / Form": "Muster Roll\r\n(See Rule 20)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Rajasthan Payment of Wages Rules, 1961",
            "Act Name": "The Rajasthan Payment of Wages Rules, 1961",
            "Rule / Form": "Form III\r\n[See Rule 18]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form II\r\n[ See Rule 21(4) ]\r\nRegister of deductions for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Rule / Form": "FORM 12\r\n(See rule 22)\r\n[Sub Rule (1)]\r\nRegister of Employment (Where opening and closing hours are ordinarily uniform)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime register of workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form X\r\n[See Rule 26 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Rule / Form": "FORM 8\r\n(See rules 13 & 14)\r\nRegister of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Rule / Form": "Muster Roll\r\n(See Rule 20)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Rajasthan Payment of Wages Rules, 1961",
            "Act Name": "The Rajasthan Payment of Wages Rules, 1961",
            "Rule / Form": "Form III\r\n[See Rule 18]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form II\r\n[ See Rule 21(4) ]\r\nRegister of deductions for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Rule / Form": "FORM 12\r\n(See rule 22)\r\n[Sub Rule (1)]\r\nRegister of Employment (Where opening and closing hours are ordinarily uniform)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime register of workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form X\r\n[See Rule 26 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Rule / Form": "FORM 8\r\n(See rules 13 & 14)\r\nRegister of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Rule / Form": "Muster Roll\r\n(See Rule 20)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Rajasthan Payment of Wages Rules, 1961",
            "Act Name": "The Rajasthan Payment of Wages Rules, 1961",
            "Rule / Form": "Form III\r\n[See Rule 18]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form II\r\n[ See Rule 21(4) ]\r\nRegister of deductions for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Rule / Form": "FORM 12\r\n(See rule 22)\r\n[Sub Rule (1)]\r\nRegister of Employment (Where opening and closing hours are ordinarily uniform)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime register of workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form X\r\n[See Rule 26 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Rule / Form": "FORM 8\r\n(See rules 13 & 14)\r\nRegister of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Rule / Form": "Muster Roll\r\n(See Rule 20)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Rajasthan Payment of Wages Rules, 1961",
            "Act Name": "The Rajasthan Payment of Wages Rules, 1961",
            "Rule / Form": "Form III\r\n[See Rule 18]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form II\r\n[ See Rule 21(4) ]\r\nRegister of deductions for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Act, 1959",
            "Rule / Form": "FORM 12\r\n(See rule 22)\r\n[Sub Rule (1)]\r\nRegister of Employment (Where opening and closing hours are ordinarily uniform)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime register of workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Minimum Wages Rules",
            "Act Name": "The Minimum Wages Rules",
            "Rule / Form": "Form X\r\n[See Rule 26 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishments Rules, 1959",
            "Rule / Form": "FORM 8\r\n(See rules 13 & 14)\r\nRegister of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Act Name": "The Rajasthan Shops and Commercial Establishment Rules, 1959",
            "Rule / Form": "Muster Roll\r\n(See Rule 20)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Rajasthan"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Act Name": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Rule / Form": "Form A\r\n[See Rule 4 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Act Name": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Act Name": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Rule / Form": "Form A\r\n[See Rule 4 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Act Name": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Act Name": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Rule / Form": "Form A\r\n[See Rule 4 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Act Name": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Act Name": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Rule / Form": "Form A\r\n[See Rule 4 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Act Name": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Act Name": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Rule / Form": "Form A\r\n[See Rule 4 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Act Name": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Act Name": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Rule / Form": "Form A\r\n[See Rule 4 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Act Name": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Act Name": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Rule / Form": "Form A\r\n[See Rule 4 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Act Name": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Act Name": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Rule / Form": "Form A\r\n[See Rule 4 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Act Name": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Act Name": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Rule / Form": "Form A\r\n[See Rule 4 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Act Name": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Act Name": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Rule / Form": "Form A\r\n[See Rule 4 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Act Name": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Act Name": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Rule / Form": "Form A\r\n[See Rule 4 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Act Name": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Act Name": "The Punjab Child Labour (Prohibition And Regulation) Rules, 1997",
            "Rule / Form": "Form A\r\n[See Rule 4 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Act Name": "The Maternity Banefit Act, 1961 and (Punjab) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Punjab"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VI\r\n[See sub rule(4) of Rule 17 and sub-rule (4) of rule19]\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VIII\r\n(See sub-rule (1) of Rule 22)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Act Name": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Rule / Form": "Form - VII\r\n(See sub-rule (1) of rule 8)\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Minimum Wages (Puducherry) Rules, 1964",
            "Act Name": "The Minimum Wages (Puducherry) Rules, 1964",
            "Rule / Form": "Form IV\r\n[ Rule 27(2) ]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 16 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - X\r\n(See sub-rule (5) of rule 17)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VI\r\n[See sub rule(4) of Rule 17 and sub-rule (4) of rule19]\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VIII\r\n(See sub-rule (1) of Rule 22)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Act Name": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Rule / Form": "Form - VII\r\n(See sub-rule (1) of rule 8)\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Minimum Wages (Puducherry) Rules, 1964",
            "Act Name": "The Minimum Wages (Puducherry) Rules, 1964",
            "Rule / Form": "Form IV\r\n[ Rule 27(2) ]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 16 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - X\r\n(See sub-rule (5) of rule 17)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VI\r\n[See sub rule(4) of Rule 17 and sub-rule (4) of rule19]\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VIII\r\n(See sub-rule (1) of Rule 22)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Act Name": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Rule / Form": "Form - VII\r\n(See sub-rule (1) of rule 8)\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Minimum Wages (Puducherry) Rules, 1964",
            "Act Name": "The Minimum Wages (Puducherry) Rules, 1964",
            "Rule / Form": "Form IV\r\n[ Rule 27(2) ]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 16 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - X\r\n(See sub-rule (5) of rule 17)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VI\r\n[See sub rule(4) of Rule 17 and sub-rule (4) of rule19]\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VIII\r\n(See sub-rule (1) of Rule 22)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Act Name": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Rule / Form": "Form - VII\r\n(See sub-rule (1) of rule 8)\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Minimum Wages (Puducherry) Rules, 1964",
            "Act Name": "The Minimum Wages (Puducherry) Rules, 1964",
            "Rule / Form": "Form IV\r\n[ Rule 27(2) ]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 16 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - X\r\n(See sub-rule (5) of rule 17)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VI\r\n[See sub rule(4) of Rule 17 and sub-rule (4) of rule19]\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VIII\r\n(See sub-rule (1) of Rule 22)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Act Name": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Rule / Form": "Form - VII\r\n(See sub-rule (1) of rule 8)\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Minimum Wages (Puducherry) Rules, 1964",
            "Act Name": "The Minimum Wages (Puducherry) Rules, 1964",
            "Rule / Form": "Form IV\r\n[ Rule 27(2) ]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 16 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - X\r\n(See sub-rule (5) of rule 17)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VI\r\n[See sub rule(4) of Rule 17 and sub-rule (4) of rule19]\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VIII\r\n(See sub-rule (1) of Rule 22)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Act Name": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Rule / Form": "Form - VII\r\n(See sub-rule (1) of rule 8)\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Minimum Wages (Puducherry) Rules, 1964",
            "Act Name": "The Minimum Wages (Puducherry) Rules, 1964",
            "Rule / Form": "Form IV\r\n[ Rule 27(2) ]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 16 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - X\r\n(See sub-rule (5) of rule 17)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VI\r\n[See sub rule(4) of Rule 17 and sub-rule (4) of rule19]\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VIII\r\n(See sub-rule (1) of Rule 22)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Act Name": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Rule / Form": "Form - VII\r\n(See sub-rule (1) of rule 8)\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Minimum Wages (Puducherry) Rules, 1964",
            "Act Name": "The Minimum Wages (Puducherry) Rules, 1964",
            "Rule / Form": "Form IV\r\n[ Rule 27(2) ]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 16 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - X\r\n(See sub-rule (5) of rule 17)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VI\r\n[See sub rule(4) of Rule 17 and sub-rule (4) of rule19]\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VIII\r\n(See sub-rule (1) of Rule 22)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Act Name": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Rule / Form": "Form - VII\r\n(See sub-rule (1) of rule 8)\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Minimum Wages (Puducherry) Rules, 1964",
            "Act Name": "The Minimum Wages (Puducherry) Rules, 1964",
            "Rule / Form": "Form IV\r\n[ Rule 27(2) ]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 16 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - X\r\n(See sub-rule (5) of rule 17)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VI\r\n[See sub rule(4) of Rule 17 and sub-rule (4) of rule19]\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VIII\r\n(See sub-rule (1) of Rule 22)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Act Name": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Rule / Form": "Form - VII\r\n(See sub-rule (1) of rule 8)\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Minimum Wages (Puducherry) Rules, 1964",
            "Act Name": "The Minimum Wages (Puducherry) Rules, 1964",
            "Rule / Form": "Form IV\r\n[ Rule 27(2) ]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 16 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - X\r\n(See sub-rule (5) of rule 17)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VI\r\n[See sub rule(4) of Rule 17 and sub-rule (4) of rule19]\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VIII\r\n(See sub-rule (1) of Rule 22)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Act Name": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Rule / Form": "Form - VII\r\n(See sub-rule (1) of rule 8)\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Minimum Wages (Puducherry) Rules, 1964",
            "Act Name": "The Minimum Wages (Puducherry) Rules, 1964",
            "Rule / Form": "Form IV\r\n[ Rule 27(2) ]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 16 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - X\r\n(See sub-rule (5) of rule 17)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VI\r\n[See sub rule(4) of Rule 17 and sub-rule (4) of rule19]\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VIII\r\n(See sub-rule (1) of Rule 22)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Act Name": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Rule / Form": "Form - VII\r\n(See sub-rule (1) of rule 8)\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Minimum Wages (Puducherry) Rules, 1964",
            "Act Name": "The Minimum Wages (Puducherry) Rules, 1964",
            "Rule / Form": "Form IV\r\n[ Rule 27(2) ]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 16 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - X\r\n(See sub-rule (5) of rule 17)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VI\r\n[See sub rule(4) of Rule 17 and sub-rule (4) of rule19]\r\nRegister of Advances Paid, Deductions for Damages or Losses and Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - VIII\r\n(See sub-rule (1) of Rule 22)\r\nRegister of Employment",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Act Name": "The Puducherry Industrial Establishments (National and Festival Holidays) Rules, 1964",
            "Rule / Form": "Form - VII\r\n(See sub-rule (1) of rule 8)\r\nRegister of National and Festival Holidays",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Minimum Wages (Puducherry) Rules, 1964",
            "Act Name": "The Minimum Wages (Puducherry) Rules, 1964",
            "Rule / Form": "Form IV\r\n[ Rule 27(2) ]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Act Name": "The Puducherry Child Labour (Prohibition and Regulation) Rules, 1995",
            "Rule / Form": "Form A\r\n[See Rule 16 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Act Name": "The Puducherry Shops and Establishments (Amendment) Rules, 2010",
            "Rule / Form": "Form - X\r\n(See sub-rule (5) of rule 17)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Act Name": "The Maternity Benefit Act, 1961 and (Mines & Circus) Rules, 1963",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Puducherry"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form - II\r\nRule 21(4)\r\nRegister of deductions for Damage or Loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\nRule 21 (4)\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - D\r\n[See Rule 27 (1) (a)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form H\r\n[Rule 14]\r\nRegister of Employment and Remuneration of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form V\r\n[Rule 26(5)]\r\nMUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form X\r\nRegister of Wages\r\n[See Rule 26 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form I\r\n[ See Rule 14]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - E\r\n[See Rule 27 (1) (b)]\r\nRegister for Fines and Unpaid Accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form - II\r\nRule 21(4)\r\nRegister of deductions for Damage or Loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\nRule 21 (4)\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - D\r\n[See Rule 27 (1) (a)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form H\r\n[Rule 14]\r\nRegister of Employment and Remuneration of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form V\r\n[Rule 26(5)]\r\nMUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form X\r\nRegister of Wages\r\n[See Rule 26 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form I\r\n[ See Rule 14]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - E\r\n[See Rule 27 (1) (b)]\r\nRegister for Fines and Unpaid Accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form - II\r\nRule 21(4)\r\nRegister of deductions for Damage or Loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\nRule 21 (4)\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - D\r\n[See Rule 27 (1) (a)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form H\r\n[Rule 14]\r\nRegister of Employment and Remuneration of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form V\r\n[Rule 26(5)]\r\nMUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form X\r\nRegister of Wages\r\n[See Rule 26 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form I\r\n[ See Rule 14]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - E\r\n[See Rule 27 (1) (b)]\r\nRegister for Fines and Unpaid Accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form - II\r\nRule 21(4)\r\nRegister of deductions for Damage or Loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\nRule 21 (4)\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - D\r\n[See Rule 27 (1) (a)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form H\r\n[Rule 14]\r\nRegister of Employment and Remuneration of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form V\r\n[Rule 26(5)]\r\nMUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form X\r\nRegister of Wages\r\n[See Rule 26 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form I\r\n[ See Rule 14]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - E\r\n[See Rule 27 (1) (b)]\r\nRegister for Fines and Unpaid Accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form - II\r\nRule 21(4)\r\nRegister of deductions for Damage or Loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\nRule 21 (4)\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - D\r\n[See Rule 27 (1) (a)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form H\r\n[Rule 14]\r\nRegister of Employment and Remuneration of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form V\r\n[Rule 26(5)]\r\nMUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form X\r\nRegister of Wages\r\n[See Rule 26 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form I\r\n[ See Rule 14]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - E\r\n[See Rule 27 (1) (b)]\r\nRegister for Fines and Unpaid Accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form - II\r\nRule 21(4)\r\nRegister of deductions for Damage or Loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\nRule 21 (4)\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - D\r\n[See Rule 27 (1) (a)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form H\r\n[Rule 14]\r\nRegister of Employment and Remuneration of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form V\r\n[Rule 26(5)]\r\nMUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form X\r\nRegister of Wages\r\n[See Rule 26 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form I\r\n[ See Rule 14]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - E\r\n[See Rule 27 (1) (b)]\r\nRegister for Fines and Unpaid Accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form - II\r\nRule 21(4)\r\nRegister of deductions for Damage or Loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\nRule 21 (4)\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - D\r\n[See Rule 27 (1) (a)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form H\r\n[Rule 14]\r\nRegister of Employment and Remuneration of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form V\r\n[Rule 26(5)]\r\nMUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form X\r\nRegister of Wages\r\n[See Rule 26 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form I\r\n[ See Rule 14]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - E\r\n[See Rule 27 (1) (b)]\r\nRegister for Fines and Unpaid Accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form - II\r\nRule 21(4)\r\nRegister of deductions for Damage or Loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\nRule 21 (4)\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - D\r\n[See Rule 27 (1) (a)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form H\r\n[Rule 14]\r\nRegister of Employment and Remuneration of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form V\r\n[Rule 26(5)]\r\nMUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form X\r\nRegister of Wages\r\n[See Rule 26 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form I\r\n[ See Rule 14]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - E\r\n[See Rule 27 (1) (b)]\r\nRegister for Fines and Unpaid Accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form - II\r\nRule 21(4)\r\nRegister of deductions for Damage or Loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\nRule 21 (4)\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - D\r\n[See Rule 27 (1) (a)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form H\r\n[Rule 14]\r\nRegister of Employment and Remuneration of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form V\r\n[Rule 26(5)]\r\nMUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form X\r\nRegister of Wages\r\n[See Rule 26 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form I\r\n[ See Rule 14]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - E\r\n[See Rule 27 (1) (b)]\r\nRegister for Fines and Unpaid Accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form - II\r\nRule 21(4)\r\nRegister of deductions for Damage or Loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\nRule 21 (4)\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - D\r\n[See Rule 27 (1) (a)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form H\r\n[Rule 14]\r\nRegister of Employment and Remuneration of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form V\r\n[Rule 26(5)]\r\nMUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form X\r\nRegister of Wages\r\n[See Rule 26 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form I\r\n[ See Rule 14]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - E\r\n[See Rule 27 (1) (b)]\r\nRegister for Fines and Unpaid Accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form - II\r\nRule 21(4)\r\nRegister of deductions for Damage or Loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\nRule 21 (4)\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - D\r\n[See Rule 27 (1) (a)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form H\r\n[Rule 14]\r\nRegister of Employment and Remuneration of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form V\r\n[Rule 26(5)]\r\nMUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form X\r\nRegister of Wages\r\n[See Rule 26 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form I\r\n[ See Rule 14]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - E\r\n[See Rule 27 (1) (b)]\r\nRegister for Fines and Unpaid Accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form - II\r\nRule 21(4)\r\nRegister of deductions for Damage or Loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\nRule 21 (4)\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - D\r\n[See Rule 27 (1) (a)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form H\r\n[Rule 14]\r\nRegister of Employment and Remuneration of Employees",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form V\r\n[Rule 26(5)]\r\nMUSTER ROLL",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Delhi Minimum Wages Rules, 1950",
            "Act Name": "The Delhi Minimum Wages Rules, 1950",
            "Rule / Form": "Form X\r\nRegister of Wages\r\n[See Rule 26 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Delhi Shops and Establishments Rules, 1954",
            "Act Name": "The Delhi Shops and Establishments Rules, 1954",
            "Rule / Form": "Form I\r\n[ See Rule 14]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Delhi Labour Welfare Fund Rules, 1997",
            "Act Name": "The Delhi Labour Welfare Fund Rules, 1997",
            "Rule / Form": "FORM - E\r\n[See Rule 27 (1) (b)]\r\nRegister for Fines and Unpaid Accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Delhi"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Maharashtra Payment of Wages Rules, 1963",
            "Act Name": "The Maharashtra Payment of Wages Rules, 1963",
            "Rule / Form": "Form IV\r\n(See Rule 18)\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "Form - B\r\n(See Rule 21)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Act Name": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Rule / Form": "Form I\r\n[See Rule 12]\r\nRegister of Workmen",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form O\r\n[See Rule 19]\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form - P\r\n( See rule 20)\r\nNotice of Maximum Leave Accumulated",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "FORM \" C \"\r\n(See rule 21)\r\nRegister for fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Rule / Form": "Form 10\r\n(See Rule 12(1))\r\nMaternity Benefit Registers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Maharashtra Payment of Wages Rules, 1963",
            "Act Name": "The Maharashtra Payment of Wages Rules, 1963",
            "Rule / Form": "Form IV\r\n(See Rule 18)\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "Form - B\r\n(See Rule 21)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Act Name": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Rule / Form": "Form I\r\n[See Rule 12]\r\nRegister of Workmen",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form O\r\n[See Rule 19]\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form - P\r\n( See rule 20)\r\nNotice of Maximum Leave Accumulated",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "FORM \" C \"\r\n(See rule 21)\r\nRegister for fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Rule / Form": "Form 10\r\n(See Rule 12(1))\r\nMaternity Benefit Registers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Maharashtra Payment of Wages Rules, 1963",
            "Act Name": "The Maharashtra Payment of Wages Rules, 1963",
            "Rule / Form": "Form IV\r\n(See Rule 18)\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "Form - B\r\n(See Rule 21)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Act Name": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Rule / Form": "Form I\r\n[See Rule 12]\r\nRegister of Workmen",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form O\r\n[See Rule 19]\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form - P\r\n( See rule 20)\r\nNotice of Maximum Leave Accumulated",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "FORM \" C \"\r\n(See rule 21)\r\nRegister for fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Rule / Form": "Form 10\r\n(See Rule 12(1))\r\nMaternity Benefit Registers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Maharashtra Payment of Wages Rules, 1963",
            "Act Name": "The Maharashtra Payment of Wages Rules, 1963",
            "Rule / Form": "Form IV\r\n(See Rule 18)\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "Form - B\r\n(See Rule 21)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Act Name": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Rule / Form": "Form I\r\n[See Rule 12]\r\nRegister of Workmen",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form O\r\n[See Rule 19]\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form - P\r\n( See rule 20)\r\nNotice of Maximum Leave Accumulated",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "FORM \" C \"\r\n(See rule 21)\r\nRegister for fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Rule / Form": "Form 10\r\n(See Rule 12(1))\r\nMaternity Benefit Registers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Maharashtra Payment of Wages Rules, 1963",
            "Act Name": "The Maharashtra Payment of Wages Rules, 1963",
            "Rule / Form": "Form IV\r\n(See Rule 18)\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "Form - B\r\n(See Rule 21)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Act Name": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Rule / Form": "Form I\r\n[See Rule 12]\r\nRegister of Workmen",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form O\r\n[See Rule 19]\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form - P\r\n( See rule 20)\r\nNotice of Maximum Leave Accumulated",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "FORM \" C \"\r\n(See rule 21)\r\nRegister for fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Rule / Form": "Form 10\r\n(See Rule 12(1))\r\nMaternity Benefit Registers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Maharashtra Payment of Wages Rules, 1963",
            "Act Name": "The Maharashtra Payment of Wages Rules, 1963",
            "Rule / Form": "Form IV\r\n(See Rule 18)\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "Form - B\r\n(See Rule 21)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Act Name": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Rule / Form": "Form I\r\n[See Rule 12]\r\nRegister of Workmen",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form O\r\n[See Rule 19]\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form - P\r\n( See rule 20)\r\nNotice of Maximum Leave Accumulated",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "FORM \" C \"\r\n(See rule 21)\r\nRegister for fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Rule / Form": "Form 10\r\n(See Rule 12(1))\r\nMaternity Benefit Registers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Maharashtra Payment of Wages Rules, 1963",
            "Act Name": "The Maharashtra Payment of Wages Rules, 1963",
            "Rule / Form": "Form IV\r\n(See Rule 18)\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "Form - B\r\n(See Rule 21)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Act Name": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Rule / Form": "Form I\r\n[See Rule 12]\r\nRegister of Workmen",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form O\r\n[See Rule 19]\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form - P\r\n( See rule 20)\r\nNotice of Maximum Leave Accumulated",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "FORM \" C \"\r\n(See rule 21)\r\nRegister for fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Rule / Form": "Form 10\r\n(See Rule 12(1))\r\nMaternity Benefit Registers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Maharashtra Payment of Wages Rules, 1963",
            "Act Name": "The Maharashtra Payment of Wages Rules, 1963",
            "Rule / Form": "Form IV\r\n(See Rule 18)\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "Form - B\r\n(See Rule 21)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Act Name": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Rule / Form": "Form I\r\n[See Rule 12]\r\nRegister of Workmen",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form O\r\n[See Rule 19]\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form - P\r\n( See rule 20)\r\nNotice of Maximum Leave Accumulated",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "FORM \" C \"\r\n(See rule 21)\r\nRegister for fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Rule / Form": "Form 10\r\n(See Rule 12(1))\r\nMaternity Benefit Registers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Maharashtra Payment of Wages Rules, 1963",
            "Act Name": "The Maharashtra Payment of Wages Rules, 1963",
            "Rule / Form": "Form IV\r\n(See Rule 18)\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "Form - B\r\n(See Rule 21)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Act Name": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Rule / Form": "Form I\r\n[See Rule 12]\r\nRegister of Workmen",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form O\r\n[See Rule 19]\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form - P\r\n( See rule 20)\r\nNotice of Maximum Leave Accumulated",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "FORM \" C \"\r\n(See rule 21)\r\nRegister for fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Rule / Form": "Form 10\r\n(See Rule 12(1))\r\nMaternity Benefit Registers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Maharashtra Payment of Wages Rules, 1963",
            "Act Name": "The Maharashtra Payment of Wages Rules, 1963",
            "Rule / Form": "Form IV\r\n(See Rule 18)\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "Form - B\r\n(See Rule 21)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Act Name": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Rule / Form": "Form I\r\n[See Rule 12]\r\nRegister of Workmen",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form O\r\n[See Rule 19]\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form - P\r\n( See rule 20)\r\nNotice of Maximum Leave Accumulated",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "FORM \" C \"\r\n(See rule 21)\r\nRegister for fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Rule / Form": "Form 10\r\n(See Rule 12(1))\r\nMaternity Benefit Registers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Maharashtra Payment of Wages Rules, 1963",
            "Act Name": "The Maharashtra Payment of Wages Rules, 1963",
            "Rule / Form": "Form IV\r\n(See Rule 18)\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "Form - B\r\n(See Rule 21)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Act Name": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Rule / Form": "Form I\r\n[See Rule 12]\r\nRegister of Workmen",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form O\r\n[See Rule 19]\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form - P\r\n( See rule 20)\r\nNotice of Maximum Leave Accumulated",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "FORM \" C \"\r\n(See rule 21)\r\nRegister for fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Rule / Form": "Form 10\r\n(See Rule 12(1))\r\nMaternity Benefit Registers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Maharashtra Payment of Wages Rules, 1963",
            "Act Name": "The Maharashtra Payment of Wages Rules, 1963",
            "Rule / Form": "Form IV\r\n(See Rule 18)\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Bombay Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "Form - B\r\n(See Rule 21)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Maharashtra Minimum Wages Rules, 1963",
            "Act Name": "The Maharashtra Minimum Wages Rules, 1963",
            "Rule / Form": "Form No - II\r\n[See Rule 27(I)]\r\nMuster - Roll - Cum - Wages Register\r\nThe Maharashtra Minimum Wages Rules, 1963\r\n[Part II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Act Name": "The Maharashtra Workmen's Minimum House Rent Allowance Rules, 1990",
            "Rule / Form": "Form I\r\n[See Rule 12]\r\nRegister of Workmen",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form O\r\n[See Rule 19]\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Act Name": "The Maharashtra Shops and Establishment (Regulation of Employment and Condition of Service) Act, 2017",
            "Rule / Form": "Form - P\r\n( See rule 20)\r\nNotice of Maximum Leave Accumulated",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Act Name": "The Maharashtra Labour Welfare Fund Act & Rules, 1953",
            "Rule / Form": "FORM \" C \"\r\n(See rule 21)\r\nRegister for fines and unpaid accumulations for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Maharashtra) Rules, 1965",
            "Rule / Form": "Form 10\r\n(See Rule 12(1))\r\nMaternity Benefit Registers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Maharashtra"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Act Name": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Rule / Form": "Form - II\r\n[See Rule 22(4)]\r\nRegister of deductions for damage or loss caused to the emloyer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form N\r\n[See Rules 20 (1) and 21 (2) ]\r\nRegister of Employees Attendance, Wages, Over-time, Fine of other deductions etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Act Name": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Rule / Form": "FORM V\r\n[Rule 29(5)]\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form I\r\n[See Rules 13 (1) and 20 (4)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form J\r\nSee Rule 13(2)\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Rule / Form": "Form - L\r\n[See Rule 15 (1)]\r\nRegister of Showing Dates of Lime - Washing Etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Act Name": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Rule / Form": "FORM E\r\n[See Rule 33]\r\nRegister of unpaid accumulation and fines for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Act Name": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Rule / Form": "Form - II\r\n[See Rule 22(4)]\r\nRegister of deductions for damage or loss caused to the emloyer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form N\r\n[See Rules 20 (1) and 21 (2) ]\r\nRegister of Employees Attendance, Wages, Over-time, Fine of other deductions etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Act Name": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Rule / Form": "FORM V\r\n[Rule 29(5)]\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form I\r\n[See Rules 13 (1) and 20 (4)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form J\r\nSee Rule 13(2)\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Rule / Form": "Form - L\r\n[See Rule 15 (1)]\r\nRegister of Showing Dates of Lime - Washing Etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Act Name": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Rule / Form": "FORM E\r\n[See Rule 33]\r\nRegister of unpaid accumulation and fines for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Act Name": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Rule / Form": "Form - II\r\n[See Rule 22(4)]\r\nRegister of deductions for damage or loss caused to the emloyer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form N\r\n[See Rules 20 (1) and 21 (2) ]\r\nRegister of Employees Attendance, Wages, Over-time, Fine of other deductions etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Act Name": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Rule / Form": "FORM V\r\n[Rule 29(5)]\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form I\r\n[See Rules 13 (1) and 20 (4)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form J\r\nSee Rule 13(2)\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Rule / Form": "Form - L\r\n[See Rule 15 (1)]\r\nRegister of Showing Dates of Lime - Washing Etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Act Name": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Rule / Form": "FORM E\r\n[See Rule 33]\r\nRegister of unpaid accumulation and fines for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Act Name": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Rule / Form": "Form - II\r\n[See Rule 22(4)]\r\nRegister of deductions for damage or loss caused to the emloyer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form N\r\n[See Rules 20 (1) and 21 (2) ]\r\nRegister of Employees Attendance, Wages, Over-time, Fine of other deductions etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Act Name": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Rule / Form": "FORM V\r\n[Rule 29(5)]\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form I\r\n[See Rules 13 (1) and 20 (4)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form J\r\nSee Rule 13(2)\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Rule / Form": "Form - L\r\n[See Rule 15 (1)]\r\nRegister of Showing Dates of Lime - Washing Etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Act Name": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Rule / Form": "FORM E\r\n[See Rule 33]\r\nRegister of unpaid accumulation and fines for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Act Name": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Rule / Form": "Form - II\r\n[See Rule 22(4)]\r\nRegister of deductions for damage or loss caused to the emloyer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form N\r\n[See Rules 20 (1) and 21 (2) ]\r\nRegister of Employees Attendance, Wages, Over-time, Fine of other deductions etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Act Name": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Rule / Form": "FORM V\r\n[Rule 29(5)]\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form I\r\n[See Rules 13 (1) and 20 (4)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form J\r\nSee Rule 13(2)\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Rule / Form": "Form - L\r\n[See Rule 15 (1)]\r\nRegister of Showing Dates of Lime - Washing Etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Act Name": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Rule / Form": "FORM E\r\n[See Rule 33]\r\nRegister of unpaid accumulation and fines for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Act Name": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Rule / Form": "Form - II\r\n[See Rule 22(4)]\r\nRegister of deductions for damage or loss caused to the emloyer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form N\r\n[See Rules 20 (1) and 21 (2) ]\r\nRegister of Employees Attendance, Wages, Over-time, Fine of other deductions etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Act Name": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Rule / Form": "FORM V\r\n[Rule 29(5)]\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form I\r\n[See Rules 13 (1) and 20 (4)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form J\r\nSee Rule 13(2)\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Rule / Form": "Form - L\r\n[See Rule 15 (1)]\r\nRegister of Showing Dates of Lime - Washing Etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Act Name": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Rule / Form": "FORM E\r\n[See Rule 33]\r\nRegister of unpaid accumulation and fines for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Act Name": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Rule / Form": "Form - II\r\n[See Rule 22(4)]\r\nRegister of deductions for damage or loss caused to the emloyer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form N\r\n[See Rules 20 (1) and 21 (2) ]\r\nRegister of Employees Attendance, Wages, Over-time, Fine of other deductions etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Act Name": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Rule / Form": "FORM V\r\n[Rule 29(5)]\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form I\r\n[See Rules 13 (1) and 20 (4)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form J\r\nSee Rule 13(2)\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Rule / Form": "Form - L\r\n[See Rule 15 (1)]\r\nRegister of Showing Dates of Lime - Washing Etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Act Name": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Rule / Form": "FORM E\r\n[See Rule 33]\r\nRegister of unpaid accumulation and fines for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Act Name": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Rule / Form": "Form - II\r\n[See Rule 22(4)]\r\nRegister of deductions for damage or loss caused to the emloyer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form N\r\n[See Rules 20 (1) and 21 (2) ]\r\nRegister of Employees Attendance, Wages, Over-time, Fine of other deductions etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Act Name": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Rule / Form": "FORM V\r\n[Rule 29(5)]\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form I\r\n[See Rules 13 (1) and 20 (4)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form J\r\nSee Rule 13(2)\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Rule / Form": "Form - L\r\n[See Rule 15 (1)]\r\nRegister of Showing Dates of Lime - Washing Etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Act Name": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Rule / Form": "FORM E\r\n[See Rule 33]\r\nRegister of unpaid accumulation and fines for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Act Name": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Rule / Form": "Form - II\r\n[See Rule 22(4)]\r\nRegister of deductions for damage or loss caused to the emloyer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form N\r\n[See Rules 20 (1) and 21 (2) ]\r\nRegister of Employees Attendance, Wages, Over-time, Fine of other deductions etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Act Name": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Rule / Form": "FORM V\r\n[Rule 29(5)]\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form I\r\n[See Rules 13 (1) and 20 (4)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form J\r\nSee Rule 13(2)\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Rule / Form": "Form - L\r\n[See Rule 15 (1)]\r\nRegister of Showing Dates of Lime - Washing Etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Act Name": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Rule / Form": "FORM E\r\n[See Rule 33]\r\nRegister of unpaid accumulation and fines for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Act Name": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Rule / Form": "Form - II\r\n[See Rule 22(4)]\r\nRegister of deductions for damage or loss caused to the emloyer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form N\r\n[See Rules 20 (1) and 21 (2) ]\r\nRegister of Employees Attendance, Wages, Over-time, Fine of other deductions etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Act Name": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Rule / Form": "FORM V\r\n[Rule 29(5)]\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form I\r\n[See Rules 13 (1) and 20 (4)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form J\r\nSee Rule 13(2)\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Rule / Form": "Form - L\r\n[See Rule 15 (1)]\r\nRegister of Showing Dates of Lime - Washing Etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Act Name": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Rule / Form": "FORM E\r\n[See Rule 33]\r\nRegister of unpaid accumulation and fines for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Act Name": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Rule / Form": "Form - II\r\n[See Rule 22(4)]\r\nRegister of deductions for damage or loss caused to the emloyer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form N\r\n[See Rules 20 (1) and 21 (2) ]\r\nRegister of Employees Attendance, Wages, Over-time, Fine of other deductions etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Act Name": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Rule / Form": "FORM V\r\n[Rule 29(5)]\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form I\r\n[See Rules 13 (1) and 20 (4)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form J\r\nSee Rule 13(2)\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Rule / Form": "Form - L\r\n[See Rule 15 (1)]\r\nRegister of Showing Dates of Lime - Washing Etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Act Name": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Rule / Form": "FORM E\r\n[See Rule 33]\r\nRegister of unpaid accumulation and fines for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Act Name": "The Minimum Wages Madhya Pradesh Rules, 1958",
            "Rule / Form": "Form - II\r\n[See Rule 22(4)]\r\nRegister of deductions for damage or loss caused to the emloyer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form N\r\n[See Rules 20 (1) and 21 (2) ]\r\nRegister of Employees Attendance, Wages, Over-time, Fine of other deductions etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Act Name": "The Madhya Pradesh Minimum Wages Rules, 1958",
            "Rule / Form": "FORM V\r\n[Rule 29(5)]\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Madhya Pradesh Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form I\r\n[See Rules 13 (1) and 20 (4)]\r\nRegister of Leave",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops and Establishments Act, 1958 with Rules, 1959",
            "Rule / Form": "Form J\r\nSee Rule 13(2)\r\nLeave Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Act Name": "The Madhya Pradesh Shops And Establishment Act, 1958 and Rules, 1959",
            "Rule / Form": "Form - L\r\n[See Rule 15 (1)]\r\nRegister of Showing Dates of Lime - Washing Etc.",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Act Name": "The Madhya Pradesh Shram Kalyan Nidhi Rules, 1984",
            "Rule / Form": "FORM E\r\n[See Rule 33]\r\nRegister of unpaid accumulation and fines for the year 2021",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Act Name": "The Maternity Benefit Act, 1961 and (Madhya Pradesh) Rules, 1965",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Madhya Pradesh"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Kerala Payment of Wages (General) Rules, 1958",
            "Act Name": "The Kerala Payment of Wages (General) Rules, 1958",
            "Rule / Form": "Form III\r\n[See Rule 17]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of Deductions for Damage or Loss caused to the employer by the neglet or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fine",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Rule / Form": "Form B\r\n[Rule 10 (1)]\r\nRegister of Employment\r\nThe Kerala Shops & Commercial Establishment Rules, 1961\r\nWhere opening and closing hours are ordinarily uniform",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form V\r\n[Rule 28 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 15 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "Kerala Minimum Wages Rules, 1958",
            "Act Name": "Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form XI\r\n[See Rule 29 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Rule / Form": "Form F\r\n[See Rule 10(9)]\r\nRegister of Holidays, Leave Etc., Granted",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Kerala Payment of Wages (General) Rules, 1958",
            "Act Name": "The Kerala Payment of Wages (General) Rules, 1958",
            "Rule / Form": "Form III\r\n[See Rule 17]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of Deductions for Damage or Loss caused to the employer by the neglet or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fine",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Rule / Form": "Form B\r\n[Rule 10 (1)]\r\nRegister of Employment\r\nThe Kerala Shops & Commercial Establishment Rules, 1961\r\nWhere opening and closing hours are ordinarily uniform",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form V\r\n[Rule 28 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 15 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "Kerala Minimum Wages Rules, 1958",
            "Act Name": "Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form XI\r\n[See Rule 29 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Rule / Form": "Form F\r\n[See Rule 10(9)]\r\nRegister of Holidays, Leave Etc., Granted",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Kerala Payment of Wages (General) Rules, 1958",
            "Act Name": "The Kerala Payment of Wages (General) Rules, 1958",
            "Rule / Form": "Form III\r\n[See Rule 17]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of Deductions for Damage or Loss caused to the employer by the neglet or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fine",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Rule / Form": "Form B\r\n[Rule 10 (1)]\r\nRegister of Employment\r\nThe Kerala Shops & Commercial Establishment Rules, 1961\r\nWhere opening and closing hours are ordinarily uniform",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form V\r\n[Rule 28 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 15 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "Kerala Minimum Wages Rules, 1958",
            "Act Name": "Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form XI\r\n[See Rule 29 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Rule / Form": "Form F\r\n[See Rule 10(9)]\r\nRegister of Holidays, Leave Etc., Granted",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Kerala Payment of Wages (General) Rules, 1958",
            "Act Name": "The Kerala Payment of Wages (General) Rules, 1958",
            "Rule / Form": "Form III\r\n[See Rule 17]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of Deductions for Damage or Loss caused to the employer by the neglet or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fine",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Rule / Form": "Form B\r\n[Rule 10 (1)]\r\nRegister of Employment\r\nThe Kerala Shops & Commercial Establishment Rules, 1961\r\nWhere opening and closing hours are ordinarily uniform",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form V\r\n[Rule 28 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 15 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "Kerala Minimum Wages Rules, 1958",
            "Act Name": "Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form XI\r\n[See Rule 29 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Rule / Form": "Form F\r\n[See Rule 10(9)]\r\nRegister of Holidays, Leave Etc., Granted",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Kerala Payment of Wages (General) Rules, 1958",
            "Act Name": "The Kerala Payment of Wages (General) Rules, 1958",
            "Rule / Form": "Form III\r\n[See Rule 17]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of Deductions for Damage or Loss caused to the employer by the neglet or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fine",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Rule / Form": "Form B\r\n[Rule 10 (1)]\r\nRegister of Employment\r\nThe Kerala Shops & Commercial Establishment Rules, 1961\r\nWhere opening and closing hours are ordinarily uniform",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form V\r\n[Rule 28 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 15 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "Kerala Minimum Wages Rules, 1958",
            "Act Name": "Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form XI\r\n[See Rule 29 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Rule / Form": "Form F\r\n[See Rule 10(9)]\r\nRegister of Holidays, Leave Etc., Granted",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Kerala Payment of Wages (General) Rules, 1958",
            "Act Name": "The Kerala Payment of Wages (General) Rules, 1958",
            "Rule / Form": "Form III\r\n[See Rule 17]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of Deductions for Damage or Loss caused to the employer by the neglet or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fine",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Rule / Form": "Form B\r\n[Rule 10 (1)]\r\nRegister of Employment\r\nThe Kerala Shops & Commercial Establishment Rules, 1961\r\nWhere opening and closing hours are ordinarily uniform",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form V\r\n[Rule 28 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 15 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "Kerala Minimum Wages Rules, 1958",
            "Act Name": "Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form XI\r\n[See Rule 29 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Rule / Form": "Form F\r\n[See Rule 10(9)]\r\nRegister of Holidays, Leave Etc., Granted",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Kerala Payment of Wages (General) Rules, 1958",
            "Act Name": "The Kerala Payment of Wages (General) Rules, 1958",
            "Rule / Form": "Form III\r\n[See Rule 17]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of Deductions for Damage or Loss caused to the employer by the neglet or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fine",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Rule / Form": "Form B\r\n[Rule 10 (1)]\r\nRegister of Employment\r\nThe Kerala Shops & Commercial Establishment Rules, 1961\r\nWhere opening and closing hours are ordinarily uniform",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form V\r\n[Rule 28 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 15 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "Kerala Minimum Wages Rules, 1958",
            "Act Name": "Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form XI\r\n[See Rule 29 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Rule / Form": "Form F\r\n[See Rule 10(9)]\r\nRegister of Holidays, Leave Etc., Granted",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Kerala Payment of Wages (General) Rules, 1958",
            "Act Name": "The Kerala Payment of Wages (General) Rules, 1958",
            "Rule / Form": "Form III\r\n[See Rule 17]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of Deductions for Damage or Loss caused to the employer by the neglet or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fine",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Rule / Form": "Form B\r\n[Rule 10 (1)]\r\nRegister of Employment\r\nThe Kerala Shops & Commercial Establishment Rules, 1961\r\nWhere opening and closing hours are ordinarily uniform",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form V\r\n[Rule 28 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 15 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "Kerala Minimum Wages Rules, 1958",
            "Act Name": "Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form XI\r\n[See Rule 29 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Rule / Form": "Form F\r\n[See Rule 10(9)]\r\nRegister of Holidays, Leave Etc., Granted",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Kerala Payment of Wages (General) Rules, 1958",
            "Act Name": "The Kerala Payment of Wages (General) Rules, 1958",
            "Rule / Form": "Form III\r\n[See Rule 17]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of Deductions for Damage or Loss caused to the employer by the neglet or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fine",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Rule / Form": "Form B\r\n[Rule 10 (1)]\r\nRegister of Employment\r\nThe Kerala Shops & Commercial Establishment Rules, 1961\r\nWhere opening and closing hours are ordinarily uniform",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form V\r\n[Rule 28 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 15 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "Kerala Minimum Wages Rules, 1958",
            "Act Name": "Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form XI\r\n[See Rule 29 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Rule / Form": "Form F\r\n[See Rule 10(9)]\r\nRegister of Holidays, Leave Etc., Granted",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Kerala Payment of Wages (General) Rules, 1958",
            "Act Name": "The Kerala Payment of Wages (General) Rules, 1958",
            "Rule / Form": "Form III\r\n[See Rule 17]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of Deductions for Damage or Loss caused to the employer by the neglet or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fine",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Rule / Form": "Form B\r\n[Rule 10 (1)]\r\nRegister of Employment\r\nThe Kerala Shops & Commercial Establishment Rules, 1961\r\nWhere opening and closing hours are ordinarily uniform",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form V\r\n[Rule 28 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 15 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "Kerala Minimum Wages Rules, 1958",
            "Act Name": "Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form XI\r\n[See Rule 29 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Rule / Form": "Form F\r\n[See Rule 10(9)]\r\nRegister of Holidays, Leave Etc., Granted",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Kerala Payment of Wages (General) Rules, 1958",
            "Act Name": "The Kerala Payment of Wages (General) Rules, 1958",
            "Rule / Form": "Form III\r\n[See Rule 17]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of Deductions for Damage or Loss caused to the employer by the neglet or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fine",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Rule / Form": "Form B\r\n[Rule 10 (1)]\r\nRegister of Employment\r\nThe Kerala Shops & Commercial Establishment Rules, 1961\r\nWhere opening and closing hours are ordinarily uniform",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form V\r\n[Rule 28 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 15 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "Kerala Minimum Wages Rules, 1958",
            "Act Name": "Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form XI\r\n[See Rule 29 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Rule / Form": "Form F\r\n[See Rule 10(9)]\r\nRegister of Holidays, Leave Etc., Granted",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Kerala Payment of Wages (General) Rules, 1958",
            "Act Name": "The Kerala Payment of Wages (General) Rules, 1958",
            "Rule / Form": "Form III\r\n[See Rule 17]\r\nRegister of advances made to employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form II\r\n[See Rule 21 (4)]\r\nRegister of Deductions for Damage or Loss caused to the employer by the neglet or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fine",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishment Rules, 1961",
            "Rule / Form": "Form B\r\n[Rule 10 (1)]\r\nRegister of Employment\r\nThe Kerala Shops & Commercial Establishment Rules, 1961\r\nWhere opening and closing hours are ordinarily uniform",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Kerala Minimum Wages Rules, 1958",
            "Act Name": "The Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form V\r\n[Rule 28 (2)]\r\nOvertime Register for Workers",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Act Name": "The Kerala Child Labour (Prohibition and Regulation) Rules, 1993",
            "Rule / Form": "Form A\r\n[See Rule 15 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "Kerala Minimum Wages Rules, 1958",
            "Act Name": "Kerala Minimum Wages Rules, 1958",
            "Rule / Form": "Form XI\r\n[See Rule 29 (1)]\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Act Name": "The Kerala Shops & Commercial Establishments Rules, 1961",
            "Rule / Form": "Form F\r\n[See Rule 10(9)]\r\nRegister of Holidays, Leave Etc., Granted",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Kerala) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Kerala"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM A\r\n(See Rule 29)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Act Name": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "Form 1\r\n[See rule 3(3)]\r\n(Register of employees placed under suspension)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - F\r\n(See Rule 8)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nRegister of leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - H\r\n(See Rule 9)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nLeave with wage Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM B\r\n[See Rule 29]\r\nRegister of Fines Realised and unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Act Name": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Rule / Form": "Form ‘A’\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM A\r\n(See Rule 29)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Act Name": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "Form 1\r\n[See rule 3(3)]\r\n(Register of employees placed under suspension)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - F\r\n(See Rule 8)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nRegister of leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - H\r\n(See Rule 9)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nLeave with wage Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM B\r\n[See Rule 29]\r\nRegister of Fines Realised and unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Act Name": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Rule / Form": "Form ‘A’\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM A\r\n(See Rule 29)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Act Name": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "Form 1\r\n[See rule 3(3)]\r\n(Register of employees placed under suspension)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - F\r\n(See Rule 8)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nRegister of leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - H\r\n(See Rule 9)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nLeave with wage Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM B\r\n[See Rule 29]\r\nRegister of Fines Realised and unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Act Name": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Rule / Form": "Form ‘A’\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM A\r\n(See Rule 29)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Act Name": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "Form 1\r\n[See rule 3(3)]\r\n(Register of employees placed under suspension)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - F\r\n(See Rule 8)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nRegister of leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - H\r\n(See Rule 9)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nLeave with wage Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM B\r\n[See Rule 29]\r\nRegister of Fines Realised and unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Act Name": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Rule / Form": "Form ‘A’\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM A\r\n(See Rule 29)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Act Name": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "Form 1\r\n[See rule 3(3)]\r\n(Register of employees placed under suspension)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - F\r\n(See Rule 8)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nRegister of leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - H\r\n(See Rule 9)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nLeave with wage Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM B\r\n[See Rule 29]\r\nRegister of Fines Realised and unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Act Name": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Rule / Form": "Form ‘A’\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM A\r\n(See Rule 29)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Act Name": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "Form 1\r\n[See rule 3(3)]\r\n(Register of employees placed under suspension)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - F\r\n(See Rule 8)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nRegister of leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - H\r\n(See Rule 9)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nLeave with wage Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM B\r\n[See Rule 29]\r\nRegister of Fines Realised and unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Act Name": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Rule / Form": "Form ‘A’\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM A\r\n(See Rule 29)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Act Name": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "Form 1\r\n[See rule 3(3)]\r\n(Register of employees placed under suspension)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - F\r\n(See Rule 8)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nRegister of leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - H\r\n(See Rule 9)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nLeave with wage Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM B\r\n[See Rule 29]\r\nRegister of Fines Realised and unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Act Name": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Rule / Form": "Form ‘A’\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM A\r\n(See Rule 29)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Act Name": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "Form 1\r\n[See rule 3(3)]\r\n(Register of employees placed under suspension)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - F\r\n(See Rule 8)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nRegister of leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - H\r\n(See Rule 9)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nLeave with wage Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM B\r\n[See Rule 29]\r\nRegister of Fines Realised and unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Act Name": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Rule / Form": "Form ‘A’\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM A\r\n(See Rule 29)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Act Name": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "Form 1\r\n[See rule 3(3)]\r\n(Register of employees placed under suspension)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - F\r\n(See Rule 8)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nRegister of leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - H\r\n(See Rule 9)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nLeave with wage Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM B\r\n[See Rule 29]\r\nRegister of Fines Realised and unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Act Name": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Rule / Form": "Form ‘A’\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM A\r\n(See Rule 29)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Act Name": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "Form 1\r\n[See rule 3(3)]\r\n(Register of employees placed under suspension)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - F\r\n(See Rule 8)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nRegister of leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - H\r\n(See Rule 9)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nLeave with wage Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM B\r\n[See Rule 29]\r\nRegister of Fines Realised and unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Act Name": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Rule / Form": "Form ‘A’\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM A\r\n(See Rule 29)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Act Name": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "Form 1\r\n[See rule 3(3)]\r\n(Register of employees placed under suspension)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - F\r\n(See Rule 8)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nRegister of leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - H\r\n(See Rule 9)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nLeave with wage Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM B\r\n[See Rule 29]\r\nRegister of Fines Realised and unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Act Name": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Rule / Form": "Form ‘A’\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM A\r\n(See Rule 29)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - I]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM 'T'\r\nCombined Muster Roll-cum-Register of Wages\r\n[See Rule 24(9-B) of the Karnataka Shops and Commercial Establishments Rules, 1963]\r\nin lieu of\r\n[Part - II]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Act Name": "The Karnataka Child Labour (Prohibition and Regulation) Rules, 1998",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "the Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "Form 1\r\n[See rule 3(3)]\r\n(Register of employees placed under suspension)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - F\r\n(See Rule 8)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nRegister of leave with wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Act Name": "The Karnataka Shops and Commercial Establishments Rules, 1963",
            "Rule / Form": "FORM - H\r\n(See Rule 9)\r\nThe Karnataka Shops and Commercial Establishments Rules, 1963\r\nLeave with wage Book",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Act Name": "The Karnataka Labour Welfare Fund Rules, 1968",
            "Rule / Form": "FORM B\r\n[See Rule 29]\r\nRegister of Fines Realised and unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Act Name": "The Maternity Benefit Act, 1961 and (Karnataka) Rules, 1966",
            "Rule / Form": "Form ‘A’\r\n(See Rule 3)\r\nMuster Roll",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Karnataka"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers\r\nThe Punjab Minimum Wages Rules, 1950",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Act Name": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]\r\nRegister of Children Employed\r\nThe Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021\r\nThe Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers\r\nThe Punjab Minimum Wages Rules, 1950",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Act Name": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]\r\nRegister of Children Employed\r\nThe Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021\r\nThe Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers\r\nThe Punjab Minimum Wages Rules, 1950",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Act Name": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]\r\nRegister of Children Employed\r\nThe Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021\r\nThe Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers\r\nThe Punjab Minimum Wages Rules, 1950",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Act Name": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]\r\nRegister of Children Employed\r\nThe Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021\r\nThe Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers\r\nThe Punjab Minimum Wages Rules, 1950",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Act Name": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]\r\nRegister of Children Employed\r\nThe Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021\r\nThe Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers\r\nThe Punjab Minimum Wages Rules, 1950",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Act Name": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]\r\nRegister of Children Employed\r\nThe Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021\r\nThe Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers\r\nThe Punjab Minimum Wages Rules, 1950",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Act Name": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]\r\nRegister of Children Employed\r\nThe Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021\r\nThe Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers\r\nThe Punjab Minimum Wages Rules, 1950",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Act Name": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]\r\nRegister of Children Employed\r\nThe Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021\r\nThe Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers\r\nThe Punjab Minimum Wages Rules, 1950",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Act Name": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]\r\nRegister of Children Employed\r\nThe Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021\r\nThe Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers\r\nThe Punjab Minimum Wages Rules, 1950",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Act Name": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]\r\nRegister of Children Employed\r\nThe Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021\r\nThe Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers\r\nThe Punjab Minimum Wages Rules, 1950",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Act Name": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]\r\nRegister of Children Employed\r\nThe Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021\r\nThe Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II - A\r\nRegister of Advances of Employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form II\r\n[Rule 21(4)]\r\nRegister of deduction for damage or loss caused to the employer by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form E\r\nRegister of Deductions\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form I\r\n[See Rule 21 (4)]\r\nRegister of Fines",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 & Rules, 1966",
            "Rule / Form": "FORM A\r\n(See Rule 22)\r\nRegister of Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "FORM C\r\nRegister of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Punjab Minimum Wages Rules, 1950",
            "Act Name": "The Punjab Minimum Wages Rules, 1950",
            "Rule / Form": "Form IV\r\n[Rule 25 (3)]\r\nOvertime Register for workers\r\nThe Punjab Minimum Wages Rules, 1950",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Act Name": "The Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Rule / Form": "Form A\r\n[See Rule 3 (1)]\r\nRegister of Children Employed\r\nThe Haryana Child Labour (Prohibition and Regulation) Rules, 2006",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Form D\r\nRegister of wages of Employees\r\n(Rule 5 of the Punjab Shops and Commercial Establishments Rules, 1958)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Act Name": "the Punjab Shops and Commercial Establishments Rules, 1958",
            "Rule / Form": "Register of Leave with Wages",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Act Name": "The Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Rule / Form": "Form B\r\n(See Rule 22)\r\nRegister of fines realized and unpaid accumulation for the year 2021\r\nThe Punjab Labour Welfare Fund Act, 1965 and Rules, 1966",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Act Name": "The Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Haryana) Rules, 1967",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Haryana"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Gujarat Payment of Wages Rules, 1963",
            "Act Name": "The Gujarat Payment of Wages Rules, 1963",
            "Rule / Form": "Form III\r\n[See Rule 17(3)]\r\nRegister of Advances made to employed persons\r\nThe Gujarat Payment of Wages Rules, 1963",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form-II\r\nRule 21(4)\r\nRegister of deductions for absence from duty or for damage or loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form I\r\n[Rule 21 (4)]\r\nRegister of Fines\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM A\r\n(See Rule 21)\r\nRegister of Wages\r\nThe Gujarat Labour Welfare Fund Rules, 1962",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - I)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV\r\n[Rule 25(2)]\r\nOvertime register for workers\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]\r\nThe Child Labour (Prohibition and Regulation) Rules, 1988",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV-A\r\n[See Rule 26 (1)]\r\nRegister of Wages\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Rule / Form": "Form N\r\n[See Rule 17]\r\nLeave Book\r\nThe Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM B\r\n[Rule 21]\r\nRegister of fines realized and unpaid accumulation",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Act Name": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Rule / Form": "FORM C\r\n[Rule 3AA]\r\nThe Labour Welfare Fund (Gujarat) Rules, 1962\r\nRegister of particular of employees in whose respect the unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jan",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "FORM L\r\n[See Rule 15]\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964\r\nRecord relating to the employment, dismissal, payment of bonus, etc.,\r\nwoman",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Gujarat Payment of Wages Rules, 1963",
            "Act Name": "The Gujarat Payment of Wages Rules, 1963",
            "Rule / Form": "Form III\r\n[See Rule 17(3)]\r\nRegister of Advances made to employed persons\r\nThe Gujarat Payment of Wages Rules, 1963",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form-II\r\nRule 21(4)\r\nRegister of deductions for absence from duty or for damage or loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form I\r\n[Rule 21 (4)]\r\nRegister of Fines\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM A\r\n(See Rule 21)\r\nRegister of Wages\r\nThe Gujarat Labour Welfare Fund Rules, 1962",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - I)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV\r\n[Rule 25(2)]\r\nOvertime register for workers\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]\r\nThe Child Labour (Prohibition and Regulation) Rules, 1988",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV-A\r\n[See Rule 26 (1)]\r\nRegister of Wages\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Rule / Form": "Form N\r\n[See Rule 17]\r\nLeave Book\r\nThe Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM B\r\n[Rule 21]\r\nRegister of fines realized and unpaid accumulation",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Act Name": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Rule / Form": "FORM C\r\n[Rule 3AA]\r\nThe Labour Welfare Fund (Gujarat) Rules, 1962\r\nRegister of particular of employees in whose respect the unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Feb",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "FORM L\r\n[See Rule 15]\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964\r\nRecord relating to the employment, dismissal, payment of bonus, etc.,\r\nwoman",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Gujarat Payment of Wages Rules, 1963",
            "Act Name": "The Gujarat Payment of Wages Rules, 1963",
            "Rule / Form": "Form III\r\n[See Rule 17(3)]\r\nRegister of Advances made to employed persons\r\nThe Gujarat Payment of Wages Rules, 1963",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form-II\r\nRule 21(4)\r\nRegister of deductions for absence from duty or for damage or loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form I\r\n[Rule 21 (4)]\r\nRegister of Fines\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM A\r\n(See Rule 21)\r\nRegister of Wages\r\nThe Gujarat Labour Welfare Fund Rules, 1962",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - I)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV\r\n[Rule 25(2)]\r\nOvertime register for workers\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]\r\nThe Child Labour (Prohibition and Regulation) Rules, 1988",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV-A\r\n[See Rule 26 (1)]\r\nRegister of Wages\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Rule / Form": "Form N\r\n[See Rule 17]\r\nLeave Book\r\nThe Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM B\r\n[Rule 21]\r\nRegister of fines realized and unpaid accumulation",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Act Name": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Rule / Form": "FORM C\r\n[Rule 3AA]\r\nThe Labour Welfare Fund (Gujarat) Rules, 1962\r\nRegister of particular of employees in whose respect the unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Mar",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "FORM L\r\n[See Rule 15]\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964\r\nRecord relating to the employment, dismissal, payment of bonus, etc.,\r\nwoman",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Gujarat Payment of Wages Rules, 1963",
            "Act Name": "The Gujarat Payment of Wages Rules, 1963",
            "Rule / Form": "Form III\r\n[See Rule 17(3)]\r\nRegister of Advances made to employed persons\r\nThe Gujarat Payment of Wages Rules, 1963",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form-II\r\nRule 21(4)\r\nRegister of deductions for absence from duty or for damage or loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form I\r\n[Rule 21 (4)]\r\nRegister of Fines\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM A\r\n(See Rule 21)\r\nRegister of Wages\r\nThe Gujarat Labour Welfare Fund Rules, 1962",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - I)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV\r\n[Rule 25(2)]\r\nOvertime register for workers\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]\r\nThe Child Labour (Prohibition and Regulation) Rules, 1988",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV-A\r\n[See Rule 26 (1)]\r\nRegister of Wages\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Rule / Form": "Form N\r\n[See Rule 17]\r\nLeave Book\r\nThe Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM B\r\n[Rule 21]\r\nRegister of fines realized and unpaid accumulation",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Act Name": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Rule / Form": "FORM C\r\n[Rule 3AA]\r\nThe Labour Welfare Fund (Gujarat) Rules, 1962\r\nRegister of particular of employees in whose respect the unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Apr",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "FORM L\r\n[See Rule 15]\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964\r\nRecord relating to the employment, dismissal, payment of bonus, etc.,\r\nwoman",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Gujarat Payment of Wages Rules, 1963",
            "Act Name": "The Gujarat Payment of Wages Rules, 1963",
            "Rule / Form": "Form III\r\n[See Rule 17(3)]\r\nRegister of Advances made to employed persons\r\nThe Gujarat Payment of Wages Rules, 1963",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form-II\r\nRule 21(4)\r\nRegister of deductions for absence from duty or for damage or loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form I\r\n[Rule 21 (4)]\r\nRegister of Fines\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM A\r\n(See Rule 21)\r\nRegister of Wages\r\nThe Gujarat Labour Welfare Fund Rules, 1962",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - I)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV\r\n[Rule 25(2)]\r\nOvertime register for workers\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]\r\nThe Child Labour (Prohibition and Regulation) Rules, 1988",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV-A\r\n[See Rule 26 (1)]\r\nRegister of Wages\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Rule / Form": "Form N\r\n[See Rule 17]\r\nLeave Book\r\nThe Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM B\r\n[Rule 21]\r\nRegister of fines realized and unpaid accumulation",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Act Name": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Rule / Form": "FORM C\r\n[Rule 3AA]\r\nThe Labour Welfare Fund (Gujarat) Rules, 1962\r\nRegister of particular of employees in whose respect the unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-May",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "FORM L\r\n[See Rule 15]\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964\r\nRecord relating to the employment, dismissal, payment of bonus, etc.,\r\nwoman",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Gujarat Payment of Wages Rules, 1963",
            "Act Name": "The Gujarat Payment of Wages Rules, 1963",
            "Rule / Form": "Form III\r\n[See Rule 17(3)]\r\nRegister of Advances made to employed persons\r\nThe Gujarat Payment of Wages Rules, 1963",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form-II\r\nRule 21(4)\r\nRegister of deductions for absence from duty or for damage or loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form I\r\n[Rule 21 (4)]\r\nRegister of Fines\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM A\r\n(See Rule 21)\r\nRegister of Wages\r\nThe Gujarat Labour Welfare Fund Rules, 1962",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - I)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV\r\n[Rule 25(2)]\r\nOvertime register for workers\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]\r\nThe Child Labour (Prohibition and Regulation) Rules, 1988",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV-A\r\n[See Rule 26 (1)]\r\nRegister of Wages\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Rule / Form": "Form N\r\n[See Rule 17]\r\nLeave Book\r\nThe Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM B\r\n[Rule 21]\r\nRegister of fines realized and unpaid accumulation",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Act Name": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Rule / Form": "FORM C\r\n[Rule 3AA]\r\nThe Labour Welfare Fund (Gujarat) Rules, 1962\r\nRegister of particular of employees in whose respect the unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jun",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "FORM L\r\n[See Rule 15]\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964\r\nRecord relating to the employment, dismissal, payment of bonus, etc.,\r\nwoman",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Gujarat Payment of Wages Rules, 1963",
            "Act Name": "The Gujarat Payment of Wages Rules, 1963",
            "Rule / Form": "Form III\r\n[See Rule 17(3)]\r\nRegister of Advances made to employed persons\r\nThe Gujarat Payment of Wages Rules, 1963",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form-II\r\nRule 21(4)\r\nRegister of deductions for absence from duty or for damage or loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form I\r\n[Rule 21 (4)]\r\nRegister of Fines\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM A\r\n(See Rule 21)\r\nRegister of Wages\r\nThe Gujarat Labour Welfare Fund Rules, 1962",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - I)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV\r\n[Rule 25(2)]\r\nOvertime register for workers\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]\r\nThe Child Labour (Prohibition and Regulation) Rules, 1988",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV-A\r\n[See Rule 26 (1)]\r\nRegister of Wages\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Rule / Form": "Form N\r\n[See Rule 17]\r\nLeave Book\r\nThe Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM B\r\n[Rule 21]\r\nRegister of fines realized and unpaid accumulation",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Act Name": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Rule / Form": "FORM C\r\n[Rule 3AA]\r\nThe Labour Welfare Fund (Gujarat) Rules, 1962\r\nRegister of particular of employees in whose respect the unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Jul",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "FORM L\r\n[See Rule 15]\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964\r\nRecord relating to the employment, dismissal, payment of bonus, etc.,\r\nwoman",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Gujarat Payment of Wages Rules, 1963",
            "Act Name": "The Gujarat Payment of Wages Rules, 1963",
            "Rule / Form": "Form III\r\n[See Rule 17(3)]\r\nRegister of Advances made to employed persons\r\nThe Gujarat Payment of Wages Rules, 1963",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form-II\r\nRule 21(4)\r\nRegister of deductions for absence from duty or for damage or loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form I\r\n[Rule 21 (4)]\r\nRegister of Fines\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM A\r\n(See Rule 21)\r\nRegister of Wages\r\nThe Gujarat Labour Welfare Fund Rules, 1962",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - I)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV\r\n[Rule 25(2)]\r\nOvertime register for workers\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]\r\nThe Child Labour (Prohibition and Regulation) Rules, 1988",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV-A\r\n[See Rule 26 (1)]\r\nRegister of Wages\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Rule / Form": "Form N\r\n[See Rule 17]\r\nLeave Book\r\nThe Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM B\r\n[Rule 21]\r\nRegister of fines realized and unpaid accumulation",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Act Name": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Rule / Form": "FORM C\r\n[Rule 3AA]\r\nThe Labour Welfare Fund (Gujarat) Rules, 1962\r\nRegister of particular of employees in whose respect the unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Aug",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "FORM L\r\n[See Rule 15]\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964\r\nRecord relating to the employment, dismissal, payment of bonus, etc.,\r\nwoman",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Gujarat Payment of Wages Rules, 1963",
            "Act Name": "The Gujarat Payment of Wages Rules, 1963",
            "Rule / Form": "Form III\r\n[See Rule 17(3)]\r\nRegister of Advances made to employed persons\r\nThe Gujarat Payment of Wages Rules, 1963",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form-II\r\nRule 21(4)\r\nRegister of deductions for absence from duty or for damage or loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form I\r\n[Rule 21 (4)]\r\nRegister of Fines\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM A\r\n(See Rule 21)\r\nRegister of Wages\r\nThe Gujarat Labour Welfare Fund Rules, 1962",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - I)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV\r\n[Rule 25(2)]\r\nOvertime register for workers\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]\r\nThe Child Labour (Prohibition and Regulation) Rules, 1988",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV-A\r\n[See Rule 26 (1)]\r\nRegister of Wages\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Rule / Form": "Form N\r\n[See Rule 17]\r\nLeave Book\r\nThe Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM B\r\n[Rule 21]\r\nRegister of fines realized and unpaid accumulation",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Act Name": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Rule / Form": "FORM C\r\n[Rule 3AA]\r\nThe Labour Welfare Fund (Gujarat) Rules, 1962\r\nRegister of particular of employees in whose respect the unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Sep",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "FORM L\r\n[See Rule 15]\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964\r\nRecord relating to the employment, dismissal, payment of bonus, etc.,\r\nwoman",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Gujarat Payment of Wages Rules, 1963",
            "Act Name": "The Gujarat Payment of Wages Rules, 1963",
            "Rule / Form": "Form III\r\n[See Rule 17(3)]\r\nRegister of Advances made to employed persons\r\nThe Gujarat Payment of Wages Rules, 1963",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form-II\r\nRule 21(4)\r\nRegister of deductions for absence from duty or for damage or loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form I\r\n[Rule 21 (4)]\r\nRegister of Fines\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM A\r\n(See Rule 21)\r\nRegister of Wages\r\nThe Gujarat Labour Welfare Fund Rules, 1962",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - I)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV\r\n[Rule 25(2)]\r\nOvertime register for workers\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]\r\nThe Child Labour (Prohibition and Regulation) Rules, 1988",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV-A\r\n[See Rule 26 (1)]\r\nRegister of Wages\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Rule / Form": "Form N\r\n[See Rule 17]\r\nLeave Book\r\nThe Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM B\r\n[Rule 21]\r\nRegister of fines realized and unpaid accumulation",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Act Name": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Rule / Form": "FORM C\r\n[Rule 3AA]\r\nThe Labour Welfare Fund (Gujarat) Rules, 1962\r\nRegister of particular of employees in whose respect the unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Oct",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "FORM L\r\n[See Rule 15]\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964\r\nRecord relating to the employment, dismissal, payment of bonus, etc.,\r\nwoman",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Gujarat Payment of Wages Rules, 1963",
            "Act Name": "The Gujarat Payment of Wages Rules, 1963",
            "Rule / Form": "Form III\r\n[See Rule 17(3)]\r\nRegister of Advances made to employed persons\r\nThe Gujarat Payment of Wages Rules, 1963",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form-II\r\nRule 21(4)\r\nRegister of deductions for absence from duty or for damage or loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form I\r\n[Rule 21 (4)]\r\nRegister of Fines\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM A\r\n(See Rule 21)\r\nRegister of Wages\r\nThe Gujarat Labour Welfare Fund Rules, 1962",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - I)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV\r\n[Rule 25(2)]\r\nOvertime register for workers\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]\r\nThe Child Labour (Prohibition and Regulation) Rules, 1988",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV-A\r\n[See Rule 26 (1)]\r\nRegister of Wages\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Rule / Form": "Form N\r\n[See Rule 17]\r\nLeave Book\r\nThe Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM B\r\n[Rule 21]\r\nRegister of fines realized and unpaid accumulation",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Act Name": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Rule / Form": "FORM C\r\n[Rule 3AA]\r\nThe Labour Welfare Fund (Gujarat) Rules, 1962\r\nRegister of particular of employees in whose respect the unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Nov",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "FORM L\r\n[See Rule 15]\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964\r\nRecord relating to the employment, dismissal, payment of bonus, etc.,\r\nwoman",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Gujarat Payment of Wages Rules, 1963",
            "Act Name": "The Gujarat Payment of Wages Rules, 1963",
            "Rule / Form": "Form III\r\n[See Rule 17(3)]\r\nRegister of Advances made to employed persons\r\nThe Gujarat Payment of Wages Rules, 1963",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form-II\r\nRule 21(4)\r\nRegister of deductions for absence from duty or for damage or loss caused to the employer, by the neglect or default of the employed persons",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Act Name": "The Ease of Compliance to Maintain Registers under various Labour Laws Rules, 2017",
            "Rule / Form": "FORM B\r\n[See rule 2(1)]\r\nFORMAT FOR WAGE REGISTER",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form I\r\n[Rule 21 (4)]\r\nRegister of Fines\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM A\r\n(See Rule 21)\r\nRegister of Wages\r\nThe Gujarat Labour Welfare Fund Rules, 1962",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - I)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020",
            "Rule / Form": "Form - P\r\n[See Rule 26]\r\nMuster - Roll - Cum - Wages Register\r\nThe Gujarat Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2019 and Rules, 2020\r\n(PART - II)",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV\r\n[Rule 25(2)]\r\nOvertime register for workers\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Act Name": "The Child Labour (Prohibition and Regulation) Rules, 1988",
            "Rule / Form": "Form A\r\n[See Rule 16(1)]\r\nThe Child Labour (Prohibition and Regulation) Rules, 1988",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Gujarat Minimum Wages Rules, 1961",
            "Act Name": "The Gujarat Minimum Wages Rules, 1961",
            "Rule / Form": "Form IV-A\r\n[See Rule 26 (1)]\r\nRegister of Wages\r\nThe Gujarat Minimum Wages Rules, 1961",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Act Name": "The Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Rule / Form": "Form N\r\n[See Rule 17]\r\nLeave Book\r\nThe Gujarat Shops and Establishments (Regulation Of Employment And Conditions Of Services) Act, 2019 And Rules, 2020",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Act Name": "The Gujarat Labour Welfare Fund Rules, 1962",
            "Rule / Form": "FORM B\r\n[Rule 21]\r\nRegister of fines realized and unpaid accumulation",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Act Name": "The Labour Welfare Fund (Gujarat) Rules, 1962",
            "Rule / Form": "FORM C\r\n[Rule 3AA]\r\nThe Labour Welfare Fund (Gujarat) Rules, 1962\r\nRegister of particular of employees in whose respect the unpaid accumulations",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "Form A\r\n(See Rule 3)\r\nMuster Roll\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        },
        {
            "Due Date": "7-Dec",
            "Simplified Name of Compliance": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Act Name": "The Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964",
            "Rule / Form": "FORM L\r\n[See Rule 15]\r\nThe Maternity Benefit Act, 1961 and (Gujarat) Rules, 1964\r\nRecord relating to the employment, dismissal, payment of bonus, etc.,\r\nwoman",
            "Compliance Type": "Registers",
            "Periodicity": "Monthly",
            "Responsibility": "Establishment",
            "State": "Gujarat"
        }
    ];
    
    }
