import React from 'react';
class Attendance_Log extends React.Component{
    componentWillReceiveProps(props){
     // alert(JSON.stringify(props.logList));

    }
    render(){
        return(<div class="modal fade" id="viewAttendLog">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="card card-primary">
                    <div class="card-header">
                        <h3 class="card-title">Daily Attendance</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                   
                    <form id="frmViewAttendLog" role="form">
                       
                        <div className="card-body">
                        <table className="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap table-responsive">
                        <tbody>
                        <tr>
            <td>Date</td>
            <td>Attendance</td>
            <td>Time In</td>
            <td>Time In (Location)</td>
            <td>Time Out</td>
            <td>Time Out (Location)</td>
            <td>Track History</td>
        </tr>
        {(this.props.logList.length!=0)?this.props.logList.map((item,index)=>{
            return <tr>
                <td>{item.att_dt_time}</td>
                <td>{item.att_status}</td>
                <td>{item.att_dt_time}</td>
                <td>{"location:"+"("+item.time_in_loc+")"}</td>
                <td>{item.time_out}</td>
                <td>{"timeOut:"+"("+item.time_out_loc+")"}</td>
                <td>{"track history"}</td>
            </tr>
        }):''}
        </tbody>
                        </table>
                          
                         
                        </div>
                        
    
                        {/* <div class="card-footer">
                            <button type="button" class="btn _btnSetBank btn-primary">Submit</button>
                        </div> */}
                    </form>
                </div>
    
    
            </div>
           
        </div>
        
    </div>)
    }
}
export default Attendance_Log;