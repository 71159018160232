import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Option_Module from '../user_pages/option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';

import Wa_Credit_Balance_Comp from '../components/wa_credit_balance';


let loginUser="";
let userObject="";
let companyName="";
let clsCouponMaster;

let rf="0";
class Coupon_Master extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ listUsers:[],crediUser:{},creditBalance:[],masterBalance:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsCouponMaster=this;
      }
      resetFilters(){
        $("#ser_text").val("");
        clsCouponMaster.getCouponMaster();
      }

      getCouponMaster(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getCouponMaster"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:"3"});
        map.push({name:"ser_userType",value:$("#ser_userType").val()});
        map.push({name:"ser_text",value:$("#ser_text").val()});
       
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getCouponMaster";
        CallMapMethod_CBack(clsCouponMaster,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                clsCouponMaster.setState({listUsers:listData},()=>{
                 // window.getPagination("#tbData");
                 clsCouponMaster.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                 
                  if(rf!=="0"){
                    clsCouponMaster.getSearch(rf);
                    //alert("i m in setup "+rf);
                    $("#serLoanStatus").val(rf);
                  }
                });
              }
        });
      }
      okSucc(data,smg){
        if(data==="001"){
          //alert("record added successfully");
          Custom_Alert("Successful","Record Added Successfully",true);
          clsCouponMaster.getCouponMaster();
          $("#frmCreate").find("input[type=text]").val("");
        }else{
          //alert(smg);
          Custom_Alert("Not Working",smg,false);
        }
        // alert(data);
      }
okSuccCreditB(data,msg){
  //alert(data);
  if(data===-10){
    Custom_Alert("Validation","Fill Required Fileds",false);
    
    return;
  }else{
    //alert(data+msg);
    Custom_Alert("Completed",msg,true);
    
  }
    
}


componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.getCouponMaster();
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row content12 mt-2 mb-2">
{/* 
<div className="col-lg-2 col-md-2 col-sm-4 float-right">
<select  className="form-control form-control-sm" id="ser_userType" name="ser_userType">
           <option value="0">-User Type-</option>
           <option value="3">-User-</option>
          
         </select>
</div> */}

<div className='col-lg-4 col-md-4 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_text" name="ser_text" rf="#btnCouponSearch" className="_setSearchKeyUp keyEvent_btn form-control form-control-sm" placeholder='search' />
             </div>
         
         </div>

</div>

<div className="col-lg-2 col-md-2 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div onClick={()=>this.getCouponMaster()} id="btnCouponSearch" name="btnCouponSearch" style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <div onClick={()=>this.resetFilters()} className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>

</div>
    </div>    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Coupon List</b></h3>
                
                <a className="btn btn-sm btn-danger float-right" data-toggle="modal" data-target="#divCreateCoupon" href="javascript:void(0)">Add New</a>
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Coupon Code</th>
                      <th>Applicable</th>
                      <th>Apply Value</th>
                      <th>Final Amount</th>
                      <th>User Limit</th>
                      <th>Uses Limit</th>
                      <th>Min Invoice Amt</th>
                      <th>Max Dicount Amt</th> 
                      <th>Activation Date</th>
                      <th>Deactivation Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.listUsers.map((item,index)=>{
                       return(<tr>
                        <td>{index+1}</td>
                    
                  <td>{item.coupan_code}</td>
                  <td>{item.type}</td>
                  <td>{item.amt_val}</td>
                  <td>{item.final_val}</td>
                  <td>{item.per_user_limit}</td>
                  <td>{item.uses_limit}</td>
                  <td>{item.minimum_invoice_value}</td>
                  <td>{item.maximum_discount_limit}</td>
                  <td>{item.month_activation_date}</td>
                  <td>{item.month_deactivation_date}</td>
                  <td><span className="badge bg-danger dash_rejected_claim1">{item.status}</span></td>
                  <td>{/*<a data-toggle="modal" data-target="#divEmployee" href="javascript:void(0)">View</a> */}| <a  data-toggle="modal11" data-target="#divWaCreditBalance11" onClick={()=>this.onCreditBalanceSel(item)}  href="javascript:void(0)">Edit</a> |</td>
                    </tr>)
                  }))}
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
<div className="modal fade bd-example-modal-xl"  id="divCreateCoupon" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Add Product Vendor</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmCreateCoupon" name="frmCreateCoupon">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="addCouponMaster" />
                            <input type="hidden" name="m_type" id="m_type" value="addCouponMaster" />
                            <input type="hidden" name="type_id" id="type_id" value={"3"} />
                            <input type="hidden" name="upper_id" id="upper_id" value={userObject[0].app_user_id} />
                            <div className="card-body">
                                <div className="row">
                               
                                    <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">COUPON Code</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="coupan_code" name="coupan_code" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">COUPON Type</label>
                                            <div className="input-group input-group-sm">
                                                <select className="form-control required" id="type" name="type">
                                                    <option value={"0"}>-Select-</option>
                                                    <option value={"percentage"}>-Percentage-</option>
                                                    <option value={"total"}>-Total-</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Coupon Value<span>Amount</span></label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="amt_val" name="amt_val" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Final Value</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="final_val" name="final_val" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Per User Limit</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="per_user_limit" name="per_user_limit" className="form-control required" placeholder="Per User Limit" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Total Uses Limit</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="uses_limit" name="uses_limit" className="form-control required" placeholder="Uses Limit" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Min. Invoice Value</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="minimum_invoice_value" name="minimum_invoice_value" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Max. Discount Amount</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="maximum_discount_limit" name="maximum_discount_limit" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Activation Date</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="activation_date" name="activation_date" className="form-control required" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/MM/yyyy" data-mask placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Deactivation Date</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="deactivation_date" name="deactivation_date" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/MM/yyyy" data-mask className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Applied For</label>
                                            <div className="input-group input-group-sm">
                                                <select className="form-control required" id="applied_for" name="applied_for">
                                                    <option value={"0"}>-Select-</option>
                                                    <option value={"billing"}>-Products Amount-</option>
                                                    {/* <option value={"total"}>-Total-</option> */}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Password</label>
                                            <div className="input-group input-group-sm">
                                                <input type="password" id="password" name="password" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                    </div> */}
                                   
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>
    
          </div>)
      }
}
export default Coupon_Master;