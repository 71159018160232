import $, { extend } from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,RespBkMthdMapp} from '../function_lib/common_lib';
import Header from './header';
import Footer from './footer';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';

import AddLeaveRequest from '../components/add_leave_request';
import Attendance_Log from '../components/attendance_log';

let loginUser="";
let userObject="";
let companyName="";
let listItems;
let clsObject;
class Attendance_List extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        this.state={ stListItems:"",isAllow:false,stLogItems:[] };
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          companyName=userObject[0].company_name;
          //window.location.href="./Dashboard";
        }
      }
      callBackNg(data,msg){
         //alert(msg);
         //$("#tbData").html(msg);
         //return;
       if(data.trim()!=="" && data.trim()!=="0"){
          listItems=JSON.parse(data);
          this.setState({stListItems:listItems,isAllow:true},()=>{
            RespBkMthdMapp(clsObject,"getEmpAttendance","shCollapse");
          });
          //alert(JSON.stringify(this.state.stListItems));
          this.forceUpdateHandler();
       }
   }
   getEmpAttendance(refId){
    var map=[];
    map.push({name:"curl_type",value:"getAttendanceListLog"});
    map.push({name:"res_func",value:"callBackLogNg"});
    map.push({name:"company_id",value:userObject[0].app_user_id});
    map.push({name:"non_enc_emp_id",value:refId});
    map.push({name:"month_nm",value:"11"});
    map["res_func"]="callBackLogNg";
    map["curl_type"]="getAttendanceListLog";
    CallMapMethod(this,map);
   }
   callBackLogNg(data,msg){
     if(data.trim()!=="" && data.trim()!=="0"){
      this.setState({stLogItems:JSON.parse(data)});
      this.forceUpdateHandler();
     }
     //alert(data);
   } 
componentDidMount(){
  clsObject=this;
    var map=[];
    map.push({name:"curl_type",value:"getAttendanceList"});
    map.push({name:"res_func",value:"callBackNg"});
    map.push({name:"company_id",value:userObject[0].app_user_id});
    map.push({name:"emp_id",value:userObject[0].id});
    map.push({name:"month_nm",value:"11"});
    map["res_func"]="callBackNg";
    map["curl_type"]="getAttendanceList";
    CallMapMethod(this,map);
}

      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row">
<div className="col-md-2"> 
        
        {/* <!-- Widget: user widget style 2 --> */}
        <div className="card card-widget widget-user-2 shadow "> 
          {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}
          
          <div className="card-footer p-0">
            <ul className="nav flex-column  table-hover">
              <li className="nav-item"> <a href="javascript:void(0)" className="nav-link  hover"><h6><i className="fa fa-user-alt font-small text-orange"></i><span class="float-left badge bg-primary" data-toggle="modal" data-target="#divLeaveRequest">Add New</span></h6> </a> </li>
            </ul>
          </div>
          
          {/* <!-- /.widget-user -->  */}
        </div>
        
        {/* <!-- Widget: user widget style 2 --> */}
        <div className="card card-widget widget-user-2 shadow "> 
          {/* <!-- Add the bg color to the header using any of the bg-* classNamees --> */}
          
          <div className="card-footer p-0">
            <ul className="nav flex-column">
              <li className="nav-item"><h6> <a href="#" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i>Total Requests<span className="float-right badge bg-primary">2</span> </a></h6> </li>
              <li className="nav-item"><h6> <a href="#" className="nav-link hover"><i className="fa fa-angle-double-right text-orange"></i> Pending for Approval <span className="float-right badge bg-info">5</span> </a></h6> </li>
            
            </ul>
          </div>
          
          {/* <!-- /.widget-user -->  */}
        </div>
      </div>
      <div className="col-lg-10 col-md-10 col-sm-12 col-12">
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Attendance List</b></h3>
              </div>
                <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap table-responsive">
                  <thead>
                  <tr class="border">
                        <th>Employee Name</th>
                        <th>Month</th>
                        <th>Present</th>
                                                                <th>Absent</th>
                                                                <th>Leave</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                  </thead>
                  <tbody id="tbData">
                  {(this.state.isAllow==true) ? this.state.stListItems.map((item,index)=>{
                     return <tr>
<td>
    <div className="user-block">
        <img className="img-circle" src={defaultPic} alt="User Image" />
        <span className="username"><a href="#">{item.emp_name}</a></span>
        <span className="description">Emp Code:{item.emp_code} Mobile:{item.contact_no}</span>
    </div>
</td>
<td>{item.month_nm}</td>
<td>{item.Present}</td>
<td>{item.Absent}</td>
<td>{item.Leave}</td>
<td></td>
<td>
    <div class="card-tools">
        <a param1={item.emp_id} data-toggle="modal" data-target="#viewAttendLog" class="btn btn-tool shCollapse" aria-expanded="false">
            View
        </a>
    </div>
</td>

</tr>
                  }
                  ):''
                 }
                  </tbody>
                </table>
             
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>
<AddLeaveRequest />
<Attendance_Log logList={this.state.stLogItems} />
          </div>)
      }
}
export default Attendance_List;